import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  createAboutDplk,
  fetchDetailAboutDplk,
  updateAboutDplk,
  aboutDplkDetail,
} from 'actions/AboutDplk'
import { message } from 'antd'
import FormAboutDplkView from 'components/pages/aboutDplk/Form'
import Helper from 'utils/Helper'
import history from 'utils/history'
import withForms from 'utils/hocs/withForms'

export function mapStateToProps(state) {
  const {
    isFecthing,
    detailAboutDplk,
    metaDetailAboutDplk,
  } = state.root.aboutDplk

  return {
    isFecthing,
    detailAboutDplk,
    metaDetailAboutDplk,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailAboutDplk: bindActionCreators(fetchDetailAboutDplk, dispatch),
  createAboutDplk: bindActionCreators(createAboutDplk, dispatch),
  updateAboutDplk: bindActionCreators(updateAboutDplk, dispatch),
  aboutDplkDetail: bindActionCreators(aboutDplkDetail, dispatch),
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withForms(),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('longDesc', 'setLongDesc', ''),
  withState('imageFile', 'setImageFile', ''),
  withState('imageThumbnail', 'setImageThumbnail', ''),
  withState('detailData', 'setDetailData', {}),
  withState('status', 'setStatus', 'inactive'),
  withState('isSubmit', 'setIsSubmit', false),
  withState('orderNumber', 'setOrderNumber', 0),
  withHandlers({
    onChangeOrder: props => (val) => {
      const value = Math.max(Number(0), Math.min(Number(10), Number(val)))
      props.setOrderNumber(value)
    },
    handleUpload: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setImageFile(file))
      }
      return false
    },
    handleUploadThumbnail: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setImageThumbnail(file))
      }
      return false
    },
    handleChangeEditor: props => (values) => {
      props.setLongDesc(values)
    },
    handleRadio: props => (e) => {
      const { value } = e.target
      props.setStatus(value)
    },
    onSubmit: props => (event) => {
      event.preventDefault()
      props.setIsSubmit(true)
      let payload
      if (props.match.params.id) {
        if (props.imageFile && (props.imageFile !== props.detailData.img_path)) {
          payload = {
            ...props.form,
            ...payload,
            img: props.imageFile,
          }
        }
        if (props.imageThumbnail && (props.imageThumbnail !== props.detailData.thumbnail_path)) {
          payload = {
            ...props.form,
            ...payload,
            thumbnail: props.imageThumbnail,
          }
        }
        payload = {
          ...props.form,
          ...payload,
          is_active: props.status === 'active',
          content: props.longDesc ? props.longDesc : props.form.content,
          order: parseInt(props.orderNumber, 10),
        }
        props.updateAboutDplk(payload, props.match.params.id)
          .then(() => {
            history.push('/about-dplk')
          }).catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
        /* if (payload.title && payload.short_description && payload.content && payload.is_active) {
          props.updateAboutDplk(payload, props.match.params.id)
            .then((res) => {
              history.push('/about-dplk')
            }).catch((err) => {
              message.error(err)
              props.setIsSubmit(false)
            })
        } else {
          message.error('Please complete the form')
          props.setIsSubmit(false)
        } */
      } else {
        payload = {
          ...props.form,
          order: parseInt(props.orderNumber, 10),
          is_active: props.status === 'active',
          img: props.imageFile,
          thumbnail: props.imageThumbnail,
          content: props.longDesc,
        }
        if (props.form.title && props.orderNumber && props.form.short_description && props.longDesc && props.status && props.imageFile && props.imageThumbnail) {
          props.createAboutDplk(payload)
            .then(() => {
              history.push('/about-dplk')
            }).catch((err) => {
              message.error(err)
              props.setIsSubmit(false)
            })
        } else {
          message.error('Please complete the form')
          props.setIsSubmit(false)
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, /* detailAboutDplk, */ setDetailData, setOrderNumber,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Category', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'DPLK Anda Form')

      if (match.params.id) {
        setIsEdit(true)
        this.props.fetchDetailAboutDplk(match.params.id)
          .then((res) => {
            setDetailData(res)
            setOrderNumber(res.order)
            this.props.setForm({
              title: res.title,
              short_description: res.short_description,
              content: res.content,
            })
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        this.props.aboutDplkDetail({})
      }
    },
    componentDidUpdate(prevProps) {
      const {
        detailData, /* form, */ setImageFile, setImageThumbnail,
      } = this.props

      if (detailData !== prevProps.detailData) {
        setImageFile(detailData.img_path)
        setImageThumbnail(detailData.thumbnail_path)
        this.props.setStatus(detailData.status)
      }
    },
  }),
)(FormAboutDplkView)
