import {
  ABOUT_REQUEST,
  ABOUT_SUCCESS,
  ABOUT_FAILURE,
  ABOUT_UPDATED,
  ABOUT_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataAbout: [],
  detailAbout: {},
  metaAbout: {
    total_count: 0,
    current: 0,
  },
}

export default function about(state = initialState, action) {
  switch (action.type) {
    case ABOUT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ABOUT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataAbout: action.data,
        metaAbout: action.meta,
      }
    case ABOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case ABOUT_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataAbout: state.dataAbout.filter(item => item.id !== action.data.id),
      }
    case ABOUT_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailAbout: action.data,
      }
    default:
      return state
  }
}
