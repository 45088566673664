import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import BannerAppsPage from 'containers/pages/webManagement/bannerApps/List'
import BannerAppsForm from 'containers/pages/webManagement/bannerApps/Form'

const bannerApps = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/web-management/banner-apps') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementbanner-apps-read') > -1) {
      return <BannerAppsPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementbanner-apps-write') > -1) {
      return <BannerAppsForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

bannerApps.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default bannerApps
