import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import BenefitsPage from 'containers/pages/benefits/List'
import BenefitsForm from 'containers/pages/benefits/Form'
import BenefitsDetail from 'containers/pages/benefits/detail'

const Colek = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/benefit') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pelajari-manfaat-read') > -1) {
      return <BenefitsPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pelajari-manfaat-write') > -1) {
      return <BenefitsForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pelajari-manfaat-read') > -1) {
      return <BenefitsDetail location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Colek.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Colek
