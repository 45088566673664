import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchDenomOttocash, deleteDenom } from 'actions/DanaOttocash'
import ManagementOttocashView from 'components/pages/danaOttocash/management/List'
// import { pickBy, identity, isNull } from 'lodash'
// import qs from 'query-string'
import { message } from 'antd'

// export function mapStateToProps(state) {}
export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDenomOttocash: bindActionCreators(fetchDenomOttocash, dispatch),
  deleteDenom: bindActionCreators(deleteDenom, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('denomList', 'setDenomList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    getDenomList: props => () => {
      props.fetchDenomOttocash()
        .then((result) => {
          const { data, meta } = result
          props.setDenomList({
            data,
            meta,
            isFetching: false,
          })
        })
        .catch((error) => {
          props.setDenomList({
            data: [],
            meta: {},
            isFetching: false,
          })
          message.error(error)
        })
    },
  }),
  // withHandlers({
  //   onLoad: props => (isSearch) => {
  //     const { page, search } = props.stateSearch
  //     const payload = {
  //       page: isSearch ? '' : page,
  //       search: search || '',
  //     }
  //      props.setIsFetching(true)
  //      props.fetchClaim(`?${qs.stringify(pickBy(payload, identity))}`)
  //   },
  // }),
  withHandlers({
    // handleSearch: props => (val) => {
    //   const { onLoad, setStateSearch, stateSearch } = props
    //   setStateSearch({
    //     ...stateSearch,
    //     search: val,
    //   })
    //   setTimeout(() => {
    //     onLoad(true)
    //   }, 300)
    // },
    // handlePage: props => (val) => {
    //   const { onLoad, setStateSearch, stateSearch } = props
    //   setStateSearch({
    //     ...stateSearch,
    //     page: val,
    //   })
    //   setTimeout(() => {
    //     onLoad()
    //   }, 300)
    // },
    handleDelete: props => (id) => {
      props.deleteDenom(id)
        .then((result) => {
          message.success(result.data)
          props.getDenomList()
        })
        .catch((error) => { message.error(error) })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getDenomList } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Management Dana Ottocash'])
      this.props.updateSiteConfigs({
        titlePage: 'Management Dana Ottocash',
        activePage: 'dana-ottocash/management-ottocash',
        activeSubPage: 'ottocash',
      })
      getDenomList()
    },
  }),
)(ManagementOttocashView)
