import API from 'utils/API'
import { message } from 'antd'
import {
  ULTRA_VOUCHER_BRAND_REQUEST,
  ULTRA_VOUCHER_BRAND_SUCCESS,
  ULTRA_VOUCHER_BRAND_FAILURE,
} from 'constants/ActionTypes'

export const ultraVoucherBrandsRequest = () => ({
  type: ULTRA_VOUCHER_BRAND_REQUEST,
})

export const ultraVoucherBrandsSuccess = (data, meta) => ({
  type: ULTRA_VOUCHER_BRAND_SUCCESS,
  data,
  meta,
})

export const ultraVoucherBrandsFailure = errorMessage => ({
  type: ULTRA_VOUCHER_BRAND_FAILURE,
  errorMessage,
})

export const fetchUltraVoucherBrands = params => (
  (dispatch) => {
    dispatch(ultraVoucherBrandsRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/voucher-brand/fetch${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(ultraVoucherBrandsSuccess(data, meta))
        } else {
          dispatch(ultraVoucherBrandsFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ultraVoucherBrandsFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchSyncData = params => (
  (dispatch) => {
    dispatch(ultraVoucherBrandsRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/voucher-brand/sync${params || ''}`
    return API.get(url).then(
      (response) => {
        const { meta } = response.data
        if (meta.status) {
          message.success(meta.message)
          dispatch(fetchUltraVoucherBrands())
        } else {
          dispatch(ultraVoucherBrandsFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ultraVoucherBrandsFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const updateBrand = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/voucher-brand/update/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      message.error(err.message)
      reject(err.message)
    })
  ))
)
