import {
  AUTO_DEBIT_LOG_PAYMENT_REQUEST,
  AUTO_DEBIT_LOG_PAYMENT_SUCCESS,
  AUTO_DEBIT_LOG_PAYMENT_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataAutoDebitLogPayment: [],
  metaAutoDebitLogPayment: {},
}

export default function autoDebitLogPayment(state = initialState, action) {
  switch (action.type) {
    case AUTO_DEBIT_LOG_PAYMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case AUTO_DEBIT_LOG_PAYMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataAutoDebitLogPayment: action.data,
        metaAutoDebitLogPayment: action.meta,
      }
    case AUTO_DEBIT_LOG_PAYMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataAutoDebitLogPayment: action.data === null ? [] : [],
        metaAutoDebitLogPayment: {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
