import PropTypes from 'prop-types'
import {
  Table, Button,
} from 'antd'
import history from 'utils/history'

const DownlineUser = ({
  data, handlePage,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (data.meta.per_page * (data.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Registered Number',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: text => text || '-',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      render: text => text || '0',
    },
    {
      title: 'Expired Date',
      dataIndex: 'last_commission_date',
      key: 'last_commission_date',
      render: text => text || '-',
    },
  ]

  return (
    <div className="pb-3">
      <div className="btn-primary-wrapper mb-5">
        <Button type="primary" icon="left" onClick={() => history.goBack()}>Go Back</Button>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={data.list}
        loading={data.isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: data.meta ? data.meta.total_count : data.length,
          current: data.meta ? data.meta.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

DownlineUser.propTypes = {
  data: PropTypes.object,
  handlePage: PropTypes.func,
}

export default DownlineUser
