import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Button,
  Affix,
} from 'antd'
import history from 'utils/history'
import { Row, Col } from 'react-bootstrap'

const InjectPointDetail = ({
  handlePage, list, handleSubmit, isSubmit,
}) => {
  const columns = [
    {
      title: 'Nama nasabah',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: text => text || '-',
    },
    {
      title: 'Alamat email',
      dataIndex: 'email',
      key: 'email',
      render: text => text || '-',
    },
    {
      title: 'Nomor HP',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: text => text || '-',
    },
    {
      title: 'Nomor Peserta',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Point',
      dataIndex: 'points',
      key: 'points',
      render: text => text || '-',
    },
    {
      title: 'Description Point',
      dataIndex: 'description',
      key: 'description',
      width: 250,
      render: text => text || '-',
    },
    {
      title: 'Keterangan',
      dataIndex: 'message',
      key: 'message',
      width: 200,
      render: text => text || '-',
    },
  ]
  return (
    <React.Fragment>
      <div className="position-relative">
        {list.data && list.data.rows && list.data.rows.length > 0 && (
        <div className="pb-3">
          {`${list.data.total_users || '0'} nasabah akan di import, ${list.data.total_points || 0} Total point poin.`}
        </div>
        )}
        <Table
          rowKey="id"
          className="table-striped-rows"
          columns={columns}
          dataSource={list.data.rows}
          loading={list.isFetching}
          scroll={{ x: 'max-content' }}
          pagination={{
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            total: list.meta ? list.meta.total_count : list.data.rows.length,
            current: list.meta ? list.meta.current_page : 1,
            defaultPageSize: 10,
            onChange: handlePage,
          }}
          rowClassName={record => (record.is_error ? 'text-danger' : '')}
        />
        <Affix style={{
          position: 'sticky', bottom: 0, right: 10, width: '100%',
        }}
        >
          <Row gutter={24} className="form-action position-sticky bg-white m-0 p-3">
            <Col md={4} className="btn-primary-wrapper d-flex justify-content-between">
              {list.data && list.data.status == 'pending' && (
              <React.Fragment>
                <Button
                  type="primary"
                  className="mr-md-3 mr-1 w-md-25 w-100"
                  onClick={() => handleSubmit('')}
                  loading={isSubmit}
                  disabled={list.data? list.data.has_errors : false}
                >
                  Kirim
                </Button>
                <Button
                  type="danger"
                  className="mr-md-3 mr-1 w-md-25 w-100"
                  onClick={() => handleSubmit('discarded')}
                  loading={isSubmit}
                >
                  Batal Kirim
                </Button>
              </React.Fragment>
              )}
              {list.data && list.data.status == 'draft' && (
                <React.Fragment>
                  <Button
                    type="primary"
                    className="mr-md-3 mr-1 w-md-25 w-100"
                    onClick={() => handleSubmit('approved')}
                    loading={isSubmit}
                  >
                    Approved
                  </Button>
                  <Button
                    type="danger"
                    className="mr-md-3 mr-1 w-md-25 w-100"
                    onClick={() => handleSubmit('rejected')}
                    loading={isSubmit}
                  >
                    Reject
                  </Button>
                </React.Fragment>
              )}

            </Col>
            <Col md={8} className="btn-primary-wrapper mt-3 mt-md-0">
              <Button className="ml-auto d-block px-5" onClick={() => history.goBack()}>Back</Button>
            </Col>
          </Row>
        </Affix>
      </div>
    </React.Fragment>
  )
}

InjectPointDetail.propTypes = {
  list: PropTypes.object,
  isFetching: PropTypes.bool,
  handlePage: PropTypes.func,
  handleSubmit: PropTypes.func,
  isSubmit: PropTypes.bool,
}

export default InjectPointDetail
