import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfigs, updateSiteConfiguration } from 'actions/Site'
import {
  fetchProfileDetail, fetchInvestList, updateProfile,
  approveUpdateProfile, fetchRelationList,
} from 'actions/UpdateProfile'
import {
  fetchProvince, fetchCity, fetchSubDistricts,
  fetchVillages, fetchPostCode,
} from 'actions/Location'
import UpdateDetailDataView from 'components/pages/updateData/Detail'
import { message } from 'antd'
import history from 'utils/history'

export function mapStateToProps(state) {

  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchProfileDetail: bindActionCreators(fetchProfileDetail, dispatch),
  fetchInvestList: bindActionCreators(fetchInvestList, dispatch),
  updateProfile: bindActionCreators(updateProfile, dispatch),
  approveUpdateProfile: bindActionCreators(approveUpdateProfile, dispatch),
  fetchRelationList: bindActionCreators(fetchRelationList, dispatch),
  fetchProvince: bindActionCreators(fetchProvince, dispatch),
  fetchCity: bindActionCreators(fetchCity, dispatch),
  fetchSubDistricts: bindActionCreators(fetchSubDistricts, dispatch),
  fetchVillages: bindActionCreators(fetchVillages, dispatch),
  fetchPostCode: bindActionCreators(fetchPostCode, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('rejectModal', 'setRejectModal', false),
  withState('rejectNotes', 'setRejectNotes', ''),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('isApprove', 'setIsApprove', false),
  withState('isDisable', 'setIsDisable', false),
  withState('detail', 'setDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withState('investList', 'setInvestList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('relationList', 'setRelationList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('provinceList', 'setProvinceList', {
    data: [],
    isFetching: true,
  }),
  withState('cityList', 'setCityList', {
    data: [],
    isFetching: false,
  }),
  withState('subDistrictsList', 'setSubDistrictsList', {
    data: [],
    isFetching: false,
  }),
  withState('villagesList', 'setVillagesList', {
    data: [],
    isFetching: false,
  }),
  withHandlers({
    fetchProvinceList: props => async () => {
      const { setProvinceList } = props
      try {
        const res = await props.fetchProvince()
        setProvinceList({ data: res.data, isFetching: false })
      } catch (err) {
        setProvinceList({ data: [], isFetching: false })
      }
    },
    fetchCityList: props => async (id) => {
      const { setCityList } = props
      try {
        const res = await props.fetchCity(id)
        setCityList({ data: res.data, isFetching: false })
      } catch (err) {
        setCityList({ data: [], isFetching: false })
      }
    },
    fetchSubDistrictsList: props => async (id) => {
      const { setSubDistrictsList } = props
      try {
        const res = await props.fetchSubDistricts(id)
        setSubDistrictsList({ data: res.data, isFetching: false })
      } catch (err) {
        setSubDistrictsList({ data: [], isFetching: false })
      }
    },
    fetchVillagesList: props => async (id) => {
      const { setVillagesList } = props
      try {
        const res = await props.fetchVillages(id)
        setVillagesList({ data: res.data, isFetching: false })
      } catch (err) {
        setVillagesList({ data: [], isFetching: false })
      }
    },
  }),
  withHandlers({
    handleProvinceList: props => (values, form) => {
      form.setFieldsValue({
        city: '', sub_district: '', village: '', post_code: '',
      })
      props.fetchCityList(values)
    },
    handleCityList: props => (values, form) => {
      form.setFieldsValue({ sub_district: '', village: '', post_code: '' })
      props.fetchSubDistrictsList(values)
    },
    handleSubDistrictsList: props => (values, form) => {
      form.setFieldsValue({ village: '', post_code: '' })
      props.fetchVillagesList(values)
    },
    handleVillagesList: props => async (values, form) => {
      try {
        const res = await props.fetchPostCode(values)
        form.setFieldsValue({ post_code: res.data.name })
      } catch (err) {
        form.setFieldsValue({ post_code: '000000' })
      }
    },
    toggleRejectModal: props => () => {
      props.setRejectModal(!props.rejectModal)
    },
    getDetail: props => (id) => {
      const { setDetail } = props
      props.fetchProfileDetail(id)
        .then((result) => {
          setDetail({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
          props.fetchProvinceList()
          props.fetchCityList(result.data.city.new.province_id)
          props.fetchSubDistrictsList(result.data.sub_district.new.city_id)
          props.fetchVillagesList(result.data.village.new.sub_district_id)
        })
        .catch(() => {
          setDetail({
            data: {},
            meta: {},
            isFetching: false,
          })
        })
    },
    getInvestList: props => (params) => {
      const { setInvestList } = props
      props.fetchInvestList(params)
        .then((result) => {
          setInvestList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setInvestList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
    getRelationList: props => (params) => {
      const { setRelationList } = props
      props.fetchRelationList(params)
        .then((result) => {
          setRelationList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setRelationList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleChangeEdit: props => () => {
      const { isEdit, setIsEdit } = props
      setIsEdit(!isEdit)
    },
    handleSubmit: props => (values) => {
      const {
        detail, investList, match,
        setIsSubmit, setIsDisable,
      } = props

      setIsSubmit(true)
      setIsDisable(true)

      let benefit_recipients = []

      // create benefit_recipents array
      if (values.id && values.name) {
        values.id.forEach((item, index) => {
          benefit_recipients.push({ id: item, name: values.name[index] })
        })
      }

      // get investment id
      const investCodeId = investList.data.find(item => item.name === values.investment_code)

      // phone_number
      let phone_number = values.phone_number === detail.data.phone_number.old ? detail.data.phone_number.old : values.phone_number.toString()
      if (phone_number.substr(0, 3) !== '628' && phone_number.substr(0, 1) === '0') {
        phone_number = phone_number.replace('0', '62')
      }
      // final payload
      const payload = {
        address: values.address === detail.data.address.old ? detail.data.address.old : values.address,
        email: values.email === detail.data.email.old ? detail.data.email.old : values.email,
        identity_number: values.identity_number === detail.data.identity_number.old ? detail.data.identity_number.old : values.identity_number.toString(),
        phone_number: phone_number,
        benefit_recipients: benefit_recipients,
        rt: values.rt === detail.data.rt.old ? detail.data.rt.old : values.rt,
        rw: values.rw === detail.data.rw.old ? detail.data.rw.old : values.rw,
        province_id: values.province === detail.data.province.old.id ? detail.data.province.old.id : values.province.toString(),
        city_id: values.city === detail.data.city.old.id ? detail.data.city.old.id : values.city.toString(),
        sub_district_id: values.sub_district === detail.data.sub_district.old.id ? detail.data.sub_district.old.id : values.sub_district.toString(),
        village_id: values.village === detail.data.village.old.id ? detail.data.village.old.id : values.village.toString(),
        postcode: values.post_code === detail.data.post_code.old.id ? detail.data.post_code.old.id : values.post_code.toString(),
        identity_photo: detail.data.identity_photo.new || detail.data.identity_photo.old,
        npwp_photo: detail.data.npwp_photo.new || detail.data.npwp_photo.old,
      }

      props.updateProfile(payload, match.params.id)
        .then(() => {
          setIsSubmit(false)
          setIsDisable(false)
          message.success('Data has been updated')
          history.push('/update-data')
        })
        .catch((err) => {
          setIsDisable(false)
          setIsSubmit(false)
          message.error(err)
        })
    },
    handleReject: props => () => {
      const {
        setIsApprove, setIsDisable, match, rejectNotes,
      } = props
      if (rejectNotes === '') {
        message.error('Reject reason cant be empty')
        return
      }
      setIsApprove(true)
      setIsDisable(true)
      const payload = {
        status: 'rejected',
        notes: rejectNotes,
      }
      props.approveUpdateProfile(payload, match.params.id)
        .then(() => {
          setIsApprove(false)
          setIsDisable(false)
          message.success('Request has been rejected')
          history.push('/update-data')
        })
        .catch((err) => {
          setIsApprove(false)
          setIsDisable(false)
          message.error(err)
        })
    },
    handleChangeRejectNotes: props => (e) => {
      props.setRejectNotes(e.target.value)
    },
    handleApprove: props => () => {
      const { setIsApprove, setIsDisable, match } = props
      setIsApprove(true)
      setIsDisable(true)

      props.approveUpdateProfile({ status: 'approved' }, match.params.id)
        .then(() => {
          setIsApprove(false)
          setIsDisable(false)
          message.success('Request has been approved')
          history.push('/update-data')
        })
        .catch((err) => {
          setIsApprove(false)
          setIsDisable(false)
          message.error(err)
        })
    },
    getRelationName: props => (id) => {
      const relationship = props.relationList.data.find(item => item.id === id)
      if (relationship && relationship.name) {
        return relationship.name
      }
      return id
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Verify Perubahan Data', 'Lihat Detail'])
      this.props.updateSiteConfigs({
        titlePage: 'Pengajuan Perubahan Data Nasabah',
        activePage: 'update-data',
        activeSubPage: 'update-data',
      })
      this.props.getDetail(this.props.match.params.id)
      this.props.getInvestList()
      this.props.getRelationList()
    },
  }),
)(UpdateDetailDataView)
