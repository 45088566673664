import PropTypes from 'prop-types'
import {
  Table, Select,
  Button, Input, DatePicker,
} from 'antd'
import Helper from 'utils/Helper'

const OttocashLogs = ({
  dataOttocash, metaOttocash, handlePage,
  handleSearch, isFetching, handleLimit,
  handleReport, limitList, isExport,
  statusList, handleChangeStatus, handleChangeDate,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (metaOttocash.per_page * (metaOttocash.current_page - 1)) + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'account_user_name',
      key: 'account_user_name',
      render: text => text || '-',
    },
    {
      title: 'Account Registered Number',
      dataIndex: 'account_registered_number',
      key: 'account_registered_number',
      render: text => text || '-',
    },
    {
      title: 'Disburse Account Number',
      dataIndex: 'disburse_account_number',
      key: 'disburse_account_number',
      render: text => text || '-',
    },
    {
      title: 'Ref Number',
      dataIndex: 'ottocash_ref_number',
      key: 'ottocash_ref_number',
      render: text => text || '-',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Disburse Amount',
      dataIndex: 'disburse_amount',
      key: 'disburse_amount',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'tx_date',
      key: 'tx_date',
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => text || '-',
    },
    /* {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/dana-ottocash/management-ottocash/${record.id}/edit`)} />
          <Popconfirm
            title="Are you sure delete this list?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" />
          </Popconfirm>
        </div>
      ),
    }, */
  ]

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div className="d-flex mr-2">
            <h6 className="mb-1 mr-2 align-self-center">Filter By: </h6>
            <Select
              placeholder="Limit"
              style={{ width: 120 }}
              onChange={handleLimit}
              className="mr-2"
            >
              {limitList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <Select
              placeholder="Status"
              style={{ width: 120 }}
              onChange={handleChangeStatus}
              className="mr-2"
            >
              {statusList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <DatePicker.RangePicker
              format="YYYY-MM-DD"
              placeholder={['Start Date', 'End Date']}
              onChange={handleChangeDate}
            />
          </div>
          <div className="mr-3">
            <Input.Search
              allowClear
              placeholder="Search by Title"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div className="mr-3 btn-primary-wrapper">
            <Button
              loading={isExport}
              type="primary"
              className="align-items-center btn-border-mitra"
              onClick={handleReport}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        loading={isFetching}
        columns={columns}
        dataSource={dataOttocash}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaOttocash ? metaOttocash.per_page : 10,
          total: metaOttocash ? metaOttocash.total_count : dataOttocash.length,
          current: metaOttocash ? metaOttocash.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

OttocashLogs.propTypes = {
  dataOttocash: PropTypes.array,
  metaOttocash: PropTypes.object,
  isFetching: PropTypes.bool,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  isExport: PropTypes.bool,
  handleReport: PropTypes.func,
  handleLimit: PropTypes.func,
  limitList: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  statusList: PropTypes.array,
  handleChangeDate: PropTypes.func,
}

export default OttocashLogs
