import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { fetchPaymentConfigurationDetail, updatePaymentConfiguration } from 'actions/PaymentConfiguration'
import { message } from 'antd'
import history from 'utils/history'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import PaymentConfigurationFormView from 'components/pages/paymentConfiguration/Form'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  fetchPaymentConfigurationDetail: bindActionCreators(fetchPaymentConfigurationDetail, dispatch),
  updatePaymentConfiguration: bindActionCreators(updatePaymentConfiguration, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('detailPaymentConfiguration', 'setPaymentConfiguration', {
    data: {},
    isFetching: true,
  }),
  withState('dataPayload', 'setDataPayload', {}),
  withState('modalConfirmation', 'setModalConfirmation', false),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    getPaymentChannelDetail: props => (param) => {
      const { setPaymentConfiguration } = props
      props.fetchPaymentConfigurationDetail(param)
        .then((result) => {
          setPaymentConfiguration({
            data: result.data,
            isFetching: false,
          })
        })
        .catch(() => {
          setPaymentConfiguration({
            data: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleModalConfirmation: props => (value) => {
      const { setDataPayload } = props
      if (value) {
        setDataPayload(value)
      }
      props.setModalConfirmation(!props.modalConfirmation)
    },
    handleSubmit: props => () => {
      const {
        match, setIsSubmit, dataPayload,
      } = props
      setIsSubmit(true)
      const payload = {
        is_active: dataPayload.status,
        description: dataPayload.description || '-',
        error_message: dataPayload.error_message || '-',
      }
      if (match.params.id) {
        props.updatePaymentConfiguration(match.params.id, payload)
          .then(() => {
            setIsSubmit(false)
            message.success('Payment Configuration berhasil di updated')
            history.push('/payment-configuration')
          })
          .catch(() => {
            setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match, getPaymentChannelDetail } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Payment Configuration', 'Form'])
      this.props.updateSiteConfigs({
        titlePage: 'Payment Configuration - Form',
        activePage: 'payment-configuration',
      })
      if (match.params.id) {
        getPaymentChannelDetail(match.params.id)
      }
    },
  }),
)(PaymentConfigurationFormView)
