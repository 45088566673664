import PropTypes from 'prop-types'
import {
  Card, Table,
  Icon, Popconfirm,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

/* const TitleCard = () => (
  <div className="d-flex justify-content-between align-items-center">
    <div />
    <div>
      <Button
        type="primary"
        className="rounded-pill"
        onClick={() => history.push('/content/pakde/add')}
      >
        Add Item
      </Button>
    </div>
  </div>
) */

const FaqPage = ({
  isFetching, handlePage,
  metaPakDe, dataPakDe,
  handleDelete, handleTab,
  activeTab, mngContent,
}) => {
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
        render: text => (text || '-'),
      },
      {
        title: 'Question',
        dataIndex: 'question',
        key: 'question',
        render: text => (text || '-'),
      },
      {
        title: 'Answer',
        dataIndex: 'answer',
        key: 'answer',
        render: text => (text || '-'),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'action',
        width: 80,
        render: (text, row) => (
          <div className="d-flex justify-content-around">
            <Icon type="edit" onClick={() => history.push(`/faqs/${row.id}/edit`)} />
            <Popconfirm
              title="Are you sure delete this item?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(row.id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>
        ),
      },
    ]

    return <Table className="table-striped-rows" columns={columns} dataSource={record.faqs} pagination={false} />
  }
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => (text || '-'),
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: text => (text || '-'),
    },
  ]
  const columnList = [
    {
      title: 'Menu',
      dataIndex: 'menu',
      key: 'menu',
      render: text => (text || '-'),
    },
    {
      title: 'Type',
      dataIndex: 'name',
      key: 'name',
      render: text => (text || '-'),
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      ellipsis: true,
      render: text => (text || '-'),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => (text ? moment.utc(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: text => (text ? moment.utc(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/content-management/pakde/${record.id}/edit`)} />
          {/* <Popconfirm
            title="Are you sure delete this item?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.title_id)}
          >
            <Icon type="delete" />
          </Popconfirm> */}
        </div>
      ),
    },
  ]
  const contentList = {
    candidate: {
      columns,
      expandedRowRender,
      loading: isFetching,
      dataSource: dataPakDe,
      pagination: {
        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
        total: metaPakDe.total_count,
        current: metaPakDe.current_page,
        onChange: handlePage,
      },
    },
    list: {
      columns: columnList,
      loading: isFetching,
      dataSource: mngContent.data,
      pagination: {
        hideOnSinglePage: true,
      },
    },
  }

  return (
    <Card
      tabList={[{ key: 'list', tab: 'List' }/* , { key: 'candidate', tab: 'Candidate' } */]}
      activeTabKey={activeTab}
      onTabChange={handleTab}
    >
      <Table
        {...contentList[activeTab]}
        rowKey="id"
        pagination={false}
        // columns={columns}
        // dataSource={dataPakDe}
        // loading={isFetching}
        className="components-table-demo-nested table-striped-rows"
        // pagination={{
        //   showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
        //   total: metaPakDe ? metaPakDe.total_count : dataPakDe.length,
        //   current: metaPakDe ? metaPakDe.current_page : 1,
        //   onChange: handlePage,
        // }}
      />
    </Card>
  )
}

FaqPage.propTypes = {
  isFetching: PropTypes.bool,
  dataPakDe: PropTypes.array,
  metaPakDe: PropTypes.object,
  handlePage: PropTypes.func,
  handleDelete: PropTypes.func,
  activeTab: PropTypes.string,
  handleTab: PropTypes.func,
  mngContent: PropTypes.object,
}

export default FaqPage
