import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDplkFee } from 'actions/CalculatorManagement'
import DplkFeeView from 'components/pages/calculatorManagement/dplkFee/List'
import { message } from 'antd'

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDplkFee: bindActionCreators(fetchDplkFee, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('listDplkFee', 'setListDplkFee', []),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Calculator management', 'DPLK Fee'])
      this.props.updateSiteConfiguration('titlePage', 'DPLK Fee')
      this.props.fetchDplkFee()
        .then((result) => {
          this.props.setListDplkFee(result)
        })
        .catch((error) => {
          message.error(error.message)
        })
    },
  }),
)(DplkFeeView)
