import PropTypes from 'prop-types'
import {
  Table, Button, Input,
  Select, Tag, Popconfirm,
  Icon,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

const AdminList = ({
  list, status, orderBy, sortBy,
  handleChangeStatus, handleChangeOrder,
  handlePage, handleDelete,
  handleSearch, handleChangeSort, currentUser,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (list.meta.per_page * (list.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button type="link" onClick={() => history.push(`/admin-management/admin/${record.id}/detail`)}>{text}</Button>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => text || '-',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text => text.name || '-',
    },
    {
      title: 'Last Sign-in',
      dataIndex: 'last_login',
      key: 'last_login',
      render: text => (text !== '0001-01-01T00:00:00Z' ? moment(text).format('DD MMM YYYY') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (text != undefined ? <Tag color={text.toLowerCase() === 'active' ? 'green' : 'red'}>{text || '-'}</Tag> : ''),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          {record.name.toLowerCase() != 'superadmin'
            ? (
              <React.Fragment>
                <Icon type="edit" onClick={() => history.push(`/admin-management/admin/${record.id}/edit`)} />
                <Popconfirm
                  title="Are you sure delete this User?"
                  okText="Yes"
                  cancelText="No"
                  placement="topLeft"
                  onConfirm={() => handleDelete(record.id)}
                >
                  {(currentUser.permissions && currentUser.permissions.indexOf('manage-user-role-managementuser-roles-delete') > -1) && (
                    <Icon type="delete" />
                  )}
                </Popconfirm>
              </React.Fragment>
            )
            : '-'
          }
        </div>
      ),
    },
  ]
  return (
    <div className="pb-3">
      <div className="pb-3">
        {/* <div className="d-flex justify-content-between"> */}
        {/* <div className="btn-primary-wrapper d-flex justify-content-between mb-4">
          <Button type="primary" icon="plus" onClick={() => history.push('/admin-management/admin/add')}>
            Add Admin
          </Button>
        </div> */}
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          {/* <Select
            placeholder="Role"
            style={{ width: 120 }}
            className="mr-2"
            onChange={handleChangeRole}
            loading={roleList.isFetching}
            allowClear
          >
            {(roleList.data || '').map(item => <Select.Option key={item.id} value={item.name}>{item.name}</Select.Option>)}
          </Select> */}
          <Select
            placeholder="Sort by"
            style={{ width: 180 }}
            className="mr-2"
            onChange={handleChangeSort}
            allowClear
          >
            {sortBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select>
          <Select
            placeholder="Order by"
            style={{ width: 180 }}
            className="mr-2"
            onChange={handleChangeOrder}
            allowClear
          >
            {orderBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select>
          <Select
            placeholder="Status"
            style={{ width: 120 }}
            className="mr-2"
            onChange={handleChangeStatus}
            allowClear
          >
            {status.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select>
          <div>
            <Input.Search
              allowClear
              placeholder="Search by Name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <Button className="ml-auto" type="primary" onClick={() => history.push('/admin-management/admin/add')}>
            Add Admin
          </Button>
        </div>

        {/* </div> */}
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={list.data}
        loading={list.isFetching}
        pagination={{
          total: list.meta ? list.meta.total_count : list.data.length,
          current: list.meta ? list.meta.current_page : 1,
          showSizeChanger: true,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

AdminList.propTypes = {
  list: PropTypes.object,
  status: PropTypes.array,
  orderBy: PropTypes.array,
  sortBy: PropTypes.array,
  // roleList: PropTypes.object,
  handleChangeStatus: PropTypes.func,
  handleChangeOrder: PropTypes.func,
  handleChangeSort: PropTypes.func,
  handlePage: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSearch: PropTypes.func,
  currentUser: PropTypes.object,
}

export default AdminList
