import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { createPakde, updatePakde, fetchDetailPakde } from 'actions/Pakde'
import { message } from 'antd'
import { pickBy, identifier } from 'lodash'
import FormPakDeView from 'components/pages/pakde/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailPakDe,
  } = state.root.pakde
  return {
    isFetching,
    detailPakDe,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  createPakde: bindActionCreators(createPakde, dispatch),
  updatePakde: bindActionCreators(updatePakde, dispatch),
  fetchDetailPakde: bindActionCreators(fetchDetailPakde, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmitting', 'setIsSubmitting', false),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      const payload = {
        ...values,
      }
      if (params.id) delete payload.name
      props.setIsSubmitting(true)

      props[`${params.id ? 'upd' : 'cre'}atePakde`](pickBy(payload, identifier), (params.id || null)).then(() => {
        message.success(`FAQ has been ${params.id ? 'upd' : 'cre'}ated`)
        props.setIsSubmitting(false)
        history.push('/content-management/pakde/list')
      }).catch(() => {
        props.setIsSubmitting(false)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Ajak Teman', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Ajak Teman Form')

      if (match.params.id) {
        this.props.fetchDetailPakde(match.params.id)
        setIsEdit(true)
      }
    },
  }),
)(FormPakDeView)
