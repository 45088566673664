import PropTypes from 'prop-types'
import {
  Table, Button, Input,
  Icon, Popconfirm,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

const CategoryPage = ({
  isFetching, handlePage,
  metaCategory, dataCategory,
  handleSearch, handleDelete,
  currentUser,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'no',
      width: 75,
      render: (text, row, idx) => (idx + 1),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 75,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
      render: text => (text ? moment.utc(text).format('DD MMM YY, HH:mm') : '-'),
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 100,
      render: text => (text ? moment.utc(text).format('DD MMM YY, HH:mm') : '-'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/elearning/categories/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-e-toolslearning-category-delete') > -1) && (
          <Popconfirm
            title="Are you sure delete this item?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" />
          </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search by name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => history.push('/elearning/categories/add')}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataCategory}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaCategory ? metaCategory.total_count : dataCategory.length,
          current: metaCategory ? metaCategory.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

CategoryPage.propTypes = {
  isFetching: PropTypes.bool,
  dataCategory: PropTypes.array,
  metaCategory: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default CategoryPage
