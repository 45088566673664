import {
  BENEFIT_REQUEST,
  BENEFIT_SUCCESS,
  BENEFIT_FAILURE,
  BENEFIT_UPDATED,
  BENEFIT_DETAIL_SUCCESS,
  CLAIM_TYPE_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataBenefit: [],
  detailBenefit: {},
  metaDetailBenefit: {},
  metaBenefit: {
    total_count: 0,
    current: 0,
  },
  dataClaimType: [],
  metaClaimType: {},
}

export default function benefit(state = initialState, action) {
  switch (action.type) {
    case BENEFIT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case BENEFIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataBenefit: action.data,
        metaBenefit: action.meta,
      }
    case BENEFIT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case BENEFIT_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataBenefit: state.dataBenefit.filter(item => item.id !== action.data.id),
      }
    case BENEFIT_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailBenefit: action.data,
        metaDetailBenefit: action.meta,
      }
    case CLAIM_TYPE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataClaimType: action.data,
        metaClaimType: action.meta,
      }
    default:
      return state
  }
}
