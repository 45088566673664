import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchDetailReviews,
  updateReviews,
  createReviews,
} from 'actions/Reviews'
import { message } from 'antd'
import FormReviewView from 'components/pages/webManagement/reviews/Form'
import Helper from 'utils/Helper'
import { pickBy, identity } from 'lodash'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailReviews: bindActionCreators(fetchDetailReviews, dispatch),
  createReviews: bindActionCreators(createReviews, dispatch),
  updateReviews: bindActionCreators(updateReviews, dispatch),
  // benefitDetail: bindActionCreators(benefitDetail, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('stateFile', 'setStateFile', ''),
  withState('detailReview', 'setDetailReview', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    handleUpload: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setStateFile(file))
      }
      return false
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      const formData = new FormData()
      Object.keys(pickBy(values, identity)).map((item) => {
        if (item === 'image' && values[item]) {
          return formData.append(item, values[item].file)
        }
        return formData.append(item, values[item])
      })
      if (props.match.params.id) {
        props.updateReviews(formData, props.match.params.id)
          .then(() => {
            props.setIsSubmit(false)
            history.push('/web-management/reviews')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      } else {
        props.createReviews(formData)
          .then(() => {
            props.setIsSubmit(false)
            history.push('/web-management/reviews')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetailReview, setStateFile,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Reviews', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Review Form')
      // this.props.benefitDetail({})
      if (match.params.id) {
        this.props.fetchDetailReviews(match.params.id)
          .then((res) => {
            setDetailReview(res)
            setStateFile(res.imageUrl)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormReviewView)
