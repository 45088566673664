import PropTypes from 'prop-types'
import {
  Card, Table,
  Button, Avatar,
  Input, Icon,
  Tag,
} from 'antd'
import history from 'utils/history'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

import moment from 'moment'

const UltraVoucherPage = ({
  isFetching, handlePage,
  metaUltraVoucherBrand, dataUltraVoucherBrand,
  handleSyncData, handleSearch, selectOption,
  /* handleDelete, */
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  const columns = [
    {
      title: 'Brand Code',
      dataIndex: 'brand_code',
      key: 'brand_code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      width: 200,
      dataIndex: 'image',
      key: 'image',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={75} src={image} alt="voucher" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={75} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Voucher Category',
      dataIndex: 'voucher_category_id',
      key: 'voucher_category_id',
      render: (text) => {
        let category
        if (selectOption) {
          const getCategory = selectOption.find(item => item.id === text)
          category = getCategory && getCategory.name ? getCategory.name : '-'
        }
        return category
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => text || '-',
    },
    {
      title: 'Delivery Method',
      dataIndex: 'delivery_method',
      key: 'delivery_method',
      width: 150,
      render: text => text || '-',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      width: 160,
      key: 'created_at',
      render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: text => (text === true
        ? <Tag color="green">Active</Tag>
        : <Tag color="red">Inactive</Tag>),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/ultra-vouchers/brands/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <Card>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search by name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => handleSyncData()}
            >
              Sync Data
            </Button>
          </div>
        </div>
      </div>
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={dataUltraVoucherBrand}
        loading={isFetching}
        scroll={{ x: 'max-content' }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaUltraVoucherBrand ? metaUltraVoucherBrand.per_page : 10,
          total: metaUltraVoucherBrand ? metaUltraVoucherBrand.total_count : dataUltraVoucherBrand.length,
          current: metaUltraVoucherBrand ? metaUltraVoucherBrand.current_page : 1,
          onChange: handlePage,
        }}
      />
    </Card>
  )
}


UltraVoucherPage.propTypes = {
  isFetching: PropTypes.bool,
  dataUltraVoucherBrand: PropTypes.array,
  metaUltraVoucherBrand: PropTypes.object,
  handlePage: PropTypes.func,
  handleSyncData: PropTypes.func,
  handleSearch: PropTypes.func,
  selectOption: PropTypes.array,
  // handleDelete: PropTypes.func,
}

export default UltraVoucherPage
