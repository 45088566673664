import API from 'utils/API'
import { message } from 'antd'
import {
  ULTRA_VOUCHER_CATEGORY_REQUEST,
  ULTRA_VOUCHER_CATEGORY_SUCCESS,
  ULTRA_VOUCHER_CATEGORY_FAILURE,
  ULTRA_VOUCHER_CATEGORY_UPDATED,
  ULTRA_VOUCHER_CATEGORY_DETAIL_SUCCESS,
} from 'constants/ActionTypes'
import history from 'utils/history'

export const ultraVoucherCategoryRequest = () => ({
  type: ULTRA_VOUCHER_CATEGORY_REQUEST,
})

export const ultraVoucherCategorySuccess = (data, meta) => ({
  type: ULTRA_VOUCHER_CATEGORY_SUCCESS,
  data,
  meta,
})

export const ultraVoucherCategoryFailure = errorMessage => ({
  type: ULTRA_VOUCHER_CATEGORY_FAILURE,
  errorMessage,
})

export const ultraVoucherCategoryUpdate = data => ({
  type: ULTRA_VOUCHER_CATEGORY_UPDATED,
  data,
})

export const ultraVoucherCategoryDetail = data => ({
  type: ULTRA_VOUCHER_CATEGORY_DETAIL_SUCCESS,
  data,
})

export const fetchUltraVoucherCategory = params => (
  dispatch => new Promise((resolve, reject) => {
    dispatch(ultraVoucherCategoryRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/voucher-category${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(ultraVoucherCategorySuccess(data, meta))
          resolve(data)
        } else {
          dispatch(ultraVoucherCategoryFailure(meta.message))
          reject(meta.message)
        }
      },
    ).catch((err) => {
      dispatch(ultraVoucherCategoryFailure(err.message)) // eslint-disable-line no-console
      reject(err.message)
    })
  })
)

export const createUltraVoucherCategory = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/voucher-category`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const fetchDetailUltraVoucherCategory = id => (
  (dispatch) => {
    dispatch(ultraVoucherCategoryRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/voucher-category/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(ultraVoucherCategoryDetail(data))
        } else {
          dispatch(ultraVoucherCategoryFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ultraVoucherCategoryFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const updateUltraVoucherCategory = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/voucher-category/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
          history.push('/ultra-vouchers/categories')
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const deleteVoucherCategory = id => (
  (dispatch) => {
    dispatch(ultraVoucherCategoryRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/voucher-category/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(ultraVoucherCategoryUpdate(data))
        } else {
          dispatch(ultraVoucherCategoryFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ultraVoucherCategoryFailure(err.message)) // eslint-disable-line no-console
    })
  }
)
