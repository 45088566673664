import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, withState } from 'recompose'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import ClaimDeath from 'components/pages/claim/claimMeninggalDunia'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateCheck', 'setStateCheck', {
    isLocked: false,
  }),
  lifecycle({
    async componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Claim', 'By Admin'])
      this.props.updateSiteConfigs({
        titlePage: 'Permohonan Claim By Admin',
        activePage: 'claim/claim-by-admin',
        activeSubPage: 'by-admin',
      })
    },
  }),
)(ClaimDeath)
