import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import ClaimDeathView from 'containers/pages/claim/claimMeninggalDunia/List'
import DetailCustomerClaimDeath from 'containers/pages/claim/claimMeninggalDunia/detail'
import FormClaimMeninggalDunia from 'containers/pages/claim/claimMeninggalDunia/Form'
import FormRequestView from 'containers/pages/claim/claimMeninggalDunia/formRequest'



const ClaimDeath = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/claim/claim-by-admin') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-meninggal-read') > -1) {
      return <ClaimDeathView location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-meninggal-read') > -1) {
      return <DetailCustomerClaimDeath location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-meninggal-write') > -1) {
      return <FormClaimMeninggalDunia location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-meninggal-write') > -1) {
      return <FormRequestView location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

ClaimDeath.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default ClaimDeath
