import PropTypes from 'prop-types'
import {
  Table, Button,
  Icon, DatePicker,
  Tooltip, Select,
} from 'antd'
import history from 'utils/history'

const HistoryPoint = ({
  data, handleChangeDate, handlePage,
  sortBy, handleChangeSort, orderBy,
  handleChangeOrder, isExport,
  handleReport,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (data.meta.per_page * (data.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: text => text || '-',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      render: text => text || '0',
    },
    {
      title: 'Kredit',
      dataIndex: 'credit',
      key: 'credit',
      render: text => text || '0',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: text => text || '0',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => text || '-',
    },
    {
      title: 'Downline',
      dataIndex: '',
      key: 'downline',
      width: 100,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          {record.description === 'Poin Pak-D'
            ? (
              <Tooltip title="Downline list">
                <Icon type="eye" onClick={() => history.push(`/user/history-point/downline/${record.commission_id}`)} />
              </Tooltip>
            )
            : <span>-</span>
        }
        </div>
      ),
    },
  ]

  return (
    <div className="pb-3">
      <div className="btn-primary-wrapper mb-5">
        <Button type="primary" icon="left" onClick={() => history.goBack()}>Go Back</Button>
      </div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <Select
            placeholder="Sort by"
            style={{ width: 160 }}
            className="mr-2"
            onChange={handleChangeSort}
            allowClear
          >
            {sortBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select>
          <Select
            placeholder="Order by"
            style={{ width: 160 }}
            className="mr-2"
            onChange={handleChangeOrder}
            allowClear
          >
            {orderBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select>
          <DatePicker.RangePicker
            format="DD-MM-YYYY"
            placeholder={['Start Date', 'End Date']}
            onChange={handleChangeDate}
          />

          <div className="ml-auto btn-primary-wrapper text-right">
            <Button
              loading={isExport}
              type="primary"
              className="align-items-center btn-border-mitra"
              onClick={handleReport}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={data.list}
        loading={data.isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: data.meta ? data.meta.total_count : data.length,
          current: data.meta ? data.meta.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

HistoryPoint.propTypes = {
  data: PropTypes.object,
  handleChangeDate: PropTypes.func,
  handlePage: PropTypes.func,
  sortBy: PropTypes.array,
  handleChangeSort: PropTypes.func,
  orderBy: PropTypes.array,
  handleChangeOrder: PropTypes.func,
  isExport: PropTypes.bool,
  handleReport: PropTypes.func,
}

export default HistoryPoint
