import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchQuestion, deleteQuestion } from 'actions/QuizQuestion'
import { fetchAnswer, deleteAnswer } from 'actions/QuizAnswer'
import { capitalize } from 'lodash'
import LearningQuizView from 'components/pages/elearnings/quiz'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFecthing,
    dataQuestion,
    metaQuestion,
  } = state.root.quizQuestion
  const {
    isFecthing: loadAnswer,
    dataAnswer,
    metaAnswer,
  } = state.root.quizAnswer
  return {
    isFecthing,
    dataQuestion,
    metaQuestion,
    loadAnswer,
    dataAnswer,
    metaAnswer,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchQuestion: bindActionCreators(fetchQuestion, dispatch),
  deleteQuestion: bindActionCreators(deleteQuestion, dispatch),
  fetchAnswer: bindActionCreators(fetchAnswer, dispatch),
  deleteAnswer: bindActionCreators(deleteAnswer, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('activeTab', 'setActiveTab', 'question'),
  withHandlers({
    handleSearch: () => () => {},
    handlePage: props => (page) => {
      if (props.activeTab === 'question') {
        props.fetchQuestion(`?page=${page}`)
      } else {
        props.fetchAnswer(`?page=${page}`)
      }
    },
    handleTab: () => (key) => {
      history.push(`/elearning/quiz/${key}`)
    },
    handleDelete: props => (id) => {
      if (props.activeTab === 'question') {
        props.deleteQuestion(id)
      } else {
        props.deleteAnswer(id)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'e-Learning', `Quiz - ${capitalize(this.props.match.params.type)}`])
      this.props.setActiveTab(this.props.match.params.type)
      if (this.props.match.params.type === 'question') {
        this.props.fetchQuestion()
      } else {
        this.props.fetchAnswer()
      }
    },
  }),
)(LearningQuizView)
