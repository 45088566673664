import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Row, Col,
  Skeleton, Table, Button,
} from 'antd'
import moment from 'moment'
import history from 'utils/history'


const DetailCommissionPage = ({
  detail, handlePage
}) => {

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      render: (text, record, index) => (detail.meta.per_page * (detail.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Registered Number',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: text => text || '-',
    },
    {
      title: 'Points',
      dataIndex: 'points',
      key: 'points',
      render: text => text || '0',
    },
  ]

  return (
    <div className="pb-5">
      {detail.isFetching
        ? (
          <Card className="mb-3">
            <div>
              <Skeleton active />
            </div>
          </Card>
          )
        : (
          <React.Fragment>
            <div className="btn-primary-wrapper mb-5">
              <Button type="primary" icon="left" onClick={() => history.goBack()}>Go Back</Button>
            </div>
            <Card className="mb-3">
              <h5 className="mb-4">Downline List</h5>
              <Table
                rowKey="id"
                className="table-striped-rows"
                columns={columns}
                dataSource={detail.data}
                loading={detail.isFetching}
                pagination={{
                  showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                  pageSize: detail.meta ? detail.meta.per_page : 10,
                  total: detail.meta ? detail.meta.total_count : detail.data.length,
                  current: detail.meta ? detail.meta.current_page : 1,
                  onChange: handlePage,
                }}
              />
            </Card>
          </React.Fragment>
        )
      }
    </div>
  )
}

DetailCommissionPage.propTypes = {
  detail: PropTypes.object,
  handlePage: PropTypes.func,
}

export default DetailCommissionPage
