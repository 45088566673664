import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDownloadList } from 'actions/Download'
import DownloadView from 'components/pages/download/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDownloadList: bindActionCreators(fetchDownloadList, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('list', 'setList', {
    data: [],
    meta: {},
    isFetching: true,
  }),

  withHandlers({
    getList: props => (params) => {
      const { setList } = props
      props.fetchDownloadList(params)
        .then((result) => {
          setList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleOpenNewTab: props => (val) => {
      const loc = window.open()
      loc.location.href = val
    },
    onLoad: props => (isSearch) => {
      const { page } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
      }
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'List Download'])
      this.props.updateSiteConfiguration('titlePage', 'List Download')
      this.props.getList()
    },
  }),
)(DownloadView)
