import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormContactUsCategory = ({
  isEdit, isSubmit, isDisable, detail, form,
  handleSubmit, handleChangeCategoryName,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Category Name">
          {getFieldDecorator('name', {
            initialValue: detail.name,
            rules: [{ required: true, message: 'Category name wajib diisi' }],
          })(
            <Input autoComplete="off" onChange={e => handleChangeCategoryName(e)} placeholder="Category name" />,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/web-management/contact-us-categories')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
          <Button type="primary" disabled={isDisable} loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Category' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormContactUsCategory.propTypes = {
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  isDisable: PropTypes.bool,
  form: PropTypes.any,
  detail: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleChangeCategoryName: PropTypes.func,
}

export default Form.create({ name: 'contactUsCategoryForm' })(FormContactUsCategory)
