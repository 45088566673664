import PropTypes from 'prop-types'
import {
  Table, Button, Input, Tag, Icon,
  Tooltip, Select, DatePicker,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

const UserPage = ({
  stateSearch, isFetching, dataUser, metaUser, sortBy, orderBy, statusList, isExport,
  handlePage, handleSearch, handleChangeSort, handleChangeStatus,
  handleChangeOrder, handleReport, handleChangeDate, datePickerStatus,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (metaUser.per_page * (metaUser.current_page - 1)) + index + 1,
    },
    {
      title: 'Member ID',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button type="link" onClick={() => history.push(`/user/${record.id}/detail`)}>{text}</Button>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      render: text => text || '-',
    },
    {
      title: 'Joined Date',
      dataIndex: 'joined_date',
      key: 'joined_date',
      render: text => (text ? moment.utc(text).format('DD MMM YYYY, HH:mm') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: text => <Tag color={text.toLowerCase() === 'active' ? 'green' : 'red'} className="text-capitalize">{text || '-'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Tooltip title="History point">
            <Icon type="eye" onClick={() => history.push(`/user/history-point/${record.id}`)} />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <div className="pb-3">
      <div className="wrapper-filter-member-management pb-3">
        <div className="d-flex flex-column flex-md-row justify-content-between btn-primary-wrapper">
          <div className="d-flex flex-column flex-xl-row">
            <div>
              <Select
                placeholder="Sort by"
                className="select-option mb-2 mb-md-0 mr-2"
                onChange={handleChangeSort}
                allowClear
              >
                {sortBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
              </Select>
              <Select
                placeholder="Order by"
                className="select-option mb-2 mb-md-0 mr-2"
                onChange={handleChangeOrder}
                allowClear
              >
                {orderBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
              </Select>
              <Select
                placeholder="Status"
                className="select-option mb-2 mb-md-0 mr-2"
                onChange={handleChangeStatus}
                style={{ color: stateSearch.status ? 'rgba(0, 0, 0, 0.65)' : '#bfbfbf' }}
                value={stateSearch.status || 'Status'}
                allowClear
                autoClearSearchValue
              >
                {statusList.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
              </Select>
            </div>
            <div className="mt-0 mt-md-3 mt-xl-0">
              {datePickerStatus === true && (
              <DatePicker.RangePicker
                format="DD-MM-YYYY"
                className="mb-2 mb-md-0 mr-2"
                placeholder={['Start date', 'End date']}
                onChange={handleChangeDate}
              />
              )}
              <Input.Search
                className="search-member-management"
                placeholder="Search..."
                allowClear
                enterButton
                onSearch={handleSearch}
              />
            </div>
          </div>
          <div className="btn-primary-wrapper mt-2 mt-md-0">
            <Button
              loading={isExport}
              type="primary"
              className="align-items-center btn-border-mitra"
              onClick={handleReport}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataUser}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaUser ? metaUser.total_count : dataUser.length,
          current: metaUser ? metaUser.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

UserPage.propTypes = {
  stateSearch: PropTypes.object,
  isFetching: PropTypes.bool,
  dataUser: PropTypes.array,
  metaUser: PropTypes.object,
  sortBy: PropTypes.array,
  orderBy: PropTypes.array,
  statusList: PropTypes.array,
  isExport: PropTypes.bool,
  datePickerStatus: PropTypes.bool,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleChangeSort: PropTypes.func,
  handleChangeOrder: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleReport: PropTypes.func,
  handleChangeDate: PropTypes.func,
}

export default UserPage
