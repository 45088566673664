import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfigs, updateSiteConfiguration } from 'actions/Site'
import { fetchDuplicateDataDetail, approveUpdateProfile } from 'actions/UpdateProfile'
import DuplicateDataView from 'components/pages/updateData/duplicateData/Duplicate'
import { message } from 'antd'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDuplicateDataDetail: bindActionCreators(fetchDuplicateDataDetail, dispatch),
  approveUpdateProfile: bindActionCreators(approveUpdateProfile, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isSubmit', 'setIsSubmit', false),
  withState('detail', 'setDetail', {
    current: {},
    duplicate: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getDetail: props => (id) => {
      const { setDetail } = props
      props.fetchDuplicateDataDetail(id)
        .then((result) => {
          setDetail({
            current: result.data.current_user,
            duplicate: result.data.conflicting,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setDetail({
            current: {},
            duplicate: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleMerge: props => () => {
      props.approveUpdateProfile({status: 'approved' }, props.match.params.id)
        .then(() => {
          props.setIsSubmit(false)
          message.success('Data has been merged and approved')
          history.push('/update-data')
        })
        .catch((err) => {
          props.setIsSubmit(false)
          message.error(err)
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Verify Perubahan Data', 'Lihat Detail'])
      this.props.updateSiteConfigs({
        titlePage: 'Gabung Data Nasabah',
        activePage: 'update-data',
        activeSubPage: 'update-data',
      })
      this.props.getDetail(this.props.match.params.id)
    },
  }),
)(DuplicateDataView)
