import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Select, Row,
  Col, Avatar, Upload,
} from 'antd'
import history from 'utils/history'
import CKEditor from 'ckeditor4-react'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },

}

/* const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 24,
      span: 24,
    },
  },
} */

const FormBenefit = ({
  detailBenefit, handleSubmit,
  form, isEdit, dataClaimType,
  loadStatus, stateFile, handleUpload,
  handleChangeEditor1, descContent1,
  handleChangeEditor2, descContent2,
  handleChangeEditor3, descContent3,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Form
      {...formItemLayout}
      onSubmit={(e) => {
        e.preventDefault()
        validateFields((err, values) => {
          if (!err) {
            handleSubmit(values)
          }
        })
      }}
    >
      <Card>
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Icon">
              {getFieldDecorator('benefit_icon', {
                rules: [{ required: !isEdit }],
              })(
                <Upload
                  name="icon"
                  listType="picture-card"
                  className="avatar-uploader banner-content"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={e => handleUpload(e)}
                  accept="image/*"
                  fileList={stateFile}
                >
                  {stateFile ? (
                    <Avatar src={stateFile} size={250} shape="square" />
                  ) : (
                    <div>
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>,
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Investment type">
              {getFieldDecorator('claim_type_id', {
                initialValue: detailBenefit.claim_type && detailBenefit.claim_type.id ? detailBenefit.claim_type.id : '',
                rules: [{ required: true }],
              })(
                <Select
                  loading={loadStatus}
                  placeholder="Select Payment"
                  disabled={isEdit}
                >
                  {(dataClaimType || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                </Select>,
              )}
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label="Document description title">
              {getFieldDecorator('doc_desc_title', {
                initialValue: detailBenefit.doc_desc_title,
                rules: [{ required: true }],
              })(
                <Input placeholder="Document description title" />,
              )}
            </Form.Item>
          </Col> */}
          {/* <Col span={12}>
            <Form.Item label="Document description header">
              {getFieldDecorator('doc_desc_header', {
                initialValue: detailBenefit.doc_desc_header,
                rules: [{ required: true }],
              })(
                <Input placeholder="Document description header" />,
              )}
            </Form.Item>
          </Col> */}
          {/* <Col span={12}>
            <Form.Item label="Claim description">
              {getFieldDecorator('claim_desc', {
                initialValue: detailBenefit.claim_desc,
                rules: [{ required: true }],
              })(
                <Input.TextArea autoSize placeholder="Claim description" />,
              )}
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={24} className="mb-5">
          {/* <Col span={12}>
            <Form.Item label="Benefit title">
              {getFieldDecorator('benefit_desc_title', {
                initialValue: detailBenefit.benefit_desc_title,
                rules: [{ required: true }],
              })(
                <Input placeholder="Benefit description title" />,
              )}
            </Form.Item>
          </Col> */}
          {/* <Col span={12}>
            <Form.Item label="Benefit header">
              {getFieldDecorator('benefit_desc_header', {
                initialValue: detailBenefit.benefit_desc_header,
                rules: [{ required: true }],
              })(
                <Input placeholder="Benefit description header" />,
              )}
            </Form.Item>
          </Col> */}
          {/* <Col span={12}>
            <Form.Item label="Benefit footer">
              {getFieldDecorator('benefit_desc_footer', {
                initialValue: detailBenefit.benefit_desc_footer,
                rules: [{ required: false }],
              })(
                <Input.TextArea autoSize placeholder="Benefit description footer" />,
              )}
            </Form.Item>
          </Col> */}
          <Col span={15}>
            <Form.Item label="Benefit content">
              {getFieldDecorator('benefit_desc_content', {
                initialValue: detailBenefit.benefit_desc_content,
                rules: [{ required: true }],
              })(
                <Input.TextArea autoSize placeholder="Input content" />,
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Tax title">
              {getFieldDecorator('tax_desc_title', {
                initialValue: detailBenefit.tax_desc_title,
                rules: [{ required: true }],
              })(
                <Input placeholder="Tax description title" />,
              )}
            </Form.Item>
          </Col>
        </Row> */}

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Tax header 1">
              {getFieldDecorator('tax_desc_header_1', {
                initialValue: detailBenefit.tax_desc_header_1,
                rules: [{ required: true }],
              })(
                <Input placeholder="Tax description header 1" />,
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* <Col span={12}>
            <Form.Item label="Tax footer 1">
              {getFieldDecorator('tax_desc_footer_1', {
                initialValue: detailBenefit.tax_desc_footer_1,
                rules: [{ required: false }],
              })(
                <Input.TextArea autoSize placeholder="Tax description footer 1" />,
              )}
            </Form.Item>
          </Col> */}
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Tax content 1">
              {isEdit
                ? descContent1
                  ? (
                    <CKEditor
                      name="descContent1"
                      data={descContent1}
                      config={{
                        extraPlugins: 'justify,font,colorbutton',
                        toolbarGroups: [
                          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                          {
                            name: 'paragraph',
                            groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                          },
                        ],
                      }}
                      onChange={e => handleChangeEditor1(e.editor.getData())}
                    />
                  )
                  : <p>Loading editor...</p>

                : (
                  <CKEditor
                    name="descContent1"
                    data={descContent1}
                    config={{
                      extraPlugins: 'justify,font,colorbutton',
                      toolbarGroups: [
                        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                        {
                          name: 'paragraph',
                          groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                        },
                      ],
                    }}
                    onChange={e => handleChangeEditor1(e.editor.getData())}
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Tax header 2">
              {getFieldDecorator('tax_desc_header_2', {
                initialValue: detailBenefit.tax_desc_header_2,
                rules: [{ required: true }],
              })(
                <Input placeholder="Tax description header 2" />,
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* <Col span={12}>
            <Form.Item label="Tax footer 2">
              {getFieldDecorator('tax_desc_footer_2', {
                initialValue: detailBenefit.tax_desc_footer_2,
                rules: [{ required: false }],
              })(
                <Input.TextArea autoSize placeholder="Tax description footer 2" />,
              )}
            </Form.Item>
          </Col> */}
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Tax content 2">
              {isEdit
                ? descContent2
                  ? (
                    <CKEditor
                      name="descContent2"
                      data={descContent2}
                      config={{
                        extraPlugins: 'justify,font,colorbutton',
                        toolbarGroups: [
                          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                          {
                            name: 'paragraph',
                            groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                          },
                        ],
                      }}
                      onChange={e => handleChangeEditor2(e.editor.getData())}
                    />
                  )
                  : <p>Loading editor...</p>
                : (
                  <CKEditor
                    name="descContent2"
                    data={descContent2}
                    config={{
                      extraPlugins: 'justify,font,colorbutton',
                      toolbarGroups: [
                        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                        {
                          name: 'paragraph',
                          groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                        },
                      ],
                    }}
                    onChange={e => handleChangeEditor2(e.editor.getData())}
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Tax header 3">
              {getFieldDecorator('tax_desc_header_3', {
                initialValue: detailBenefit.tax_desc_header_3,
                rules: [{ required: true }],
              })(
                <Input placeholder="Tax description header 3" />,
              )}
            </Form.Item>
          </Col>
        </Row>
        {/* <Col span={12}>
            <Form.Item label="Tax footer 3">
              {getFieldDecorator('tax_desc_footer_3', {
                initialValue: detailBenefit.tax_desc_footer_3,
                rules: [{ required: false }],
              })(
                <Input.TextArea autoSize placeholder="Tax description footer 3" />,
              )}
            </Form.Item>
          </Col> */}
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Tax content 3">
              {isEdit
                ? descContent3
                  ? (
                    <CKEditor
                      name="descContent3"
                      data={descContent3}
                      config={{
                        extraPlugins: 'justify,font,colorbutton',
                        toolbarGroups: [
                          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                          {
                            name: 'paragraph',
                            groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                          },
                        ],
                      }}
                      onChange={e => handleChangeEditor3(e.editor.getData())}
                    />
                  )
                  : <p>Loading editor...</p>
                : (
                  <CKEditor
                    name="descContent3"
                    data={descContent3}
                    config={{
                      extraPlugins: 'justify,font,colorbutton',
                      toolbarGroups: [
                        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                        {
                          name: 'paragraph',
                          groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                        },
                      ],
                    }}
                    onChange={e => handleChangeEditor3(e.editor.getData())}
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <div className="d-flex justify-content-end btn-primary-wrapper">
              <Button onClick={() => history.push('/benefit')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
              <Button type="primary" htmlType="submit">{!isEdit ? 'Add Benefit' : 'Submit Change'}</Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

FormBenefit.propTypes = {
  detailBenefit: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  dataClaimType: PropTypes.array,
  loadStatus: PropTypes.bool,
  stateFile: PropTypes.any,
  handleUpload: PropTypes.func,
  handleChangeEditor1: PropTypes.func,
  descContent1: PropTypes.string,
  handleChangeEditor2: PropTypes.func,
  descContent2: PropTypes.string,
  handleChangeEditor3: PropTypes.func,
  descContent3: PropTypes.string,
}

export default Form.create({ name: 'benefitForm' })(FormBenefit)
