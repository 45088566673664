import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle } from 'recompose'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import UserView from 'components/pages/customers'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { params } = this.props.match
      this.props.updateSiteConfiguration('breadList', ['Home', `Customer - ${params.type === 'phone' ? 'Phone Number' : 'Verify'}`])
      this.props.updateSiteConfiguration('titlePage', `Customer ${params.type === 'phone' ? 'Phone Number' : 'Verify'}`)
    },
  }),
)(UserView)
