import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle } from 'recompose'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import InvestmentHistoryView from 'components/pages/calculationDplk/investmentHistory'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Dplk-Calculation-Table', 'Investment-History'])
      this.props.updateSiteConfiguration('titlePage', 'Investment History')
    },
  }),
)(InvestmentHistoryView)
