import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import OttocashLog from 'containers/pages/danaOttocash/logOttocash/List'

const Commission = ({
  currentUser, location,
}) => {
  if (location.pathname === '/dana-ottocash/management-ottocash/logs') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-ottocashlogs-read') > -1) {
      return <OttocashLog location={location} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Commission.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Commission
