import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import PakdeBenefitsPage from 'containers/pages/webManagement/pakdeBenefits/List'
import PakdeBenefitsForm from 'containers/pages/webManagement/pakdeBenefits/Form'

const PakdeBenefits = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/web-management/pak-d-benefits') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementpak-d-benefits-read') > -1) {
      return <PakdeBenefitsPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementpak-d-benefits-write') > -1) {
      return <PakdeBenefitsForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

PakdeBenefits.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default PakdeBenefits
