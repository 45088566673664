import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Radio, Upload, Avatar, message,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 3,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const FormBanner = ({
  handleSubmit, form, isEdit, status,
  stateFileDesktop, stateFileMobile,
  handleUploadMobile, handleUploadDesktop, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  const [fileUpload, setFileUpload] = React.useState(false)
  const handleSizeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Ukuran file melebihi maksimal. Mohon untuk upload file kembali dengan ukuran dibawah 2 MB')
      setFileUpload(true)
      return true
    }
    setFileUpload(false)
    return false
  }
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (fileUpload) {
              message.error('Ukuran file melebihi maksimal. Mohon untuk upload file kembali dengan ukuran dibawah 2 MB')
              return
            }
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Desktop Image">
          {getFieldDecorator('desktop_image', {
            rules: [{ required: !isEdit, message: 'Desktop Image wajib di upload' }],
          })(
            <Upload
              name="desktop_image"
              listType="picture-card"
              className="avatar-uploader banner-content"
              showUploadList={false}
              beforeUpload={handleSizeUpload}
              onChange={e => handleUploadDesktop(e)}
              accept="image/*"
              fileList={stateFileDesktop}
            >
              {stateFileDesktop ? (
                <Avatar src={stateFileDesktop} size={250} shape="square" className="banner-preview" />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label="Mobile Image">
          {getFieldDecorator('mobile_image', {
            rules: [{ required: !isEdit, message: 'Mobile Image wajib di upload' }],
          })(
            <Upload
              name="mobile_image"
              listType="picture-card"
              className="avatar-uploader banner-content"
              showUploadList={false}
              beforeUpload={handleSizeUpload}
              onChange={e => handleUploadMobile(e)}
              accept="image/jpg,image/jpeg,image/png"
              fileList={stateFileMobile}
            >
              {stateFileMobile ? (
                <Avatar src={stateFileMobile} size={250} shape="square" className="banner-preview" />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('active', {
            initialValue: status && status === true,
            rules: [{ required: true, message: 'Status wajib dipilih' }],
          })(
            <Radio.Group
              buttonStyle="solid"
              name="active"
            >
              <Radio.Button value>True</Radio.Button>
              <Radio.Button value={false}>False</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>

        <div className="d-flex justify-content-end pt-4 btn-primary-wrapper">
          <Button onClick={() => history.push('/web-management/banners')} className="mr-2">Cancel</Button>
          <Button loading={isSubmit} type="primary" htmlType="submit">{!isEdit ? 'Tambah Banner' : 'Simpan Perubahan'}</Button>
        </div>
      </Form>
    </Card>
  )
}

FormBanner.propTypes = {
  stateFileDesktop: PropTypes.any,
  stateFileMobile: PropTypes.any,
  handleUploadDesktop: PropTypes.func,
  handleUploadMobile: PropTypes.func,
  isSubmit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  status: PropTypes.bool,
  handleRadio: PropTypes.func,
}

export default Form.create({ name: 'bannerForm' })(FormBanner)
