import { connect } from 'react-redux'
import {
  compose, lifecycle, withState, withHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchMessages } from 'actions/ContactUsMessages'
import SimpanMessagesView from 'components/pages/webManagement/contactUsMessages/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchMessages: bindActionCreators(fetchMessages, dispatch),
})

const status = [
  { id: 1, label: 'Solved', value: 'true' },
  { id: 2, label: 'Unread/Unsolved', value: 'false' },
]

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('messageList', 'setMessageList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('statusMessage', 'setStatusMessage', status),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    getMessage: props => (params) => {
      props.fetchMessages(params)
        .then((result) => {
          props.setMessageList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          props.setMessageList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search, isSolved } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        solved: isSolved || '',
      }

      props.getMessage(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        isSolved: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Contact Us Messages'])
      this.props.updateSiteConfigs({
        titlePage: 'Contact Us Messages',
        activePage: 'web-management/contact-us-messages',
        activeSubPage: 'web-management',
      })
      this.props.getMessage()
    },
  }),
)(SimpanMessagesView)
