import PropTypes from 'prop-types'
import {
  Table, Icon, Avatar, Button, Popconfirm,
} from 'antd'
import history from 'utils/history'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

const Reviews = ({
  reviewList, handleDelete, currentUser,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, row, idx) => (idx + 1),
      width: 100,
    },
    {
      title: 'Image',
      width: 200,
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (image, record) => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={75} src={image} alt={record.name} style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={75} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      render: text => text || '-',
      width: 100,
    },
    {
      title: 'Occupation',
      dataIndex: 'occupation',
      key: 'occupation',
      render: text => text || '-',
    },
    {
      title: 'Highlight',
      dataIndex: 'highlight',
      key: 'highlight',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Commentary',
      dataIndex: 'commentary',
      key: 'commentary',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/web-management/reviews/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementreviews-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this benefit?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center pb-3">
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/web-management/reviews/add')}
          >
            Add Reviews
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={reviewList.data}
        loading={reviewList.isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

Reviews.propTypes = {
  reviewList: PropTypes.object,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default Reviews
