import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useState } from 'react'
import Helper from 'utils/Helper'
import moment from 'moment'

const DetailTable = ({
  data, title, isFetching,
}) => {
  const [page, setPage] = useState({
    current: 1,
    pageSize: 10,
  })

  const pageChange = (pagination) => {
    setPage({
      current: pagination.current,
      pageSize: pagination.pageSize,
    })
  }
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => (page.pageSize * (page.current - 1)) + index + 1,
    },
    /* {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => index + 1,
    }, */
    /* {
      title: 'Ownership',
      dataIndex: 'Ownership',
      key: 'Ownership',
      render: text => text.name || '-',
    }, */
    /* {
      title: 'Metode Pembayaran',
      dataIndex: 'CaraPembayaran',
      key: 'CaraPembayaran',
      render: text => text || '-',
    }, */
    {
      title: 'Keterangan',
      dataIndex: 'Keterangan',
      key: 'Keterangan',
      render: text => text || '-',
    },
    {
      title: 'Tanggal',
      dataIndex: 'TanggalEntry',
      key: 'TanggalEntry',
      render: text => (text ? moment(text).format('DD-MM-YY, HH:mm') : '-'),
    },
    /* {
      title: 'Group Number',
      dataIndex: 'GroupNumber',
      key: 'GroupNumber',
      render: text => text || '-',
    }, */
    {
      title: 'Invest Code',
      dataIndex: 'InvestCode',
      key: 'InvestCode',
      render: text => text || '-',
    },
    /* {
      title: 'Kanal Pembayaran',
      dataIndex: 'KanalPembayaran',
      key: 'KanalPembayaran',
      render: text => text || '-',
    }, */
    {
      title: 'Debit',
      dataIndex: 'Debit',
      key: 'Debit',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Kredit',
      dataIndex: 'Kredit',
      key: 'Kredit',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    /* {
      title: 'Kode Reference',
      dataIndex: 'ReferenceCode',
      key: 'ReferenceCode',
      render: text => text || '-',
    }, */
    /* {
      title: 'Saldo Awal',
      dataIndex: 'SaldoAwal',
      key: 'SaldoAwal',
      render: text => (
        Helper.convertPrice(text)
      ),
    }, */
    {
      title: 'Saldo Akhir',
      dataIndex: 'SaldoAkhir',
      key: 'SaldoAkhir',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
  ]

  return (
    <div>
      <h5 className="mb-4">{title}</h5>
      <Table
        rowKey="ID"
        className="table-striped-rows"
        columns={columns}
        dataSource={data}
        scroll={{ x: true }}
        onChange={pageChange}
        loading={isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

DetailTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  isFetching: PropTypes.bool,
}

export default DetailTable
