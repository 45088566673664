import PropTypes from 'prop-types'
import {
  Table, Icon, Popconfirm, Button,
} from 'antd'
import moment from 'moment'
import history from 'utils/history'

const contactUsCategories = ({
  categoyList, handleDelete, currentUser,
}) => {
  const columns = [
    /* {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => (messageList.meta.per_page * (messageList.meta.current_page - 1)) + index + 1,
    }, */
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => moment(text).format('DD MMM YYYY, HH:mm'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: text => moment(text).format('DD MMM YYYY, HH:mm'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 90,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`/web-management/contact-us-categories/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementcontact-us-categories-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this category?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon type="delete" style={{ color: '#ff5159' }} />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center pb-3">
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/web-management/contact-us-categories/add')}
          >
            Add Category
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={categoyList.data}
        loading={categoyList.isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

contactUsCategories.propTypes = {
  categoyList: PropTypes.any,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default contactUsCategories
