import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchConfig, createConfig, updateConfig } from 'actions/CommissionConfig'
import { message } from 'antd'
import FormCommissionConfigView from 'components/pages/commission/config/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataConfig,
    metaConfig,
  } = state.root.commissionConfig

  return {
    isFetching,
    dataConfig,
    metaConfig,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  createConfig: bindActionCreators(createConfig, dispatch),
  updateConfig: bindActionCreators(updateConfig, dispatch),
  fetchConfig: bindActionCreators(fetchConfig, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('detail', 'setDetail', {}),
  withState('default', 'setDefault', true),
  withState('loadStatus', 'setLoadStatus', false),
  withHandlers({
    handleRadio: props => (e) => {
      const { value } = e.target
      props.setDefault({ is_default: value })
    },
    handleSubmit: props => (values) => {
      if (props.match.params.id) {
        props.updateConfig(values, props.match.params.id).then(() => {
          history.push('/commission/config')
        }).catch((err) => {
          message.error(err)
        })
      } else {
        props.createConfig(values).then(() => {
          history.push('/commission/config')
        }).catch((err) => {
          message.error(err)
        })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetail, dataConfig,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Commission', 'Config', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Commission Config')

      if (match.params.id) {
        this.props.fetchConfig().then(() => {
          setDetail(dataConfig.filter(x => x.id === match.params.id)[0])
        }).catch((err) => {
          message.error(err)
        })
        setIsEdit(true)
      }
    },
  }),
)(FormCommissionConfigView)
