import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import List from 'containers/pages/calculatorManagement/List'
import Form from 'containers/pages/calculatorManagement/Form'

const CalcManagement = ({ location, match, currentUser }) => {
  if (location.pathname === '/calculator-management/asumsi-nilai-suku-bunga') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-calculator-managementasumsi-nilai-suku-bunga-read') > -1) {
      return <List location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/edit') || location.pathname.includes('/add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-calculator-managementasumsi-nilai-suku-bunga-write') > -1) {
      return <Form match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

CalcManagement.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default CalcManagement
