import {
  SETORAN_REQUEST,
  SETORAN_SUCCESS,
  SETORAN_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataSetoran: [],
  metaSetoran: {},
}

export default function setoran(state = initialState, action) {
  switch (action.type) {
    case SETORAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case SETORAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataSetoran: action.data,
        metaSetoran: action.meta,
      }
    case SETORAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
