import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import OttocashManagementList from 'containers/pages/danaOttocash/management/List'
import OttocashManagementForm from 'containers/pages/danaOttocash/management/Form'

const Commission = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/dana-ottocash/management-ottocash') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-ottocashmanagement-dana-ottocash-read') > -1) {
      return <OttocashManagementList location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-ottocashmanagement-dana-ottocash-write') > -1) {
      return <OttocashManagementForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Commission.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Commission
