import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchPakde, deletePakde, fetchManagementContent } from 'actions/Pakde'
import { message } from 'antd'
import ColekView from 'components/pages/colek/List'

export function mapStateToProps(state) {
  const {
    dataFaq,
    metaFaq,
  } = state.root.faq
  const {
    isFetching,
    dataPakDe,
    metaPakDe,
  } = state.root.pakde

  return {
    isFetching,
    dataFaq,
    metaFaq,
    dataPakDe,
    metaPakDe,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchPakde: bindActionCreators(fetchPakde, dispatch),
  deletePakde: bindActionCreators(deletePakde, dispatch),
  fetchManagementContent: bindActionCreators(fetchManagementContent, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('mngContent', 'setMngContent', {
    load: true,
    data: [],
    meta: {
      total_count: 0,
      current: 0,
    },
  }),
  withHandlers({
    handleSearch: () => () => {},
    handlePage: props => (page) => {
      props.fetchPakde(page ? `?${page}` : '')
    },
    handleDelete: props => (id) => {
      props.deletePakde(id).then(() => {
        props.setMngContent({
          ...props.mngContent,
          load: true,
        })
        props.fetchManagementContent('?menu=pak-de').then((res) => {
          props.setMngContent({
            ...props.mngContent,
            load: false,
            data: res.data,
            meta: res.meta,
          })
        }).catch((err) => {
          props.setMngContent({
            ...props.mngContent,
            load: false,
            data: [],
            meta: {
              total_count: 0,
              current: 0,
            },
          })
          message.error(err)
        })
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        setMngContent, mngContent,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Colek Teman'])
      this.props.updateSiteConfiguration('titlePage', 'Colek Teman')

      this.props.fetchManagementContent('?menu=colek-teman').then((res) => {
        setMngContent({
          ...mngContent,
          load: false,
          data: res.data,
          meta: res.meta,
        })
      }).catch((err) => {
        setMngContent({
          ...mngContent,
          load: false,
          data: [],
          meta: {
            total_count: 0,
            current: 0,
          },
        })
        message.error(err)
      })
    },
  }),
)(ColekView)
