import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchPenjelasan, updatePenjelasan } from 'actions/PenjelasanSimpan'
import { message } from 'antd'
import FormPenjelasanSimpanView from 'components/pages/webManagement/penjelasanSimpan/Form'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchPenjelasan: bindActionCreators(fetchPenjelasan, dispatch),
  updatePenjelasan: bindActionCreators(updatePenjelasan, dispatch),
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('longDesc', 'setLongDesc', ''),
  withState('isSubmit', 'setIsSubmit', false),
  withState('detail', 'setDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getDetail: props => () => {
      props.fetchPenjelasan()
        .then((result) => {
          props.setDetail({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch((error) => {
          props.setDetail({
            data: {},
            meta: {},
            isFetching: false,
          })
          message.error(error.message)
        })
    },
  }),
  withHandlers({
    onSubmit: props => (values) => {
      const { params } = props.match
      props.setIsSubmit(!props.isSubmit)
      props.updatePenjelasan(values, params.id)
        .then(() => {
          props.setIsSubmit(!props.isSubmit)
          history.push('/web-management/penjelasan-simpan')
        }).catch((err) => {
          message.error(err)
          props.setIsSubmit(!props.isSubmit)
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Penejelasan Simpan', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Penjelasan Simpan Form')

      this.props.getDetail()
    },
  }),
)(FormPenjelasanSimpanView)
