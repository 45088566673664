import PropTypes from 'prop-types'
import {
  Table, Icon, Tag,
} from 'antd'
import history from 'utils/history'

const AppsVersion = ({
  isFetching, listAppsVersion,
}) => {
  const columns = [
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: text => text || '-',
    },
    {
      title: 'Version code',
      dataIndex: 'version_code',
      key: 'version_code',
      render: text => text || '-',
    },
    {
      title: 'Force update',
      dataIndex: 'force_update',
      key: 'force_update',
      render: (text) => {
        let tags
        if (text === true) {
          tags = <Tag color="green">true</Tag>
        } else {
          tags = <Tag color="red">false</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/apps-version/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={listAppsVersion}
        loading={isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

AppsVersion.propTypes = {
  isFetching: PropTypes.bool,
  listAppsVersion: PropTypes.array,
}

export default AppsVersion
