import PropTypes from 'prop-types'
import {
  Table, Icon, Tag, Input, Select,
} from 'antd'
import history from 'utils/history'

const UpdateDataList = ({
  list, handlePage, handleSearch, handleChangeStatus,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (list.meta.per_page * (list.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Nama Nasabah',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Nomor Nasabah',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <Tag>{text}</Tag>,
    },
    {
      title: 'Keterangan',
      dataIndex: 'notes',
      key: 'notes',
      render: text => text || '-',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="eye" className="cursor-pointer" onClick={() => history.push(`/update-data/${record.id}/detail`)} />
        </div>
      ),
    },
  ]

  const statusList = [
    {
      key: 'inquiry',
      name: 'Inquiry',
    },
    {
      key: 'approved',
      name: 'Approved',
    },
    {
      key: 'rejected',
      name: 'Rejected',
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex flex-column flex-md-row justify-content-between btn-primary-wrapper">
          <div>
            <Select
              placeholder="Status"
              style={{ width: 160 }}
              className="mr-2"
              onChange={handleChangeStatus}
              allowClear
            >
              {statusList.map(item => <Select.Option key={item.key} value={item.key}>{item.name}</Select.Option>)}
            </Select>
            <Input.Search
              placeholder="Search..."
              style={{ width: 240 }}
              allowClear
              enterButton
              onSearch={handleSearch}
            />
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={list.data}
        loading={list.isFetching}
        scroll={{ x: 'max-content' }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: list.meta ? list.meta.total_count : list.data.length,
          current: list.meta ? list.meta.current_page : 1,
          defaultPageSize: 10,
          onChange: handlePage,
        }}
        rowClassName={record => (record.is_error ? 'text-danger' : '')}
      />
    </div>
  )
}

UpdateDataList.propTypes = {
  list: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleChangeStatus: PropTypes.func,
}

export default UpdateDataList
