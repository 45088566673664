
import PropTypes from 'prop-types'
import {
  Table, Button, Input,
  Select, Tag, Popconfirm, Icon
} from 'antd'
import history from 'utils/history'

const RoleList = ({
  list, status, handleDelete, currentUser,
  handleChangeStatus, handleSearch, handlePage,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (list.meta.per_page * (list.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Role Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button type="link" onClick={() => history.push(`/admin-management/role/${record.id}/detail`)}>{text}</Button>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: text => <Tag color={text === true ? 'green' : 'red'}>{text === true ? 'Active' : 'Inactive'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          {["bmc", "bbc", "staff"].indexOf(record.name.toLowerCase()) < 0 &&
            <React.Fragment>
              <Icon type="edit" onClick={() => history.push(`/admin-management/role/${record.id}/edit`)}/>
              {record.name.toLowerCase() != "super admin" &&
                <Popconfirm
                  title="Are you sure delete this User?"
                  okText="Yes"
                  cancelText="No"
                  placement="topLeft"
                  onConfirm={() => handleDelete(record.id)}
                >
                  {(currentUser.permissions && currentUser.permissions.indexOf('manage-user-role-managementuser-role-access-matrix-delete') > -1) && (
                    <Icon type="delete" />
                  )}
                </Popconfirm>
              }
            </React.Fragment>
          }
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        {/* <div className="d-flex justify-content-between"> */}
        <div className="btn-primary-wrapper d-flex justify-content-between mb-4">
          <Button type="primary" icon={<Icon type="plus" />} onClick={() => history.push('/admin-management/role/add')}>
            Add Role
          </Button>
        </div>
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <Select
            placeholder="Status"
            style={{ width: 120 }}
            className="mr-2"
            onChange={handleChangeStatus}
            allowClear
          >
            {status.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select>
          {/* <Select
            placeholder="Order by"
            style={{ width: 130 }}
            className="mr-2"
            onChange={handleChangeOrder}
            allowClear
          >
            {orderBy.map(item => <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>)}
          </Select> */}
          <div>
            <Input.Search
              allowClear
              placeholder="Search by Name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
        </div>

        {/* </div> */}
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={list.data}
        loading={list.isFetching}
        pagination={{
          total: list.meta ? list.meta.total_count : list.data.length,
          current: list.meta ? list.meta.current_page : 1,
          showSizeChanger: true,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

RoleList.propTypes = {
  list: PropTypes.object,
  status: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  handleSearch: PropTypes.func,
  handleDelete: PropTypes.func,
  handlePage: PropTypes.func,
  currentUser: PropTypes.object,
}

export default RoleList
