import {
  OTTOCASH_REQUEST,
  OTTOCASH_SUCCESS,
  OTTOCASH_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataOttocash: [],
  metaOttocash: {},
}

export default function ottocash(state = initialState, action) {
  switch (action.type) {
    case OTTOCASH_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case OTTOCASH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataOttocash: action.data,
        metaOttocash: action.meta,
      }
    case OTTOCASH_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataOttocash: [],
        metaOttocash: {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
