import {
  ULTRA_VOUCHER_CATEGORY_REQUEST,
  ULTRA_VOUCHER_CATEGORY_SUCCESS,
  ULTRA_VOUCHER_CATEGORY_FAILURE,
  ULTRA_VOUCHER_CATEGORY_UPDATED,
  ULTRA_VOUCHER_CATEGORY_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataUltraVoucherCategory: [],
  metaUltraVoucherCategory: {
    total_count: 0,
    current: 0,
  },
  detailUltraVoucherCategory: {},
  metaDetailUltraVoucherCategory: {},
}

export default function ultraVoucherCategories(state = initialState, action) {
  switch (action.type) {
    case ULTRA_VOUCHER_CATEGORY_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ULTRA_VOUCHER_CATEGORY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataUltraVoucherCategory: action.data,
        metaVoucher: action.meta,
      }
    case ULTRA_VOUCHER_CATEGORY_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case ULTRA_VOUCHER_CATEGORY_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataUltraVoucherCategory: state.dataUltraVoucherCategory.filter(item => item.id !== action.data.id),
      }
    case ULTRA_VOUCHER_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailUltraVoucherCategory: action.data,
        metaDetailUltraVoucherCategory: action.meta,
      }
    default:
      return state
  }
}
