import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchContentCourse } from 'actions/LearningContent'
import {
  fetchDetailQuestion, createQuestion, updateQuestion,
  quizQuestionDetail, fetchQuestion,
} from 'actions/QuizQuestion'
import {
  fetchDetailAnswer, createAnswer, updateAnswer, quizAnswerDetail,
} from 'actions/QuizAnswer'
import { message } from 'antd'
import { capitalize } from 'lodash'
import FormQuestionView from 'components/pages/elearnings/quiz/Form'
import history from 'utils/history'
import queryString from 'query-string'

export function mapStateToProps(state) {
  const {
    isFecthing,
    detailQuestion,
    dataQuestion,
  } = state.root.quizQuestion
  const {
    detailAnswer,
  } = state.root.quizAnswer
  return {
    isFecthing,
    detailQuestion,
    dataQuestion,
    detailAnswer,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  createQuestion: bindActionCreators(createQuestion, dispatch),
  updateQuestion: bindActionCreators(updateQuestion, dispatch),
  fetchDetailQuestion: bindActionCreators(fetchDetailQuestion, dispatch),
  quizQuestionDetail: bindActionCreators(quizQuestionDetail, dispatch),
  fetchQuestion: bindActionCreators(fetchQuestion, dispatch),
  createAnswer: bindActionCreators(createAnswer, dispatch),
  updateAnswer: bindActionCreators(updateAnswer, dispatch),
  fetchDetailAnswer: bindActionCreators(fetchDetailAnswer, dispatch),
  quizAnswerDetail: bindActionCreators(quizAnswerDetail, dispatch),
  fetchContentCourse: bindActionCreators(fetchContentCourse, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('loadCourse', 'setLoadCourse', true),
  withState('selectOption', 'setSelectOption', []),
  withState('selectedOption', 'setSelectedOption', ''),
  withState('isEdit', 'setIsEdit', false),
  withState('isAnswer', 'setIsAnswer', false),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match

      props[`${params.id ? 'upd' : 'cre'}ate${capitalize(params.type)}`](values, (params.id || null)).then(() => {
        message.success(`${capitalize(params.type)} has been ${params.id ? 'upd' : 'cre'}ated`).then(() => (
          history.push(`/elearning/quiz/${params.type}`)
        ))
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
        setSelectOption,
        setSelectedOption,
        setLoadCourse,
        setIsAnswer,
        location,
      } = this.props
      const arr = []
      this.props[`quiz${capitalize(match.params.type)}Detail`]({})
      this.props.updateSiteConfiguration('breadList', ['Home', 'e-Learning', `Quiz - ${capitalize(match.params.type)}`])
      this.props.fetchContentCourse().then((res) => {
        res.map(item => arr.push({ id: item.id, label: item.title }))
        setLoadCourse(false)
      })
      if (match.params.type === 'answer') {
        setIsAnswer(true)
        this.props.fetchQuestion()
        setSelectedOption((queryString.parse(location.search) || {}).qid)
      } else {
        setSelectOption(arr)
      }
      if (match.params.id) {
        setIsEdit(true)
        this.props[`fetchDetail${capitalize(match.params.type)}`](match.params.id)
      }
    },
    componentWillReceiveProps(prevProps) {
      const { dataQuestion, setSelectOption } = this.props
      if (dataQuestion !== prevProps.dataQuestion) {
        setSelectOption(dataQuestion.map(item => ({ id: item.id, label: item.question })))
      }
    },
  }),
)(FormQuestionView)
