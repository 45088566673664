import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import ColekPakge from 'containers/pages/colek/List'
import ColekForm from 'containers/pages/colek/Form'

const Colek = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/content-management/colek-teman') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-content-managementcolek-teman-read') > -1) {
      return <ColekPakge location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-content-managementcolek-teman-write') > -1) {
      return <ColekForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Colek.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Colek
