import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchBanners,
  updateBanner,
  createBanner,
} from 'actions/Banners'
import { message } from 'antd'
import FormBannerView from 'components/pages/webManagement/banners/Form'
import Helper from 'utils/Helper'
import { pickBy, identity, find } from 'lodash'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchBanners: bindActionCreators(fetchBanners, dispatch),
  createBanner: bindActionCreators(createBanner, dispatch),
  updateBanner: bindActionCreators(updateBanner, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('stateFileDesktop', 'setStateFileDesktop', ''),
  withState('stateFileMobile', 'setStateFileMobile', ''),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleUploadDesktop: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setStateFileDesktop(file))
      }
      return false
    },
    handleUploadMobile: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setStateFileMobile(file))
      }
      return false
    },
    handleSubmit: props => (value) => {
      props.setIsSubmit(true)
      const payload = {
        ...value,
      }
      const formData = new FormData()
      Object.keys(pickBy(payload, identity)).map((item) => {
        if (item === 'desktop_image' && payload[item]) {
          return formData.append(item, payload[item].file)
        }
        if (item === 'mobile_image' && payload[item]) {
          return formData.append(item, payload[item].file)
        }
        if (item === 'active' && payload[item]) {
          return formData.append(item, payload[item])
        }
        return formData.append(item, payload[item])
      })
      formData.append('active', value.active)
      if (props.match.params.id) {
        props.updateBanner(formData, props.match.params.id)
          .then(() => {
            props.setIsSubmit(false)
            message.success('Banner list berhasil di update')
            history.push('/web-management/banners')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      } else {
        props.createBanner(formData)
          .then(() => {
            props.setIsSubmit(false)
            message.success('Banner list berhasil di tambahkan')
            history.push('/web-management/banners')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setStateFileDesktop,
        setStatus, setStateFileMobile,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Banners', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Banners Form')
      if (match.params.id) {
        this.props.fetchBanners()
          .then((result) => {
            const detail = find(result.data, ['id', match.params.id])
            setStateFileDesktop(detail.desktop_image)
            setStateFileMobile(detail.mobile_image)
            setStatus(detail.active)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormBannerView)
