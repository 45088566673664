import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import CommissionConfigPage from 'containers/pages/commission/config/List'
import CommissionConfigForm from 'containers/pages/commission/config/Form'

const CommissionConfig = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/commission/config') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-commission-write') > -1) {
      return <CommissionConfigPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname === '/commission/config/add') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-commission-write') > -1) {
      return <CommissionConfigForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/edit')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-commission-write') > -1) {
      return <CommissionConfigForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

CommissionConfig.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default CommissionConfig
