import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import PDPList from 'containers/pages/pdp/List'
import PDPForm from 'containers/pages/pdp/Form'

const PDP = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/pdp') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-csopdp-read') > -1) {
      return <PDPList location={location} />
    }
    return <ForbiddenPage />
  }

  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-csopdp-write') > -1) {
      return <PDPForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

PDP.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default PDP
