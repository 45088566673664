import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchInjectPoint, createInjectPoint, fetchInjectPointWithId, UpdateStatusInjectPoint,
} from 'actions/injectPoint'

import InjectPointView from 'components/pages/injectPoint/detail'
import { pickBy, identity, result } from 'lodash'
import qs from 'query-string'
import { List, message } from 'antd'
import history from 'utils/history'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchInjectPoint: bindActionCreators(fetchInjectPoint, dispatch),
  fetchInjectPointWithId: bindActionCreators(fetchInjectPointWithId, dispatch),
  createInjectPoint: bindActionCreators(createInjectPoint, dispatch),
  UpdateStatusInjectPoint: bindActionCreators(UpdateStatusInjectPoint, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isSubmit', 'setIsSubmit', false),
  withState('stateSearch', 'setStateSearch', {}),
  withState('list', 'setList', {
    data: {
      rows: [],
    },
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getList: props => (params) => {
      const { setList } = props
      props.fetchInjectPoint(params)
        .then((result) => {
          setList({
            data: {
              rows: [],
              ...result.data,
            },
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: {
              rows: [],
            },
            meta: {},
            isFetching: false,
          })
        })
    },
    getListWithId: props => (params) => {
      const { match, setList } = props
      props.fetchInjectPointWithId(match.params.id, params)
        .then((result) => {
          setList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: {
              rows: [],
            },
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { match } = props
      const {
        page, search,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        limit: 10,
      }
      if (match.params.id) {
        props.getListWithId(`?${qs.stringify(pickBy(payload, identity))}`)
      } else {
        props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
      }
    },
  }),
  withHandlers({
    handleSubmit: props => (val) => {
      props.setIsSubmit(true)
      const { match } = props
      const payload = {
        status: val || 'draft',
      }
      props.UpdateStatusInjectPoint(match.params.id, payload)
        .then((result) => {
          message.success('status data has been update')
          setTimeout(() => {
            history.push('/inject-point')
          }, 300)
        })
        .catch((err) => {
          props.setIsSubmit(false)
        })
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { onLoad } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Inject Point'])
      this.props.updateSiteConfiguration('titlePage', 'Inject Point')
      onLoad()
    },
  }),
)(InjectPointView)
