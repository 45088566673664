import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { fetchDetailAdmin } from 'actions/Admin'
import DetailAdminView from 'components/pages/adminManagement/admin/Detail'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  fetchDetailAdmin: bindActionCreators(fetchDetailAdmin, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isFetching', 'setIsFetching', false),
  withState('detail', 'setDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getDetail: props => (id) => {
      const { setDetail } = props
      props.fetchDetailAdmin(id)
        .then((result) => {
          setDetail({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setDetail({
            data: {},
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getDetail, match } = this.props
      getDetail(match.params.id)
    },
  }),
)(DetailAdminView)
