import React from 'react'
import PropTypes from 'prop-types'
import {
  Table, Icon,
  Button, Input, Modal,
  Upload, Tag,
} from 'antd'
import history from 'utils/history'
import { Row, Col } from 'react-bootstrap'

const InjectPointPage = ({
  isFetching, handlePage, list,
  metaNotif, dataNotif,
  handleSearch, uploadModal,
  handleUpload, toggleUploadModal,
  handleChange, isAttached,
  previewFile, file, uploading,
  currentUser,
}) => {
  const columns = [
    {
      title: 'Upload By',
      dataIndex: 'uploaded_by',
      key: 'uploaded_by',
      render: text => text.name || '-',
    },
    {
      title: 'Total Data',
      dataIndex: 'total_rows',
      key: 'total_rows',
      render: text => text || '-',
    },
    {
      title: 'Total Users',
      dataIndex: 'total_users',
      key: 'total_users',
      render: text => text || '-',
    },
    {
      title: 'Total Point',
      dataIndex: 'total_points',
      key: 'total_points',
      render: text => text || '-',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: text => (text === 'approved'
          ? <Tag color="green">{text}</Tag>
          : <Tag>{text}</Tag>),
      },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
        {(currentUser.permissions && currentUser.permissions.indexOf('manage-inject-point-read') > -1) && (
          <Icon type="edit" onClick={() => history.push(`/inject-point/${record.id}/edit`)} />
        )}
        </div>
      ),
    },
  ]
  return (
    <React.Fragment>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
        {(currentUser.permissions && currentUser.permissions.indexOf('manage-inject-point-write') > -1) && (

          <div>
            <Button
              type="primary"
              onClick={() => toggleUploadModal()}
            >
              Upload
            </Button>
          </div>
        )}
        </div>
      </div>

      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={list.data}
        loading={isFetching}
        scroll={{ x: 'max-content' }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: list.meta ? list.meta.total_count : list.data,
          current: list.meta ? list.meta.current_page : 1,
          defaultPageSize: 10,
          onChange: handlePage,
        }}
        rowClassName={record => (record.is_error ? 'text-danger' : '')}
      />
      <Modal
        title="Inject Point"
        centered
        visible={uploadModal}
        footer={null}
        onCancel={() => toggleUploadModal()}
      >
        <div>
          <Row>
            <Col md="4" span={8} className="mb-2">
              Bulk Upload Excel *
            </Col>
            <Col md="8" span={8} className="mb-2">
              <Upload
                name="document"
                listType="document"
                beforeUpload={() => false}
                onChange={e => handleChange(e)}
                fileList={previewFile}
                accept=".xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >
                <Button>
                  <Icon type="upload" />
                  {' '}
                  Attach file
                </Button>
              </Upload>
              <small>Ekstensi file yang diizinkan adalah .xlsx</small>
            </Col>
          </Row>
          <div className="mt-5 d-flex justify-content-center">
            <Button
              type="primary"
              onClick={() => handleUpload(file)}
              disabled={isAttached}
              loading={uploading}
            >
              Kirim
            </Button>
          </div>

        </div>
      </Modal>
    </React.Fragment>
  )
}

InjectPointPage.propTypes = {
  list: PropTypes.object,
  isFetching: PropTypes.bool,
  dataNotif: PropTypes.array,
  metaNotif: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  uploadModal: PropTypes.bool,
  toggleUploadModal: PropTypes.func,
  handleChange: PropTypes.func,
  handleUpload: PropTypes.func,
  isAttached: PropTypes.bool,
  previewFile: PropTypes.any,
  file: PropTypes.any,
  uploading: PropTypes.bool,
  currentUser: PropTypes.object,
}

export default InjectPointPage
