import { connect } from 'react-redux'
import {
  compose, lifecycle, withState, withHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchBanners, deleteBanner } from 'actions/Banners'
import BannerView from 'components/pages/webManagement/banners/List'
import { message } from 'antd'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchBanners: bindActionCreators(fetchBanners, dispatch),
  deleteBanner: bindActionCreators(deleteBanner, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('bannerList', 'setBannerList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getBannerList: props => (params) => {
      props.fetchBanners(params)
        .then((result) => {
          props.setBannerList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch((error) => {
          props.setBannerList({
            data: [],
            meta: {},
            isFetching: false,
          })
          message.error(error.message)
        })
    },
  }),
  withHandlers({
    handleDelete: props => (id) => {
      props.deleteBanner(id)
        .then(() => {
          props.getBannerList()
        })
        .catch((error) => { message.error(error) })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Banners'])
      this.props.updateSiteConfigs({
        titlePage: 'Banner List',
        activePage: 'web-management/banners',
        activeSubPage: 'web-management',
      })
      this.props.getBannerList()
    },
  }),
)(BannerView)
