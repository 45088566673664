import API from 'utils/API'
import { message } from 'antd'
import {
  PAKDE_REQUEST,
  PAKDE_SUCCESS,
  PAKDE_FAILURE,
  PAKDE_UPDATED,
  PAKDE_DETAIL_SUCCESS,
  REMIND_FRIEND_SUCCESS,
} from 'constants/ActionTypes'
import config from 'app/config'

export const pakdeRequest = () => ({
  type: PAKDE_REQUEST,
})

export const pakdeSuccess = (data, meta) => ({
  type: PAKDE_SUCCESS,
  data,
  meta,
})

export const pakdeFailure = errorMessage => ({
  type: PAKDE_FAILURE,
  errorMessage,
})

export const pakdeUpdate = data => ({
  type: PAKDE_UPDATED,
  data,
})

export const pakdeDetail = data => ({
  type: PAKDE_DETAIL_SUCCESS,
  data,
})

export const remindFriendSuccess = (data, meta) => ({
  type: REMIND_FRIEND_SUCCESS,
  data,
  meta,
})

export const fetchManagementContent = params => (
  () => {
    const url = `${config.api_url_content}/management-content${params || ''}`

    return new Promise((resolve, reject) => {
      API.get(
        url,
      ).then(
        (response) => {
          const { meta } = response.data
          if (meta.status) {
            resolve(response.data)
          } else {
            reject(meta.message)
          }
        },
      ).catch((err) => {
        reject(err.message) // eslint-disable-line no-console
      })
    })
  }
)

export const fetchPakde = params => (
  (dispatch) => {
    dispatch(pakdeRequest())
    const url = `/pak-de/candidate${params || ''}`

    return API.get(
      url,
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(pakdeSuccess(data, meta))
        } else {
          dispatch(pakdeFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(pakdeFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailPakde = id => (
  (dispatch) => {
    dispatch(pakdeRequest())
    const url = `${config.api_url_content}/management-content/view/${id}`

    return API.get(
      url,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(pakdeDetail(data))
        } else {
          dispatch(pakdeFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(pakdeFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deletePakde = id => (
  (dispatch) => {
    dispatch(pakdeRequest())

    return new Promise((resolve, reject) => (
      API.delete(
        `${config.api_url_content}/management-content/delete/${id}`,
      ).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            message.success('Data has been deleted')
            dispatch(pakdeUpdate(data))
            resolve(data)
          } else {
            message.error(meta.message)
            dispatch(pakdeFailure(meta.message))
            reject(meta.message)
          }
        },
      ).catch((err) => {
        message.error(err.message)
        dispatch(pakdeFailure(err.message)) // eslint-disable-line no-console
        reject(err.message)
      })
    ))
  }
)

export const createPakde = payload => (
  () => (
    new Promise((resolve, reject) => (
      API.post(
        `${config.api_url_content}/management-content/create?menu=pak-de`,
        payload,
      ).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
          } else {
            message.error(meta.message)
            reject(meta.message)
          }
        },
      ).catch((err) => {
        if (err.response && err.response.data) {
          const { meta } = err.response.data
          message.error(meta.message)
          reject(meta.message)
        } else {
          message.error(err.message)
          reject(err.message)
        }
      })
    ))
  )
)

export const updatePakde = (payload, id) => (
  () => (
    new Promise((resolve, reject) => (
      API.put(
        `${config.api_url_content}/management-content/edit/${id}`,
        payload,
      ).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
          } else {
            message.error(meta.message)
            reject(meta.message)
          }
        },
      ).catch((err) => {
        if (err.response && err.response.data) {
          const { meta } = err.response.data
          message.error(meta.message)
          reject(meta.message)
        } else {
          message.error(err.message)
          reject(err.message)
        }
      })
    ))
  )
)

// ingatkan teman menabung
// export const fetchRemindFriend = params => (
//   (dispatch) => {
//     dispatch(pakdeRequest())
//     const url = `https://private-e1cc1-david245.apiary-mock.com/remind-friend${params || ''}`
//     return API.get(url).then(
//       (response) => {
//         const { data, meta } = response.data
//         if (meta.status) {
//           dispatch(remindFriendSuccess(data, meta))
//         } else {
//           dispatch(pakdeFailure(meta.message))
//         }
//       },
//     ).catch((err) => {
//       dispatch(pakdeFailure(err.message)) // eslint-disable-line no-console
//     })
//   }
// )
