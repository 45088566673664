import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchCustomer, verifyCustomer } from 'actions/Customer'
import UserView from 'components/pages/customers/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataCustomer,
    metaCustomer,
  } = state.root.customer
  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataCustomer,
    metaCustomer,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
  verifyCustomer: bindActionCreators(verifyCustomer, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('isVerifyPhone', 'setIsVerifyPhone', false),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchCustomer(props.match.params.type === 'phone' ? `-phone?${qs.stringify(pickBy(payload, identity))}` : `?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleVerify: props => (id, mail) => {
      const payload = {
        email: mail,
      }
      props.verifyCustomer(id, payload)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { params } = this.props.match
      this.props.updateSiteConfiguration('breadList', ['Home', `Customer - ${params.type === 'phone' ? 'Phone Number' : 'Verify'}`])
      this.props.updateSiteConfiguration('titlePage', `Customer ${params.type === 'phone' ? 'Phone Number' : 'Verify'}`)
      this.props.fetchCustomer(params.type === 'phone' ? '-phone' : '')
      if (params.type === 'phone') {
        this.props.setIsVerifyPhone(true)
      } else {
        this.props.setIsVerifyPhone(false)
        history.push('/dashboard')
      }
    },
  }),
)(UserView)
