import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfigs, updateSiteConfiguration } from 'actions/Site'
import { fetchTemankuDownlineList } from 'actions/User'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import TemankuDownlineView from 'components/pages/users/temanku'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  fetchTemankuDownlineList: bindActionCreators(fetchTemankuDownlineList, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

const dataStatusClaim = [
  { id: 1, label: 'Semua', value: '' },
  { id: 2, label: 'Sudah Klaim', value: 'sudah klaim' },
  { id: 3, label: 'Belum Klaim', value: 'belum klaim' },
]

const dataStatusIuran = [
  { id: 1, label: 'Semua', value: '' },
  { id: 2, label: 'Sudah Mengiur', value: 'sudah mengiur' },
  { id: 3, label: 'Belum Mengiur', value: 'belum mengiur' },
]

const dataStatusRegistration = [
  { id: 1, label: 'Semua', value: '' },
  { id: 2, label: 'Terdaftar', value: 'Terdaftar' },
  { id: 3, label: 'Belum Mendaftar', value: 'Belum Mendaftar' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('statusClaim', 'setStatusClaim', dataStatusClaim),
  withState('statusIuran', 'setStatusIuran', dataStatusIuran),
  withState('statusRegistration', 'setStatusRegistration', dataStatusRegistration),
  withState('stateSearch', 'setStateSearch', {}),
  withState('dataTemanku', 'setDataTemanku', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getTemankuDownlineList: props => (params) => {
      const { match, setDataTemanku } = props
      props.fetchTemankuDownlineList(match.params.id, params)
        .then((result) => {
          setDataTemanku({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setDataTemanku({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, search, statusClaim, monthlyStatus, statusRegistration,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        claim_status: statusClaim || '',
        monthly_fee_status: monthlyStatus || '',
        registration_status: statusRegistration || '',
      }
      props.getTemankuDownlineList(`&${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleChangeStatusClaim: props => (val) => {
      const {
        onLoad, setStateSearch, stateSearch, setDataTemanku,
      } = props
      setDataTemanku({
        data: [],
        meta: {},
        isFetching: true,
      })
      setStateSearch({
        ...stateSearch,
        statusClaim: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeStatusIuran: props => (val) => {
      const {
        onLoad, setStateSearch, stateSearch, setDataTemanku,
      } = props
      setDataTemanku({
        data: [],
        meta: {},
        isFetching: true,
      })
      setStateSearch({
        ...stateSearch,
        monthlyStatus: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeStatusRegistration: props => (val) => {
      const {
        onLoad, setStateSearch, stateSearch, setDataTemanku,
      } = props
      setDataTemanku({
        data: [],
        meta: {},
        isFetching: true,
      })
      setStateSearch({
        ...stateSearch,
        statusRegistration: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleSearch: props => (val) => {
      const {
        onLoad, setStateSearch, stateSearch, setDataTemanku,
      } = props
      setDataTemanku({
        data: [],
        meta: {},
        isFetching: true,
      })
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const {
        onLoad, setStateSearch, stateSearch, setDataTemanku,
      } = props
      setDataTemanku({
        data: [],
        meta: {},
        isFetching: true,
      })
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Member Management', 'Temanku', 'List'])
      this.props.updateSiteConfigs({
        titlePage: 'Member Management - Temanku',
        activePage: 'user',
        activeSubPage: 'user',
      })
      this.props.onLoad()
    },
  }),
)(TemankuDownlineView)
