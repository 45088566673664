import {
  ABOUT_DPLK_REQUEST,
  ABOUT_DPLK_SUCCESS,
  ABOUT_DPLK_FAILURE,
  ABOUT_DPLK_UPDATED,
  ABOUT_DPLK_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataAboutDplk: [],
  detailAboutDplk: {},
  metaAboutDplk: {},
}

export default function aboutDplk(state = initialState, action) {
  switch (action.type) {
    case ABOUT_DPLK_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ABOUT_DPLK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataAboutDplk: action.data,
        metaAboutDplk: action.meta,
      }
    case ABOUT_DPLK_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case ABOUT_DPLK_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataAboutDplk: state.dataAboutDplk.filter(item => item.id !== action.data.id),
      }
    case ABOUT_DPLK_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailAboutDplk: action.data,
      }
    default:
      return state
  }
}
