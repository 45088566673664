import { combineReducers } from 'redux'
import site from 'reducers/Site'
import auth from 'reducers/Auth'
import learningContent from 'reducers/LearningContent'
import quizQuestion from 'reducers/QuizQuestion'
import quizAnswer from 'reducers/QuizAnswer'
import notification from 'reducers/Notification'
import user from 'reducers/User'
import benefit from 'reducers/Benefit'
import about from 'reducers/About'
import voucher from 'reducers/Voucher'
import deposit from 'reducers/Deposit'
import category from 'reducers/Category'
import faq from 'reducers/Faq'
import customer from 'reducers/Customer'
import pakde from 'reducers/Pakde'
import calculatorManagement from 'reducers/CalculatorManagement'
import calculationDplk from 'reducers/CalculationDplk'

import setoran from 'reducers/Setoran'
import transaction from 'reducers/Transaction'
import commission from 'reducers/Commission'
import ultraVoucherCategory from 'reducers/UltraVoucherCategories'
import ultraVoucher from 'reducers/UltraVouchers'
import ultraVoucherBrand from 'reducers/UltraVoucherBrands'
import commissionConfig from 'reducers/CommissionConfig'
import aboutDplk from 'reducers/AboutDplk'
import ottocash from 'reducers/Ottocash'
import ottopay from 'reducers/Ottopay'
import uvLogs from 'reducers/UltraVoucherLogs'
import dashboard from 'reducers/Dashboard'

import autoDebitPendaftaran from 'reducers/AutoDebitPendaftaran'
import autoDebitPembatalan from 'reducers/AutoDebitPembatalan'
import autoDebitLogPayment from 'reducers/AutoDebitLogPayment'

export default combineReducers({
  site,
  auth,
  learningContent,
  notification,
  quizQuestion,
  quizAnswer,
  user,
  benefit,
  about,
  voucher,
  deposit,
  category,
  faq,
  customer,
  pakde,
  calculatorManagement,
  calculationDplk,
  setoran,
  transaction,
  commission,
  ultraVoucherCategory,
  ultraVoucher,
  ultraVoucherBrand,
  commissionConfig,
  aboutDplk,
  ottocash,
  ottopay,
  uvLogs,
  dashboard,
  autoDebitPendaftaran,
  autoDebitPembatalan,
  autoDebitLogPayment,
})
