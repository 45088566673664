import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Radio,
  InputNumber, Button,
} from 'antd'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormCommissionConfig = ({
  detail, handleSubmit,
  form, isEdit, isSubmit,
  handleRadio,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Commission Percent">
          {getFieldDecorator('commission_percent', {
            initialValue: isEdit ? detail.commission_percent : '',
            rules: [{ required: true }],
          })(
            <InputNumber
              placeholder="%"
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
            />,
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('is_default', {
            initialValue: isEdit ? detail.is_default : true,
            rules: [{ required: false }],
          })(
            <Radio.Group
              buttonStyle="solid"
              onChange={e => handleRadio(e)}
            >
              <Radio.Button value>Set as Default</Radio.Button>
              <Radio.Button value={false}>Inactive</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button loading={isSubmit} type="primary" htmlType="submit">{!isEdit ? 'Add Data' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormCommissionConfig.propTypes = {
  detail: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  handleRadio: PropTypes.func,
}

export default Form.create({ name: 'commissionConfigForm' })(FormCommissionConfig)
