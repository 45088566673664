import API from 'utils/API'
import { message } from 'antd'
import {
  ABOUT_REQUEST,
  ABOUT_SUCCESS,
  ABOUT_FAILURE,
  ABOUT_UPDATED,
  ABOUT_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const aboutRequest = () => ({
  type: ABOUT_REQUEST,
})

export const aboutSuccess = (data, meta) => ({
  type: ABOUT_SUCCESS,
  data,
  meta,
})

export const aboutFailure = errorMessage => ({
  type: ABOUT_FAILURE,
  errorMessage,
})

export const aboutUpdate = data => ({
  type: ABOUT_UPDATED,
  data,
})

export const aboutDetail = data => ({
  type: ABOUT_DETAIL_SUCCESS,
  data,
})

export const fetchAbout = params => (
  (dispatch) => {
    dispatch(aboutRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/about-us${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(aboutSuccess(data, meta))
        } else {
          dispatch(aboutFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(aboutFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailAbout = id => (
  (dispatch) => {
    dispatch(aboutRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/about-us/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(aboutDetail(data))
        } else {
          dispatch(aboutFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(aboutFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteAbout = id => (
  (dispatch) => {
    dispatch(aboutRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/about-us/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Data has been deleted')
          dispatch(aboutUpdate(data))
        } else {
          message.error(meta.message)
          dispatch(aboutFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(aboutFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createAbout = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/about-us`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const updateAbout = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/about-us/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)
