import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchUltraVoucherBrands, fetchSyncData } from 'actions/UltraVoucherBrands'
import { fetchUltraVoucherCategory } from 'actions/UltraVoucherCategories'
import UltraVoucherBrandView from 'components/pages/ultraVouchers/brands/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import { message } from 'antd'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataUltraVoucherBrand,
    metaUltraVoucherBrand,
  } = state.root.ultraVoucherBrand

  return {
    isFetching,
    dataUltraVoucherBrand,
    metaUltraVoucherBrand,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchUltraVoucherBrands: bindActionCreators(fetchUltraVoucherBrands, dispatch),
  fetchSyncData: bindActionCreators(fetchSyncData, dispatch),
  fetchUltraVoucherCategory: bindActionCreators(fetchUltraVoucherCategory, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('selectOption', 'setSelectOption', []),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchUltraVoucherBrands(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),

  withHandlers({
    handleSyncData: props => () => {
      props.fetchSyncData()
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val.toLowerCase(),
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { setSelectOption } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Ultra Voucher', 'Brands'])
      this.props.updateSiteConfiguration('titlePage', 'Ultra Voucher - Brands')
      this.props.fetchUltraVoucherBrands()
      this.props.fetchUltraVoucherCategory().then((res) => {
        setSelectOption(res)
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
)(UltraVoucherBrandView)
