import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import ClaimNormalView from 'containers/pages/claim/claimNormal/List'
import ClaimNormalDetail from 'containers/pages/claim/claimNormal/detail'

const ClaimNormal = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/claim/claim-normal') { 
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-normal-read') > -1) {
      return <ClaimNormalView location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-normal-read') > -1) {
      return <ClaimNormalDetail location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

ClaimNormal.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default ClaimNormal
