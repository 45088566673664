import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchCommission, approveCommission, exportFile } from 'actions/Commission'
import CommissionPage from 'components/pages/commission/List'
import { message } from 'antd'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
// import { saveAs } from 'file-saver'
import moment from 'moment'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataCommission,
    metaCommission,
  } = state.root.commission
  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataCommission,
    metaCommission,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchCommission: bindActionCreators(fetchCommission, dispatch),
  approveCommission: bindActionCreators(approveCommission, dispatch),
  exportFile: bindActionCreators(exportFile, dispatch),
})
const dataSelect = [
  { id: 1, label: 'pending' },
  { id: 2, label: 'approved' },
]
const dataLimit = [
  { id: 1, label: '10' },
  { id: 2, label: '15' },
  { id: 3, label: '25' },
  { id: 4, label: '50' },
  { id: 5, label: '100' },
]
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('clearSelected', 'setClearSelected', false),
  withState('statusList', 'setStatusListn', dataSelect),
  withState('limitList', 'setLimitList', dataLimit),
  withState('paramsFile', 'setParamsFile', ''),
  withState('stateSearch', 'setStateSearch', {}),
  withState('isExport', 'setIsExport', false),
  /* withHandlers({
    getExportFile: props => (val) => {
      const { exportFile } = props
      props.exportFile(val)
      .then((result)=>{
        props.setDataFile(result.data)
      })
    }
  }), */
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, search, status, limit,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        status: status || '',
        limit: limit || '',
      }
      props.setParamsFile(`?${qs.stringify(pickBy(payload, identity))}`)
      props.fetchCommission(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleReport: props => () => {
      const { paramsFile, setIsExport } = props
      setIsExport(true)
      props.exportFile(paramsFile)
        .then((result) => {
          if (result) {
            // const FileSaver = require('file-saver') // eslint-disable-line global-require
            // const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            // FileSaver.saveAs(blob, `commission_report_${moment().format('DD_MM_YY')}.xlsx`)
            history.push('/download')
            setIsExport(false)
          } else {
            message.error('Something when wrong')
          }
        }).catch((error) => {
          message.error(error)
          setIsExport(false)
        })
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        status: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleLimit: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        limit: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleSubmit: props => (val) => {
      props.setClearSelected(!props.clearSelected)
      props.approveCommission({ commission_tx_ids: val }).catch((err) => {
        message.error(err)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Commission'])
      this.props.updateSiteConfigs({
        titlePage: 'Commission',
        activePage: 'commission',
        activeSubPage: 'commission',
      })
      this.props.fetchCommission()
      // this.props.getExportFile()
    },
  }),
)(CommissionPage)
