import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchHighlight, createHighlight, updateHighlight } from 'actions/HighlightSimpan'
import { message } from 'antd'
import FormHighlightView from 'components/pages/webManagement/highlightSimpan/Form'
import Helper from 'utils/Helper'
import { pickBy, identity, find } from 'lodash'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchHighlight: bindActionCreators(fetchHighlight, dispatch),
  createHighlight: bindActionCreators(createHighlight, dispatch),
  updateHighlight: bindActionCreators(updateHighlight, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('stateFile', 'setStateFile', ''),
  withState('detailHighlight', 'setDetailHighlight', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleUpload: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setStateFile(file))
      }
      return false
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      const payload = {
        ...values,
      }
      const formData = new FormData()
      Object.keys(pickBy(payload, identity)).map((item) => {
        if (item === 'icon' && payload[item]) {
          return formData.append(item, payload[item].file)
        }
        return formData.append(item, payload[item])
      })
      formData.append('active', values.active)
      if (props.match.params.id) {
        props.updateHighlight(formData, props.match.params.id)
          .then(() => {
            message.success('Highlight berhasil di update')
            props.setIsSubmit(false)
            history.push('/web-management/highlight-simpan')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      } else {
        props.createHighlight(formData)
          .then(() => {
            message.success('Highlight berhasil di tambahkan')
            props.setIsSubmit(false)
            history.push('/web-management/highlight-simpan')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetailHighlight, setStateFile, setStatus,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Highlight Simpan', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Highlight Form')
      if (match.params.id) {
        this.props.fetchHighlight()
          .then((result) => {
            const detail = find(result.data, ['id', match.params.id])
            setDetailHighlight(detail)
            setStateFile(detail.icon)
            setStatus(detail.active)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormHighlightView)
