import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchOttocashLog, statusOttocashLog, exportFile } from 'actions/DanaOttocash'
import OttocashLogsView from 'components/pages/danaOttocash/logOttocash/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
// import { saveAs } from 'file-saver'
import { message } from 'antd'
import moment from 'moment'


export function mapStateToProps(state) {
  const {
    isFetching,
    dataOttocash,
    metaOttocash,
  } = state.root.ottocash

  return {
    isFetching,
    dataOttocash,
    metaOttocash,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchOttocashLog: bindActionCreators(fetchOttocashLog, dispatch),
  statusOttocashLog: bindActionCreators(statusOttocashLog, dispatch),
  exportFile: bindActionCreators(exportFile, dispatch),
})

const dataLimit = [
  { id: 1, label: '10' },
  { id: 2, label: '15' },
  { id: 3, label: '25' },
  { id: 4, label: '50' },
  { id: 5, label: '100' },
]

const dataStatus = [
  { id: 1, label: 'pending' },
  { id: 2, label: 'success' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('limitList', 'setLimitList', dataLimit),
  withState('statusList', 'setStatusListn', dataStatus),
  withState('dataFile', 'setDataFile', ''),
  withState('paramsFile', 'setParamsFile', ''),
  withState('stateSearch', 'setStateSearch', {}),
  withState('isExport', 'setIsExport', false),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search, limit, status, startDate, endDate } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        status: status || '',
        start_date: startDate || '',
        end_date: endDate || '',
        limit: limit || '',
      }
      props.setParamsFile(`?${qs.stringify(pickBy(payload, identity))}`)
      props.fetchOttocashLog(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleReport: props => () => {
      const { paramsFile, setIsExport } = props
      setIsExport(true)
      props.exportFile(paramsFile)
        .then((result) => {
          if (result) {
            const FileSaver = require('file-saver') // eslint-disable-line global-require
            const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            FileSaver.saveAs(blob, `ottocash_log_report_${moment().format('DD_MM_YY')}.xlsx`)
            setIsExport(false)
          } else {
            message.error('Something when wrong')
          }
        }).catch((error) => {
          message.error(error)
          setIsExport(false)
        })
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        status: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleLimit: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        limit: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeDate: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      let getDateStart
      let getDateEnd
      if (val.length === 2) {
        getDateStart = `${val[0].format('YYYY-MM-DD')}`
        getDateEnd = `${val[1].format('YYYY-MM-DD')}`
      } else {
        getDateStart = ''
        getDateEnd = ''
      }
      setStateSearch({
        ...stateSearch,
        startDate: getDateStart,
        endDate: getDateEnd,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Management Dana Ottocash', 'Logs'])
      this.props.updateSiteConfigs({
        titlePage: 'Ottocash Disburse Logs',
        activePage: 'dana-ottocash/management-ottocash/logs',
        activeSubPage: 'ottocash',
      })
      this.props.fetchOttocashLog()
    },
  }),
)(OttocashLogsView)
