import {
  SET_SITE_THEME,
  SET_SITE_CONFIGURATION,
  SET_SITE_CONFIGS,
} from 'constants/ActionTypes'

const initialState = {
  light: false,
  titlePage: 'Dasboard',
  activePage: 'dashboard',
  activeSubPage: 'dashboard',
  breadList: ['Home'],
}

export default function site(state = initialState, action) {
  switch (action.type) {
    case SET_SITE_THEME:
      return {
        ...state,
        light: action.theme,
      }
    case SET_SITE_CONFIGURATION:
      return {
        ...state,
        [action.dataKey]: action.dataValue,
      }
    case SET_SITE_CONFIGS:
      return {
        ...state,
        titlePage: action.params.titlePage,
        activePage: action.params.activePage,
        activeSubPage: action.params.activeSubPage,
      }
    default:
      return state
  }
}
