import PropTypes from 'prop-types'
import {
  Table, Button, Icon,
} from 'antd'
import moment from 'moment'

const DownloadPage = ({
  handlePage,
  list, onLoad,
  handleOpenNewTab,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => (list.meta.per_page * (list.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Type Download',
      dataIndex: 'name',
      key: 'name',
      render: text => (text || '-'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: text => (text || '-'),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => (text ? moment(text).format('YYYY-MM-DD, HH:mm') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (text || '-'),
    },
    {
      title: 'Download File',
      dataIndex: 'url',
      key: 'url',
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button disabled={!record.status.includes('done')} type="primary" onClick={() => handleOpenNewTab(text)}>Download</Button>
        </div>
      ),
    },
  ]
  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div className="ml-auto">
            <Button type="primary" onClick={onLoad}>
              <Icon type="reload" /> Refresh Data
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={list.data}
        // expandedRowRender={expandedRowRender}
        loading={list.isFetching}
        className="components-table-demo-nested"
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: list ? list.meta.per_page : 10,
          total: list ? list.meta.total_count : list.data.length,
          current: list ? list.meta.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

DownloadPage.propTypes = {
  isFetching: PropTypes.bool,
  handlePage: PropTypes.func,
  list: PropTypes.object,
  onLoad: PropTypes.func,
  handleOpenNewTab: PropTypes.func,
}

export default DownloadPage
