import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfigs, updateSiteConfiguration } from 'actions/Site'
import { fetchHistoryPoint, exportFileHistoryPoint } from 'actions/User'
import HistoryPointView from 'components/pages/users/historyPoint'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import history from 'utils/history'
import { message } from 'antd'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchHistoryPoint: bindActionCreators(fetchHistoryPoint, dispatch),
  exportFileHistoryPoint: bindActionCreators(exportFileHistoryPoint, dispatch),
})

const sortBy = [
  { id: 1, value: 'debit', name: 'Debit' },
  { id: 2, value: 'credit', name: 'Credit' },
  { id: 3, value: 'description', name: 'Description' },
]

const orderBy = [
  { id: 1, value: 'asc', name: 'Ascending' },
  { id: 2, value: 'desc', name: 'Descending' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('sortBy', 'setSortBy', sortBy),
  withState('orderBy', 'setOrderBy', orderBy),
  withState('stateSearch', 'setStateSearch', {}),
  withState('data', 'setData', {
    list: [],
    meta: {},
    isFetching: true,
  }),
  withState('paramsFile', 'setParamsFile', ''),
  withState('isExport', 'setIsExport', false),
  withHandlers({
    getList: props => (params) => {
      const { match, setData } = props
      props.fetchHistoryPoint(match.params.id, params)
        .then((result) => {
          setData({
            list: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setData({
            list: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, startDate, endDate,
        order_by, sort_by
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        start_date: startDate || '',
        end_date: endDate || '',
        order: order_by || '',
        sort: sort_by || ''
      }
      const payloadExport = {
        start_date: startDate || '',
        end_date: endDate || '',
        order: order_by || '',
        sort: sort_by || ''
      }
      props.setParamsFile(`?${qs.stringify(pickBy(payloadExport, identity))}`)
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
    },
    handleReport: props => () => {

      const { paramsFile, setIsExport, match } = props
      setIsExport(true)
      props.exportFileHistoryPoint(match.params.id, paramsFile)
        .then((result) => {
          if (result) {
            setIsExport(false)
            history.push('/download')
          } else {
            message.error('Something when wrong')
          }
        }).catch((error) => {
          message.error(error)
          setIsExport(false)
        })
    },
  }),
  withHandlers({
    handleChangeSort: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        sort_by: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeOrder: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        order_by: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeDate: props => (dates) => {
      const { onLoad, setStateSearch, stateSearch } = props
      let start = ''
      let end = ''
      if (dates.length === 2) {
        start = dates[0].format('DD-MM-YYYY')
        end = dates[1].format('DD-MM-YYYY')
      } else {
        start = ''
        end = ''
      }
      setStateSearch({
        ...stateSearch,
        startDate: start,
        endDate: end,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Member Management', 'History Poin'])
      this.props.updateSiteConfigs({
        titlePage: 'History Point',
        activePage: 'user',
        activeSubPage: 'user',
      })
      this.props.getList()
    },
  }),
)(HistoryPointView)
