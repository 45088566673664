import PropTypes from 'prop-types'
import {
  Table, Tag, Tooltip,
  Input, Icon, Button,
  Select,
} from 'antd'
import history from 'utils/history'
// import { CSVLink } from 'react-csv'
import moment from 'moment'

const CommissionPage = ({
  isFetching, dataCommission, currentUser,
  handleSearch, handleSubmit,
  handlePage, metaCommission,
  handleChangeStatus, isLoadStatus,
  statusList, handleLimit,
  limitList, isExport, handleReport,
}) => {
  let value = []
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      value = selectedRowKeys
    },

    getCheckboxProps: record => ({
      disabled: record.status === 'approved',
    }),
  }

  const columns = [
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 110,
      render: text => (text ? moment(text).format('DD MMM YY') : '-'),
    },
    {
      title: 'Nama',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: 'Nomor Peserta',
      dataIndex: 'registered_number',
      key: 'registered_number',
    },
    {
      title: 'Teman PAKDE',
      dataIndex: 'total_pakde_friends',
      key: 'total_pakde_friends',
      render: text => text || '0',
    },
    {
      title: 'Komisi',
      dataIndex: 'to_be_disbursed_commission',
      key: 'to_be_disbursed_commission',
      render: text => (text ? `${text} Poin` : '0'),
    },
    {
      title: 'Total Komisi',
      dataIndex: 'total_commission',
      key: 'total_commission',
      render: text => (text ? `${text} Poin` : '0'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => (text === 'approved'
        ? <Tag color="green">{text}</Tag>
        : <Tag>{text}</Tag>),
    },
    {
      title: 'Downline',
      dataIndex: '',
      key: 'Downline',
      width: 100,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Tooltip title="Downline list">
            <Icon type="eye" onClick={() => history.push(`/commission/detail/${record.id}`)} />
          </Tooltip>
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div className="d-flex mr-2">
            <h6 className="mb-1 mr-2 align-self-center">Filter By: </h6>
            <Select
              placeholder="Status"
              style={{ width: 120 }}
              onChange={handleChangeStatus}
              loading={isLoadStatus}
              disabled={isLoadStatus}
              className="mr-2"
            >
              {statusList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <Select
              placeholder="Limit"
              style={{ width: 120 }}
              onChange={handleLimit}
              loading={isLoadStatus}
              disabled={isLoadStatus}
              className="mr-2"
            >
              {limitList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <Input.Search
              allowClear
              placeholder="Search by Name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          {/* <div className="mr-3">
            <CSVLink
              data={dataFile}
              target="_blank"
              filename={`commission_${moment(new Date()).format('hhmmss')}.csv`}
              variant="primary"
              className="btn btn-primary ant-btn-primary"
            >
              Unduh Tabel Klaim
            </CSVLink>
          </div> */}
          <div className="mr-3 btn-primary-wrapper">
            <Button
              loading={isExport}
              type="primary"
              className="align-items-center btn-border-mitra"
              onClick={handleReport}
            >
              Export
            </Button>
          </div>
        </div>
        {(currentUser.permissions && currentUser.permissions.indexOf('manage-commission-write') > -1) && (
          <div className="btn-primary-wrapper">
            <Button
              type="primary"
              onClick={() => history.push('/commission/config')}
            >
              <Icon type="setting" />
              Atur Komisi
            </Button>
          </div>
        )}
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataCommission}
        loading={isFetching}
        rowSelection={rowSelection}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaCommission ? metaCommission.per_page : 10,
          total: metaCommission ? metaCommission.total_count : dataCommission.length,
          current: metaCommission ? metaCommission.current_page : 1,
          onChange: handlePage,
        }}
      />
      <div className="btn-primary-wrapper justify-content-end d-flex w-100 pt-3">
        <Button disabled={dataCommission === null} size="large" type="primary" onClick={() => handleSubmit(value)}>Approve</Button>
      </div>
    </div>
  )
}

CommissionPage.propTypes = {
  isFetching: PropTypes.bool,
  dataCommission: PropTypes.array,
  metaCommission: PropTypes.object,
  handleSearch: PropTypes.func,
  handleSubmit: PropTypes.func,
  handlePage: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  isLoadStatus: PropTypes.bool,
  statusList: PropTypes.array,
  handleLimit: PropTypes.func,
  // dataFile: PropTypes.string,
  isExport: PropTypes.bool,
  handleReport: PropTypes.func,
  limitList: PropTypes.array,
  currentUser: PropTypes.object,
}

export default CommissionPage
