import PropTypes from 'prop-types'
import {
  Table, Card, Button,
  Input, Icon, Popconfirm,
} from 'antd'
import { capitalize } from 'lodash'
import history from 'utils/history'
import moment from 'moment'

const TitleCard = ({ handleSearch, type }) => (
  <div className="d-flex justify-content-between align-items-center">
    <div>
      <Input.Search
        allowClear
        placeholder="Search..."
        onSearch={handleSearch}
      />
    </div>
    <div>
      <Button
        type="primary"
        className="rounded-pill"
        onClick={() => history.push(`/elearning/quiz/${type.toLowerCase()}/add`)}
      >
        {`Add ${type}`}
      </Button>
    </div>
  </div>
)

const LearningQuiz = ({
  dataQuestion, isFetching,
  handleSearch, metaQuestion,
  handlePage, activeTab,
  handleTab, handleDelete,
  dataAnswer, metaAnswer,
  loadAnswer,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, idx) => idx + 1,
    },
    {
      title: 'Course ID',
      dataIndex: 'course_id',
      key: 'course_id',
      width: 300,
    },
    {
      title: 'Question',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: 'Date',
      key: 'date',
      children: [
        {
          title: 'Created',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
        {
          title: 'Updated',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
      ],
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/elearning/quiz/${activeTab}/${record.id}/edit`)} />
          <Popconfirm
            title="Are you sure delete this Question?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Icon type="delete" />
          </Popconfirm>
          <Icon type="font-colors" onClick={() => history.push(`/elearning/quiz/answer/add?qid=${record.id}`)} />
        </div>
      ),
    },
  ]
  const columnsAnswer = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, idx) => idx + 1,
    },
    {
      title: 'Question ID',
      dataIndex: 'question_id',
      key: 'question_id',
      width: 300,
    },
    {
      title: 'Answer',
      dataIndex: 'answer',
      key: 'answer',
    },
    {
      title: 'Correct Answer',
      dataIndex: 'is_correct_answer',
      key: 'is_correct_answer',
      render: text => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Date',
      key: 'date',
      children: [
        {
          title: 'Created',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
        {
          title: 'Updated',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
      ],
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/elearning/quiz/${activeTab}/${record.id}/edit`)} />
          <Popconfirm
            title="Are you sure delete this Answer?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Icon type="delete" />
          </Popconfirm>
        </div>
      ),
    },
  ]
  const contentList = {
    question: {
      columns,
      loading: isFetching,
      dataSource: dataQuestion,
      pagination: {
        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
        total: metaQuestion.total_count,
        current: metaQuestion.current_page,
        onChange: handlePage,
      },
    },
    answer: {
      columns: columnsAnswer,
      loading: loadAnswer,
      dataSource: dataAnswer,
      pagination: {
        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
        total: metaAnswer.total_count,
        current: metaAnswer.current_page,
        onChange: handlePage,
      },
    },
  }
  return (
    <Card
      tabList={[{ key: 'question', tab: 'Question' }, { key: 'answer', tab: 'Answer' }]}
      activeTabKey={activeTab}
      onTabChange={handleTab}
    >
      <Table
        {...contentList[activeTab]}
        bordered
        rowKey="id"
        title={() => TitleCard({ handleSearch, type: capitalize(activeTab) })}
      />
    </Card>
  )
}

TitleCard.propTypes = {
  handleSearch: PropTypes.func,
  type: PropTypes.string,
}

LearningQuiz.propTypes = {
  isFetching: PropTypes.bool,
  loadAnswer: PropTypes.bool,
  dataQuestion: PropTypes.array,
  metaQuestion: PropTypes.object,
  dataAnswer: PropTypes.array,
  metaAnswer: PropTypes.object,
  handleSearch: PropTypes.func,
  handlePage: PropTypes.func,
  activeTab: PropTypes.string,
  handleTab: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default LearningQuiz
