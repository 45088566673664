import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchUltraVoucherCategory, deleteVoucherCategory } from 'actions/UltraVoucherCategories'
import UltraVoucherCategoryView from 'components/pages/ultraVouchers/categories/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataUltraVoucherCategory,
    metaUltraVoucherCategory,
  } = state.root.ultraVoucherCategory

  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataUltraVoucherCategory,
    metaUltraVoucherCategory,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchUltraVoucherCategory: bindActionCreators(fetchUltraVoucherCategory, dispatch),
  deleteVoucherCategory: bindActionCreators(deleteVoucherCategory, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchUltraVoucherCategory(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),

  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleDelete: props => (id) => {
      props.deleteVoucherCategory(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Ultra Voucher', 'Categories'])
      this.props.updateSiteConfiguration('titlePage', 'Ultra Voucher - Categories')
      this.props.fetchUltraVoucherCategory()
    },
  }),
)(UltraVoucherCategoryView)
