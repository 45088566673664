import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button,
} from 'antd'
import history from 'utils/history'
import CKEditor from 'ckeditor4-react'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormPdp = (props) => {
  const {
    form, handleChangeEditor, isSubmit,
    onChange, onSubmit,
  } = props

  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={onSubmit}
      >
        <Form.Item label="Title">
          <Input name="title" type="text" placeholder="Input Title" value={form.title || ''} onChange={onChange} />
        </Form.Item>
        <Form.Item label="Content">
          {form.content
            ? (
              <CKEditor
                name="content"
                data={form.content}
                config={{
                  extraPlugins: 'justify,font,colorbutton',
                  toolbarGroups: [
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                    {
                      name: 'paragraph',
                      groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                    },
                  ],
                }}
                onChange={e => handleChangeEditor(e.editor.getData())}
              />
            )
            : <p>Loading editor...</p>
          }
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/pdp')} className="mr-2">Cancel</Button>
          <Button
            loading={isSubmit}
            type="primary"
            htmlType="submit"
          >
            Submit Change
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormPdp.propTypes = {
  onChange: PropTypes.func,
  form: PropTypes.any,
  handleChangeEditor: PropTypes.func,
  isSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default Form.create({ name: 'pdpForm' })(FormPdp)
