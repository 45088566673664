import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withState } from 'recompose'
import { createPassword } from 'actions/Auth'
import withForms from 'utils/hocs/withForms'
import CreateView from 'components/login/Create'
import history from 'utils/history'
import Swal from 'sweetalert2'
import qs from 'query-string'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  createPassword: bindActionCreators(createPassword, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    onSubmitFailed: () => () => {

    },
    onSubmit: props => (values) => {
      props.setIsSubmit(true)
      const val = qs.parse(props.location.search)
      const payload = {
        reset_token: val.t,
        password: values.password,
        password_confirmation: values.password_confirmation,
      }
      props.createPassword(payload)
        .then(() => {
          props.setIsSubmit(false)
          Swal.fire(
            'Password created successfully!',
            'You can login using the new password',
            'success',
          ).then(() => {
            history.push('/')
          })
        })
        .catch(() => {
          props.setIsSubmit(false)
        })
    },
  }),
)(CreateView)
