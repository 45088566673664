import {
  AUTO_DEBIT_PENDAFTARAN_REQUEST,
  AUTO_DEBIT_PENDAFTARAN_SUCCESS,
  AUTO_DEBIT_PENDAFTARAN_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataAutoDebitPendaftaran: [],
  metaAutoDebitPendaftaran: {},
}

export default function autoDebitPendaftaran(state = initialState, action) {
  switch (action.type) {
    case AUTO_DEBIT_PENDAFTARAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case AUTO_DEBIT_PENDAFTARAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataAutoDebitPendaftaran: action.data,
        metaAutoDebitPendaftaran: action.meta,
      }
    case AUTO_DEBIT_PENDAFTARAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataAutoDebitPendaftaran: action.data === null ? [] : [],
        metaAutoDebitPendaftaran: {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
