import API from 'utils/API'
import {
  CUSTOMER_REQUEST,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILURE,
  CUSTOMER_UPDATED,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_PROFILE_SUCCESS,
  CUSTOMER_PROFILE_UPDATED,
} from 'constants/ActionTypes'
import { message } from 'antd'

export const customerRequest = () => ({
  type: CUSTOMER_REQUEST,
})

export const customerSuccess = (data, meta) => ({
  type: CUSTOMER_SUCCESS,
  data,
  meta,
})

export const customerProfileSuccess = (data, meta) => ({
  type: CUSTOMER_PROFILE_SUCCESS,
  data,
  meta,
})

export const customerFailure = errorMessage => ({
  type: CUSTOMER_FAILURE,
  errorMessage,
})

export const customerUpdate = data => ({
  type: CUSTOMER_UPDATED,
  data,
})

export const customerProfileUpdated = data => ({
  type: CUSTOMER_PROFILE_UPDATED,
  data,
})

export const customerDetail = data => ({
  type: CUSTOMER_DETAIL_SUCCESS,
  data,
})

export const fetchCustomer = params => (
  (dispatch) => {
    dispatch(customerRequest())
    const url = `/auth/customer/fetch-inquiry${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(customerSuccess(data, meta))
        } else {
          dispatch(customerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(customerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const verifyCustomer = (id, payload) => (
  (dispatch) => {
    dispatch(customerRequest())
    const url = `/auth/customer/verify-customer/${id}`
    return API.post(url, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(customerUpdate(data))
          dispatch(fetchCustomer('-phone'))
          message.success('Success')
        } else {
          dispatch(customerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(customerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailCustRegis = id => (
  (dispatch) => {
    dispatch(customerRequest())
    const url = `/auth/customer/fetch-user-personal-detail/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(customerDetail(data))
        } else {
          dispatch(customerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(customerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchListProfile = params => (
  (dispatch) => {
    dispatch(customerRequest())
    const url = `/user/phone-email-update/list${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(customerProfileSuccess(data, meta))
        } else {
          dispatch(customerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(customerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const verifyProfileUpdate = payload => (
  dispatch => new Promise((resolve, reject) => (
    API.put('/user/phone-email-update/approval', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
          message.success(data)
          dispatch(fetchListProfile())
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const fetchDetailVerifyCustomer = id => (
  (dispatch) => {
    dispatch(customerRequest())
    const url = `/auth/customer/fetch-pending-user-detail/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(customerDetail(data))
        } else {
          dispatch(customerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(customerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)
