import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchCalcManagementList, deleteCalcManagement } from 'actions/CalculatorManagement'
import CalculatorManagementView from 'components/pages/calculatorManagement/List'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataCalcManagement,
    metaCalcManagement,
  } = state.root.calculatorManagement
  return {
    isFetching,
    dataCalcManagement,
    metaCalcManagement,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchCalcManagementList: bindActionCreators(fetchCalcManagementList, dispatch),
  deleteCalcManagement: bindActionCreators(deleteCalcManagement, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    handleSearch: () => () => {},
    handleDelete: props => (id) => {
      props.deleteCalcManagement(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Calculator management', 'Asumsi nilai suku bunga'])
      this.props.updateSiteConfiguration('titlePage', 'Asumsi Nilai Suku Bunga')
      this.props.fetchCalcManagementList()
    },
  }),
)(CalculatorManagementView)
