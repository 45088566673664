import API from 'utils/API'
import {
  AUTO_DEBIT_PENDAFTARAN_REQUEST,
  AUTO_DEBIT_PENDAFTARAN_SUCCESS,
  AUTO_DEBIT_PENDAFTARAN_FAILURE,
} from 'constants/ActionTypes'

export const autoDebitPendaftaranRequest = () => ({
  type: AUTO_DEBIT_PENDAFTARAN_REQUEST,
})

export const autoDebitPendaftaranSuccess = (data, meta) => ({
  type: AUTO_DEBIT_PENDAFTARAN_SUCCESS,
  data,
  meta,
})

export const autoDebitPendaftaranFailure = errorMessage => ({
  type: AUTO_DEBIT_PENDAFTARAN_FAILURE,
  errorMessage,
})


export const fetchAutoDebitPendaftaran = params => (
  (dispatch) => {
    dispatch(autoDebitPendaftaranRequest())
    const url = `${process.env.APP_CONFIG.api_url}/autodebet/registration-list${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(autoDebitPendaftaranSuccess(data, meta))
        } else {
          dispatch(autoDebitPendaftaranFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(autoDebitPendaftaranFailure(err.message))
    })
  }
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url}/autodebet/export-registration-list${params || ''}`).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)
