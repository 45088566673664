import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchListProfile, verifyProfileUpdate } from 'actions/Customer'
import UpdateProfileView from 'components/pages/customers/updateProfile/List'
import { message } from 'antd'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataListProfile,
    metaListProfile,
  } = state.root.customer
  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataListProfile,
    metaListProfile,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchListProfile: bindActionCreators(fetchListProfile, dispatch),
  verifyProfileUpdate: bindActionCreators(verifyProfileUpdate, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, q } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        q: q || '',
      }

      props.fetchListProfile(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        q: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleVerify: props => (id) => {
      props.verifyProfileUpdate({ update_request_ids: [id] }).catch((err) => {
        message.error(err)
      })
    },
    /* handleSearch: () => () => {},
    handlePage: props => (page) => {
      props.fetchListProfile(page ? `?role=customer&page=${page}` : '?role=customer')
    }, */
    /* handleDelete: props => (id) => {
      props.deleteUser(id)
    }, */
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Customer', 'Update Profile'])
      this.props.updateSiteConfiguration('titlePage', 'Verify Update Profile')
      this.props.fetchListProfile()
    },
  }),
)(UpdateProfileView)
