import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  aboutDetail, fetchDetailAbout, createAbout, updateAbout,
} from 'actions/About'
import { message } from 'antd'
import FormAboutView from 'components/pages/abouts/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFecthing,
    detailAbout,
  } = state.root.about
  const { currentUser } = state.root.auth
  return {
    isFecthing,
    detailAbout,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailAbout: bindActionCreators(fetchDetailAbout, dispatch),
  createAbout: bindActionCreators(createAbout, dispatch),
  updateAbout: bindActionCreators(updateAbout, dispatch),
  aboutDetail: bindActionCreators(aboutDetail, dispatch),
})
const dataSelect = [
  { id: 1, label: '1' },
  { id: 2, label: '2' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('selectOption', 'setSelectOption', dataSelect),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      const payload = values
      payload.created_by = props.currentUser.id
      props[`${params.id ? 'upd' : 'cre'}ateAbout`](payload, (params.id || null)).then(() => {
        message.success(`Data has been ${params.id ? 'upd' : 'cre'}ated`).then(() => (
          history.push('/about')
        ))
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'About - Us'])
      this.props.updateSiteConfiguration('titlePage', 'About Us Form')
      this.props.aboutDetail({})
      if (match.params.id) {
        this.props.fetchDetailAbout(match.params.id)
        setIsEdit(true)
      }
    },
  }),
)(FormAboutView)
