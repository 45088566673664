import { connect } from 'react-redux'
import {
  compose, lifecycle, withState, withHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDetailDplkFee, updateDplkFee } from 'actions/CalculatorManagement'
import FormDplkFeeView from 'components/pages/calculatorManagement/dplkFee/Form'
import history from 'utils/history'
import { message } from 'antd'

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailDplkFee: bindActionCreators(fetchDetailDplkFee, dispatch),
  updateDplkFee: bindActionCreators(updateDplkFee, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('detailDplkFee', 'setDetailDplkFee', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      props.setIsSubmit(!props.isSubmit)
      props.updateDplkFee(values, (params.id || null)).then(() => {
        message.success('Data has been updated').then(() => (
          history.push('/calculator-management/biaya-dplk')
        ))
      }).catch((err) => {
        message.error(err)
        props.setIsSubmit(!props.isSubmit)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Calculator management', 'DPLK Fee'])
      this.props.updateSiteConfiguration('titlePage', 'DPLK Fee')
      this.props.fetchDetailDplkFee(this.props.match.params.id)
        .then((result) => {
          this.props.setDetailDplkFee(result)
        })
        .catch((error) => {
          message.error(error.message)
        })
    },
  }),
)(FormDplkFeeView)
