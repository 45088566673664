import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Avatar, Row,
  Col, Skeleton, Button,
  Popconfirm, Form, Input, DatePicker,
} from 'antd'
import moment from 'moment'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import history from 'utils/history'
import DetailTable from './detailTable'


const DetailUserPage = ({
  detailUser, isFetching, handleResendEmail,
  handleResendEmailPin, isSubmit, isSubmitEdit, match, isDisable,
  currentUser, isEdit, handleChangeEdit, form, handleSubmit,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }
  const { getFieldDecorator, validateFields } = form

  return (
    <div className="pb-5">
      {isFetching
        ? [1, 2, 3].map(index => (
          <Card key={index} className="mb-3">
            <div>
              <Skeleton active />
            </div>
          </Card>
        ))
        : (
          <React.Fragment>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                validateFields((err, values) => {
                  if (!err) {
                    handleSubmit(values)
                  }
                })
              }}
            >
              <Card className="btn-primary-wrapper mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    {(currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-write') > -1) && (
                    <Popconfirm
                      title="Are you sure you want to resend the Email?"
                      okText="Resend Email"
                      cancelText="No"
                      placement="topLeft"
                      onConfirm={() => handleResendEmail()}
                    >
                      <Button className="mr-3" loading={isSubmit.resendEmail}>Resend Email</Button>
                    </Popconfirm>
                    )}
                    <Popconfirm
                      title="Are you sure you want to resend the Forgot Pin Email?"
                      okText="Resend Forgot Pin Email"
                      cancelText="No"
                      placement="topLeft"
                      onConfirm={() => handleResendEmailPin()}
                    >
                      <Button loading={isSubmit.resendPin}>Resend Email Forgot Pin</Button>
                    </Popconfirm>
                  </div>
                  <div className="d-flex">
                    <Button className="mr-2" style={{ borderColor: '#003db2', color: '#003db2' }} onClick={() => history.push(`/user/temanku/${match.params.id}`)}>Temanku</Button>
                    <Button className="mr-2" style={{ borderColor: '#003db2', color: '#003db2' }} onClick={() => history.push(`/user/pakde/${match.params.id}`)}>Downline</Button>
                    <Button type="primary" onClick={() => history.push(`/user/history-point/${match.params.id}`)}>History Point</Button>
                  </div>

                </div>
              </Card>
              <Card className="mb-3">
                <Row gutter={[24, 6]}>
                  <Col span={24}>
                    <h5 className="mb-4">Customer Detail</h5>
                  </Col>
                  <div className="d-flex justify-content-end">
                    <Button className="ml-3" onClick={handleChangeEdit}>{isEdit ? 'Cancel' : 'Edit'}</Button>
                    {isEdit
                      ? <Button type="primary" className="ml-3" htmlType="submit" disabled={isDisable} loading={isSubmitEdit}>Submit</Button> : ''
                  }
                  </div>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Member ID:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.registered_number || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Identity Number:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.identity_number || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Name:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.name || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Date of Birth:</p>
                    {isEdit
                      ? (
                        <React.Fragment>
                          <Row gutter={24}>
                            <Col span={10}>
                              <Form.Item className="mb-2">
                                {getFieldDecorator('birthplace', {
                                  initialValue: isEdit ? detailUser.birth_place : '',
                                  rules: [{ required: true }],
                                })(
                                  <Input className="col-12" />,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item>
                                {getFieldDecorator('birthdate', {
                                  initialValue: isEdit && detailUser.dob ? moment(detailUser.dob, 'YYYY-MM-DD') : null,
                                  rules: [{ required: true, message: 'date and time is required' }],
                                })(
                                  <DatePicker style={{ padding: '0px' }} className="ml-0 col-12" format="YYYY-MM-DD" />,
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      : (
                        <p style={{ fontSize: '16px' }}>
                          {detailUser.birth_place || '-'}
                          ,
                          {' '}
                          {detailUser.dob || '-'}
                        </p>
                      )
                  }
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Phone Number:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.phone_number || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Email:</p>
                    {isEdit
                      ? (
                        <Form.Item className="mb-2">
                          {getFieldDecorator('email', {
                            initialValue: isEdit ? detailUser.email : '',
                            rules: [{ type: 'email', required: true, message: 'Email Tidak Valid' }],
                          })(
                            <Input className="col-6" />,
                          )}
                        </Form.Item>
                      ) : (
                        <p style={{ fontSize: '16px' }} className="font-weight-bold">
                          {detailUser.email || '-'}
                        </p>
                      )
                  }
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Long Time Deposit</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.long_time_deposit || '-'}</p>
                  </Col>
                </Row>
              </Card>
            </Form>
            <Card className="mb-3">
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-4">NPWP Detail</h5>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="pb-3">
                  <p className="mr-3 mb-2 font-weight-bold">NPWP Photo:</p>
                  {detailUser.npwp_photo ? (
                    <SimpleReactLightbox>
                      <SRLWrapper options={lightBoxSetting}>
                        <Avatar shape="square" size={300} src={detailUser.npwp_photo} alt="NPWP Photo" style={{ cursor: 'pointer' }} />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  ) : (
                    <Avatar shape="square" size={300} src="/assets/default.jpg" alt="no img" />
                  )}
                </Col>
                <Col span={12}>
                  {/* <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">NPWP Number:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.npwp_number || '-'}</p>
                  </Col> */}
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">KTP Address:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.ktp_addr || '-'}</p>
                  </Col>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Postal Code:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.postal_code || '-'}</p>
                  </Col>
                </Col>
              </Row>
            </Card>
            <Card className="mb-3">
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-4">Income Detail</h5>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="pb-3">
                  <p className="mr-3 mb-2 font-weight-bold">Identity Photo:</p>
                  {detailUser.identity_photo ? (
                    <SimpleReactLightbox>
                      <SRLWrapper options={lightBoxSetting}>
                        <Avatar shape="square" size={300} src={detailUser.identity_photo} alt="Identity Photo" style={{ cursor: 'pointer' }} />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  ) : (
                    <Avatar shape="square" size={300} src="/assets/default.jpg" alt="no img" />
                  )}
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Identity:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.identity && detailUser.identity.name ? detailUser.identity.name : '-'}</p>
                  </Col>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Income:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser.income && detailUser.income.name ? detailUser.income.name : '-'}</p>
                  </Col>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Source Income:</p>
                    <p>{detailUser.source_of_income || ''}</p>
                  </Col>
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        )
      }

      <Card className="mb-3">
        <DetailTable title="Laporan Posisi Dana 6 Bulan Berjalan" data={detailUser.rolling_transaction_history || []} isFetching={isFetching} />
      </Card>

      <Card>
        <DetailTable title="Laporan Posisi Dana 6 Bulan Terakhir" data={detailUser.semester_transaction_history || []} isFetching={isFetching} />
      </Card>
    </div>
  )
}

DetailUserPage.propTypes = {
  detailUser: PropTypes.object,
  isFetching: PropTypes.bool,
  handleResendEmail: PropTypes.func,
  handleResendEmailPin: PropTypes.func,
  isSubmit: PropTypes.object,
  match: PropTypes.object,
  currentUser: PropTypes.object,
  isEdit: PropTypes.bool,
  handleChangeEdit: PropTypes.func,
  form: PropTypes.any,
  isSubmitEdit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isDisable: PropTypes.bool,
}

export default Form.create({ name: 'userDetailForm' })(DetailUserPage)
