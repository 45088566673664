import PropTypes from 'prop-types'
import {
  Table, Button, Avatar,
  Input, Icon,
} from 'antd'
import history from 'utils/history'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import Helper from 'utils/Helper'

const UltraVoucherPage = ({
  isFetching, handlePage,
  metaUltraVoucher, dataUltraVoucher,
  handleSyncData, handleSearch,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      width: 200,
      dataIndex: 'image',
      key: 'image',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={75} src={image} alt="voucher" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={75} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Benefit',
      dataIndex: 'benefit',
      key: 'benefit',
      render: text => text || '-',
    },
    {
      title: 'How to use',
      dataIndex: 'how_to_use',
      key: 'how_to_use',
      render: text => text || '-',
    },
    {
      title: 'Term',
      dataIndex: 'term',
      key: 'term',
      render: text => text || '-',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Point',
      dataIndex: 'point',
      key: 'point',
    },
    {
      title: 'Distribution price',
      dataIndex: 'distribution_price',
      key: 'distribution_price',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Order destination',
      dataIndex: 'order_destination',
      key: 'order_destination',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    /* {
      title: 'Date',
      key: 'date',
      children: [
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
        {
          title: 'Updated at',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
      ],
    }, */
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`/ultra-vouchers/products/${record.id}/edit`)} />
          {/* <Popconfirm
            title="Are you sure delete this data?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" style={{ color: '#ff5159' }} />
          </Popconfirm> */}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search by name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => handleSyncData()}
            >
              Sync Data
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataUltraVoucher}
        loading={isFetching}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaUltraVoucher ? metaUltraVoucher.per_page : 10,
          total: metaUltraVoucher ? metaUltraVoucher.total_count : dataUltraVoucher.length,
          current: metaUltraVoucher ? metaUltraVoucher.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}


UltraVoucherPage.propTypes = {
  isFetching: PropTypes.bool,
  dataUltraVoucher: PropTypes.array,
  metaUltraVoucher: PropTypes.object,
  handlePage: PropTypes.func,
  handleSyncData: PropTypes.func,
  handleSearch: PropTypes.func,
}

export default UltraVoucherPage
