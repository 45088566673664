import PropTypes from 'prop-types'
import {
  Card, Avatar, Row, Col,
} from 'antd'

const DetailBenefitPage = ({
  detailBenefit,
}) => (
  <div className="pb-5">
    <Card className="mb-3">
      <Row gutter={[24, 6]}>
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Benefit icon:</p>
          <Avatar shape="square" size={200} src={detailBenefit.data.benefit_icon || '-'} />
        </Col>
        <Col span={12}>
          <p className="mr-3 mb-2 font-weight-bold">Claim type:</p>
          <p style={{ fontSize: '16px' }}>{detailBenefit.data.claim_type && detailBenefit.data.claim_type.name ? detailBenefit.data.claim_type.name : ''}</p>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Claim description:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.claim_desc || '-'}</p>
          </Col>
          <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Document title:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.doc_desc_title || '-'}</p>
          </Col>
          <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Document description:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.doc_desc_header || '-'}</p>
          </Col> */}
      </Row>
    </Card>
    <Card className="mb-3">
      <Row gutter={[24, 6]}>
        <Col span={24}>
          <h5 className="mb-4">Benefit Description</h5>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Title:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.benefit_desc_title || '-'}</p>
          </Col> */}
        <Col span={12}>
          <p className="mr-3 mb-2 font-weight-bold">Header:</p>
          <p style={{ fontSize: '16px' }}>{detailBenefit.data.benefit_desc_header || '-'}</p>
        </Col>
        <Col span={12}>
          <p className="mr-3 mb-2 font-weight-bold">Content:</p>
          <p style={{ fontSize: '16px' }}>{detailBenefit.data.benefit_desc_content || '-'}</p>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Footer:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.benefit_desc_footer || '-'}</p>
          </Col> */}
      </Row>
    </Card>
    <Card>
      <Row gutter={[24, 6]}>
        <Col span={24}>
          <h5 className="mb-4">Tax Description</h5>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Title:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_title || '-'}</p>
          </Col> */}
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Header 1:</p>
          <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_header_1 || '-'}</p>
        </Col>
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Content 1:</p>
          <Card><div dangerouslySetInnerHTML={{ __html: detailBenefit.data.tax_desc_content_1 || '-' }} /></Card>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Footer 1:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_footer_1 || '-'}</p>
          </Col> */}
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Header 2:</p>
          <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_header_2 || '-'}</p>
        </Col>
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Content 2:</p>
          <Card><div dangerouslySetInnerHTML={{ __html: detailBenefit.data.tax_desc_content_2 || '-' }} /></Card>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Footer 2:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_footer_2 || '-'}</p>
          </Col> */}
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Header 2:</p>
          <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_header_2 || '-'}</p>
        </Col>
        <Col span={12} className="pb-3">
          <p className="mr-3 mb-2 font-weight-bold">Content 3:</p>
          <Card><div dangerouslySetInnerHTML={{ __html: detailBenefit.data.tax_desc_content_3 || '-' }} /></Card>
        </Col>
        {/* <Col span={12}>
            <p className="mr-3 mb-2 font-weight-bold">Footer 3:</p>
            <p style={{ fontSize: '16px' }}>{detailBenefit.data.tax_desc_footer_3 || '-'}</p>
          </Col> */}
      </Row>
    </Card>
  </div>
)

DetailBenefitPage.propTypes = {
  detailBenefit: PropTypes.object,
}

export default DetailBenefitPage
