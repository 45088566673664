import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Avatar, Row,
  Col, Skeleton, Button,
} from 'antd'
import history from 'utils/history'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'


const DuplicateDataPage = ({
  detail, handleMerge, isSubmit,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  return (
    <div className="pb-5">
      {detail.isFetching
        ? [1, 2].map(index => (
          <Card key={index} className="mb-3">
            <div>
              <Skeleton active />
            </div>
          </Card>
        ))
        : (
          <React.Fragment>
            <Card className="mb-4">
              <Row gutter={[24, 6]}>
                <Col span={15}>
                  <div className="mb-5">
                    <h5 className="mb-4">Data nasabah saat ini</h5>
                    <Card>
                      <Row gutter={[24, 6]}>
                        <Col span={6}>
                          <SimpleReactLightbox>
                            <SRLWrapper options={lightBoxSetting}>
                              <Avatar shape="square" size={90} src={detail.current.identity_photo} alt="ktp" style={{ cursor: 'pointer' }} />
                            </SRLWrapper>
                          </SimpleReactLightbox>
                        </Col>
                        <Col span={18}>
                          <h6 className="mb-3">{detail.current.name}</h6>
                          <div className="d-flex justify-content-arround">
                            <div>
                              <p className="mb-2 font-weight-bold">No. KTP/NIK</p>
                              <p className="mb-2 font-weight-bold">Nomor Ponsel</p>
                              <p className="mb-2 font-weight-bold">Alamat Email</p>
                            </div>
                            <div className="px-5">
                              <p className="mb-2">{detail.current.identity_number || '-'}</p>
                              <p className="mb-2">{detail.current.phone_number || '-'}</p>
                              <p className="mb-2">{detail.current.email || '-'}</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                  <div className="mb-3">
                    <div className="mb-4">
                      <h5>Duplicate customer data</h5>
                      <p>Nasabah yang terdaftar lebih baru akan digabungkan ke dalam akun nasabah yang terdaftar lebih lama</p>
                    </div>
                    {(detail.duplicate || []).map(item => (
                      <Card key={item.id} className="mb-4">
                        <Row gutter={[24, 6]}>
                          <Col span={6}>
                            <SimpleReactLightbox>
                              <SRLWrapper options={lightBoxSetting}>
                                <Avatar shape="square" size={90} src={item.identity_photo} alt="ktp" style={{ cursor: 'pointer' }} />
                              </SRLWrapper>
                            </SimpleReactLightbox>
                          </Col>
                          <Col span={18}>
                            <h6 className="mb-3">{item.name}</h6>
                            <div className="d-flex justify-content-arround">
                              <div>
                                <p className="mb-2 font-weight-bold">No. KTP/NIK</p>
                                <p className="mb-2 font-weight-bold">Nomor Ponsel</p>
                                <p className="mb-2 font-weight-bold">Alamat Email</p>
                              </div>
                              <div className="px-5">
                                <p className="mb-2">{item.identity_number || '-'}</p>
                                <p className="mb-2">{item.phone_number || '-'}</p>
                                <p className="mb-2">{item.email || '-'}</p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    ))
                    }
                  </div>
                </Col>
              </Row>
            </Card>
            <div className="mb-3 float-right">
              <Button className="ml-3" onClick={() => history.goBack()}>Batal</Button>
              <Button type="primary" className="ml-3" onClick={handleMerge} loading={isSubmit}>Gabung Nasabah</Button>
            </div>
          </React.Fragment>
        )
      }
    </div>
  )
}

DuplicateDataPage.propTypes = {
  detail: PropTypes.object,
  handleMerge: PropTypes.func,
  isSubmit: PropTypes.bool,
}

export default DuplicateDataPage
