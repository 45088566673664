import {
  PAKDE_REQUEST,
  PAKDE_SUCCESS,
  PAKDE_FAILURE,
  PAKDE_UPDATED,
  PAKDE_DETAIL_SUCCESS,
  REMIND_FRIEND_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataPakDe: [],
  detailPakDe: {},
  metaPakDe: {
    total_count: 0,
    current: 0,
  },
  dataRemindFriend: [],
  metaRemindFriend: {},
}

export default function pakde(state = initialState, action) {
  switch (action.type) {
    case PAKDE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case PAKDE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataPakDe: action.data,
        metaPakDe: action.meta,
      }
    case PAKDE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case PAKDE_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataPakDe: state.dataPakDe.filter(item => item.id !== action.data.id),
      }
    case PAKDE_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailPakDe: action.data,
      }
    case REMIND_FRIEND_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataRemindFriend: action.data,
        metaRemindFriend: action.meta,
      }
    default:
      return state
  }
}
