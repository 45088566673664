import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { message } from 'antd'
import { fetchRoleList, deleteRole } from 'actions/Admin'
import RoleAdminListView from 'components/pages/adminManagement/role/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import { updateSiteConfiguration } from 'actions/Site'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchRoleList: bindActionCreators(fetchRoleList, dispatch),
  deleteRole: bindActionCreators(deleteRole, dispatch),
})

// const data = [
//   {id: 1, name: 'Super-Admin', created_at: '1-5-2021', status: 'Active'},
//   {id: 2, name: 'Content Manager', created_at: '2-5-2021', status: 'Active'},
//   {id: 3, name: 'BMC Manager', created_at: '3-5-2021', status: 'Inactive'},
//   {id: 4, name: 'Marketing', created_at: '5-5-2021', status: 'Inactive'},
//   {id: 5, name: 'Admin', created_at: '5-5-2021', status: 'Active'},
// ]

const status = [
  { id: 1, value: 'active', name: 'Active' },
  { id: 2, value: 'inactive', name: 'Inactive' },
]

const orderBy = [
  { id: 1, value: 'ASC', name: 'Ascending' },
  { id: 2, value: 'DESC', name: 'Descending' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('status', 'setStatus', status),
  withState('orderBy', 'setOrderBy', orderBy),
  withState('stateSearch', 'setStateSearch', {}),
  withState('list', 'setList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getList: props => (params) => {
      const { setList } = props
      props.fetchRoleList(params)
        .then((result) => {
          setList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onSetStateSearch: props => () => {
      const { setStateSearch, stateSearch } = props
      const parseQS = qs.parse(window.location.search)
      setStateSearch({
        ...stateSearch,
        ...parseQS,
      })
    },
    onChangeLinkPage: props => () => {
      window.history.replaceState(null, undefined, `?${qs.stringify(pickBy(props.stateSearch, identity))}`)
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, limit, filterStatus, order,
        search,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        limit: limit || '',
        filter: filterStatus || '',
        order_by: order || '',
        query: search || '',
      }
      if (isSearch) {
        props.setStateSearch({
          ...props.stateSearch,
          page: 1,
        })
      }
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
      props.onChangeLinkPage()
    },
  }),
  withHandlers({
    handlePage: props => (val, pageSize) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
        limit: pageSize,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        filterStatus: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeOrder: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        order: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleDelete: props => (id) => {
      const { getList } = props
      props.deleteRole(id)
        .then(() => {
          message.success('Role has been Deleted')
          getList()
        })
        .catch((err) => { message.error(err) })
    },
  }),
  lifecycle({
    async componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Role'])
      this.props.updateSiteConfiguration('titlePage', 'Role')
      await this.props.onSetStateSearch()
      this.props.onLoad()
    },
  }),
)(RoleAdminListView)
