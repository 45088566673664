import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchPdp } from 'actions/Pdp'
import PdpView from 'components/pages/pdp/List'
import { message } from 'antd'

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchPdp: bindActionCreators(fetchPdp, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('contentPdp', 'setContentPdp', []),
  withState('isFetching', 'setIsFetching', true),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Content Management', 'PDP'])
      this.props.updateSiteConfiguration('titlePage', 'PDP')
      this.props.fetchPdp()
        .then((result) => {
          this.props.setIsFetching(false)
          this.props.setContentPdp([result])
        })
        .catch((error) => {
          message.error(error.message)
        })
    },
  }),
)(PdpView)
