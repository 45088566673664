import { connect } from 'react-redux'
import {
  compose, lifecycle, withState, withHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchPenjelasan } from 'actions/PenjelasanSimpan'
import PenjelasanSimpanView from 'components/pages/webManagement/penjelasanSimpan/List'
import { message } from 'antd'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchPenjelasan: bindActionCreators(fetchPenjelasan, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('penjelasanList', 'setPenjelasan', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getPenjelasanList: props => () => {
      props.fetchPenjelasan()
        .then((result) => {
          props.setPenjelasan({
            data: [result.data],
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch((error) => {
          props.setPenjelasan({
            data: [],
            meta: {},
            isFetching: false,
          })
          message.error(error.message)
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Penjelasan Simpan'])
      this.props.updateSiteConfigs({
        titlePage: 'Penjelasan Simpan',
        activePage: 'web-management/penjelasan-simpan',
        activeSubPage: 'web-management',
      })
      this.props.getPenjelasanList()
    },
  }),
)(PenjelasanSimpanView)
