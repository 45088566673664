import API from 'utils/API'
import { message } from 'antd'
import {
  FAQ_REQUEST,
  FAQ_SUCCESS,
  FAQ_FAILURE,
  FAQ_UPDATED,
  FAQ_DETAIL_SUCCESS,
} from 'constants/ActionTypes'
import config from 'app/config'

export const faqRequest = () => ({
  type: FAQ_REQUEST,
})

export const faqSuccess = (data, meta) => ({
  type: FAQ_SUCCESS,
  data,
  meta,
})

export const faqFailure = errorMessage => ({
  type: FAQ_FAILURE,
  errorMessage,
})

export const faqUpdate = data => ({
  type: FAQ_UPDATED,
  data,
})

export const faqDetail = data => ({
  type: FAQ_DETAIL_SUCCESS,
  data,
})

export const fetchFaq = params => (
  (dispatch) => {
    dispatch(faqRequest())
    const url = `${config.api_url_content}/faqs${params || ''}`

    return API.get(
      url,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(faqSuccess(data, meta))
        } else {
          dispatch(faqFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(faqFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailFaq = id => (
  (dispatch) => {
    dispatch(faqRequest())
    const url = `${config.api_url_content}/faqs/view/${id}`

    return API.get(
      url,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(faqDetail(data))
        } else {
          dispatch(faqFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(faqFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteFaq = id => (
  (dispatch) => {
    dispatch(faqRequest())

    return API.delete(
      `${config.api_url_content}/faqs/delete/${id}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Data has been deleted')
          dispatch(faqUpdate(data))
          dispatch(fetchFaq())
        } else {
          message.error(meta.message)
          dispatch(faqFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(faqFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createFaq = payload => (
  () => (
    new Promise((resolve, reject) => (
      API.post(
        `${config.api_url_content}/faqs/create`,
        payload,
        { headers: { 'x-api-key': config.api_key } },
      ).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
          } else {
            message.error(meta.message)
            reject(meta.message)
          }
        },
      ).catch((err) => {
        if (err.response && err.response.data) {
          const { meta } = err.response.data
          message.error(meta.message)
          reject(meta.message)
        } else {
          message.error(err.message)
          reject(err.message)
        }
      })
    ))
  )
)

export const updateFaq = (payload, id) => (
  () => (
    new Promise((resolve, reject) => (
      API.put(
        `${config.api_url_content}/faqs/edit/${id}`,
        payload,
        { headers: { 'x-api-key': config.api_key } },
      ).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
          } else {
            message.error(meta.message)
            reject(meta.message)
          }
        },
      ).catch((err) => {
        if (err.response && err.response.data) {
          const { meta } = err.response.data
          message.error(meta.message)
          reject(meta.message)
        } else {
          message.error(err.message)
          reject(err.message)
        }
      })
    ))
  )
)

export const getTitleFaq = payload => (
  () => (
    new Promise((resolve, reject) => (
      API.get(
        `${config.api_url_content}/faqs/title${payload}`,
        { headers: { 'x-api-key': config.api_key } },
      ).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
          } else {
            message.error(meta.message)
            reject(meta.message)
          }
        },
      ).catch((err) => {
        if (err.response && err.response.data) {
          const { meta } = err.response.data
          message.error(meta.message)
          reject(meta.message)
        } else {
          message.error(err.message)
          reject(err.message)
        }
      })
    ))
  )
)
