import PropTypes, { func } from 'prop-types'
import {
  Card, Form, Button,
  Input, Radio, Row, Col,
  Modal, Skeleton,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 15,
    },
  },
}
const PaymentConfigurationForm = ({
  detailPaymentConfiguration, isSubmit, form,
  modalConfirmation, handleModalConfirmation, handleSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <div>
      {detailPaymentConfiguration.isFetching
        ? (
          <>
            <Card className="shadow-sm mb-3">
              <Skeleton active />
            </Card>
          </>
        )
        : (
          <>
            <Form
              className="ant-form-custom"
              {...formItemLayout}
              onSubmit={(e) => {
                e.preventDefault()
                validateFields((err, values) => {
                  if (!err) {
                    handleModalConfirmation(values)
                  }
                })
              }}
            >
              <Card className="pt-4">
                <Row gutter={24} className="mb-5">
                  <Col xs={24} sm={17}>
                    <Form.Item label="Nama Metode Pembayaran">
                      <Input value={detailPaymentConfiguration.data ? detailPaymentConfiguration.data?.name : '-'} placeholder="BCA" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={17}>
                    <Form.Item label="Kategori Pembayaran">
                      <Input value={detailPaymentConfiguration.data.slug ? detailPaymentConfiguration.data?.slug : '-'} placeholder="VA/QRIS" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={17}>
                    <Form.Item label="Deskripsi">
                      {getFieldDecorator('description', {
                        initialValue: detailPaymentConfiguration.data ? detailPaymentConfiguration?.data?.description : '-',
                      })(
                        <Input autoComplete="off" placeholder="Deskripsi" />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={17}>
                    <Form.Item label="Pesan Error">
                      {getFieldDecorator('error_message', {
                        initialValue: detailPaymentConfiguration.data ? detailPaymentConfiguration?.data?.error_message : '-',
                      })(
                        <Input autoComplete="off" placeholder="Pembayaran Terjadi Kendala" />,
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={17}>
                    <Form.Item label="Status">
                      {getFieldDecorator('status', {
                        initialValue: detailPaymentConfiguration.data.is_active || false,
                        rules: [{ required: true, message: 'Status wajib di pilih' }],
                      })(
                        <Radio.Group>
                          <Radio.Button value>Aktif</Radio.Button>
                          <Radio.Button value={false}>Tidak Aktif</Radio.Button>
                        </Radio.Group>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} className="mb-3">
                  <Col xs={24} sm={24}>
                    <div className="d-flex justify-content-end btn-primary-wrapper">
                      <Button onClick={() => history.goBack()} className="d-inline-block mr-2">Kembali</Button>
                      <Button type="primary" htmlType="submit" className="btn-primary-wrapper">Kirim</Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Form>
            <Modal
              centered
              visible={modalConfirmation}
              footer={null}
              closable={false}
              onCancel={() => handleModalConfirmation()}
            >
              <div className="text-center px-3">
                <h5 className="text-black mb-4">Apakah anda yakin ingin mengubah data ini?</h5>
                <div className="d-flex justify-content-end">
                  <div className="mr-3">
                    <Button onClick={() => handleModalConfirmation()}>Kembali</Button>
                  </div>
                  <div>
                    <Button type="primary" className="border-0 btn-primary-wrapper" onClick={handleSubmit} loading={isSubmit} disabled={isSubmit}>Kirim</Button>
                  </div>
                </div>
              </div>
            </Modal>
          </>
        )}
    </div>
  )
}

PaymentConfigurationForm.propTypes = {
  isSubmit: PropTypes.bool,
  form: PropTypes.any,
  detailPaymentConfiguration: PropTypes.object,
  modalConfirmation: PropTypes.bool,
  handleModalConfirmation: func,
  handleSubmit: PropTypes.func,
}

export default Form.create({ name: 'paymentConfigurationForm' })(PaymentConfigurationForm)
