import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import {
  fetchPermission, createRole, fetchDetailRole, updateRole,
} from 'actions/Admin'
import { message } from 'antd'
import FormRoleView from 'components/pages/adminManagement/role/Form'
// import Helper from 'utils/Helper'
// import { pickBy, identity } from 'lodash'
import history from 'utils/history'
import { updateSiteConfiguration } from 'actions/Site'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  fetchPermission: bindActionCreators(fetchPermission, dispatch),
  createRole: bindActionCreators(createRole, dispatch),
  fetchDetailRole: bindActionCreators(fetchDetailRole, dispatch),
  updateRole: bindActionCreators(updateRole, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('stateFile', 'setStateFile', ''),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withState('roleA', 'setRoleA', null),
  withState('roleB', 'setRoleB', null),
  withState('permissionsData', 'setPermissionData', []),
  withState('permissionList', 'setPermissionList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('detail', 'setDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getPermission: props => (param) => {
      const { setPermissionList } = props
      props.fetchPermission(param)
        .then((result) => {
          const { data, meta } = result
          setPermissionList({
            data,
            meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setPermissionList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
    getDetail: props => async (id) => {
      const { setDetail } = props
      try {
        const res = await props.fetchDetailRole(id)
        if (["bmc", "bbc", "staff"].indexOf(res.data.name.toLowerCase()) > -1) {
          message.warning("This Role can't be edited")
          history.push('/admin-management/role')
        } else {
          setDetail({
            data: res.data,
            meta: res.meta,
            isFetching: false,
          })
        }
      } catch (err) {
        setDetail({
          data: {},
          meta: {},
          isFetching: false,
        })
      }
    },
  }),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleChangePermissions: props => (list) => {
      props.setPermissionData(list)
    },
    onChange: props => (val) => {
      const { name } = val.target
      const { value } = val.target

      if (name === 'admin') {
        props.setRoleA(value)
      } else {
        props.setRoleB(value)
      }
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      if (props.match.params.id) {
        props.updateRole(props.match.params.id, values)
          .then(() => {
            props.setIsSubmit(false)
            message.success('Role has been Updated')
            history.push('/admin-management/role')
          })
          .catch(() => {
            props.setIsSubmit(false)
          })
      } else {
        props.createRole(values)
          .then(() => {
            props.setIsSubmit(false)
            message.success('Role has been Created')
            history.push('/admin-management/role')
          })
          .catch(() => {
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        setIsEdit, match, getPermission, getDetail,
      } = this.props
      getPermission()
      this.props.updateSiteConfiguration('breadList', ['Home', 'Role', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Role')
      if (match.params.id) {
        setIsEdit(true)
        getDetail(match.params.id)
      }
    },
  }),
)(FormRoleView)
