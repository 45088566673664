import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Row, Tag,
  Col, Skeleton, Button, Icon,
} from 'antd'
import moment from 'moment'
import history from 'utils/history'

const DetailAdminPage = ({
  detail,
}) => (
  <div className="pb-5">
    {detail.isFetching
      ? [1, 2].map(index => (
        <Card key={index} className="mb-3">
          <div>
            <Skeleton active />
          </div>
        </Card>
      ))
      : (
        <React.Fragment>
          <div className="mb-4">
            <Button type="primary" className="font-weight-bold" onClick={() => history.goBack()}>
              <Icon type="left" />
              Go Back
            </Button>
          </div>
          <Card className="mb-3">
            <Row gutter={[24, 6]}>
              <Col span={24}>
                <h5 className="mb-4">Admin Detail</h5>
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Name:</p>
                <p style={{ fontSize: '16px' }}>{detail.data.name || '-'}</p>
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Email:</p>
                <p style={{ fontSize: '16px' }}>{detail.data.email || '-'}</p>
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Permission:</p>
                {detail.data.permissions.length !== 0
                  ? (detail.data.permissions || []).map(item => (
                    <Tag className="mb-2" key={Math.random()}>
                      {' '}
                      <p className="mb-0" style={{ fontSize: '16px' }}>{item.name}</p>
                    </Tag>
                  ))
                  : '-'
                  }
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Role:</p>
                <p style={{ fontSize: '16px' }}>{detail.data.role.name || '-'}</p>
              </Col>
            </Row>
          </Card>
          <Card className="mb-3">
            <Row gutter={[24, 6]}>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Status:</p>
                <Tag color="green"><p className="mb-0" style={{ fontSize: '16px' }}>{detail.data.status || '-'}</p></Tag>
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Last Login:</p>
                <p className="mb-0" style={{ fontSize: '16px' }}>
                  {detail.data.last_login === '0001-01-01T00:00:00Z'
                    ? '-'
                    : moment(detail.data.last_login).format('DD MMMM YYYY')
                    }
                </p>
              </Col>
            </Row>
          </Card>
        </React.Fragment>
      )
      }
  </div>
)

DetailAdminPage.propTypes = {
  detail: PropTypes.object,
}

export default DetailAdminPage
