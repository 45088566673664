import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, Radio, Select,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormVoucherBrand = ({
  detail, handleSubmit, handleSelect,
  form, isSubmit, handleRadio,
  selectOption, loadCategory,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Category">
          {getFieldDecorator('voucher_category_id', {
            initialValue: detail && detail.voucher_category_id ? detail.voucher_category_id : undefined,
            rules: [{ required: true }],
          })(
            <Select
              onChange={handleSelect}
              loading={loadCategory}
              placeholder="Select Category"
            >
              {(selectOption || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: detail.name || '',
            rules: [{ required: true }],
          })(
            <Input placeholder="Input Name" />,
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('is_active', {
            initialValue: detail.is_active || false,
            rules: [{ required: false }],
          })(
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                handleRadio(e)
              }}
            >
              <Radio.Button value={false}>Inactive</Radio.Button>
              <Radio.Button value>Active</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/ultra-vouchers/brands')} className="mr-2">Cancel</Button>
          <Button loading={isSubmit} type="primary" htmlType="submit">Submit Change</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormVoucherBrand.propTypes = {
  detail: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  handleSelect: PropTypes.func,
  loadCategory: PropTypes.bool,
  selectOption: PropTypes.array,
  isSubmit: PropTypes.bool,
  handleRadio: PropTypes.func,
}

export default Form.create({ name: 'voucherBrandForm' })(FormVoucherBrand)
