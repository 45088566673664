import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import VLogsList from 'containers/pages/ultraVouchers/logs/List'

const Logs = ({
  currentUser, location,
}) => {
  if (location.pathname === '/ultra-vouchers/logs') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-vouchervoucher-category-read') > -1) {
      return <VLogsList location={location} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Logs.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Logs
