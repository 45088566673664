import API from 'utils/API'
import {
  CALC_MANAGEMENT_REQUEST,
  CALC_MANAGEMENT_SUCCESS,
  CALC_MANAGEMENT_FAILURE,
  CALC_MANAGEMENT_UPDATED,
  CALC_MANAGEMENT_DETAIL_SUCCESS,
  INVESTATION_LIST_SUCCESS,
} from 'constants/ActionTypes'
import { message } from 'antd'

export const calcManagementRequest = () => ({
  type: CALC_MANAGEMENT_REQUEST,
})

export const calcManagementSuccess = (data, meta) => ({
  type: CALC_MANAGEMENT_SUCCESS,
  data,
  meta,
})

export const investationListtSuccess = (data, meta) => ({
  type: INVESTATION_LIST_SUCCESS,
  data,
  meta,
})

export const calcManagementFailure = errorMessage => ({
  type: CALC_MANAGEMENT_FAILURE,
  errorMessage,
})

export const calcManagementUpdate = data => ({
  type: CALC_MANAGEMENT_UPDATED,
  data,
})

export const calcManagementDetail = data => ({
  type: CALC_MANAGEMENT_DETAIL_SUCCESS,
  data,
})


export const fetchCalcManagementList = params => (
  (dispatch) => {
    dispatch(calcManagementRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/interest-rate${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(calcManagementSuccess(data, meta))
        } else {
          dispatch(calcManagementFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calcManagementFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchInvestationList = params => (
  (dispatch) => {
    dispatch(calcManagementRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/investment-type${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(investationListtSuccess(data, meta))
        } else {
          dispatch(calcManagementFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calcManagementFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createCalcManagement = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/calculator/interest-rate/store`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const deleteCalcManagement = id => (
  (dispatch) => {
    dispatch(calcManagementRequest())
    return API.delete(`${process.env.APP_CONFIG.api_url_content}/calculator/interest-rate/delete/${id}`).then(
      (response) => {
        const { meta } = response.data
        if (meta.status) {
          message.success('Data has been deleted')
          dispatch(fetchCalcManagementList())
        } else {
          message.error(meta.message)
          dispatch(calcManagementFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(calcManagementFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const updateCalcManagement = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/calculator/interest-rate/update/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const fetchDetailCalcManagement = id => (
  (dispatch) => {
    dispatch(calcManagementRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/interest-rate/view/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(calcManagementDetail(data))
        } else {
          dispatch(calcManagementFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calcManagementFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDplkFee = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/calculator/dplk-fee${params || ''}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailDplkFee = id => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/calculator/dplk-fee/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateDplkFee = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/calculator/dplk-fee/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
