import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import List from 'containers/pages/categories/List'
import Form from 'containers/pages/categories/Form'

const CategoryPage = ({ location, match, currentUser }) => {
  if (location.pathname === '/elearning/categories') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-e-toolslearning-category-read') > -1) {
      return <List location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/edit') || location.pathname.includes('/add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-e-toolslearning-category-write') > -1) {
      return <Form match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

CategoryPage.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default CategoryPage
