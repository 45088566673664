import {
  QUIZ_QUESTION_REQUEST,
  QUIZ_QUESTION_SUCCESS,
  QUIZ_QUESTION_FAILURE,
  QUIZ_QUESTION_UPDATED,
  QUIZ_QUESTION_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataQuestion: [],
  detailQuestion: {},
  metaQuestion: {
    total_count: 0,
    current: 0,
  },
}

export default function quizQuestion(state = initialState, action) {
  switch (action.type) {
    case QUIZ_QUESTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case QUIZ_QUESTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataQuestion: action.data,
        metaQuestion: action.meta,
      }
    case QUIZ_QUESTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case QUIZ_QUESTION_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataQuestion: state.dataQuestion.filter(item => item.id !== action.data.id),
      }
    case QUIZ_QUESTION_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailQuestion: action.data,
      }
    default:
      return state
  }
}
