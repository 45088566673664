import API from 'utils/API'

export const fetchPdp = () => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/retirement-fund-rule`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updatePdp = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/retirement-fund-rule/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
