import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import DownloadPage from 'containers/pages/download/List'

const Download = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/download') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-list-download-read') > -1) {
      return <DownloadPage location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Download.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Download
