import {
  UVLOGS_REQUEST,
  UVLOGS_SUCCESS,
  UVLOGS_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataUvLogs: [],
  metaUvLogs: {},
}

export default function uvLogs(state = initialState, action) {
  switch (action.type) {
    case UVLOGS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case UVLOGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataUvLogs: action.data,
        metaUvLogs: action.meta,
      }
    case UVLOGS_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataUvLogs: [],
        metaUvLogs: {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
