import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import AppsVPage from 'containers/pages/appsVersion/List'
import AppsVForm from 'containers/pages/appsVersion/Form'

const AppsVersion = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/apps-version') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-apps-version-read') > -1) {
      return <AppsVPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-apps-version-write') > -1) {
      return <AppsVForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pelajari-manfaat-read') > -1) {
      return <BenefitsDetail location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

AppsVersion.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default AppsVersion
