import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  createCalcManagement,
  fetchInvestationList,
  fetchDetailCalcManagement,
  updateCalcManagement,
  calcManagementDetail,
} from 'actions/CalculatorManagement'
import { message } from 'antd'
import FormCalculatorManagementView from 'components/pages/calculatorManagement/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataInvestationType,
    metaInvestationType,
    detailCalcManagement,
  } = state.root.calculatorManagement

  return {
    isFetching,
    dataInvestationType,
    metaInvestationType,
    detailCalcManagement,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  createCalcManagement: bindActionCreators(createCalcManagement, dispatch),
  fetchInvestationList: bindActionCreators(fetchInvestationList, dispatch),
  fetchDetailCalcManagement: bindActionCreators(fetchDetailCalcManagement, dispatch),
  updateCalcManagement: bindActionCreators(updateCalcManagement, dispatch),
  calcManagementDetail: bindActionCreators(calcManagementDetail, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      const payload = values
      props.setIsSubmit(!props.isSubmit)
      props.updateCalcManagement(payload, (params.id || null)).then(() => {
        message.success('Data has been updated').then(() => (
          history.push('/calculator-management/asumsi-nilai-suku-bunga')
        ))
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Calculator Management', 'Asumsi nilai suku bunga', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Asumsi Nilai Suku Bunga Form')
      this.props.calcManagementDetail({})
      if (match.params.id) {
        this.props.fetchDetailCalcManagement(match.params.id)
      }
      this.props.fetchInvestationList()
    },
  }),
)(FormCalculatorManagementView)
