import PropTypes from 'prop-types'
import {
  Table, Icon, Tag, Select,
} from 'antd'
import history from 'utils/history'

const contactUsMessage = ({
  messageList, handlePage,
  handleChangeStatus, statusMessage,
}) => {
  const messageLength = messageList.data && messageList.data.length ? messageList.data.length : 0

  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => (messageList.meta.per_page * (messageList.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => text || '-',
    },
    {
      title: 'Kategori',
      dataIndex: 'category',
      key: 'category',
      render: text => (text && text.name ? text.name : '-'),
    },
    {
      title: 'Pesan',
      dataIndex: 'message',
      key: 'message',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'solved',
      key: 'solved',
      render: (text) => {
        let tags
        if (text === true) {
          tags = <Tag color="green">Solved</Tag>
        } else {
          tags = <Tag color="red">Unsolved</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/web-management/contact-us-messages/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-start align-items-center pb-3">
        <h6 className="mb-1 mr-2 align-self-center">Filter By: </h6>
        <Select
          placeholder="Status Message"
          style={{ width: 250 }}
          onChange={handleChangeStatus}
          className="mr-2"
          allowClear
        >
          {statusMessage.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
        </Select>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={messageList.data}
        loading={messageList.isFetching}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: messageList.meta ? messageList.meta.per_page : 10,
          total: messageList.meta ? messageList.meta.total_count : messageLength,
          current: messageList.meta ? messageList.meta.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

contactUsMessage.propTypes = {
  messageList: PropTypes.any,
  handlePage: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  statusMessage: PropTypes.array,
}

export default contactUsMessage
