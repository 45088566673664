import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, Upload, Select,
  Col, Row,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24,
    },
    sm: {
      offset: 2,
      span: 12,
    },
  },
}

const FormClaimMeninggalDunia = ({
  form, isEdit, listBank, listRelationship, listClaimType,
  handleChange, file, previewFile,
  handleSubmit, detail, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form

  const emailRules = [{
    required: true,
    type: 'email',
  }]

  let typeButton
  if (detail.data.status && detail.data.status == 'approved') {
    typeButton = 'type=\'primary\' ghost'
  } else if (detail.data.status && detail.data.status == 'rejected') {
    typeButton = 'type=\'default\' ghost'
  } else {
    typeButton = 'type=\'default\''
  }
  return (
    <div>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Row className="pb-3" type="flex" justify="space-around">
          <Col xs={24} sm={15}>
            {isEdit
            && (
            <Card className="mb-4">
              <Row>
                <Col md={8}>
                  <h6 className="mb-3">Tanggal Permohonan</h6>
                  <p className="mb-0">{detail.data.created_at ? moment(detail.data.created_at).format('DD MMMM YYYY') : '-'}</p>
                </Col>
                <Col md={8}>
                  <h6 className="mb-3">Nama Peserta</h6>
                  <p className="mb-0">{detail.data.account ? detail.data.account.registered_number : '-'}</p>
                </Col>
                <Col md={8}>
                  <h6 className="mb-3">Jenis Klaim</h6>
                  <Button typeButton>
                    {detail.data.claim_type ? detail.data.claim_type.name : '-'}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={8}>
                  <h6 className="mb-3">Nomor Peserta</h6>
                  <p className="mb-0">{detail.data.account ? detail.data.account.registered_number : '-'}</p>
                </Col>
                <Col md={8}>
                  <h6 className="mb-3">Tanggal Bergabung</h6>
                  <p className="mb-0">{detail.data.account ? moment(detail.data.account.joined_date).format('DD MMMM YYYY') : '-'}</p>
                </Col>
                <Col md={8}>
                  <h6 className="mb-3">Status Permohonan</h6>
                  <Button typeButton>
                    {detail.data.status ? detail.data.status : '-'}
                  </Button>
                </Col>
              </Row>
            </Card>
            )}
            <Card className="mb-4">
              <h5 className="mb-4">Informasi Data Pemohon</h5>
              <Form.Item label="Nomor Peserta :">
                {getFieldDecorator('registered_number', {
                  initialValue: detail.data.account ? detail.data.account.registered_number : '',
                  rules: [{ required: true, message:'Nomor Peserta wajib diisi', }],
                })(
                  <Input name="registered_number" type="number" placeholder="Masukan Nomor Peserta" />,
                )}
              </Form.Item>
              <Form.Item label="Claim Type">
                {getFieldDecorator('claim_type_id', {
                  initialValue: detail.data.claim_type_id,
                  rules: [{ required: true,message:'Tipe klaim wajib diisi' }],
                })(
                  <Select
                    loading={listClaimType.isFetching}
                    placeholder="Select claim type"
                  >
                    {listClaimType.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Nama Pemohon :">
                {getFieldDecorator('claimant_name', {
                  initialValue: detail.data.claimant_name,
                  rules: [{ required: true, message:'Nama pemohon wajib diisi' }],
                })(
                  <Input name="claimant_name" type="text" placeholder="Masukan Nama pemohon" />,
                )}
              </Form.Item>
              <Form.Item label="Email Pemohon :">
                {getFieldDecorator('claimant_email', {
                  initialValue: detail.data.claimant_email,
                  rules: [{ type: 'email', required: true, message:'Email Tidak Valid' }],
                })(
                  <Input name="claimant_email" placeholder="Masukan Email pemohon" />,
                )}
              </Form.Item>
              <Form.Item label="Hubungan :">
                {getFieldDecorator('relation_type_id', {
                  initialValue: detail.data.relation_type_id,
                  rules: [{ required: true, message:'Hubungan pemohon wajib diisi' }],
                })(
                  <Select
                    loading={listRelationship.isFetching}
                    placeholder="Pilih Hubungan"
                  >
                    {listRelationship.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Card>
            <Card className="mb-4">
              <h5 className="mb-4">Penerima Klaim</h5>
              <Form.Item label="Nama Lengkap :">
                {getFieldDecorator('recipient_name', {
                  initialValue: detail.data.recipient_name,
                  rules: [{ required: true, message:'Nama penerima klaim wajib diisi' }],
                })(
                  <Input name="recipient_name" type="text" placeholder="Masukan Nama" />,
                )}
              </Form.Item>
              <Form.Item label="Bank :">
                {getFieldDecorator('bank_id', {
                  initialValue: detail.data.bank ? detail.data.bank.id : null,
                  rules: [{ required: true, message:'Bank penerima klaim wajib diisi' }],
                })(
                  <Select
                    loading={listBank.isFetching}
                    placeholder="Pilih Bank"
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {listBank.data.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>
                  ,
                )}
              </Form.Item>
              <Form.Item label="Nomor Rekening :">
                {getFieldDecorator('bank_account_number', {
                  initialValue: detail.data.bank_account_number,
                  rules: [{ required: true, message:'Nomor rekening penerima klaim wajib diisi' }],
                })(
                  <Input name="bank_account_number" type="number" placeholder="Masukan Nomor Rekening" />,
                )}
              </Form.Item>
              <Form.Item label="Email :">
                {getFieldDecorator('recipient_email', {
                  initialValue: detail.data.recipient_email,
                  rules: [{ type: 'email', required: true, message:'Email Tidak Valid' }],
                })(
                  <Input name="recipient_email" placeholder="Masukan Email" />,
                )}
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} sm={8}>
            <Card className="mb-4">
              <h5 className="mb-4">Dokumen Persyaratan</h5>
              <Upload
                disabled={isSubmit}
                name="document"
                listType="picture"
                beforeUpload={() => false}
                onChange={e => handleChange(e)}
                fileList={previewFile}
                accept="image/*,.pdf"
              >
                <Button type="primary" loading={isSubmit}>
                  Upload Dokumen Persyaratan Disini
                </Button>
              </Upload>
              {/* <small>Ekstensi file yang diizinkan adalah .xlsx</small> */}
            </Card>
          </Col>
        </Row>
        <Form.Item {...tailLayout} className="btn-primary-wrapper pb-4">
          <Button loading={isSubmit} onClick={() => history.push('/claim/claim-by-admin')} className="mr-2">Cancel</Button>
          <Button type="primary" loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Data' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

FormClaimMeninggalDunia.propTypes = {
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  listBank: PropTypes.object,
  listRelationship: PropTypes.object,
  listClaimType: PropTypes.object,
  handleChange: PropTypes.func,
  file: PropTypes.any,
  previewFile: PropTypes.any,
  handleSubmit: PropTypes.func,
  detail: PropTypes.object,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'claimMeninggalDunia' })(FormClaimMeninggalDunia)
