import PropTypes from 'prop-types'
import {
  Table, Tag,
  Popconfirm, Button,
  Input,
} from 'antd'
import moment from 'moment'

const ListProfile = ({
  isFetching, handlePage, handleVerify,
  dataListProfile, metaListProfile,
  handleSearch, currentUser,
}) => {
  const columns = [
    {
      title: 'Registered Number',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Name',
      dataIndex: 'user_name',
      key: 'user_name',
      render: text => text || '-',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => text || '-',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
      render: text => text || '-',
    },
    {
      title: 'Request Time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => (text ? moment.utc(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let tags
        if (text === 'approved') {
          tags = <Tag color="green">{text || '-'}</Tag>
        } else if (text === 'pending') {
          tags = <Tag color="orange">{text || '-'}</Tag>
        } else {
          tags = <Tag>{text || '-'}</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      fixed: 'right',
      render: (text, record) => (record.status === 'pending' ? (
        <div className="d-flex justify-content-around btn-primary-wrapper">
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-customer-managementverify-update-profile-write') > -1) ? (
            <Popconfirm
              title="Are you sure want to verify this Customer?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleVerify(record.id)}
            >
              <Button type="primary" size="small">
                Verify Customer
              </Button>
            </Popconfirm>
          ) : (
            <Button type="primary" disabled>
              Verify Customer
            </Button>
          )}
        </div>
      )
        : (
          <div className="d-flex justify-content-around btn-primary-wrapper">
            <Button type="primary" disabled>
              Verify Customer
            </Button>
          </div>
        )
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search..."
              onSearch={handleSearch}
              enterButton
            />
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataListProfile}
        loading={isFetching}
        scroll={{ x: 1250 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaListProfile ? metaListProfile.total_count : dataListProfile.length,
          current: metaListProfile ? metaListProfile.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

ListProfile.propTypes = {
  isFetching: PropTypes.bool,
  dataListProfile: PropTypes.array,
  metaListProfile: PropTypes.object,
  handleVerify: PropTypes.func,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  currentUser: PropTypes.object,
}

export default ListProfile
