import {
  OTTOPAY_REQUEST,
  OTTOPAY_SUCCESS,
  OTTOPAY_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataOttopay: [],
  metaOttopay: {},
}

export default function ottopay(state = initialState, action) {
  switch (action.type) {
    case OTTOPAY_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case OTTOPAY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataOttopay: action.data,
        metaOttopay: action.meta,
      }
    case OTTOPAY_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataOttopay: action.data === null ? [] : [],
        metaOttopay: {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
