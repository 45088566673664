import API from 'utils/API'
import config from 'app/config'

export const fetchProvince = () => (
  () => new Promise((resolve, reject) => (
    API.get(
      '/list-form/provinces',
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchCity = id => (
  () => new Promise((resolve, reject) => (
    API.get(
      `list-form/cities?province_id=${id}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchSubDistricts = id => (
  () => new Promise((resolve, reject) => (
    API.get(
      `list-form/subdistricts?city_id=${id}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchVillages = id => (
  () => new Promise((resolve, reject) => (
    API.get(
      `list-form/villages?sub_district_id=${id}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchPostCode = id => (
  () => new Promise((resolve, reject) => (
    API.get(
      `list-form/postcode?village_id=${id}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
