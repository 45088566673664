import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchFaq, deleteFaq } from 'actions/Faq'
import FaqView from 'components/pages/faqs/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataFaq,
    metaFaq,
  } = state.root.faq

  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataFaq,
    metaFaq,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchFaq: bindActionCreators(fetchFaq, dispatch),
  deleteFaq: bindActionCreators(deleteFaq, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        // search: search || '',
      }

      props.fetchFaq(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    /* handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    }, */
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    /* handleSearch: () => () => {},
    handlePage: props => (page) => {
      props.fetchFaq(page ? `?${page}` : '')
    }, */
    handleDelete: props => (id) => {
      props.deleteFaq(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'F.A.Q'])
      this.props.updateSiteConfiguration('titlePage', 'F.A.Q')
      this.props.fetchFaq()
    },
  }),
)(FaqView)
