import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import VProductList from 'containers/pages/ultraVouchers/products/List'
import VProductForm from 'containers/pages/ultraVouchers/products/Form'

const Products = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/ultra-vouchers/products') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-voucherproduct-read') > -1) {
      return <VProductList location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-voucherproduct-write') > -1) {
      return <VProductForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Products.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Products
