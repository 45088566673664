import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Radio, Col, Row,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormAppsVersion = ({
  form, handleSubmit, status,
  detailAppsVersion, isSubmit,
  handleRadio,
}) => {
  const { getFieldDecorator, validateFields } = form

  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Row className="pb-3">
          <Col xs={24} sm={5}>
            <p className="text-right mr-2 mb-0" style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Platform:</p>
          </Col>
          <Col xs={24} sm={12}>
            <h6 className="mb-0">{detailAppsVersion.platform}</h6>
          </Col>
        </Row>
        <Form.Item label="Version code">
          {getFieldDecorator('version_code', {
            initialValue: detailAppsVersion.version_code,
            rules: [{ required: true }],
          })(
            <Input placeholder="Version code" />,
          )}
        </Form.Item>
        <Form.Item label="Force update">
          <Radio.Group
            buttonStyle="solid"
            name="force_update"
            onChange={(e) => {
              handleRadio(e)
            }}
            value={status}
          >
            <Radio.Button value>True</Radio.Button>
            <Radio.Button value={false}>False</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/apps-version')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isSubmit}>Submit change</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormAppsVersion.propTypes = {
  detailAppsVersion: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  status: PropTypes.bool,
  handleRadio: PropTypes.func,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'calcManagementForm' })(FormAppsVersion)
