import PropTypes from 'prop-types'
import {
  Table, Icon, Tag, Button, Popconfirm,
} from 'antd'
import history from 'utils/history'

const HighlightSimpan = ({
  highlightList, handleDelete, currentUser,
}) => {
  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: 80,
      render: text => (
        <div>
          <img src={text} className="img-fluid" style={{ maxWidth: '50px' }} alt="icon-img" />
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 180,
      render: text => text || '-',
    },
    {
      title: 'Short Description',
      dataIndex: 'content',
      key: 'content',
      width: 360,
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      width: 80,
      render: (text) => {
        let tags
        if (text === true) {
          tags = <Tag color="green">true</Tag>
        } else {
          tags = <Tag color="red">false</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementhighlight-simpan-write') > -1) && (
            <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`/web-management/highlight-simpan/${record.id}/edit`)} />
          )}
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementhighlight-simpan-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this benefit?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon style={{ color: '#ff5159' }} type="delete" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center pb-3">
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/web-management/highlight-simpan/add')}
          >
            Add Highlight
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={highlightList.data}
        loading={highlightList.isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

HighlightSimpan.propTypes = {
  highlightList: PropTypes.any,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default HighlightSimpan
