import Cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import { purgeStoredState } from 'redux-persist'
import { mainPersistConfig } from 'store/configureStore'
import history from 'utils/history'
import config from 'app/config'
import Browser from 'utils/Browser'
import API from 'utils/API'
import {
  AUTHENTICATE_USER_REQUEST,
  AUTHENTICATE_USER_SUCCESS,
  AUTHENTICATE_USER_FAILURE,
  UPDATE_AUTH_CURRENT_USER,
} from 'constants/ActionTypes'
import { updateSiteConfiguration } from './Site'


export const authenticateUserRequest = () => ({
  type: AUTHENTICATE_USER_REQUEST,
})

export const authenticateUserSuccess = data => ({
  type: AUTHENTICATE_USER_SUCCESS,
  currentUser: data,
})

export const authenticateUserFailure = errorMessage => ({
  type: AUTHENTICATE_USER_FAILURE,
  errorMessage,
})

export const updateAuthCurrentUser = currentUser => ({
  type: UPDATE_AUTH_CURRENT_USER,
  currentUser,
})

export const removeToken = () => {
  Cookies.remove(config.auth_cookie_name, { path: '' })
}

export const redirectToLogin = () => (
  Browser.setWindowHref('/')
)

export const getAccessToken = () => (
  Cookies.get(config.auth_cookie_name)
)

export const loginUser = data => (
  (dispatch) => {
    dispatch(authenticateUserSuccess(data))
    Cookies.set(config.auth_cookie_name, data.auth_token, {
      path: '',
    })

    dispatch(updateSiteConfiguration('activePage', 'dashboard'))

    history.push('/home')
  }
)

export const authenticateByCredentials = params => (
  (dispatch) => {
    dispatch(authenticateUserRequest())

    return API.post('/auth/cms/login', params).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(loginUser(data))
        } else {
          dispatch(updateAuthCurrentUser(null))
          dispatch(authenticateUserFailure(response.data.meta.message))
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        dispatch(authenticateUserFailure(err.response.data.meta.message)) // eslint-disable-line no-console
      } else {
        dispatch(authenticateUserFailure(err.message)) // eslint-disable-line no-console
      }
    })
  }
)

export const authenticateByToken = () => (
  (dispatch) => {
    const accessToken = getAccessToken()
    if (isEmpty(accessToken) || accessToken === 'undefined') {
      // dispatch(updateAuthCurrentUser(null))
      dispatch(authenticateUserFailure())
      redirectToLogin()
    }

    /* return API.post(
      '/auth/authorization',
      { token: accessToken },
    ).then(
      (response) => {
        if (response.data.status) {
          dispatch(authenticateUserSuccess({ access_token: accessToken }))
        } else {
          dispatch(authenticateUserFailure())
          redirectToLogin()
        }
      },
    ).catch((err) => {
      console.error(err) // eslint-disable-line no-console
    }) */
  }
)

export const clearCurrentUser = () => (
  () => (
    purgeStoredState(mainPersistConfig).then(() => {
      removeToken()
      Browser.setWindowHref('/')
    })
  )
)

export const resetPassword = payload => (
  () => new Promise((resolve, reject) => (
    API.post(
      '/passwords/change',
      payload,
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data.meta) {
        reject(err.response.data.meta.message)
      } else {
        reject(err.message)
      }
    })
  ))
)


export const forgotPassword = payload => (
  () => new Promise((resolve, reject) => (
    API.post(
      '/passwords/reset',
      payload,
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data.meta) {
        reject(err.response.data.meta.message)
      } else {
        reject(err.message)
      }
    })
  ))
)

export const createPassword = payload => (
  () => new Promise((resolve, reject) => (
    API.post(
      '/passwords/createpasswordcms',
      payload,
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data.meta) {
        reject(err.response.data.meta.message)
      } else {
        reject(err.message)
      }
    })
  ))
)
