import PropTypes from 'prop-types'
import {
  Table, Icon,
  Button, Modal,
  Upload,
} from 'antd'

const InvestmentYear = ({
  isFetching, dataInvestmentYear,
  uploadModal, toggleUploadModal,
  handleChange, handleUpload, isAttached,
  previewFile, fileInvestmentYear,
  file, currentUser,
}) => {
  const columns = [
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Deposit',
      dataIndex: 'deposit',
      key: 'deposit',
    },
    {
      title: 'Obligation',
      dataIndex: 'obligation',
      key: 'obligation',
    },
    {
      title: 'Combination',
      dataIndex: 'combination',
      key: 'combination',
    },
  ]

  const downloadFile = () => {
    window.location.href = fileInvestmentYear.file_url
  }

  return (
    <div className="pb-5">
      <div>
        <div className="d-flex justify-content-between justify-content-md-end align-items-center mb-3 btn-primary-wrapper">
          <Button
            type="primary"
            className="mr-3"
            onClick={() => downloadFile()}
          >
            <Icon type="download" />
            Download Sheet
          </Button>
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-riwayat-hasil-pengembanganinvestment-history-write') > -1) && (

          <Button
            type="primary"
            onClick={() => toggleUploadModal()}
          >
            Upload Sheet
          </Button>
          )}
        </div>
        <Table
          rowKey="id"
          className="table-striped-rows"
          columns={columns}
          dataSource={dataInvestmentYear}
          loading={isFetching}
          scroll={{ x: 1300 }}
          pagination={false}
        />
      </div>

      <Modal
        title="Upload Investment Year Sheet"
        centered
        visible={uploadModal}
        footer={null}
        onCancel={() => toggleUploadModal()}
      >
        <div>
          <Upload
            name="document"
            listType="document"
            beforeUpload={() => false}
            onChange={e => handleChange(e)}
            fileList={previewFile}
            accept=".xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            <Button>
              <Icon type="upload" />
              {' '}
              Attach file
            </Button>
          </Upload>
          <div className="mt-5">
            <Button
              type="primary"
              onClick={() => handleUpload(file)}
              disabled={isAttached}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

InvestmentYear.propTypes = {
  isFetching: PropTypes.bool,
  dataInvestmentYear: PropTypes.array,
  uploadModal: PropTypes.bool,
  toggleUploadModal: PropTypes.func,
  handleChange: PropTypes.func,
  handleUpload: PropTypes.func,
  isAttached: PropTypes.bool,
  previewFile: PropTypes.any,
  file: PropTypes.any,
  fileInvestmentYear: PropTypes.object,
  currentUser: PropTypes.object,
}

export default InvestmentYear
