import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button, Popconfirm,
  Input, Row, Col,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormChangeVerifyEmail = ({
  detailCustomer, handleSubmit, isSubmit,
  form,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Row>
        <Col xs={24} sm={5}>
          <p className="text-right mr-2" style={{ color: '#000000d9' }}>Current Email:</p>
        </Col>
        <Col xs={24} sm={12}>
          <p>{detailCustomer.core_input ? detailCustomer.core_input.email : '-'}</p>
        </Col>
      </Row>
      <Form
        {...formItemLayout}
      >
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: detailCustomer.user_input ? detailCustomer.user_input.user_email : '',
            rules: [
              {
                required: true,
                type: 'email',
              },
            ],
          })(
            <Input placeholder="User Email" />,
          )}
        </Form.Item>

        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/customer/phone')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
          <Popconfirm
            title="Are you sure want to change email and verify this Customer?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={(e) => {
              e.preventDefault()
              validateFields((err, values) => {
                if (!err) {
                  handleSubmit(values)
                }
              })
            }}
            htmlType="submit"
          >
            <Button type="primary" loading={isSubmit}>
              Submit Change and Verify Customer
            </Button>
          </Popconfirm>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormChangeVerifyEmail.propTypes = {
  detailCustomer: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'changeEmailVerifyForm' })(FormChangeVerifyEmail)
