import {
  FAQ_REQUEST,
  FAQ_SUCCESS,
  FAQ_FAILURE,
  FAQ_UPDATED,
  FAQ_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataFaq: [],
  detailFaq: {},
  metaFaq: {},
}

export default function faq(state = initialState, action) {
  switch (action.type) {
    case FAQ_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case FAQ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataFaq: action.data,
        metaFaq: action.meta,
      }
    case FAQ_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case FAQ_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataFaq: state.dataFaq.filter(item => item.id !== action.data.id),
      }
    case FAQ_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailFaq: action.data,
      }
    default:
      return state
  }
}
