import API from 'utils/API'
import {
  TRANSACTION_REQUEST,
  TRANSACTION_SUCCESS,
  TRANSACTION_FAILURE,
  TRANSACTION_UPDATED,
  TRANSACTION_DETAIL_SUCCESS,
} from 'constants/ActionTypes'
import config from 'app/config'

export const transactionRequest = () => ({
  type: TRANSACTION_REQUEST,
})

export const transactionSuccess = (data, meta) => ({
  type: TRANSACTION_SUCCESS,
  data,
  meta,
})

export const transactionFailure = errorMessage => ({
  type: TRANSACTION_FAILURE,
  errorMessage,
})

export const transactionUpdate = data => ({
  type: TRANSACTION_UPDATED,
  data,
})

export const transactionDetail = data => ({
  type: TRANSACTION_DETAIL_SUCCESS,
  data,
})

export const fetchTransaction = params => (
  (dispatch) => {
    dispatch(transactionRequest())
    const url = `${process.env.APP_CONFIG.api_url_payment}/transactions/list${params || ''}`
    return API.get(
      url,
      { headers: { 'x-api-key': config.api_key } },
    )
      .then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            dispatch(transactionSuccess(data, meta))
          } else {
            dispatch(transactionFailure(meta.message))
          }
        },
      ).catch((err) => {
        dispatch(transactionFailure(err.message)) // eslint-disable-line no-console
      })
  }
)
