import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Radio,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const VideoSimpanForm = ({
  detail, handleSubmit, handleRadio,
  form, isEdit, status,
  isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: detail.title,
            rules: [{ required: true, message: 'Title wajib diisi' }],
          })(
            <Input placeholder="Title" />,
          )}
        </Form.Item>
        <Form.Item label="Short Description">
          {getFieldDecorator('description', {
            initialValue: detail.description,
            rules: [{ required: true, message: 'Short Description wajib diisi' }],
          })(
            <Input placeholder="Short Description" />,
          )}
        </Form.Item>
        <Form.Item label="Video URL">
          {getFieldDecorator('url', {
            initialValue: detail.url,
            rules: [
              { required: true, message: 'URL Video wajib diisi' },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                message: 'Masukan URL dengan format yang benar',
              },
            ],
          })(
            <Input autoComplete="off" type="url" placeholder="URL" />,
          )}
        </Form.Item>
        <Form.Item label="Status">
          <Radio.Group
            buttonStyle="solid"
            name="active"
            onChange={(e) => {
              handleRadio(e)
            }}
            value={status}
          >
            <Radio.Button value>True</Radio.Button>
            <Radio.Button value={false}>False</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/web-management/video-simpan')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
          <Button type="primary" loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Video' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

VideoSimpanForm.propTypes = {
  detail: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  handleRadio: PropTypes.func,
  status: PropTypes.bool,
}

export default Form.create({ name: 'videoSimpanForm' })(VideoSimpanForm)
