import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { message } from 'antd'
import { fetchAboutPakde, updateAboutPakde } from 'actions/AboutPakde'
import FormAboutPakdeView from 'components/pages/webManagement/aboutPakde/Form'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchAboutPakde: bindActionCreators(fetchAboutPakde, dispatch),
  updateAboutPakde: bindActionCreators(updateAboutPakde, dispatch),
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('longDesc', 'setLongDesc', ''),
  withState('isSubmit', 'setIsSubmit', false),
  withState('detail', 'setDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getDetail: props => () => {
      props.fetchAboutPakde()
        .then((result) => {
          props.setDetail({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch((error) => {
          props.setDetail({
            data: {},
            meta: {},
            isFetching: false,
          })
          message.error(error.message)
        })
    },
  }),
  withHandlers({
    onSubmit: props => (values) => {
      const { params } = props.match
      props.setIsSubmit(!props.isSubmit)
      props.updateAboutPakde(values, params.id)
        .then(() => {
          props.setIsSubmit(!props.isSubmit)
          history.push('/web-management/about-pak-d')
        }).catch((err) => {
          message.error(err)
          props.setIsSubmit(!props.isSubmit)
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'About Pak-D', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'About Pak-D Form')

      this.props.getDetail()
    },
  }),
)(FormAboutPakdeView)
