import API from 'utils/API'
import { message } from 'antd'
import {
  QUIZ_QUESTION_REQUEST,
  QUIZ_QUESTION_SUCCESS,
  QUIZ_QUESTION_FAILURE,
  QUIZ_QUESTION_UPDATED,
  QUIZ_QUESTION_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const quizQuestionRequest = () => ({
  type: QUIZ_QUESTION_REQUEST,
})

export const quizQuestionSuccess = (data, meta) => ({
  type: QUIZ_QUESTION_SUCCESS,
  data,
  meta,
})

export const quizQuestionFailure = errorMessage => ({
  type: QUIZ_QUESTION_FAILURE,
  errorMessage,
})

export const quizQuestionUpdate = data => ({
  type: QUIZ_QUESTION_UPDATED,
  data,
})

export const quizQuestionDetail = data => ({
  type: QUIZ_QUESTION_DETAIL_SUCCESS,
  data,
})

export const fetchQuestion = params => (
  (dispatch) => {
    dispatch(quizQuestionRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/courses-questions${params || ''}`
    return new Promise((resolve, reject) => (
      API.get(url).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
            dispatch(quizQuestionSuccess(data, meta))
          } else {
            reject(meta.message)
            dispatch(quizQuestionFailure(meta.message))
          }
        },
      ).catch((err) => {
        reject(err.message)
        dispatch(quizQuestionFailure(err.message)) // eslint-disable-line no-console
      })
    ))
  }
)

export const fetchDetailQuestion = id => (
  (dispatch) => {
    dispatch(quizQuestionRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/courses-questions/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(quizQuestionDetail(data))
        } else {
          dispatch(quizQuestionFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(quizQuestionFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteQuestion = id => (
  (dispatch) => {
    dispatch(quizQuestionRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/courses-questions/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Question has been deleted')
          dispatch(quizQuestionUpdate(data))
        } else {
          message.error(meta.message)
          dispatch(quizQuestionFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(quizQuestionFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createQuestion = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/courses-questions`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updateQuestion = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/courses-questions/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)
