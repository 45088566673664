import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, withState } from 'recompose'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import Ottopay from 'components/pages/ottopay'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateCheck', 'setStateCheck', {
    isLocked: false,
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Ottopay'])
      this.props.updateSiteConfigs({
        titlePage: 'Ottopay Transaction List',
        activePage: 'ottopay',
        activeSubPage: 'ottopay',
      })
    },
  }),
)(Ottopay)
