import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form,
  Input, Button,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormCategory = ({
  detailCategory, handleSubmit,
  form, isEdit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: detailCategory.name,
            rules: [{ required: true }],
          })(
            <Input placeholder="Input Name" />,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/elearning/categories')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit">{!isEdit ? 'Add Category' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormCategory.propTypes = {
  detailCategory: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
}

export default Form.create({ name: 'categoryForm' })(FormCategory)
