import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Router, Route, Switch } from 'react-router-dom'
import history from 'utils/history'
import privateComponent from 'utils/hocs/privateComponent'
import publicComponent from 'utils/hocs/publicComponent'
import PublicLayout from 'containers/layouts/PublicLayout'
import PrivateLayout from 'containers/layouts/PrivateLayout'

import Login from 'containers/login/Login'
import Forgot from 'containers/login/Forgot'
import Create from 'containers/login/Create'

import NotFound from 'containers/NotFound'
import Dashboard from 'containers/pages/dashboard'
import Home from 'containers/pages/home'
import LearningContent from 'containers/pages/elearnings/contents'
// import FormLearningContent from 'containers/pages/elearnings/contents/Form'
import LearningQuiz from 'containers/pages/elearnings/quiz'
import FormLearningQuiz from 'containers/pages/elearnings/quiz/Form'
import Notifications from 'containers/pages/notifications'
// import FormNotifications from 'containers/pages/notifications/Form'
import Benefits from 'containers/pages/benefits'
// import FormBenefits from 'containers/pages/benefits/Form'
import About from 'containers/pages/abouts'
// import FormAbout from 'containers/pages/abouts/Form'
import AboutDplk from 'containers/pages/aboutDplk'
// import FormAboutDplk from 'containers/pages/aboutDplk/Form'
// import DepositGuide from 'containers/pages/deposits'
// import FormDepositGuide from 'containers/pages/deposits/Form'
import Category from 'containers/pages/categories'
// import FormCategory from 'containers/pages/categories/Form'
import Faq from 'containers/pages/faqs'
// import FormFaq from 'containers/pages/faqs/Form'
import User from 'containers/pages/users'
import Customer from 'containers/pages/customers'
import UpdateProfile from 'containers/pages/customers/updateProfile'
import InjectPoint from 'containers/pages/injectPoint'
import PakDe from 'containers/pages/pakde'
// import FormPakDe from 'containers/pages/pakde/Form'
import Colek from 'containers/pages/colek'
// import FormColek from 'containers/pages/colek/Form'

import Role from 'containers/pages/adminManagement/role'
import Admin from 'containers/pages/adminManagement/admin'

// import CalculatorManagement from 'containers/pages/calculatorManagement'
import AsumsiNilaiSukuBunga from 'containers/pages/calculatorManagement'
// import FormAsumsiNilaiSukuBunga from 'containers/pages/calculatorManagement/Form'
// import FormDplkFee from 'containers/pages/calculatorManagement/dplkFee/Form'
import DplkFee from 'containers/pages/calculatorManagement/dplkFee'
import InvestmentHistory from 'containers/pages/calculationDplk/investmentHistory'
import InvestmentYear from 'containers/pages/calculationDplk/investmentYear'

import DetailBenefit from 'containers/pages/benefits/detail'
// import Deposit from 'containers/pages/setoran'
// import DetailCustRegis from 'containers/pages/customers/detail'
// import FormChangeEmailCustomer from 'containers/pages/customers/Form'
import Payment from 'containers/pages/payment'
// import RemindFriend from 'containers/pages/pakde/remindFriend'
import Commission from 'containers/pages/commission'
// import DetailUser from 'containers/pages/users/detail'
import UltraVoucherCategory from 'containers/pages/ultraVouchers/categories'
// import FormUltraVoucherCategory from 'containers/pages/ultraVouchers/categories/Form'
import UltraVoucher from 'containers/pages/ultraVouchers/products'
// import FormUltraVoucherProduct from 'containers/pages/ultraVouchers/products/Form'
import UltraVoucherBrands from 'containers/pages/ultraVouchers/brands'
// import UltraVoucherBrandForm from 'containers/pages/ultraVouchers/brands/Form'
import UltraVoucherLogs from 'containers/pages/ultraVouchers/logs'
import CommissionConfig from 'containers/pages/commission/config'
import FormCommissionConfig from 'containers/pages/commission/config/Form'
// import ApprovedCommission from 'containers/pages/commission/ApprovedList'
import Pdp from 'containers/pages/pdp'
// import FormPdp from 'containers/pages/pdp/Form'
import ClaimNormal from 'containers/pages/claim/claimNormal'
import ClaimNormalRequest from 'containers/pages/claim/claimNormal/detail'
import ClaimMeninggalDunia from 'containers/pages/claim/claimMeninggalDunia'
import ClaimMeninggalDuniaFormRequest from 'containers/pages/claim/claimMeninggalDunia/formRequest'
import ClaimMeninggalDuniaRequest from 'containers/pages/claim/claimMeninggalDunia/detail'
import ManagementOttocash from 'containers/pages/danaOttocash/management'
// import FormManagementOttocash from 'containers/pages/danaOttocash/management/Form'
import LogsOttocash from 'containers/pages/danaOttocash/logOttocash'

import PaymentConfiguration from 'containers/pages/paymentConfiguration'
import Ottopay from 'containers/pages/ottopay'
import AutoDebitPendaftaran from 'containers/pages/autoDebit/pendaftaran'
import AutoDebitPembatalan from 'containers/pages/autoDebit/pembatalan'
import AutoDebitLogPayment from 'containers/pages/autoDebit/logPayment'
// import FormAppsVersion from 'containers/pages/appsVersion/Form'
import AppsVersion from 'containers/pages/appsVersion'

// WEB MANAGEMENT
import ReviewList from 'containers/pages/webManagement/reviews'
// import FormReview from 'containers/pages/webManagement/reviews/Form'
import BannerList from 'containers/pages/webManagement/banners'
// import FormBanner from 'containers/pages/webManagement/banners/Form'
import Penjelasan from 'containers/pages/webManagement/penjelasanSimpan'
// import FormPenjelasan from 'containers/pages/webManagement/penjelasanSimpan/Form'
import Highlight from 'containers/pages/webManagement/highlightSimpan'
// import FormHighlight from 'containers/pages/webManagement/highlightSimpan/Form'
import BannerApps from 'containers/pages/webManagement/bannerApps'
// import FormBannerApps from 'containers/pages/webManagement/bannerApps/Form'
import PakdeBenefits from 'containers/pages/webManagement/pakdeBenefits'
// import FormPakdeBenefits from 'containers/pages/webManagement/pakdeBenefits/Form'
import AboutPakde from 'containers/pages/webManagement/aboutPakde'
// import FormAboutPakde from 'containers/pages/webManagement/aboutPakde/Form'
import ContactUsMessages from 'containers/pages/webManagement/contactUsMessages'
// import FormContactUsMessages from 'containers/pages/webManagement/contactUsMessages/Form'
import VideoSimpan from 'containers/pages/webManagement/videoSimpan'
// import FormVideoSimpan from 'containers/pages/webManagement/videoSimpan/Form'
import ContactUsCategory from 'containers/pages/webManagement/contactUsCategories'
// import FormContactUsCategory from 'containers/pages/webManagement/contactUsCategories/Form'

import UpdateData from 'containers/pages/updateData'
// import CommissionDetail from 'containers/pages/commission/Detail'
// import HistoryPointUser from 'containers/pages/users/historyPoint'
// import DownlinePoint from 'containers/pages/users/downline'

import Download from 'containers/pages/download'

const PublicRoute = (props) => {
  const {
    component: Component,
    redirect,
    redirectPath,
    ...rest
  } = props

  const PublicLayoutView = publicComponent(PublicLayout, redirect, redirectPath)

  return (
    <Route
      {...rest}
      render={matchProps => (
        <PublicLayoutView>
          <Component {...matchProps} />
        </PublicLayoutView>
      )}
    />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const PrivateLayoutView = privateComponent(PrivateLayout)

  return (
    <Route
      {...rest}
      render={matchProps => (
        <PrivateLayoutView>
          <Component {...matchProps} />
        </PrivateLayoutView>
      )}
    />
  )
}

const Root = ({ store, persistor }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <Router history={history}>
        <Switch>
          <PublicRoute redirect exact path="/" component={Login} />
          <PublicRoute redirect exact path="/reset" component={Forgot} />
          <PublicRoute redirect exact path="/create" component={Create} />

          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/home" component={Home} />
          <PrivateRoute exact path="/notification/add" component={Notifications} />
          <PrivateRoute exact path="/notification/:id/edit" component={Notifications} />
          <PrivateRoute exact path="/notification" component={Notifications} />
          <PrivateRoute exact path="/elearning/content/add" component={LearningContent} />
          <PrivateRoute exact path="/elearning/content/:id/edit" component={LearningContent} />
          <PrivateRoute exact path="/elearning/content" component={LearningContent} />
          {/* <PrivateRoute exact path="/elearning/content/add" component={FormLearningContent} />
          <PrivateRoute exact path="/elearning/content/:id/edit" component={FormLearningContent} /> */}
          <PrivateRoute exact path="/elearning/quiz/:type/add" component={FormLearningQuiz} />
          <PrivateRoute exact path="/elearning/quiz/:type/:id/edit" component={FormLearningQuiz} />
          <PrivateRoute exact path="/elearning/quiz/:type" component={LearningQuiz} />
          <PrivateRoute exact path="/elearning" component={Dashboard} />
          <PrivateRoute exact path="/about/add" component={About} />
          <PrivateRoute exact path="/about/:id/edit" component={About} />
          <PrivateRoute exact path="/about" component={About} />
          <PrivateRoute exact path="/about-dplk" component={AboutDplk} />
          <PrivateRoute exact path="/about-dplk/add" component={AboutDplk} />
          <PrivateRoute exact path="/about-dplk/:id/edit" component={AboutDplk} />
          <PrivateRoute exact path="/user" component={User} />
          <PrivateRoute exact path="/customer/update-profile" component={UpdateProfile} />
          <PrivateRoute exact path="/customer/:type" component={Customer} />
          <PrivateRoute exact path="/inject-point/:id/edit" component={InjectPoint} />
          <PrivateRoute exact path="/inject-point" component={InjectPoint} />
          <PrivateRoute exact path="/payment" component={Payment} />
          <PrivateRoute exact path="/benefit/add" component={Benefits} />
          <PrivateRoute exact path="/benefit/:id/edit" component={Benefits} />
          <PrivateRoute exact path="/benefit" component={Benefits} />
          {/* <PrivateRoute exact path="/deposit-guide/add" component={FormDepositGuide} />
          <PrivateRoute exact path="/deposit-guide/:id/edit" component={FormDepositGuide} />
          <PrivateRoute exact path="/deposit-guide" component={DepositGuide} /> */}
          <PrivateRoute exact path="/elearning/categories/add" component={Category} />
          <PrivateRoute exact path="/elearning/categories/:id/edit" component={Category} />
          <PrivateRoute exact path="/elearning/categories" component={Category} />
          <PrivateRoute exact path="/faqs/add" component={Faq} />
          <PrivateRoute exact path="/faqs/:id/edit" component={Faq} />
          <PrivateRoute exact path="/faqs" component={Faq} />
          <PrivateRoute exact path="/content-management/pakde/add" component={PakDe} />
          <PrivateRoute exact path="/content-management/pakde/:id/edit" component={PakDe} />
          <PrivateRoute exact path="/content-management/pakde/:type" component={PakDe} />
          <PrivateRoute exact path="/content-management/colek-teman/add" component={Colek} />
          <PrivateRoute exact path="/content-management/colek-teman/:id/edit" component={Colek} />
          <PrivateRoute exact path="/content-management/colek-teman" component={Colek} />
          <PrivateRoute exact path="/calculator-management/asumsi-nilai-suku-bunga" component={AsumsiNilaiSukuBunga} />
          <PrivateRoute exact path="/calculator-management/asumsi-nilai-suku-bunga/:id/edit" component={AsumsiNilaiSukuBunga} />
          <PrivateRoute exact path="/calculator-management/biaya-dplk" component={DplkFee} />
          <PrivateRoute exact path="/calculator-management/biaya-dplk/:id/edit" component={DplkFee} />
          <PrivateRoute exact path="/dplk-calculation-table/investment-history" component={InvestmentHistory} />
          <PrivateRoute exact path="/dplk-calculation-table/investment-year" component={InvestmentYear} />
          <PrivateRoute exact path="/benefit/:id/detail" component={DetailBenefit} />


          <PrivateRoute exact path="/admin-management/role" component={Role} />
          <PrivateRoute exact path="/admin-management/role/:id/detail" component={Role} />
          <PrivateRoute exact path="/admin-management/role/add" component={Role} />
          <PrivateRoute exact path="/admin-management/role/:id/edit" component={Role} />

          <PrivateRoute exact path="/admin-management/admin" component={Admin} />
          <PrivateRoute exact path="/admin-management/admin/:id/detail" component={Admin} />
          <PrivateRoute exact path="/admin-management/admin/add" component={Admin} />
          <PrivateRoute exact path="/admin-management/admin/:id/edit" component={Admin} />

          {/* <PrivateRoute exact path="/deposits" component={Deposit} /> */}
          <PrivateRoute exact path="/customer/:id/detail" component={Customer} />
          <PrivateRoute exact path="/customer/:id/edit" component={Customer} />
          {/* <PrivateRoute exact path="/content-management/ingatkan-teman-menabung" component={RemindFriend} /> */}
          <PrivateRoute exact path="/commission" component={Commission} />
          <PrivateRoute exact path="/user/:id/detail" component={User} />

          <PrivateRoute exact path="/ultra-vouchers/categories" component={UltraVoucherCategory} />
          <PrivateRoute exact path="/ultra-vouchers/categories/add" component={UltraVoucherCategory} />
          <PrivateRoute exact path="/ultra-vouchers/categories/:id/edit" component={UltraVoucherCategory} />

          <PrivateRoute exact path="/ultra-vouchers/products" component={UltraVoucher} />
          <PrivateRoute exact path="/ultra-vouchers/products/:id/edit" component={UltraVoucher} />
          <PrivateRoute exact path="/ultra-vouchers/brands" component={UltraVoucherBrands} />
          <PrivateRoute exact path="/ultra-vouchers/brands/:id/edit" component={UltraVoucherBrands} />
          <PrivateRoute exact path="/ultra-vouchers/logs" component={UltraVoucherLogs} />
          <PrivateRoute exact path="/commission/config" component={CommissionConfig} />
          <PrivateRoute exact path="/commission/config/add" component={FormCommissionConfig} />
          <PrivateRoute exact path="/commission/config/:id/edit" component={FormCommissionConfig} />
          {/* <PrivateRoute exact path="/commission/approved-list" component={ApprovedCommission} /> */}
          <PrivateRoute exact path="/pdp" component={Pdp} />
          <PrivateRoute exact path="/pdp/:id/edit" component={Pdp} />
          <PrivateRoute exact path="/claim/claim-normal" component={ClaimNormal} />
          <PrivateRoute exact path="/claim/claim-normal/:id/claim-request" component={ClaimNormalRequest} />
          <PrivateRoute exact path="/dana-ottocash/management-ottocash" component={ManagementOttocash} />
          <PrivateRoute exact path="/dana-ottocash/management-ottocash/add" component={ManagementOttocash} />
          <PrivateRoute exact path="/dana-ottocash/management-ottocash/:id/edit" component={ManagementOttocash} />
          <PrivateRoute exact path="/dana-ottocash/management-ottocash/logs" component={LogsOttocash} />

          <PrivateRoute exact path="/ottopay" component={Ottopay} />
          <PrivateRoute exact path="/auto-debet/pendaftaran" component={AutoDebitPendaftaran} />
          <PrivateRoute exact path="/auto-debet/pembatalan" component={AutoDebitPembatalan} />
          <PrivateRoute exact path="/auto-debet/pembatalan/:id/detail" component={AutoDebitPembatalan} />
          <PrivateRoute exact path="/auto-debet/log-payment" component={AutoDebitLogPayment} />
          <PrivateRoute exact path="/payment-configuration" component={PaymentConfiguration} />
          <PrivateRoute exact path="/payment-configuration/:id/edit" component={PaymentConfiguration} />

          <PrivateRoute exact path="/apps-version" component={AppsVersion} />
          <PrivateRoute exact path="/apps-version/:id/edit" component={AppsVersion} />

          <PrivateRoute exact path="/web-management/reviews" component={ReviewList} />
          <PrivateRoute exact path="/web-management/reviews/:id/edit" component={ReviewList} />
          <PrivateRoute exact path="/web-management/reviews/add" component={ReviewList} />
          <PrivateRoute exact path="/web-management/banners" component={BannerList} />
          <PrivateRoute exact path="/web-management/banners/:id/edit" component={BannerList} />
          <PrivateRoute exact path="/web-management/banners/add" component={BannerList} />
          <PrivateRoute exact path="/web-management/penjelasan-simpan" component={Penjelasan} />
          <PrivateRoute exact path="/web-management/penjelasan-simpan/:id/edit" component={Penjelasan} />
          <PrivateRoute exact path="/web-management/highlight-simpan" component={Highlight} />
          <PrivateRoute exact path="/web-management/highlight-simpan/:id/edit" component={Highlight} />
          <PrivateRoute exact path="/web-management/highlight-simpan/add" component={Highlight} />
          <PrivateRoute exact path="/web-management/banner-apps" component={BannerApps} />
          <PrivateRoute exact path="/web-management/banner-apps/:id/edit" component={BannerApps} />
          <PrivateRoute exact path="/web-management/pak-d-benefits" component={PakdeBenefits} />
          <PrivateRoute exact path="/web-management/pak-d-benefits/:id/edit" component={PakdeBenefits} />
          <PrivateRoute exact path="/web-management/pak-d-benefits/add" component={PakdeBenefits} />
          <PrivateRoute exact path="/web-management/about-pak-d" component={AboutPakde} />
          <PrivateRoute exact path="/web-management/about-pak-d/:id/edit" component={AboutPakde} />
          <PrivateRoute exact path="/web-management/contact-us-messages" component={ContactUsMessages} />
          <PrivateRoute exact path="/web-management/contact-us-messages/:id/edit" component={ContactUsMessages} />
          <PrivateRoute exact path="/web-management/video-simpan" component={VideoSimpan} />
          <PrivateRoute exact path="/web-management/video-simpan/:id/edit" component={VideoSimpan} />
          <PrivateRoute exact path="/web-management/video-simpan/add" component={VideoSimpan} />
          <PrivateRoute exact path="/web-management/contact-us-categories" component={ContactUsCategory} />
          <PrivateRoute exact path="/web-management/contact-us-categories/:id/edit" component={ContactUsCategory} />
          <PrivateRoute exact path="/web-management/contact-us-categories/add" component={ContactUsCategory} />

          <PrivateRoute exact path="/update-data" component={UpdateData} />
          <PrivateRoute exact path="/update-data/:id/detail" component={UpdateData} />
          <PrivateRoute exact path="/update-data/:id/duplicate/detail" component={UpdateData} />
          <PrivateRoute exact path="/commission/detail/:id" component={Commission} />
          <PrivateRoute exact path="/user/history-point/:id" component={User} />
          <PrivateRoute exact path="/user/history-point/downline/:id" component={User} />
          <PrivateRoute exact path="/user/pakde/:id" component={User} />
          <PrivateRoute exact path="/user/temanku/:id" component={User} />

          <PrivateRoute exact path="/download" component={Download} />

          <PrivateRoute exact path="/claim/claim-by-admin" component={ClaimMeninggalDunia} />
          <PrivateRoute exact path="/claim/claim-by-admin/add-form-request" component={ClaimMeninggalDuniaFormRequest} />
          <PrivateRoute exact path="/claim/claim-by-admin/:id/claim-request" component={ClaimMeninggalDuniaRequest} />
          <PrivateRoute exact path="/claim/claim-by-admin/add" component={ClaimMeninggalDunia} />
          <PrivateRoute exact path="/claim/claim-by-admin/:id/edit" component={ClaimMeninggalDunia} />
          <PrivateRoute exact path="/claim/claim-by-admin/:id/detail" component={ClaimMeninggalDunia} />

          <PublicRoute component={NotFound} />
        </Switch>
      </Router>

    </PersistGate>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.shape().isRequired,
  persistor: PropTypes.shape().isRequired,
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
}

PublicRoute.propTypes = {
  component: PropTypes.any,
  redirect: PropTypes.bool,
  redirectPath: PropTypes.string,
}

export default Root
