import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchInjectPoint, createInjectPoint } from 'actions/injectPoint'
import history from 'utils/history'
import InjectPointView from 'components/pages/injectPoint/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import Helper from 'utils/Helper'
import { message } from 'antd'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchInjectPoint: bindActionCreators(fetchInjectPoint, dispatch),
  createInjectPoint: bindActionCreators(createInjectPoint, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('uploadModal', 'setUploadModal', false),
  withState('uploading', 'setUploading', false),
  withState('isAttached', 'setIsAttached', false),
  withState('previewFile', 'setPreviewFile', ''),
  withState('file', 'setFile', ''),
  withState('isEdit', 'setIsEdit', false),
  withState('list', 'setList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getList: props => (params) => {
      const { setList } = props
      props.fetchInjectPoint(params)
        .then((result) => {
          setList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, search,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        limit: 10,
      }
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    toggleUploadModal: props => () => {
      props.setUploadModal(!props.uploadModal)
      props.setFile('')
      props.setPreviewFile('')
      props.setIsAttached(true)
    },
  }),
  withHandlers({
    handleChange: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      props.setPreviewFile(fileList)
      if (fileList.length > 0) {
        props.setIsAttached(false)
        Helper.getBase64(info.file, file => props.setFile(file))
      } else {
        props.setFile('')
        props.setIsAttached(true)
      }
    },
    handleUpload: props => (values) => {
      props.setUploading(true)
      const payload = {
        file: values,
      }
      props.createInjectPoint(payload).then((result) => {
        props.toggleUploadModal()
        props.setUploading(false)
        message.success('Redirect to list data inject point')
        setTimeout(() => {
          history.push(`/inject-point/${result.id}/edit`)
        }, 300)
      })
        .catch((err) => {
          props.setUploading(false)
          message.error(err)
        })
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { onLoad } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Inject Point'])
      this.props.updateSiteConfiguration('titlePage', 'Inject Point')
      onLoad()
    },
  }),
)(InjectPointView)
