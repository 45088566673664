import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, Select,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormAbout = ({
  detailAbout, handleSubmit,
  form, isEdit, handleSelect,
  selectOption, loadStatus,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: detailAbout.title,
            rules: [{ required: true }],
          })(
            <Input placeholder="Input Title" />,
          )}
        </Form.Item>
        <Form.Item label="Mission">
          {getFieldDecorator('mission', {
            initialValue: detailAbout.mission,
            rules: [{ required: true }],
          })(
            <Input.TextArea rows={4} placeholder="Input Mission" />,
          )}
        </Form.Item>
        <Form.Item label="Content">
          {getFieldDecorator('content', {
            initialValue: detailAbout.content,
            rules: [{ required: true }],
          })(
            <Input.TextArea rows={4} placeholder="Input Content" />,
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('status', {
            initialValue: detailAbout.status,
            rules: [{ required: true }],
          })(
            <Select
              onChange={handleSelect}
              loading={loadStatus}
              placeholder="Select Status"
            >
              {selectOption.map(item => <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>)}
            </Select>,
          )}
        </Form.Item>

        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/about')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit">{!isEdit ? 'Add Data' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormAbout.propTypes = {
  detailAbout: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  handleSelect: PropTypes.func,
  loadStatus: PropTypes.bool,
  selectOption: PropTypes.array,
}

export default Form.create({ name: 'benefitForm' })(FormAbout)
