import PropTypes from 'prop-types'
import {
  Table, Button, Icon, Popconfirm,
} from 'antd'
import history from 'utils/history'

const FaqPage = ({
  isFetching, handlePage,
  metaFaq, dataFaq,
  handleDelete, currentUser,
}) => {
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
        render: text => (text || '-'),
      },
      {
        title: 'Question',
        dataIndex: 'question',
        key: 'question',
        render: text => (text || '-'),
      },
      {
        title: 'Answer',
        dataIndex: 'answer',
        key: 'answer',
        render: text => (text || '-'),
      },
      {
        title: 'Action',
        dataIndex: '',
        key: 'action',
        width: 80,
        render: (text, row) => (
          <div className="d-flex justify-content-around">
            <Icon type="edit" onClick={() => history.push(`/faqs/${row.content_id}/edit`)} />
            <Popconfirm
              title="Are you sure delete this item?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(row.content_id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>
        ),
      },
    ]

    return <Table rowKey="content_id" columns={columns} dataSource={record.faqs} pagination={false} />
  }
  const columns = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      render: text => (text || '-'),
    },
    {
      title: 'Category',
      dataIndex: 'title',
      key: 'title',
      render: text => (text || '-'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/faqs/${record.title_id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-csof.a.q-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this item?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.title_id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3 d-flex justify-content-end align-items-center btn-primary-wrapper">
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/faqs/add')}
          >
            Add FAQ
          </Button>
        </div>
      </div>
      <Table
        rowKey="title_id"
        columns={columns}
        dataSource={dataFaq}
        expandedRowRender={expandedRowRender}
        loading={isFetching}
        className="components-table-demo-nested"
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaFaq ? metaFaq.per_page : 10,
          total: metaFaq ? metaFaq.total_count : dataFaq.length,
          current: metaFaq ? metaFaq.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

FaqPage.propTypes = {
  isFetching: PropTypes.bool,
  dataFaq: PropTypes.array,
  metaFaq: PropTypes.object,
  handlePage: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default FaqPage
