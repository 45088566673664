import API from 'utils/API'
import { message } from 'antd'
import history from 'utils/history'
import {
  LEARNING_CONTENT_REQUEST,
  LEARNING_CONTENT_SUCCESS,
  LEARNING_CONTENT_FAILURE,
  LEARNING_CONTENT_UPDATED,
  LEARNING_CONTENT_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const learningContentRequest = () => ({
  type: LEARNING_CONTENT_REQUEST,
})

export const learningContentSuccess = (data, meta) => ({
  type: LEARNING_CONTENT_SUCCESS,
  data,
  meta,
})

export const learningContentFailure = errorMessage => ({
  type: LEARNING_CONTENT_FAILURE,
  errorMessage,
})

export const learningContentUpdate = data => ({
  type: LEARNING_CONTENT_UPDATED,
  data,
})

export const learningContentDetail = data => ({
  type: LEARNING_CONTENT_DETAIL_SUCCESS,
  data,
})

export const fetchContentCourse = params => (
  (dispatch) => {
    dispatch(learningContentRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/courses${params || ''}`
    return new Promise((resolve, reject) => (
      API.get(url).then(
        (response) => {
          const { data, meta } = response.data
          if (meta.status) {
            resolve(data)
            dispatch(learningContentSuccess(data, meta))
          } else {
            reject(meta.message)
            dispatch(learningContentFailure(meta.message))
          }
        },
      ).catch((err) => {
        reject(err.message)
        dispatch(learningContentFailure(err.message)) // eslint-disable-line no-console
      })
    ))
  }
)

export const fetchDetailContentCourse = id => (
  (dispatch) => {
    dispatch(learningContentRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/courses/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(learningContentDetail(data))
        } else {
          dispatch(learningContentFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(learningContentFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteContentCourse = id => (
  (dispatch) => {
    dispatch(learningContentRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/courses/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(learningContentUpdate(data))
        } else {
          dispatch(learningContentFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(learningContentFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createContentCourse = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/courses`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Course content has been created')
          resolve(data)
          history.push('/elearning/content')
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updateContentCourse = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/courses/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Course content has been updated')
          resolve(data)
          history.push('/elearning/content')
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const fetchCourseCategories = () => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/course-categories`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
          // dispatch(learningContentUpdate(data))
        } else {
          reject(meta.message)
          // dispatch(learningContentFailure(meta.message))
        }
      },
    ).catch((err) => {
      reject(err.message)
      // dispatch(learningContentFailure(err.message)) // eslint-disable-line no-console
    })
  ))
)
