import PropTypes from 'prop-types'
import {
  Table, Select, Button, Input, DatePicker,
} from 'antd'
import Helper from 'utils/Helper'
// import { CSVLink } from 'react-csv'

const UvLogs = ({
  dataUvLogs, metaUvLogs, handlePage,
  handleSearch, isFetching, handleLimit,
  handleReport, limitList, handleChangeDate,
  isExport, statusList, handleChangeStatus,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => (metaUvLogs.per_page * (metaUvLogs.current_page - 1)) + index + 1,
    },
    {
      title: 'Order Number',
      dataIndex: 'order_no',
      key: 'order_no',
      render: text => text || '-',
    },
    {
      title: 'Username',
      dataIndex: 'account_user_name',
      key: 'account_user_name',
      render: text => text || '-',
    },
    {
      title: 'Voucher Price',
      dataIndex: 'voucher_price',
      key: 'voucher_price',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Poin Balance',
      dataIndex: 'point_balance',
      key: 'point_balance',
      render: text => text || '-',
    },
    {
      title: 'Transaction Date',
      dataIndex: 'tx_date',
      key: 'tx_date',
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => text || '-',
    },
  ]

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div className="d-flex mr-2">
            <h6 className="mb-1 mr-2 align-self-center">Filter By: </h6>
            <Select
              placeholder="Limit"
              style={{ width: 120 }}
              onChange={handleLimit}
              className="mr-2"
            >
              {limitList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <Select
              placeholder="Status"
              style={{ width: 120 }}
              onChange={handleChangeStatus}
              className="mr-2"
            >
              {statusList.map(item => <Select.Option key={item.id} value={item.label}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <DatePicker.RangePicker
              format="YYYY-MM-DD"
              placeholder={['Start Date', 'End Date']}
              onChange={handleChangeDate}
            />
          </div>
          <div className="mr-3">
            <Input.Search
              allowClear
              placeholder="Search..."
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div className="mr-3 btn-primary-wrapper">
            <Button
              loading={isExport}
              type="primary"
              className="align-items-center btn-border-mitra"
              onClick={handleReport}
            >
              Export
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        loading={isFetching}
        columns={columns}
        dataSource={dataUvLogs}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaUvLogs ? metaUvLogs.per_page : 10,
          total: metaUvLogs ? metaUvLogs.total_count : dataUvLogs.length,
          current: metaUvLogs ? metaUvLogs.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

UvLogs.propTypes = {
  dataUvLogs: PropTypes.array,
  metaUvLogs: PropTypes.object,
  isFetching: PropTypes.bool,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  isExport: PropTypes.bool,
  handleReport: PropTypes.func,
  handleLimit: PropTypes.func,
  limitList: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  statusList: PropTypes.array,
  handleChangeDate: PropTypes.func,
}

export default UvLogs
