import {
  ULTRA_VOUCHER_REQUEST,
  ULTRA_VOUCHER_SUCCESS,
  ULTRA_VOUCHER_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataUltraVoucher: [],
  metaUltraVoucher: {},
}

export default function ultraVouchers(state = initialState, action) {
  switch (action.type) {
    case ULTRA_VOUCHER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ULTRA_VOUCHER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataUltraVoucher: action.data,
        metaUltraVoucher: action.meta,
      }
    case ULTRA_VOUCHER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
