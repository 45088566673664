import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Row, Col,
  Button, Upload, Avatar,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormUltraVoucherProduct = ({
  handleSubmit, form, isEdit,
  handleUpload, iconFile, isSubmit,
  detailUVProduct,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Row gutter={[24, 6]} className="mb-4">
          <Col offset={5} span={12} className="px-1">
            <h5 className="mr-3 mb-2">{detailUVProduct.name || '-'}</h5>
          </Col>
        </Row>
        <Form.Item label="Icon">
          {getFieldDecorator('image', {
            rules: [{ required: !isEdit }],
          })(
            <Upload
              name="icon"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={e => handleUpload(e)}
              accept="image/*"
              fileList={iconFile}
            >
              {iconFile ? (
                <Avatar src={iconFile} size={250} shape="square" />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>,
          )}
        </Form.Item>

        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/ultra-vouchers/products')} className="mr-2">Cancel</Button>
          <Button loading={isSubmit} type="primary" htmlType="submit">{!isEdit ? 'Add Data' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormUltraVoucherProduct.propTypes = {
  detailUVProduct: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  handleUpload: PropTypes.func,
  iconFile: PropTypes.any,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'ultraVoucherProductForm' })(FormUltraVoucherProduct)
