import PropTypes from 'prop-types'
import React from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import { Skeleton } from 'antd'
import history from 'utils/history'

const Dashboard = ({
  isFetching, dataDashboard,
}) => (
  <React.Fragment>
    <Row>
      {isFetching ? (
        [1, 2, 3].map(number => (
          <Col lg="2" key={number} className="skeleton-dashboard pb-3">
            <Skeleton avatar={{ shape: 'square' }} title={false} paragraph={false} />
          </Col>
        ))
      ) : (
        <React.Fragment>
          <Col className="dashboard-card-wrapper" lg="2">
            <Card className="h-100 dashboard-card cursor-pointer bg-blue" onClick={() => history.push('/user')}>
              <Card.Body>
                <div className="text-center">
                  <h2 className="font-weight-bold">{((dataDashboard.total_user_active || 0) + (dataDashboard.total_user_inactive || 0)) || '-'}</h2>
                  <p className="mb-0">Seluruh Peserta</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="dashboard-card-wrapper" lg="2">
            <Card className="h-100 dashboard-card cursor-pointer bg-blue" onClick={() => history.push('/user?status=active')}>
              <Card.Body>
                <div className="text-center">
                  <h2 className="font-weight-bold">{dataDashboard.total_user_active || '-'}</h2>
                  <p className="mb-0">Peserta Aktif</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="dashboard-card-wrapper" lg="2">
            <Card className="h-100 dashboard-card cursor-pointer bg-blue" onClick={() => history.push('/user?status=inactive')}>
              <Card.Body>
                <div className="text-center">
                  <h2 className="font-weight-bold">{dataDashboard.total_user_inactive || '-'}</h2>
                  <p className="mb-0">Peserta Tidak Aktif</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="dashboard-card-wrapper" lg="2">
            <Card className="h-100 dashboard-card cursor-pointer bg-blue" onClick={() => history.push('/customer/phone')}>
              <Card.Body>
                <div className="text-center">
                  <h2 className="font-weight-bold">{dataDashboard.total_pending_users || '-'}</h2>
                  <p className="mb-0">Peserta Diproses</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="dashboard-card-wrapper" lg="2">
            <Card className="h-100 dashboard-card cursor-pointer bg-yellow" onClick={() => history.push('/claim/claim-normal')}>
              <Card.Body>
                <div className="text-center">
                  <h2 className="font-weight-bold">{dataDashboard.total_claim_requests || '-'}</h2>
                  <p className="mb-0">Permohonan Klaim</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col className="dashboard-card-wrapper" lg="2">
            <Card className="h-100 dashboard-card cursor-pointer bg-yellow" onClick={() => history.push('/update-data')}>
              <Card.Body>
                <div className="text-center">
                  <h2 className="font-weight-bold">{dataDashboard.total_user_update || '-'}</h2>
                  <p className="mb-0">Permohonan Pengkinian Data</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </React.Fragment>
      )}
    </Row>
  </React.Fragment>
)

Dashboard.propTypes = {
  isFetching: PropTypes.bool,
  dataDashboard: PropTypes.object,
}

export default Dashboard
