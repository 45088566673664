import PropTypes from 'prop-types'
import {
  Table, Icon,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

/* const TitleCard = () => (
  <div className="d-flex justify-content-between align-items-center">
    <div />
    <div className="btn-primary-wrapper">
      <Button
        type="primary"
        className="rounded-pill"
        onClick={() => history.push('/content/colek-teman/add')}
      >
        Add Item
      </Button>
    </div>
  </div>
) */

const FaqPage = ({
  mngContent,
}) => {
  const columns = [
    // {
    //   title: 'Desc',
    //   dataIndex: 'desc',
    //   key: 'desc',
    //   render: text => (text || '-'),
    // },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 150,
      render: text => (text ? moment.utc(text).format('YYYY-MM-DD, HH:mm') : '-'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 150,
      render: text => (text ? moment.utc(text).format('YYYY-MM-DD, HH:mm') : '-'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`/content-management/colek-teman/${record.id}/edit`)} />
          {/* <Popconfirm
            title="Are you sure delete this item?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" />
          </Popconfirm> */}
        </div>
      ),
    },
  ]

  return (
    <div>
      <Table
        rowKey="id"
        className="table-striped-rows components-table-demo-nested"
        columns={columns}
        dataSource={mngContent.data}
        loading={mngContent.load}
        pagination={false}
      />
    </div>
  )
}

FaqPage.propTypes = {
  // handlePage: PropTypes.func,
  // handleDelete: PropTypes.func,
  mngContent: PropTypes.object,
}

export default FaqPage
