import React from 'react'
import PropTypes from 'prop-types'
import {
  Input, Button, Form,
  Card, Row, Col, Tag,
  Avatar, Icon, InputNumber,
  Select, Modal,
} from 'antd'
import { Table } from 'react-bootstrap'
import history from 'utils/history'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import moment from "moment";

const ProfileDetail = (props) => {
  const {
    form, handleSubmit, isSubmit,
    detail, isEdit, handleChangeEdit,
    investList, isApprove, isDisable,
    handleApprove, getRelationName,
    rejectModal, toggleRejectModal,
    handleChangeRejectNotes, handleReject,
    provinceList, cityList, subDistrictsList,
    villagesList, handleProvinceList, handleCityList,
    handleSubDistrictsList, handleVillagesList, currentUser,
  } = props

  const { getFieldDecorator, validateFields } = form

  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }
  return (
    <div className="pb-5">
      <div className="btn-primary-wrapper mb-5">
        <Button type="primary" icon="left" onClick={() => history.goBack()}>Go Back</Button>
      </div>
      {detail.isFetching
        ? <h4>loading...</h4>
        : (
          <div>
            <section className="my-4">
              <h5 className="mb-3">Nasabah</h5>
              <Card>
                <Row>
                  <Col md={6}>
                    <h6 className="mb-3">{detail.data.name || '-'}</h6>
                    <p className="mb-0">{detail.data.registered_number || '-'}</p>
                  </Col>
                  <Col md={6}>
                    <h6 className="mb-3">Status Pengajuan Perubahan</h6>
                    <Tag color={detail.data.status === 'rejected' ? 'red' : ''}><p className="mb-0">{detail.data.status || '-'}</p></Tag>
                  </Col>
                  <Col md={6}>
                    <h6 className="mb-3">Keterangan</h6>
                    <p className="mb-0">{detail.data.notes || '-'}</p>
                  </Col>
                </Row>
              </Card>
            </section>

            {detail.data.conflicting.length !== 0 && detail.data.status !== 'rejected'
            && (
            <section className="my-4">
              <div className="conflict-card d-flex align-items-center">
                <div className="warning">
                  <Icon type="exclamation-circle" style={{ fontSize: '30px', color: '#fff' }} className="align-self-center w-100" />
                </div>
                <div className="content d-flex w-100 justify-content-between align-items-center">
                  <div>
                    <h6>Terdeteksi nomor HP dan KTP yang sama pada nasabah lain</h6>
                    <p className="mb-0">Gabungkan dua nasabah atau ubah data sesuai persetujuan nasabah</p>
                  </div>
                  {!isEdit
                    && <Button onClick={() => history.push(`/update-data/${props.match.params.id}/duplicate/detail`)}>Gabungkan Data Nasabah</Button>
                  }
                </div>
              </div>
            </section>
            )
          }

            <section>
              <h5 className="mb-3">Pengajuan Perubahan Data</h5>
              <Form
                onSubmit={(e) => {
                  e.preventDefault()
                  validateFields((err, values) => {
                    if (!err) {
                      handleSubmit(values)
                    }
                  })
                }}
              >
                <Table striped className="form-table">
                  <thead>
                    <tr>
                      <th>Nama Data</th>
                      <th>Data Saat Ini</th>
                      <th>Data Diubah</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Alamat Email</td>
                      <td>{detail.data.email.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('email', {
                                initialValue: isEdit ? detail.data.email.new : '',
                                rules: [{ required: false }],
                              })(
                                <Input type="email" />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.email.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.email.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Nomor Ponsel</td>
                      <td>{detail.data.phone_number.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('phone_number', {
                                initialValue: isEdit ? detail.data.phone_number.new : '',
                                rules: [
                                  { required: false },
                                  { pattern: new RegExp('^[0-9]*$'), message: 'Input hanya boleh angka' },
                                  {
                                    validator: (_, value) => (value.substr(0, 3) === '628' ? Promise.resolve() : Promise.reject(new Error('Awalan digit Nomor Ponsel harus 628'))),
                                  },
                                ],
                              })(
                                <Input className="w-100" />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.phone_number.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.phone_number.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Alamat KTP</td>
                      <td>{detail.data.address.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('address', {
                                initialValue: isEdit ? detail.data.address.new : '',
                                rules: [{ required: false }],
                              })(
                                <Input />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.address.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.address.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>RT</td>
                      <td>{detail.data.rt.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('rt', {
                                initialValue: isEdit ? detail.data.rt.new : '',
                                rules: [{ required: false }],
                              })(
                                <Input />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.rt.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.rt.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>RW</td>
                      <td>{detail.data.rw.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('rw', {
                                initialValue: isEdit ? detail.data.rw.new : '',
                                rules: [{ required: false }],
                              })(
                                <Input />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.rw.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.rw.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Provinsi</td>
                      <td>{detail.data.province.old.name}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item>
                              {getFieldDecorator('province', {
                                initialValue: detail.data.province.new.id || '',
                              })(
                                <Select
                                  loading={provinceList.isFetching}
                                  placeholder="Select province"
                                  onChange={e => handleProvinceList(e, form)}
                                >
                                  {(provinceList.data || []).map(item => <Select.Option key={item.id}>{item.name}</Select.Option>)}
                                </Select>,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.province.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.province.new.name}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Kota</td>
                      <td>{detail.data.city.old.name}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item>
                              {getFieldDecorator('city', {
                                initialValue: detail.data.city.new.id || '',
                              })(
                                <Select
                                  loading={cityList.isFetching}
                                  placeholder="Select Payment"
                                  onChange={e => handleCityList(e, form)}
                                >
                                  {(cityList.data || []).map(item => <Select.Option key={item.id}>{item.name}</Select.Option>)}
                                </Select>,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.city.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.city.new.name}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Kecamatan</td>
                      <td>{detail.data.sub_district.old.name}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item>
                              {getFieldDecorator('sub_district', {
                                initialValue: detail.data.sub_district.new.id || '',
                              })(
                                <Select
                                  loading={subDistrictsList.isFetching}
                                  placeholder="Select Payment"
                                  onChange={e => handleSubDistrictsList(e, form)}
                                >
                                  {(subDistrictsList.data || []).map(item => <Select.Option key={item.id}>{item.name}</Select.Option>)}
                                </Select>,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.sub_district.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.sub_district.new.name}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Kelurahan</td>
                      <td>{detail.data.village.old.name}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item>
                              {getFieldDecorator('village', {
                                initialValue: detail.data.village.new.id || '',
                              })(
                                <Select
                                  loading={villagesList.isFetching}
                                  placeholder="Select Payment"
                                  onChange={e => handleVillagesList(e, form)}
                                >
                                  {(villagesList.data || []).map(item => <Select.Option key={item.id}>{item.name}</Select.Option>)}
                                </Select>,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.village.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.village.new.name}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Kode POS</td>
                      <td>{detail.data.post_code.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('post_code', {
                                initialValue: isEdit ? detail.data.post_code.new : '',
                                rules: [{ required: false }],
                              })(
                                <InputNumber className="w-100" />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.post_code.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.post_code.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>No. KTP/NIK</td>
                      <td>{detail.data.identity_number.old}</td>
                      {isEdit
                        ? (
                          <td>
                            <Form.Item className="mb-0">
                              {getFieldDecorator('identity_number', {
                                initialValue: isEdit ? detail.data.identity_number.new : '',
                                rules: [{ required: false }],
                              })(
                                <InputNumber className="w-100" />,
                              )}
                            </Form.Item>
                          </td>
                        )
                        : (
                          <td>
                            {detail.data.identity_number.isUpdated && detail.data.status != 'approved'
                              ? (
                                <div>
                                  {detail.data.identity_number.new}
                                  <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />
                                </div>
                              )
                              : <span>-</span>
                        }
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>Identitas Diri</td>
                      <td>
                        <SimpleReactLightbox>
                          <SRLWrapper options={lightBoxSetting}>
                            <Avatar shape="square" size={70} src={detail.data.identity_photo.old} alt="identity" style={{ cursor: 'pointer' }} />
                          </SRLWrapper>
                        </SimpleReactLightbox>
                      </td>
                      {(detail.data.identity_photo.isUpdated === true && detail.data.status != 'approved')
                        ? (
                          <td>
                            <div className="d-flex">
                              <SimpleReactLightbox>
                                <SRLWrapper options={lightBoxSetting}>
                                  <Avatar shape="square" size={70} src={detail.data.identity_photo.new} alt="identity" style={{ cursor: 'pointer' }} />
                                </SRLWrapper>
                              </SimpleReactLightbox>
                              <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3 align-self-center" style={{ fontSize: '18px' }} />
                            </div>
                          </td>
                        )
                        : (
                          <td>
                            <span>-</span>
                          </td>
                        )
                    }
                    </tr>
                    <tr>
                      <td>NPWP</td>
                      <td>
                        <SimpleReactLightbox>
                          <SRLWrapper options={lightBoxSetting}>
                            <Avatar shape="square" size={70} src={detail.data.npwp_photo.old} alt="npwp" style={{ cursor: 'pointer' }} />
                          </SRLWrapper>
                        </SimpleReactLightbox>
                      </td>
                      {(detail.data.npwp_photo.isUpdated === true && detail.data.status != 'approved')
                        ? (
                          <td>
                            <div className="d-flex">
                              <SimpleReactLightbox>
                                <SRLWrapper options={lightBoxSetting}>
                                  <Avatar shape="square" size={70} src={detail.data.npwp_photo.new} alt="npwp" style={{ cursor: 'pointer' }} />
                                </SRLWrapper>
                              </SimpleReactLightbox>
                              <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3 align-self-center" style={{ fontSize: '18px' }} />
                            </div>
                          </td>
                        )
                        : (
                          <td>
                            <span>-</span>
                          </td>
                        )
                    }
                    </tr>
                  </tbody>
                </Table>

                <div className="mb-3">
                  <Row gutter={[24, 6]}>
                    <Col md={12} className="pb-3">
                      <h5>Informasi Penerima Manfaat saat ini</h5>
                      {(detail.data.benefit_recipients.old || []).map((item, index) => (
                        <Card className="mb-3" key={item.id}>
                          <Row>
                            <Col md={24}>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Nama Lengkap</h6>
                                <p className="mb-2">{item.name}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Hubungan</h6>
                                <p className="mb-2">{getRelationName(item.relationship_id)}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Tanggal Lahir</h6>
                                <p className="mb-2">{moment(item.dob).format('DD MMM YYYY')}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <SimpleReactLightbox>
                                  <h6 className="mb-2">Identitas Diri</h6>
                                  {(item.identity_photo !== '') ? (
                                    <SRLWrapper options={lightBoxSetting}>
                                      <Avatar shape="square" size={70} src={item.identity_photo} alt="identity" style={{ cursor: 'pointer' }} />
                                    </SRLWrapper>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </SimpleReactLightbox>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </Col>
                    <Col md={12} className="pb-3">
                      <h5>Informasi Penerima Manfaat Terbaru</h5>
                      {(detail.data.benefit_recipients.new || []).map((item, index) => (
                        <Card className="mb-3" key={item.id}>
                          <Row>
                            <Col md={24}>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Nama Lengkap</h6>
                                {isEdit
                                  ? (
                                    <React.Fragment>
                                      <Form.Item className="mb-0">
                                        {getFieldDecorator(`id[${index}]`, {
                                          initialValue: isEdit ? item.id : '',
                                        })(
                                          <Input type="hidden" />,
                                        )}
                                      </Form.Item>
                                      <Form.Item className="mb-2">
                                        {getFieldDecorator(`name[${index}]`, {
                                          initialValue: isEdit ? item.name : '',
                                          rules: [{ required: true }],
                                        })(
                                          <Input />,
                                        )}
                                      </Form.Item>
                                    </React.Fragment>
                                  )
                                  : (
                                    <p className="mb-2">
                                      {detail.data.status != 'approved' ? item.name : '-'}
                                      {detail.data.benefit_recipients.isUpdated && detail.data.status != 'approved' && <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3" style={{ fontSize: '18px' }} />}
                                    </p>
                                  )
                              }
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Hubungan</h6>
                                <p className="mb-2">{detail.data.status !='approved' ? getRelationName(item.relationship_id) : '-'}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Tanggal Lahir</h6>
                                <p className="mb-2">{detail.data.status !== 'approved' ? moment(item.dob).format('DD MMM YYYY') : '-'}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <h6 className="mb-2">Identitas Diri</h6>
                                {(detail.data.benefit_recipients.isUpdated === true && detail.data.status !== 'approved' && item.identity_photo !== '')
                                  ? (
                                    <div className="d-flex justify-content-end">
                                      <SimpleReactLightbox>
                                        <SRLWrapper options={lightBoxSetting}>
                                          <Avatar shape="square" size={70} src={item.identity_photo} alt="identity" style={{ cursor: 'pointer' }} />
                                        </SRLWrapper>
                                      </SimpleReactLightbox>
                                      <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="mx-3 align-self-center" style={{ fontSize: '18px' }} />
                                    </div>
                                  )
                                  : (
                                    <td>
                                      <span>-</span>
                                    </td>
                                  )
                                }
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                    </Col>
                  </Row>
                </div>
                {(currentUser.permissions && currentUser.permissions.indexOf('manage-pengkinian-data-write') > -1) && (
                <div className="d-flex justify-content-end">
                  {(detail.data.status !== 'rejected' && detail.data.status !== 'approved')
                    && (
                    <React.Fragment>
                      <Button className="ml-3" onClick={handleChangeEdit} disabled={isDisable}>{isEdit ? 'Cancel' : 'Edit'}</Button>
                      <Button type="danger" className="ml-3" onClick={toggleRejectModal} disabled={isDisable}>Reject</Button>
                      {isEdit
                        ? <Button type="primary" className="ml-3" htmlType="submit" loading={isSubmit} disabled={isDisable}>Submit</Button>
                        : <Button type="primary" className="ml-3" onClick={handleApprove} loading={isApprove} disabled={isDisable}>Approve</Button>
                      }
                    </React.Fragment>
                    )
                  }
                </div>
                )}
              </Form>
            </section>
          </div>
        )
      }
      <Modal
        title="Reject Reason"
        centered
        visible={rejectModal}
        footer={null}
        onCancel={() => toggleRejectModal()}
      >
        <Row>
          <Col md={12}>
            <Input.TextArea rows={8} onChange={handleChangeRejectNotes} />
          </Col>
          <Col md={12}>
            <div className="pt-3">
              <Button type="danger" className="float-right" loading={isSubmit} onClick={handleReject}>Reject</Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  )
}

ProfileDetail.propTypes = {
  form: PropTypes.any,
  isSubmit: PropTypes.bool,
  isApprove: PropTypes.bool,
  handleSubmit: PropTypes.func,
  detail: PropTypes.object,
  isEdit: PropTypes.bool,
  handleChangeEdit: PropTypes.func,
  investList: PropTypes.object,
  isDisable: PropTypes.bool,
  handleApprove: PropTypes.func,
  getRelationName: PropTypes.func,
  rejectModal: PropTypes.bool,
  toggleRejectModal: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  handleReject: PropTypes.func,
  provinceList: PropTypes.object,
  cityList: PropTypes.object,
  subDistrictsList: PropTypes.object,
  villagesList: PropTypes.object,
  handleProvinceList: PropTypes.func,
  handleCityList: PropTypes.func,
  handleSubDistrictsList: PropTypes.func,
  handleVillagesList: PropTypes.func,
  currentUser: PropTypes.object,
}

export default Form.create({ name: 'profileDetailForm' })(ProfileDetail)
