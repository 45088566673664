import PropTypes from 'prop-types'
import {
  Table, Icon, Popconfirm, Button, Input,
  Avatar,
} from 'antd'
import history from 'utils/history'

const BenefitPage = ({
  isFetching, handleSearch,
  dataBenefit, handleDelete, currentUser,
  /* handlePage, */
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Icon',
      dataIndex: 'benefit_icon',
      key: 'benefit_icon',

      render: text => <Avatar shape="square" size={50} src={text} />,
    },
    /* {
      title: 'Benefit Title',
      dataIndex: 'benefit_desc_title',
      key: 'benefit_desc_title',
      width: 600,
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button type="link" onClick={() => history.push(`/benefit/${record.id}/detail`)}>{text}</Button>
        </div>
      ),
    }, */
    {
      title: 'Claim Type',
      dataIndex: 'claim_type',
      key: 'content',
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button type="link" onClick={() => history.push(`/benefit/${record.id}/detail`)}>{text.name}</Button>
        </div>
      ),
    },
    /* {
      title: 'Tax Title',
      dataIndex: 'tax_desc_title',
      key: 'tax_desc_title',
    }, */
    /* {
      title: 'Date',
      key: 'date',
      children: [
        {
          title: 'Created',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
        {
          title: 'Updated',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 150,
          render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
        },
      ],
    }, */
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 150,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          {/* <p className="mb-0" style={{cursor: 'pointer', color: '#69c0ff'}} onClick={() => history.push(`/benefit/${record.id}/detail`)}>Detail</p> */}
          <Icon type="edit" onClick={() => history.push(`/benefit/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-pelajari-manfaat-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this benefit?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center pb-3">
        <div>
          <Input.Search
            allowClear
            placeholder="Search..."
            onSearch={handleSearch}
          />
        </div>
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/benefit/add')}
            disabled={dataBenefit.length === 4}
          >
            Add Benefit
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataBenefit}
        loading={isFetching}
        pagination={false}
      />
    </div>
  )
}
/* TitleCard.propTypes = {
  handleSearch: PropTypes.func,
} */

BenefitPage.propTypes = {
  isFetching: PropTypes.bool,
  dataBenefit: PropTypes.array,
  // handlePage: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSearch: PropTypes.func,
  currentUser: PropTypes.object,
}

export default BenefitPage
