import PropTypes from 'prop-types'
import {
  Table, Icon,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

/* const TitleCard = ({ handleSearch }) => (
  <div className="d-flex justify-content-between align-items-center">
    <div>
      <Input.Search
        allowClear
        placeholder="Search..."
        onSearch={handleSearch}
      />
    </div>
    <div>
      <Button
        type="primary"
        className="rounded-pill"
        onClick={() => history.push('/about/add')}
      >
        Add Data
      </Button>
    </div>
  </div>
) */

const AboutPage = ({
  isFetching, dataAbout,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => text || '-',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Mission',
      dataIndex: 'mission',
      key: 'mission',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: text => text || '-',
    },
    {
      title: 'Date',
      key: 'date',
      children: [
        {
          title: 'Created',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 110,
          render: text => (text ? moment(text).format('DD MMM YY') : '-'),
        },
        {
          title: 'Updated',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 110,
          render: text => (text ? moment(text).format('DD MMM YY') : '-'),
        },
      ],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 80,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/about/${record.id}/edit`)} />
          {/* <Popconfirm
            title="Are you sure delete this data?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" />
          </Popconfirm> */}
        </div>
      ),
    },
  ]

  return (
    <div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataAbout}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}
// TitleCard.propTypes = {
//   handleSearch: PropTypes.func,
// }

AboutPage.propTypes = {
  isFetching: PropTypes.bool,
  dataAbout: PropTypes.array,
  // handleDelete: PropTypes.func,
}

export default AboutPage
