import {
  LEARNING_CONTENT_REQUEST,
  LEARNING_CONTENT_SUCCESS,
  LEARNING_CONTENT_FAILURE,
  LEARNING_CONTENT_UPDATED,
  LEARNING_CONTENT_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataCourse: [],
  detailCourse: {},
  metaCourse: {
    total_count: 0,
    current: 0,
  },
}

export default function learningContent(state = initialState, action) {
  switch (action.type) {
    case LEARNING_CONTENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case LEARNING_CONTENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataCourse: action.data,
        metaCourse: action.meta,
      }
    case LEARNING_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case LEARNING_CONTENT_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataCourse: state.dataCourse.filter(item => item.id !== action.data.id),
      }
    case LEARNING_CONTENT_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailCourse: action.data,
      }
    default:
      return state
  }
}
