import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import history from 'utils/history'

const PenjelasanSimpan = ({
  penjelasanList,
}) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => text || '-',
    },
    {
      title: 'Short Description',
      dataIndex: 'content',
      key: 'content',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/web-management/penjelasan-simpan/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <Table
      rowKey="id"
      className="table-striped-rows"
      columns={columns}
      dataSource={penjelasanList.data}
      loading={penjelasanList.isFetching}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  )
}

PenjelasanSimpan.propTypes = {
  penjelasanList: PropTypes.any,
}

export default PenjelasanSimpan
