import { connect } from 'react-redux'
import {
  compose, lifecycle, withState, withHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchPakdeBenefits, deletePakdeBenefits } from 'actions/PakdeBenefits'
import PakdeBenefitsView from 'components/pages/webManagement/pakdeBenefits/List'
import { message } from 'antd'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchPakdeBenefits: bindActionCreators(fetchPakdeBenefits, dispatch),
  deletePakdeBenefits: bindActionCreators(deletePakdeBenefits, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('benefitList', 'setBenefitList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getPakdeBenefitList: props => () => {
      props.fetchPakdeBenefits()
        .then((result) => {
          props.setBenefitList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          props.setBenefitList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleDelete: props => (id) => {
      props.deletePakdeBenefits(id)
        .then(() => {
          props.getPakdeBenefitList()
        })
        .catch((error) => { message.error(error) })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Pak-D Benefits'])
      this.props.updateSiteConfigs({
        titlePage: 'Pak-D Benefit List',
        activePage: 'web-management/pak-d-benefits',
        activeSubPage: 'web-management',
      })
      this.props.getPakdeBenefitList()
    },
  }),
)(PakdeBenefitsView)
