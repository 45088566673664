import {
  CATEGORY_COURSE_REQUEST,
  CATEGORY_COURSE_SUCCESS,
  CATEGORY_COURSE_FAILURE,
  CATEGORY_COURSE_UPDATED,
  CATEGORY_COURSE_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataCategory: [],
  detailCategory: {},
  metaCategory: {
    total_count: 0,
    current: 0,
  },
}

export default function category(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_COURSE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case CATEGORY_COURSE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataCategory: action.data,
        metaCategory: action.meta,
      }
    case CATEGORY_COURSE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case CATEGORY_COURSE_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataCategory: state.dataCategory.filter(item => item.id !== action.data.id),
      }
    case CATEGORY_COURSE_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailCategory: action.data,
      }
    default:
      return state
  }
}
