import PropTypes from 'prop-types'
import {
  Table, Input,
  Button, Icon,
  Popconfirm, Avatar,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

const UltraVoucherCategoryPage = ({
  isFetching, handlePage,
  metaUltraVoucherCategory, dataUltraVoucherCategory,
  handleDelete, handleSearch, currentUser,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Image',
      width: 200,
      dataIndex: 'image',
      key: 'image',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={75} src={image} alt="voucher" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={75} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Order',
      dataIndex: 'order',
      width: 100,
      key: 'order',
    },
    {
      title: 'Date',
      key: 'date',
      children: [
        {
          title: 'Created at',
          dataIndex: 'created_at',
          key: 'created_at',
          width: 150,
          render: text => (text ? moment.utc(text).format('DD MMM YY, HH:mm') : '-'),
        },
        {
          title: 'Updated at',
          dataIndex: 'updated_at',
          key: 'updated_at',
          width: 150,
          render: text => (text ? moment.utc(text).format('DD MMM YY, HH:mm') : '-'),
        },
      ],
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/ultra-vouchers/categories/${record.id}/edit`)} />
          <Popconfirm
            title="Are you sure delete this data?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            {(currentUser.permissions && currentUser.permissions.indexOf('manage-vouchervoucher-category-delete') > -1) && (
              <Icon type="delete" />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search by name"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => history.push('/ultra-vouchers/categories/add')}
            >
              Add Data
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataUltraVoucherCategory}
        loading={isFetching}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaUltraVoucherCategory ? metaUltraVoucherCategory.total_count : dataUltraVoucherCategory.length,
          current: metaUltraVoucherCategory ? metaUltraVoucherCategory.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}


UltraVoucherCategoryPage.propTypes = {
  isFetching: PropTypes.bool,
  dataUltraVoucherCategory: PropTypes.array,
  metaUltraVoucherCategory: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default UltraVoucherCategoryPage
