import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  categoryDetail, fetchDetailCategory, createCategory, updateCategory,
} from 'actions/Category'
import { message } from 'antd'
import FormCategoryView from 'components/pages/categories/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFecthing,
    detailCategory,
  } = state.root.category
  return {
    isFecthing,
    detailCategory,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailCategory: bindActionCreators(fetchDetailCategory, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  updateCategory: bindActionCreators(updateCategory, dispatch),
  categoryDetail: bindActionCreators(categoryDetail, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      props[`${params.id ? 'upd' : 'cre'}ateCategory`](values, (params.id || null)).then(() => {
        message.success(`Category has been ${params.id ? 'upd' : 'cre'}ated`).then(() => (
          history.push('/elearning/categories')
        ))
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'e-Learning', 'Category'])
      this.props.updateSiteConfiguration('titlePage', 'e-Learning Category Form')
      this.props.categoryDetail({})
      if (match.params.id) {
        this.props.fetchDetailCategory(match.params.id)
        setIsEdit(true)
      }
    },
  }),
)(FormCategoryView)
