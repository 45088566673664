import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, withState } from 'recompose'
import { updateSiteConfigs } from 'actions/Site'
import AutoDebitPendaftaran from 'components/pages/autoDebit/pendaftaran'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth
  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateCheck', 'setStateCheck', {
    isLocked: false,
  }),
  lifecycle({
    async componentDidMount() {
      this.props.updateSiteConfigs({
        titlePage: 'Autodebet - Pendaftaran',
        activePage: 'auto-debet/pendaftaran',
        activeSubPage: 'pendaftaran',
      })
    },
  }),
)(AutoDebitPendaftaran)
