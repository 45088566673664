import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import List from 'containers/pages/users/List'
import Detail from 'containers/pages/users/detail'
import HistoryPoint from 'containers/pages/users/historyPoint'
import HistoryPointDownline from 'containers/pages/users/downline'
import PakdeDownline from 'containers/pages/users/pakde'
import TemankuDownline from 'containers/pages/users/temanku'

const UserPage = ({ location, match, currentUser }) => {
  if (location.pathname === '/user') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-read') > -1) {
      return <List location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/user/history-point/downline')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-read') > -1) {
      return <HistoryPointDownline match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/user/history-point')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-read') > -1) {
      return <HistoryPoint match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/user/pakde')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-read') > -1) {
      return <PakdeDownline match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/user/temanku')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-read') > -1) {
      return <TemankuDownline match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-read') > -1) {
      return <Detail match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

UserPage.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default UserPage
