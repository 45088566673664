import {
  CALCULATION_DPLK_REQUEST,
  CALCULATION_DPLK_SUCCESS,
  CALCULATION_DPLK_FAILURE,
  CALCULATION_DPLK_UPDATED,
  INVESTMENT_YEAR_SUCCESS,
  INVEST_YEAR_DOWNLOAD_SUCCESS,
  INVEST_PAST5YEAR_DOWNLOAD_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataInvestmentHistory: [],
  metaInvestmentHistory: {},
  dataInvestmentYear: [],
  metaInvestmentYear: {},
  fileInvestmentYear: [],
  metaFileInvestmentYear: {},
  fileInvestmentPast5Year: [],
  metaFileInvestmentPast5Year: {},
}

export default function calculationDplk(state = initialState, action) {
  switch (action.type) {
    case CALCULATION_DPLK_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case CALCULATION_DPLK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataInvestmentHistory: action.data,
        metaCalculationDplk: action.meta,
      }
    case INVESTMENT_YEAR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataInvestmentYear: action.data,
        metaInvestmentYear: action.meta,
      }
    case INVEST_YEAR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fileInvestmentYear: action.data,
        metaFileInvestmentYear: action.meta,
      }
    case INVEST_PAST5YEAR_DOWNLOAD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fileInvestmentPast5Year: action.data,
        metaFileInvestmentPast5Year: action.meta,
      }
    case CALCULATION_DPLK_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case CALCULATION_DPLK_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataInvestmentHistory: state.dataInvestmentHistory.filter(item => item.id !== action.data.id),
      }
    default:
      return state
  }
}
