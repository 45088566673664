import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchNotification, deleteNotification } from 'actions/Notification'
import NotificationView from 'components/pages/notifications/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataNotif,
    metaNotif,
  } = state.root.notification
  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataNotif,
    metaNotif,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchNotification: bindActionCreators(fetchNotification, dispatch),
  deleteNotification: bindActionCreators(deleteNotification, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchNotification(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchNotification(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleDelete: props => (id) => {
      props.deleteNotification(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Notification', 'List'])
      this.props.updateSiteConfigs({
        titlePage: 'Notification List',
        activePage: 'notification',
        activeSubPage: 'notification',
      })
      this.props.fetchNotification()
    },
  }),
)(NotificationView)
