import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import InjectPointPage from 'containers/pages/injectPoint/List'
import InjectPointDetail from 'containers/pages/injectPoint/detail'

const InjectPoint = ({
  location, match, currentUser
}) => {
  
  if (location.pathname === '/inject-point') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-inject-point-read') > -1) {
      return <InjectPointPage location={location} />
    }
    return <ForbiddenPage />
  }

  if (location.pathname.includes('edit')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-inject-point-write') > -1) {
      return <InjectPointDetail location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

InjectPoint.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default InjectPoint
