import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Row, Tag,
  Col, Skeleton, Button, Icon,
} from 'antd'
import history from 'utils/history'

const DetailRolePage = ({
  detail,
}) => (
  <div className="pb-5">
    {detail.isFetching
      ? [1].map(index => (
        <Card key={index} className="mb-3">
          <div>
            <Skeleton active />
          </div>
        </Card>
      ))
      : (
        <React.Fragment>
          <div className="mb-4">
            <Button type="primary" className="font-weight-bold" onClick={() => history.goBack()}>
              <Icon type="left" />
              Go Back
            </Button>
          </div>
          <Card className="mb-3">
            <Row gutter={[24, 6]}>
              <Col span={24}>
                <h5 className="mb-4">Role Detail</h5>
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Name:</p>
                <p style={{ fontSize: '16px' }}>{detail.data.name || '-'}</p>
              </Col>
              <Col span={12} className="mb-3">
                <p className="mr-3 mb-1 font-weight-bold">Status:</p>
                <Tag color={detail.data.active === true ? 'green' : 'red'}><p className="mb-0" style={{ fontSize: '16px' }}>{detail.data.active === true ? 'Active' : 'Inactive'}</p></Tag>
              </Col>
              <Col span={12}>
                <p className="mr-3 mb-1 font-weight-bold">Permission:</p>
                {detail.data.permissions && detail.data.permissions.length !== 0
                  ? (detail.data.permissions || []).map(item => (
                    <Tag className="mb-2" key={item.id}>
                      {' '}
                      <p className="mb-0" style={{ fontSize: '16px' }}>{item.name}</p>
                    </Tag>
                  ))
                  : '-'
                  }
              </Col>
            </Row>
          </Card>
        </React.Fragment>
      )
      }
  </div>
)

DetailRolePage.propTypes = {
  detail: PropTypes.object,
}

export default DetailRolePage
