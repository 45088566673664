import API from 'utils/API'
import { message } from 'antd'
import history from 'utils/history'
import {
  BENEFIT_REQUEST,
  BENEFIT_SUCCESS,
  BENEFIT_FAILURE,
  BENEFIT_UPDATED,
  BENEFIT_DETAIL_SUCCESS,
  CLAIM_TYPE_SUCCESS,
} from 'constants/ActionTypes'

export const benefitRequest = () => ({
  type: BENEFIT_REQUEST,
})

export const benefitSuccess = (data, meta) => ({
  type: BENEFIT_SUCCESS,
  data,
  meta,
})

export const claimTypeSuccess = (data, meta) => ({
  type: CLAIM_TYPE_SUCCESS,
  data,
  meta,
})

export const benefitFailure = errorMessage => ({
  type: BENEFIT_FAILURE,
  errorMessage,
})

export const benefitUpdate = data => ({
  type: BENEFIT_UPDATED,
  data,
})

export const benefitDetail = data => ({
  type: BENEFIT_DETAIL_SUCCESS,
  data,
})

export const fetchBenefit = params => (
  (dispatch) => {
    dispatch(benefitRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/benefits${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(benefitSuccess(data, meta))
        } else {
          dispatch(benefitFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(benefitFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

/* export const fetchDetailBenefit = id => (
  (dispatch) => {
    dispatch(benefitRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/benefits/view/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(benefitDetail(data))
        } else {
          dispatch(benefitFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(benefitFailure(err.message)) // eslint-disable-line no-console
    })
  }
) */

export const fetchDetailBenefit = id => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/benefits/view/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const deleteBenefit = id => (
  (dispatch) => {
    dispatch(benefitRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/benefits/delete/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Benefit has been deleted')
          dispatch(benefitUpdate(data))
        } else {
          message.error(meta.message)
          dispatch(benefitFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(benefitFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createBenefit = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/benefits/store`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Benefit has been created')
          history.push('/benefit')
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data.meta) {
        reject(err.response.data.meta.message)
      } else {
        reject(err.message)
      }
    })
  ))
)

export const updateBenefit = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/benefits/update/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Benefit has been updated')
          history.push('/benefit')
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      message.error(err.message)
      reject(err.message)
    })
  ))
)

export const fetchClaimType = params => (
  (dispatch) => {
    dispatch(benefitRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/benefits/claim-type${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(claimTypeSuccess(data, meta))
        } else {
          dispatch(benefitFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(benefitFailure(err.message)) // eslint-disable-line no-console
    })
  }
)
