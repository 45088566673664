import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import ContactUsCategoriesPage from 'containers/pages/webManagement/contactUsCategories/List'
import ContactUsCategoriesForm from 'containers/pages/webManagement/contactUsCategories/Form'

const ContactUsCat = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/web-management/contact-us-categories') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementcontact-us-categories-read') > -1) {
      return <ContactUsCategoriesPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementcontact-us-categories-write') > -1) {
      return <ContactUsCategoriesForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

ContactUsCat.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default ContactUsCat
