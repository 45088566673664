import API from 'utils/API'
import { message } from 'antd'

export const fetchInjectPoint = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url}/points/inject-point-request${params || ''}`).then(
      (response) => {
        const { meta } = response.data
        if (meta.status) {
          resolve(response.data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchInjectPointWithId = (id, params) => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url}/points/inject-point-request/${id}${params || ''}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(response.data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createInjectPoint = payload => (
  () => new Promise((resolve, reject) => (
    API.post(
      `${process.env.APP_CONFIG.api_url}/points/inject-point-request`,
      payload,
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)


export const UpdateStatusInjectPoint = (id,payload) => (
  () => new Promise((resolve, reject) => (
    API.put(
      `${process.env.APP_CONFIG.api_url}/points/inject-point-request/${id}`,
      payload,
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
