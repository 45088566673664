import {
  CALC_MANAGEMENT_REQUEST,
  CALC_MANAGEMENT_SUCCESS,
  CALC_MANAGEMENT_FAILURE,
  CALC_MANAGEMENT_UPDATED,
  CALC_MANAGEMENT_DETAIL_SUCCESS,
  INVESTATION_LIST_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataCalcManagement: [],
  detailCalcManagement: {},
  dataInvestationType: [],
  metaInvestationType: {},
  metaCalcManagement: {},
}

export default function calculatorManagement(state = initialState, action) {
  switch (action.type) {
    case CALC_MANAGEMENT_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case CALC_MANAGEMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataCalcManagement: action.data,
        metaCalcManagement: action.meta,
      }
    case CALC_MANAGEMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case CALC_MANAGEMENT_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataCalcManagement: state.dataCalcManagement.filter(item => item.id !== action.data.id),
      }
    case INVESTATION_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataInvestationType: action.data,
        metaInvestationType: action.meta,
      }
    case CALC_MANAGEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailCalcManagement: action.data,
      }
    default:
      return state
  }
}
