import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form,
  Input, Button,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormRequestView = ({
  handleSubmit, isSubmit,
  form, isEdit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                type: 'email',
              },
            ],
          })(
            <Input placeholder="Masukan Email Pemohon" />,
          )}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message : 'Nama pemohon wajib diisi' }],
          })(
              <Input placeholder="Masukan Nama Pemohon" type="text" />,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/claim/claim-by-admin')} className="mr-2">Cancel</Button>
          <Button loading={isSubmit} type="primary" htmlType="submit">{!isEdit ? 'Add Form Request' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormRequestView.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'formRequest' })(FormRequestView)
