import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import BannersPage from 'containers/pages/webManagement/banners/List'
import BannersForm from 'containers/pages/webManagement/banners/Form'

const banners = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/web-management/banners') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementbanners-read') > -1) {
      return <BannersPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementbanners-write') > -1) {
      return <BannersForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

banners.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default banners
