import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchAbout, deleteAbout } from 'actions/About'
import AboutView from 'components/pages/abouts/List'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataAbout,
    metaAbout,
  } = state.root.about

  return {
    isFetching,
    dataAbout,
    metaAbout,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchAbout: bindActionCreators(fetchAbout, dispatch),
  deleteAbout: bindActionCreators(deleteAbout, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    handleSearch: () => () => {},
    handlePage: props => (page) => {
      props.fetchAbout(page ? `?${page}` : '')
    },
    handleDelete: props => (id) => {
      props.deleteAbout(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'About - Us'])
      this.props.updateSiteConfiguration('titlePage', 'About Us')
      this.props.fetchAbout()
    },
  }),
)(AboutView)
