import API from 'utils/API'

export const fetchClaim = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/claim${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchClaimStatus = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/claim/statuses${params || ''}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchClaimType = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/list-form/claim-type${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailClaim = id => (
  () => new Promise((resolve, reject) => (
    API.get(`/claim/detail/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateStatusClaim = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`/claim/${id}/status`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createFormRequest = (payload) => (
  () => new Promise((resolve, reject) => (
      API.post(`/email/claim-form`, payload).then(
          (response) => {
              const { data, meta } = response.data
              if (meta.status) {
                  resolve(data)
              } else {
                  reject(meta.message)
              }
          },
      ).catch((err) => {
          reject(err.message) // eslint-disable-line no-console
      })
  ))
)

export const checkDocumentsClaim = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.post(`/claim/${id}/validate`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const uploadBukitTransfer = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.post(`/claim/${id}/payment-document`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

// export const exportDoc = (id) => (
//   () => {
//     const fileURL = `/claim/detail/${id}/export`
//     const loc = window.open()
//     loc.location.href = fileURL
//   }
// )

export const exportDoc = (id) => (
  () => new Promise((resolve, reject) => (
    API.get(`/claim/detail/${id}/export`, { responseType: 'arraybuffer' }).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

// export const exportDoc = (id) => (
//   () => new Promise((resolve, reject) => (
//     API.get(`/claim/detail/${id}/export`).then(
//       (response) => {
//         resolve(response)
//       },
//     ).catch((err) => {
//       reject(err.message) // eslint-disable-line no-console
//     })
//   ))
// )
export const deleteClaim = id => (
  () => new Promise((resolve, reject) => (
    API.delete(`${process.env.APP_CONFIG.api_url}/claim/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchClaimRelationship = params => (
  () => new Promise((resolve, reject) => (
    API.get(`list-form/relationship-type-claim${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchClaimTypeForm = params => (
  () => new Promise((resolve, reject) => (
    API.get(`claim/types${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailClaimCMS = id => (
  () => new Promise((resolve, reject) => (
    API.get(`/cms/claim/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createClaim = payload => (
  () => new Promise((resolve, reject) => {
    API.post(
      'cms/claim', payload,
      // { headers: { 'content-type': 'mul' } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  })
)

export const uploadDocumentClaim = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.post(`/claim/claim/${id}/documents`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)