import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import List from 'containers/pages/calculationDplk/investmentYear/List'
// import Form from 'containers/pages/calculatorManagement/dplkFee/Form'

const InvestmentYear = ({ location, match, currentUser }) => {
  if (location.pathname === '/dplk-calculation-table/investment-year') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-riwayat-hasil-pengembanganinvestment-year-read') > -1) {
      return <List location={location} />
    }
    return <ForbiddenPage />
  }
  // if (location.pathname.includes('/edit') || location.pathname.includes('/add')) {
  //   if (currentUser.permissions && currentUser.permissions.indexOf('manage-riwayat-hasil-pengembanganinvestment-year-write') > -1) {
  //     return <Form match={match} location={location} />
  //   }
  //   return <ForbiddenPage />
  // }
  return ''
}

InvestmentYear.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default InvestmentYear
