import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Row, Col,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },

}

/* const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 24,
      span: 24,
    },
  },
} */

const FormPenjelasanSimpan = ({
  detail, onSubmit,
  form, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Form
      {...formItemLayout}
      onSubmit={(e) => {
        e.preventDefault()
        validateFields((err, values) => {
          if (!err) {
            onSubmit(values)
          }
        })
      }}
    >
      <Card>
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="TItle">
              {getFieldDecorator('title', {
                initialValue: detail.data.title,
                rules: [{ required: true }],
              })(
                <Input placeholder="Title" />,
              )}
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item label="Short Description">
              {getFieldDecorator('content', {
                initialValue: detail.data.content,
                rules: [{ required: true }],
              })(
                <Input.TextArea rows={3} placeholder="Short Description" />,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <div className="d-flex justify-content-end btn-primary-wrapper">
              <Button onClick={() => history.push('/web-management/penjelasan-simpan')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
              <Button type="primary" loading={isSubmit} htmlType="submit">Submit Change</Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

FormPenjelasanSimpan.propTypes = {
  detail: PropTypes.object,
  onSubmit: PropTypes.func,
  form: PropTypes.any,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'penjelasanSimpanForm' })(FormPenjelasanSimpan)
