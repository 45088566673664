import {
  AUTO_DEBIT_PEMBATALAN_REQUEST,
  AUTO_DEBIT_PEMBATALAN_SUCCESS,
  AUTO_DEBIT_PEMBATALAN_FAILURE,
  AUTO_DEBIT_PEMBATALAN_DETAIL_SUCCESS,
  AUTO_DEBIT_PEMBATALAN_DETAIL_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataAutoDebitPembatalan: [],
  metaAutoDebitPembatalan: {},
  detailAutoDebitPembatalan: {},
}

export default function autoDebitLogPayment(state = initialState, action) {
  switch (action.type) {
    case AUTO_DEBIT_PEMBATALAN_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case AUTO_DEBIT_PEMBATALAN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataAutoDebitPembatalan: action.data,
        metaAutoDebitPembatalan: action.meta,
      }
    case AUTO_DEBIT_PEMBATALAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataAutoDebitPembatalan: action.data === null ? [] : [],
        metaAutoDebitPembatalan: {},
        errorMessage: action.errorMessage,
      }
    case AUTO_DEBIT_PEMBATALAN_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailAutoDebitPembatalan: action.data,
      }
    case AUTO_DEBIT_PEMBATALAN_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        detailAutoDebitPembatalan: action.data === null ? {} : {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
