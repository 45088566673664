import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDetailVerifyCustomer, verifyCustomer } from 'actions/Customer'
import { message } from 'antd'
import ChaneEmailForm from 'components/pages/customers/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailCustomer,
  } = state.root.customer

  return {
    isFetching,
    detailCustomer,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailVerifyCustomer: bindActionCreators(fetchDetailVerifyCustomer, dispatch),
  verifyCustomer: bindActionCreators(verifyCustomer, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('detail', 'setDetail', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      if (props.match.params.id) {
        props.verifyCustomer(props.match.params.id, values)
          .then(() => {
          // message.success('Success')
            props.setIsSubmit(false)
            history.push('/customer/phone')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Customer by Phone', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Change Email Customer')
      if (match.params.id) {
        this.props.fetchDetailVerifyCustomer(match.params.id)
      }
    },
  }),
)(ChaneEmailForm)
