import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import history from 'utils/history'

const DplkFee = ({
  isFetching, listDplkFee,
}) => {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Persentase',
      dataIndex: 'percentage',
      key: 'percentage',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/calculator-management/biaya-dplk/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={listDplkFee}
        loading={isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

DplkFee.propTypes = {
  isFetching: PropTypes.bool,
  listDplkFee: PropTypes.array,
}

export default DplkFee
