import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Row, Radio,
  Col, Divider, Checkbox,
} from 'antd'
import history from 'utils/history'
import Helper from 'utils/Helper'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
}

const firstInputLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
}

const FormRole = ({
  handleSubmit, isEdit, isSubmit,
  permissionList, detail,
  form,
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form
  if (!permissionList.isFetching && !detail.isFetching) {
    useEffect(() => {
      if (isEdit && detail.data) {
        const getPermission = Helper.getPermissionId(detail.data.permissions)
        const getPermissionName = Helper.getPermissionName(detail.data.permissions)
        // form.setFieldsValue({
        //   //   name: detail.data.name,
        //   //   active: detail.data.active,
        // // Permissions: ['A'],
        // })
        setFieldsValue({
          Permissions: getPermission,
        })
      }
    }, [detail.data])
  }
  return (
    <div>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Card className="mb-3">
          <Form.Item {...firstInputLayout} label="Role Name">
            {getFieldDecorator('name', {
              initialValue: detail.data ? detail.data.name : '',
              rules: [{ required: true }],
            })(
              <Input placeholder="Name" />,
            )}
          </Form.Item>
          <Form.Item {...firstInputLayout} label="Status">
            {getFieldDecorator('active', {
              initialValue: !!detail.data.active,
              rules: [{ required: true }],
            })(
              <Radio.Group>
                <Radio value>Active</Radio>
                <Radio value={false}>Inactive</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
        </Card>
        <Card className="pt-4">
          <Row gutter={24} className="mb-3">
            <Col span={12}>
              <h6>Feature</h6>
            </Col>
            <Col span={4}>
              <h6>Add/Edit</h6>
            </Col>
            <Col span={4}>
              <h6>Read</h6>
            </Col>
            <Col span={4}>
              <h6>Delete</h6>
            </Col>
          </Row>

          <Form.Item {...formItemLayout} name="Permissions" className="mb-0">
            {getFieldDecorator('Permissions', {
            })(
              <Checkbox.Group className="w-100">

                {!permissionList.isFetching
                && permissionList.data.map(item => (
                  <div key={item.id}>
                    <Divider className="my-2" />
                    <Row gutter={24}>
                      <Col span={12} className="align-self-center">
                        <p className="mb-0 h-100">
                          {item.name}
                          {item.sub_name ? ` - ${item.sub_name}` : ''}
                        </p>
                      </Col>
                      <Col span={12}>
                        <Row gutter={24} className="mb-0">
                          <Col span={8}>
                            {(item.Permission || []).map((itemCreate) => {
                              if (itemCreate.name.includes('create') || itemCreate.name.includes('write')) {
                                return (
                                  <Checkbox key={itemCreate.id} value={itemCreate.id} style={{ lineHeight: '32px' }} /> // sparate looping checkbox just in case if one of them is null
                                )
                              }
                            })}
                            {/* <Checkbox key="A" value="A" style={{ lineHeight: '32px' }} /> */}
                          </Col>
                          <Col span={8}>
                            {(item.Permission || []).map((itemRead) => {
                              if (itemRead.name.includes('read')) {
                                return (
                                  <Checkbox key={itemRead.id} value={itemRead.id} style={{ lineHeight: '32px' }} /> // sparate looping checkbox just in case if one of them is null
                                )
                              }
                            })}
                            {/* <Checkbox key="B" value="B" style={{ lineHeight: '32px' }} /> */}
                          </Col>
                          <Col span={8}>
                            {(item.Permission || []).map((itemDelete) => {
                              if (itemDelete.name.includes('delete')) {
                                return (
                                  <Checkbox key={itemDelete.id} value={itemDelete.id} style={{ lineHeight: '32px' }} /> // sparate looping checkbox just in case if one of them is null
                                )
                              }
                            })}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ))}

              </Checkbox.Group>,
            )}

            {/* <Checkbox.Group className="w-100">
              {!permissionList.isFetching
                && permissionList.data.map(item => (
                  <div key={item.id}>
                    <Divider className="my-2" />
                    <Row gutter={24}>
                      <Col span={12} className="align-self-center">
                        <p className="mb-0 h-100">
                          {item.name}
                          {item.sub_name ? ` - ${item.sub_name}` : ''}
                        </p>
                      </Col>
                      <Col span={12}>
                        <Row gutter={24} className="mb-0">
                          <Col span={8}>
                            {(item.Permission || []).map((itemCreate) => {
                              if (itemCreate.name.includes('create') || itemCreate.name.includes('write')) {
                                return (
                                  <Checkbox key={itemCreate.id} value={itemCreate.id} style={{ lineHeight: '32px' }} /> // sparate looping checkbox just in case if one of them is null
                                )
                              }
                            })}
                          </Col>
                          <Col span={8}>
                            {(item.Permission || []).map((itemRead) => {
                              if (itemRead.name.includes('read')) {
                                return (
                                  <Checkbox key={itemRead.id} value={itemRead.id} style={{ lineHeight: '32px' }} /> // sparate looping checkbox just in case if one of them is null
                                )
                              }
                            })}
                          </Col>
                          <Col span={8}>
                            {(item.Permission || []).map((itemDelete) => {
                              if (itemDelete.name.includes('delete')) {
                                return (
                                  <Checkbox key={itemDelete.id} value={itemDelete.id} style={{ lineHeight: '32px' }} /> // sparate looping checkbox just in case if one of them is null
                                )
                              }
                            })}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ))
              }
            </Checkbox.Group> */}
          </Form.Item>


          <Row gutter={24} className="my-5">
            <Col span={24}>
              <div className="d-flex justify-content-end btn-primary-wrapper">
                <Button onClick={() => history.goBack()} className="mr-2" style={{ display: 'inline-block' }} disabled={isSubmit}>Cancel</Button>
                <Button type="primary" loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Role' : 'Submit Change'}</Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  )
}

FormRole.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  permissionList: PropTypes.object,
  detail: PropTypes.object,
}

export default Form.create({ name: 'adminForm' })(FormRole)
