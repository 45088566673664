import API from 'utils/API'

export const fetchAppsVersion = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/app-version${params || ''}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailAppsVersion = id => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/app-version/id/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateAppsVersion = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/app-version/edit/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
