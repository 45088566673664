import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchPaymentConfigurationList } from 'actions/PaymentConfiguration'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import PaymentConfigurationView from 'components/pages/paymentConfiguration/List'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth
  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchPaymentConfigurationList: bindActionCreators(fetchPaymentConfigurationList, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

const dataStatus = [
  { id: 1, label: 'Aktif', value: 'true' },
  { id: 2, label: 'Tidak Aktif', value: 'false' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('paymentConfigurationData', 'setPaymentConfigurationData', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('statusList', 'setStatusList', dataStatus),
  withState('metaFilter', 'setMetaFilter', {}),
  withHandlers({
    getFetchPaymentConfigurationList: props => (params) => {
      const { setPaymentConfigurationData } = props
      props.fetchPaymentConfigurationList(params)
        .then((result) => {
          setPaymentConfigurationData({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setPaymentConfigurationData({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, status,
      } = props.metaFilter
      const payload = {
        page: isSearch ? '' : page,
        is_active: status || '',
      }
      props.getFetchPaymentConfigurationList(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleChangeStatus: props => (val) => {
      const { onLoad, setMetaFilter, metaFilter } = props
      setMetaFilter({
        ...metaFilter,
        status: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setMetaFilter, metaFilter } = props
      setMetaFilter({
        ...metaFilter,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Payment Configuration', 'List'])
      this.props.updateSiteConfigs({
        titlePage: 'Payment Configuration - List',
        activePage: 'payment-configuration',
      })
      this.props.onLoad()
    },
  }),
)(PaymentConfigurationView)
