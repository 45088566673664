import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAILURE,
  NOTIFICATION_UPDATED,
  NOTIFICATION_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataNotif: [],
  detailNotif: {},
  metaNotif: {
    total_count: 0,
    current: 0,
  },
}

export default function notification(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataNotif: action.data,
        metaNotif: action.meta,
      }
    case NOTIFICATION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case NOTIFICATION_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataNotif: state.dataNotif.filter(item => item.id !== action.data.id),
      }
    case NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailNotif: action.data,
      }
    default:
      return state
  }
}
