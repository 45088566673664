import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Row,
  Col, Avatar, Upload,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },

}

const FormReview = ({
  detailReview, handleSubmit,
  form, isEdit, stateFile,
  handleUpload, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Form
      {...formItemLayout}
      onSubmit={(e) => {
        e.preventDefault()
        validateFields((err, values) => {
          if (!err) {
            handleSubmit(values)
          }
        })
      }}
    >
      <Card>
        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Image">
              {getFieldDecorator('image', {
                rules: [{ required: !isEdit }],
              })(
                <Upload
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader banner-content"
                  showUploadList={false}
                  beforeUpload={() => false}
                  onChange={e => handleUpload(e)}
                  accept="image/*"
                  fileList={stateFile}
                >
                  {stateFile ? (
                    <Avatar src={stateFile} size={250} shape="square" />
                  ) : (
                    <div>
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  )}
                </Upload>,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                initialValue: detailReview.name,
                rules: [{ required: true }],
              })(
                <Input placeholder="Name" />,
              )}
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item label="Age">
              {getFieldDecorator('age', {
                initialValue: detailReview.age,
                rules: [{ required: false }],
              })(
                <Input placeholder="Age" />,
              )}
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item label="Occupation">
              {getFieldDecorator('occupation', {
                initialValue: detailReview.occupation,
                rules: [{ required: true }],
              })(
                <Input placeholder="Occupation" />,
              )}
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item label="Commentary">
              {getFieldDecorator('commentary', {
                initialValue: detailReview.commentary,
                rules: [{ required: true }],
              })(
                <Input.TextArea rows={3} placeholder="Commentary" />,
              )}
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item label="Highlight">
              {getFieldDecorator('highlight', {
                initialValue: detailReview.highlight,
                rules: [{ required: true }],
              })(
                <Input.TextArea rows={5} placeholder="Highlight" />,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} className="mb-5">
          <Col span={15}>
            <div className="d-flex justify-content-end btn-primary-wrapper">
              <Button onClick={() => history.push('/web-management/reviews')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
              <Button type="primary" loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Reviews' : 'Submit Change'}</Button>
            </div>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}

FormReview.propTypes = {
  detailReview: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  stateFile: PropTypes.any,
  handleUpload: PropTypes.func,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'reviewForm' })(FormReview)
