import API from 'utils/API'
import config from 'app/config'

export const fetchPaymentConfigurationList = params => (
  () => new Promise((resolve, reject) => (
    API.get(
      `${process.env.APP_CONFIG.api_url_payment}/v2/payment-methods/list${params || ''}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const fetchPaymentConfigurationDetail = id => (
  () => new Promise((resolve, reject) => (
    API.get(
      `${process.env.APP_CONFIG.api_url_payment}/payment-method/${id || ''}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updatePaymentConfiguration = (id, payload) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_payment}/payment-method/${id}`, payload,
      { headers: { 'x-api-key': config.api_key } }).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)
