import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchAppsVersion } from 'actions/AppsVersion'
import AppsVersionView from 'components/pages/appsVersion/List'
import { message } from 'antd'

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchAppsVersion: bindActionCreators(fetchAppsVersion, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('listAppsVersion', 'setListAppsVersion', []),
  withState('isFetching', 'setIsFetching', true),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Apps Management', 'Apps Version'])
      this.props.updateSiteConfigs({
        titlePage: 'Management Apps Version',
        activePage: 'apps-version',
        activeSubPage: 'apps-version',
      })
      this.props.fetchAppsVersion()
        .then((result) => {
          this.props.setListAppsVersion(result)
          this.props.setIsFetching(false)
        })
        .catch((error) => {
          message.error(error.message)
          this.props.setIsFetching(false)
        })
    },
  }),
)(AppsVersionView)
