import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDetailBenefit } from 'actions/Benefit'
import DetailBenefitView from 'components/pages/benefits/detail'
import { message } from 'antd'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailBenefit: bindActionCreators(fetchDetailBenefit, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('detailBenefit', 'setDetailBenefit', {
    data: {},
    isFetching: true,
  }),
  withHandlers({

  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Benefit', 'Detail'])
      this.props.updateSiteConfiguration('titlePage', 'Detail Pelajari Manfaat')
      if (match.params.id) {
        this.props.fetchDetailBenefit(match.params.id)
          .then((res) => {
            this.props.setDetailBenefit({
              data: res,
              isFetching: false,
            })
          })
          .catch((err) => {
            message.error(err)
            this.props.setDetailBenefit({
              data: {},
              isFetching: false,
            })
          })
      }
    },
  }),
)(DetailBenefitView)
