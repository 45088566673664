import API from 'utils/API'

export const fetchDownloadList = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/downloads${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
