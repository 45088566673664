import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import history from 'utils/history'

const Pdp = ({
  isFetching, contentPdp,
}) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 500,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/pdp/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <Table
      rowKey="id"
      className="table-striped-rows"
      columns={columns}
      dataSource={contentPdp}
      loading={isFetching}
      scroll={{ x: 1300 }}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  )
}

Pdp.propTypes = {
  contentPdp: PropTypes.array,
  isFetching: PropTypes.bool,
}

export default Pdp
