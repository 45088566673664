import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchCalculationDplkList,
  fetchInvesPast5YearDownload,
  uploadInvestmentPast5Years,
} from 'actions/CalculationDplk'
import InvestmentHistoryView from 'components/pages/calculationDplk/investmentHistory/List'
import { message } from 'antd'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataInvestmentHistory,
    metaInvestmentYear,
    fileInvestmentPast5Year,
    metafileInvestmentPast5Year,
  } = state.root.calculationDplk
  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataInvestmentHistory,
    metaInvestmentYear,
    fileInvestmentPast5Year,
    metafileInvestmentPast5Year,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchCalculationDplkList: bindActionCreators(fetchCalculationDplkList, dispatch),
  fetchInvesPast5YearDownload: bindActionCreators(fetchInvesPast5YearDownload, dispatch),
  uploadInvestmentPast5Years: bindActionCreators(uploadInvestmentPast5Years, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('uploadModal', 'setUploadModal', false),
  withState('uploading', 'setUploading', false),
  withState('isAttached', 'setIsAttached', false),
  withState('previewFile', 'setPreviewFile', ''),
  withState('file', 'setFile', ''),
  withHandlers({
    handleSearch: () => () => {},
    toggleUploadModal: props => () => {
      props.setUploadModal(!props.uploadModal)
    },
    handleChange: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      props.setPreviewFile(fileList)
      if (fileList.length > 0) {
        props.setIsAttached(false)
      } else {
        props.setIsAttached(true)
      }
      props.setFile(info.file)
    },
    handleUpload: props => (values) => {
      const formData = new FormData()
      formData.append('file', values)
      props.uploadInvestmentPast5Years(formData).then(() => {
        props.setUploadModal(!props.uploadModal)
        message.success('File has been uploaded').then(() => (
          props.setUploadModal(!props.uploadModal)
        ))
      }).catch((err) => {
        message.error(err)
        props.setUploadModal(!props.uploadModal)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Dplk-Calculation-Table', 'Investment-History'])
      this.props.updateSiteConfiguration('titlePage', 'Investment History')
      this.props.fetchCalculationDplkList()
      this.props.fetchInvesPast5YearDownload()
    },
  }),
)(InvestmentHistoryView)
