import { connect } from 'react-redux'
import { compose, lifecycle, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import InjectPoint from 'components/pages/injectPoint'


export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateCheck', 'setStateCheck', {
    isLocked: false,
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Inject Point'])
      this.props.updateSiteConfiguration('titlePage', 'Inject Point')
    },
  }),
)(InjectPoint)
