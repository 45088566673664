import PropTypes from 'prop-types'
import {
  Table,
  Button, Input, Icon,
  Avatar, Popconfirm,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

const AboutDplkPage = ({
  isFetching, handlePage,
  metaAboutDplk, dataAboutDplk,
  handleSearch, handleDelete,
  currentUser,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail_path',
      key: 'thumbnail_path',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={75} src={image} alt="voucher" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={75} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Image',
      dataIndex: 'img_path',
      key: 'img_path',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={75} src={image} alt="voucher" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={75} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Description',
      dataIndex: 'short_description',
      key: 'short_description',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text || '-'}</p></div>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 110,
      render: text => (text ? moment(text).format('DD MMM YY') : '-'),
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      render: text => text || '-',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/about-dplk/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-dplk-anda-delete') > -1) && (
          <Popconfirm
            title="Are you sure delete this data?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" />
          </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex flex-sm-row flex-column align-items-center btn-primary-wrapper">
          <div className="custom-input-txt pb-3 pb-md-0">
            <Input.Search
              allowClear
              placeholder="Search by Title"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <Button
            className="ml-auto"
            type="primary"
            onClick={() => history.push('/about-dplk/add')}
          >
            Add
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataAboutDplk}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaAboutDplk ? metaAboutDplk.total_count : dataAboutDplk.length,
          current: metaAboutDplk ? metaAboutDplk.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}


AboutDplkPage.propTypes = {
  isFetching: PropTypes.bool,
  dataAboutDplk: PropTypes.array,
  metaAboutDplk: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default AboutDplkPage
