import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { createDenomOttocash, fetchDetailDenom, updateDenomOttocash } from 'actions/DanaOttocash'
import { message } from 'antd'
import FormManagementOttocashView from 'components/pages/danaOttocash/management/Form'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  createDenomOttocash: bindActionCreators(createDenomOttocash, dispatch),
  fetchDetailDenom: bindActionCreators(fetchDetailDenom, dispatch),
  updateDenomOttocash: bindActionCreators(updateDenomOttocash, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('detail', 'setDetail', {}),
  withState('status', 'setStatus', 'inactive'),
  withState('denomDetail', 'setDenomDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    handleRadio: props => (e) => {
      const { value } = e.target
      props.setStatus({ status: value })
    },
    handleSubmit: props => (values) => {
      const payload = {
        ...values,
        nominal_amount: parseInt(values.nominal_amount, 10),
        price_amount: parseInt(values.price_amount, 10),
      }
      if (props.match.params.id) {
        props.updateDenomOttocash(payload, props.match.params.id)
          .then(() => {
            message.success('Update Success')
            history.push('/dana-ottocash/management-ottocash')
          }).catch(() => {
            message.error('Something when wrong')
          })
      } else {
        props.createDenomOttocash(payload)
          .then(() => {
            message.success('Create Success')
            history.push('/dana-ottocash/management-ottocash')
          }).catch(() => {
            message.error('Something when wrong')
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDenomDetail,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Management Dana Ottocash', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Management Dana Ottocash Form')

      if (match.params.id) {
        this.props.fetchDetailDenom(match.params.id)
          .then((result) => {
            const { data, meta } = result
            setDenomDetail({
              data,
              meta,
              isFetching: false,
            })
          })
          .catch((error) => {
            message.error(error)
            setDenomDetail({
              data: {},
              meta: {},
              isFetching: false,
            })
          })
        setIsEdit(true)
      }
    },
  }),
)(FormManagementOttocashView)
