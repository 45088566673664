import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  updateUltraVoucherProduct,
  fetchUltraVoucher,
} from 'actions/UltraVouchers'
import { message } from 'antd'
import FormUltraVoucherProductView from 'components/pages/ultraVouchers/products/Form'
import Helper from 'utils/Helper'
import history from 'utils/history'
import { pickBy, identity, find } from 'lodash'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataUltraVoucher,
    metaUltraVoucher,
  } = state.root.ultraVoucher

  return {
    isFetching,
    dataUltraVoucher,
    metaUltraVoucher,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchUltraVoucher: bindActionCreators(fetchUltraVoucher, dispatch),
  // createUltraVoucherCategory: bindActionCreators(createUltraVoucherCategory, dispatch),
  updateUltraVoucherProduct: bindActionCreators(updateUltraVoucherProduct, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('iconFile', 'setIconFile', ''),
  withState('detailUVProduct', 'setDetailUVProduct', {}),
  withHandlers({
    handleUpload: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setIconFile(file))
      }
      return false
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      const formData = new FormData()
      Object.keys(pickBy(values, identity)).map((item) => {
        if (item === 'image' && values[item]) {
          return formData.append(item, values[item].file)
        }
        return formData.append(item, values[item])
      })
      if (props.match.params.id) {
        props.updateUltraVoucherProduct(formData, props.match.params.id).then(() => {
          history.push('/ultra-vouchers/products')
        }).catch((err) => {
          props.setIsSubmit(false)
          message.error(err)
        })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setIconFile, setDetailUVProduct,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Ultra Voucher', 'Products'])
      this.props.updateSiteConfiguration('titlePage', 'Ultra Voucher - Products')
      this.props.fetchUltraVoucher()

      if (match.params.id) {
        const detailVoucher = find(this.props.dataUltraVoucher, ['id', match.params.id])
        setDetailUVProduct(detailVoucher)
        setIconFile(detailVoucher.image)
        setIsEdit(true)
      }
    },
  }),
)(FormUltraVoucherProductView)
