import API from 'utils/API'
import {
  OTTOPAY_REQUEST,
  OTTOPAY_SUCCESS,
  OTTOPAY_FAILURE,
} from 'constants/ActionTypes'

export const ottopayRequest = () => ({
  type: OTTOPAY_REQUEST,
})

export const ottopaySuccess = (data, meta) => ({
  type: OTTOPAY_SUCCESS,
  data,
  meta,
})

export const ottopayFailure = errorMessage => ({
  type: OTTOPAY_FAILURE,
  errorMessage,
})


export const fetchOttopay = params => (
  (dispatch) => {
    dispatch(ottopayRequest())
    const url = `${process.env.APP_CONFIG.api_url_payment}/ottopay/list${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(ottopaySuccess(data, meta))
        } else {
          dispatch(ottopayFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ottopayFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_payment}/ottopay/list/export${params || ''}`, { responseType: 'arraybuffer' }).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const syncOttopayPayment = () => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_payment}/transactions/sync/confirmed`).then(
      (response) => {
        const { meta } = response.data
        if (meta.status) {
          resolve(response.data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
