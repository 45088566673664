import PropTypes from 'prop-types'
import { Button, Form, Alert } from 'react-bootstrap'

const Login = (props) => {
  const {
    onChange,
    onSubmit,
    onSubmitForgot,
    form,
    errorMessage,
    isSubmit,
    viewSwitch,
    handleViewSwitch,
  } = props
  return (
    <div
      className="form-container d-flex align-items-center"
      style={{
        backgroundImage: 'url(./assets/bg_dplk.svg)',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
      }}
    >
      {viewSwitch ? (
        <Form className="form-signin px-3 py-5 card-login" onSubmit={onSubmit}>
          <img className="mb-4" src="./assets/logo_dplk.svg" alt="logo" />

          <Form.Control onChange={onChange} value={form.identifier || ''} name="identifier" type="text" placeholder="Email Address" className="mb-2" />
          <Form.Control onChange={onChange} value={form.password || ''} name="password" type="password" placeholder="Password" className="mb-2" />

          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

          <Button block size="lg" variant="primary" disabled={isSubmit} type="submit" className="mt-5">Sign in</Button>
          <Button variant="link" className="px-0" disabled={isSubmit} onClick={handleViewSwitch}>Forgot Password</Button>
        </Form>
      ) : (
        <Form className="form-signin px-3 py-5 card-forgot" onSubmit={onSubmitForgot}>
          <div className="mb-3">
            <h5 className="primary-color mb-1">Lupa Password?</h5>
            <p style={{ color: '#495057' }}>
              Masukan email yang terdaftar untuk mendapatkan
              <i> reset link</i>
            </p>
          </div>
          <Form.Control onChange={onChange} value={form.identifier || ''} name="identifier" type="text" placeholder="Email Address" className="mb-2" />
          <Button block size="lg" variant="primary" disabled={isSubmit} type="submit" className="mt-5">Send</Button>
          <Button variant="link" className="px-0" disabled={isSubmit} onClick={handleViewSwitch}>Sign In</Button>
        </Form>
      )
      }
    </div>
  )
}

Login.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitForgot: PropTypes.func,
  form: PropTypes.any,
  errorMessage: PropTypes.string,
  isSubmit: PropTypes.bool,
  viewSwitch: PropTypes.bool,
  handleViewSwitch: PropTypes.func,
}

export default Login
