import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchAutoDebitPendaftaran, exportFile } from 'actions/AutoDebitPendaftaran'
import AutoDebitPendaftaranView from 'components/pages/autoDebit/pendaftaran/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import { message } from 'antd'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataAutoDebitPendaftaran,
    metaAutoDebitPendaftaran,
  } = state.root.autoDebitPendaftaran

  return {
    isFetching,
    dataAutoDebitPendaftaran,
    metaAutoDebitPendaftaran,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchAutoDebitPendaftaran: bindActionCreators(fetchAutoDebitPendaftaran, dispatch),
  exportFile: bindActionCreators(exportFile, dispatch),
})

const dataStatus = [
  { id: 1, label: 'Active', value: 'active' },
  { id: 2, label: 'In Progress', value: 'in progress' },
  { id: 3, label: 'Inactive', value: 'in active' },
  { id: 4, label: 'Failed', value: 'failed' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('statusList', 'setStatusList', dataStatus),
  withState('dataFile', 'setDataFile', ''),
  withState('paramsFile', 'setParamsFile', ''),
  withState('stateSearch', 'setStateSearch', {}),
  withState('isExport', 'setIsExport', false),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, search, status, startDate, endDate,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        status: status || '',
        start_date: startDate || '',
        end_date: endDate || '',
      }
      props.setParamsFile(`?${qs.stringify(pickBy(payload, identity))}`)
      props.fetchAutoDebitPendaftaran(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleReport: props => () => {
      const { paramsFile, setIsExport } = props
      setIsExport(true)
      props.exportFile(paramsFile)
        .then((result) => {
          if (result) {
            setIsExport(false)
            history.push('/download')
            setIsExport(false)
          } else {
            message.error('Something when wrong')
          }
        }).catch((error) => {
          message.error(error)
          setIsExport(false)
        })
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        status: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeDate: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      let start = ''
      let end = ''
      if (val.length === 2) {
        start = val[0].format('DD-MM-YYYY')
        end = val[1].format('DD-MM-YYYY')
      } else {
        start = ''
        end = ''
      }
      setStateSearch({
        ...stateSearch,
        startDate: start,
        endDate: end,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Autodebet', 'Pendaftaran', 'List'])
      this.props.updateSiteConfigs({
        titlePage: 'Autodebet - Pendaftaran',
        activePage: 'auto-debet/pendaftaran',
        activeSubPage: 'pendaftaran',
      })
      this.props.fetchAutoDebitPendaftaran()
    },
  }),
)(AutoDebitPendaftaranView)
