import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import PendaftaranView from 'containers/pages/autoDebit/pendaftaran/List'

const AutoDebitPendaftaran = ({
  currentUser, location,
}) => {
  if (location.pathname === '/auto-debet/pendaftaran') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-autodebit-registration-read') > -1) {
      return <PendaftaranView location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

AutoDebitPendaftaran.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
}

export default AutoDebitPendaftaran
