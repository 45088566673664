import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import VBrandsList from 'containers/pages/ultraVouchers/brands/List'
import VBrandsForm from 'containers/pages/ultraVouchers/brands/Form'

const Brands = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/ultra-vouchers/brands') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-voucherbrand-read') > -1) {
      return <VBrandsList location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-voucherbrand-write') > -1) {
      return <VBrandsForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Brands.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Brands
