import API from 'utils/API'
import {
  CALCULATION_DPLK_REQUEST,
  CALCULATION_DPLK_SUCCESS,
  CALCULATION_DPLK_FAILURE,
  CALCULATION_DPLK_UPDATED,
  INVESTMENT_YEAR_SUCCESS,
  INVEST_YEAR_DOWNLOAD_SUCCESS,
  INVEST_PAST5YEAR_DOWNLOAD_SUCCESS,
} from 'constants/ActionTypes'
import { message } from 'antd'

export const calculationDplkRequest = () => ({
  type: CALCULATION_DPLK_REQUEST,
})

export const calculationDplkSuccess = (data, meta) => ({
  type: CALCULATION_DPLK_SUCCESS,
  data,
  meta,
})

export const investmentYearSuccess = (data, meta) => ({
  type: INVESTMENT_YEAR_SUCCESS,
  data,
  meta,
})

export const invesYearDownloadSuccess = (data, meta) => ({
  type: INVEST_YEAR_DOWNLOAD_SUCCESS,
  data,
  meta,
})

export const invesPast5YearDownloadSuccess = (data, meta) => ({
  type: INVEST_PAST5YEAR_DOWNLOAD_SUCCESS,
  data,
  meta,
})

export const calculationDplkFailure = errorMessage => ({
  type: CALCULATION_DPLK_FAILURE,
  errorMessage,
})

export const calculationDplkUpdate = data => ({
  type: CALCULATION_DPLK_UPDATED,
  data,
})


export const fetchCalculationDplkList = params => (
  (dispatch) => {
    dispatch(calculationDplkRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/investment-history${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(calculationDplkSuccess(data, meta))
        } else {
          dispatch(calculationDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calculationDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchInvestmentYearList = params => (
  (dispatch) => {
    dispatch(calculationDplkRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/investment-year${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(investmentYearSuccess(data, meta))
        } else {
          dispatch(calculationDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calculationDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchInvestmentYearDownload = params => (
  (dispatch) => {
    dispatch(calculationDplkRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/investment-year/download${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(invesYearDownloadSuccess(data, meta))
        } else {
          dispatch(calculationDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calculationDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchInvesPast5YearDownload = params => (
  (dispatch) => {
    dispatch(calculationDplkRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/calculator/investment-history/download${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(invesPast5YearDownloadSuccess(data, meta))
        } else {
          dispatch(calculationDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(calculationDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const uploadInvestmentYear = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/calculator/investment-year/upload`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const uploadInvestmentPast5Years = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/calculator/investment-history/upload`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)
