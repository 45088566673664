import API from 'utils/API'
import {
  AUTO_DEBIT_PEMBATALAN_REQUEST,
  AUTO_DEBIT_PEMBATALAN_SUCCESS,
  AUTO_DEBIT_PEMBATALAN_FAILURE,
  AUTO_DEBIT_PEMBATALAN_DETAIL_SUCCESS,
  AUTO_DEBIT_PEMBATALAN_DETAIL_FAILURE,
} from 'constants/ActionTypes'

export const autoDebitPembatalanRequest = () => ({
  type: AUTO_DEBIT_PEMBATALAN_REQUEST,
})

export const autoDebitPembatalanSuccess = (data, meta) => ({
  type: AUTO_DEBIT_PEMBATALAN_SUCCESS,
  data,
  meta,
})

export const autoDebitPembatalanFailure = errorMessage => ({
  type: AUTO_DEBIT_PEMBATALAN_FAILURE,
  errorMessage,
})

export const autoDebitPembatalanDetailSuccess = data => ({
  type: AUTO_DEBIT_PEMBATALAN_DETAIL_SUCCESS,
  data,
})

export const autoDebitPembatalanDetailFailure = data => ({
  type: AUTO_DEBIT_PEMBATALAN_DETAIL_FAILURE,
  data,
})

export const fetchAutoDebitPembatalan = params => (
  (dispatch) => {
    dispatch(autoDebitPembatalanRequest())
    const url = `${process.env.APP_CONFIG.api_url}/autodebet/registration-cancellation-list${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(autoDebitPembatalanSuccess(data, meta))
        } else {
          dispatch(autoDebitPembatalanFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(autoDebitPembatalanFailure(err.message))
    })
  }
)

// Fetch Pembatalan Detail //
export const fetchAutoDebitPembatalanDetail = id => (
  (dispatch) => {
    dispatch(autoDebitPembatalanRequest())
    const url = `${process.env.APP_CONFIG.api_url}/autodebet/registration-cancellation-detail/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(autoDebitPembatalanDetailSuccess(data))
        } else {
          dispatch(autoDebitPembatalanDetailFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(autoDebitPembatalanDetailFailure(err.message))
    })
  }
)

export const updatedStatusPembatalan = (id, payload) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url}/autodebet/approval-registration-cancellation/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url}/autodebet/export-registration-cancellation-list${params || ''}`).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)
