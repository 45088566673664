import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataDashboard: {},
  metaDashboard: {},
}

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataDashboard: action.data,
        metaDashboard: action.meta,
      }
    case DASHBOARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        dataDashboard: {},
        metaDashboard: {},
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
