import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Card, Button, Input,
  Icon, Checkbox, Tooltip,
  Tag, Modal, Alert, Spin,
  Upload, Divider
} from 'antd'
import { Row, Col } from 'react-bootstrap'

import { isEmpty } from 'lodash'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import history from 'utils/history'

const ClaimNormalDetail = ({
  detailClaim, imageModal, toggleImageModal,
  imgUrl, onChangeDoc, isFetchingData, onSubmitRequest,
  handleStatusClaim, isSubmit, scrollToTop, handleDownload,
  handleChangeNote, reason, handleFullReject,
  handleChangeRejectNotes, toggleRejectModal, rejectModal,
  toggleUploadModal, uploadModal, previewFile,
  isUploading, handleChangeFile, handleUpload, fileName,
  checkStatus, handleDownloadClaimDoc, isExport
  /* handleScrollToTop, documents, */
}) => {

  const naik = useRef(null)
  if (scrollToTop) {
    naik.current.scrollIntoView({ behavior: 'smooth' })
  }
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }
  const statusClaim = detailClaim.data && detailClaim.data.status

  let tagColor
  if (detailClaim.data.status && detailClaim.data.status.name === 'fund transferred') {
    tagColor = 'green'
  } else if ((detailClaim.data.status && detailClaim.data.status.name === 'rejected') || (detailClaim.data.status && detailClaim.data.status.name === 'full rejection')) {
    tagColor = 'red'
  } else {
    tagColor = ''
  }

  let alertStatus
  if (statusClaim && statusClaim.name === 'rejected') {
    alertStatus = {
      message: 'Permohonan dokumen telah ditolak, Form pengajaun dapat direview kembali setelah peserta sudah memperbaharui dokumen yang tidak sesuai',
      type: 'error',
    }
  } else if (statusClaim && statusClaim.name === 'approved') {
    alertStatus = {
      message: "Dokumen telah lengkap dan sudah valid, klik 'Approve Document' untuk menyetujui permohonan klaim",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'administration') {
    alertStatus = {
      message: "Permohonan klaim telah disetujui, klik 'Process Transfer' untuk melakukan Transfer",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'full rejection') {
    alertStatus = {
      message: detailClaim.data.reviewer && detailClaim.data.reviewer.review_notes ? detailClaim.data.reviewer.review_notes : 'Full Rejected',
      type: 'error',
    }
  } 

  return (
    <div className="pb-5">
      <div ref={naik} />
      <Row className="pb-4">
        {alertStatus
          && (
          <Col md="12">
            <Alert
              message={alertStatus.message}
              type={alertStatus.type}
              closable
            />
          </Col>
          )
        }
        <Col md="12" className="float-right btn-primary-wrapper">
          {statusClaim && (statusClaim.name === 'inquiry' || statusClaim.name === 'need review')
            && <Button className="float-right" type="primary" loading={isSubmit} onClick={() => handleStatusClaim('document review')}>Review Document</Button>
          }
        </Col>
      </Row>

      <Row className="pb-4">
        <Col md="12">
          <div className="custom-card-wrapper">
            <Card bordered={false}>
              <Row>
                <Col md="6" className="mb-3">
                  <h6 className="mb-1 mb-md-2">{detailClaim.data.name || '-'}</h6>
                  <p className="mb-0">
                    No. Peserta:
                    <span className="ml-2">{detailClaim.data.registered_number || '-'}</span>
                  </p>
                </Col>
                <Col md="6" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Status</h6>
                  <Tag color={tagColor} style={{ fontSize: '1rem' }}>{statusClaim && statusClaim.status_label ? statusClaim.status_label : '-'}</Tag>
                </Col>
                <Col md="6" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Account Name</h6>
                  <p className="mb-0">{detailClaim.data.account_name || '-'}</p>
                </Col>
                {statusClaim && statusClaim.name !== "inquiry" &&
                  <Col md="6" className="mb-3">
                    <h6 className="mb-1 mb-md-2">Bukti Transfer</h6>
                    <Button onClick={() => toggleUploadModal()}>
                    {detailClaim.data.payment_document ? 'View' : 'Upload'}
                    </Button>
                  </Col>
                }
                <Col md="6" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Tanggal Bergabung</h6>
                  <p className="mb-0">{detailClaim.data.join_date || '-'}</p>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
      {isFetchingData
        && (
        <Row className="pb-4">
          <Col md="12">
            <div className="custom-card-wrapper">
              <Card bordered={false}>
                <div className="text-center">
                  <Spin className="mb-3" />
                  <p className="mb-0 font-weight-bold">Load Data...</p>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
        )
      }
      {statusClaim && (statusClaim.name !== 'inquiry' && statusClaim.name !== 'need review')
        && (
        <React.Fragment>
          <Row className="pb-4">
            <Col md="12">
              <div className="custom-card-wrapper">
                <Card bordered={false}>
                  <Row className="mb-3">
                    <Col md="12" className="pb-3">
                      <h6 className="font-weight-bold">Jenis Klaim Manfaat Pensiun</h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Jenis Klaim Mafaat Pensiun</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {!isEmpty(detailClaim.data.claim_type) ? detailClaim.data.claim_type.name : '-'}
                      </h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Hubungan</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {!isEmpty(detailClaim.data.relation_type) ? detailClaim.data.relation_type.name : ''}
                      </h6>
                    </Col>
                  </Row>

                  <Row className="pb-2">
                    <Col md="12" className="pb-3">
                      <h6 className="font-weight-bold">Jenis Klaim Manfaat Pensiun</h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Nama Lengkap</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {detailClaim.data.name || '-'}
                      </h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Bank</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {detailClaim.data.bank ? detailClaim.data.bank.bank_name : '-'}
                      </h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Nomor Rekening</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {detailClaim.data.bank ? detailClaim.data.bank.bank_account_no : '-'}
                      </h6>
                    </Col>
                  </Row>
                  {(detailClaim.data.documents || []).map(item => (
                    <Row className="pb-2" key={item.id}>
                      {(statusClaim && statusClaim.name === 'document review')
                        && (
                        <Col md="4" className="mb-1 align-items-center d-flex">
                          {item.status === 'approved' ? (
                            <React.Fragment>
                              <Icon
                                type="check-circle"
                                theme="twoTone"
                                twoToneColor="#52c41a"
                                style={{ fontSize: '22px' }}
                                className="mr-2"
                              />
                              <div className="align-items-center">
                                <h6 className="mb-1 font-grey">{item.type}</h6>
                              </div>
                            </React.Fragment>
                          ) : (
                            <Tooltip placement="right" title="Check jika dokumen sesuai">
                              <Checkbox name={item.id} onChange={onChangeDoc} className="d-flex align-items-end" id={item.id}>
                                <h6 className="mb-0 font-grey">{item.type}</h6>
                              </Checkbox>
                            </Tooltip>
                          )}
                        </Col>
                        )
                      }
                      {(statusClaim && statusClaim.name === 'full rejection')
                        && (
                          <Col md="4" className="align-items-center d-flex">
                            <Icon
                              type='close-circle'
                              theme="twoTone"
                              twoToneColor='#f5222d'
                              style={{ fontSize: '22px' }}
                              className="mr-2"
                            />
                            <div className="align-items-center">
                              <h6 className="mb-1 font-grey">{item.type}</h6>
                            </div>
                          </Col>
                        )
                      }
                      {statusClaim && (
                        statusClaim.name === 'approved'
                        || statusClaim.name === 'administration'
                        || statusClaim.name === 'fund transferred'
                        || statusClaim.name === 'rejected')
                        && (
                        <Col md="4" className="align-items-center d-flex">
                          <Icon
                            type={item.status === 'approved' ? 'check-circle' : 'close-circle'}
                            theme="twoTone"
                            twoToneColor={item.status === 'approved' ? '#52c41a' : '#f5222d'}
                            style={{ fontSize: '22px' }}
                            className="mr-2"
                          />
                          <div className="align-items-center">
                            <h6 className="mb-1 font-grey">{item.type}</h6>
                          </div>
                        </Col>
                        )
                      }
                      <Col md="8" className="mb-3">
                        <h6 className="mb-0 align-items-center d-flex">
                          <span className="mr-3 d-none d-md-inline-block">:</span>
                          <Icon type="file-image" className="mr-2" />
                          Terkirim
                          <Row>
                            <Col md="4">
                              <Button
                                type="link"
                                style={{ cursor: 'pointer', color: '#69c0ff' }}
                                className="ml-3"
                                onClick={() => toggleImageModal(item.url || '')
                                }
                              >
                                Lihat File
                              </Button>
                              {item.url &&
                                <Button
                                  type="link"
                                  style={{ cursor: 'pointer', color: '#69c0ff' }}
                                  className="ml-3"
                                  onClick={() => handleDownload(item.url)
                                  }
                                >
                                  Download
                                </Button>
                              }
                              {/*<a href={item.url} target="_blank" download={item.url ? item.url.slice(item.url.lastIndexOf('/')+ 1) : 'file'}>Download</a>*/}
                            </Col>
                            {statusClaim && statusClaim.name === 'document review' ?
                              <Col md="8" className="align-self-center">
                                <Input.TextArea
                                  id={item.id}
                                  className="w-100"
                                  autoSize={{ minRows: 2, maxRows: 2 }}
                                  onChange={(e) => handleChangeNote(e)}
                                  disabled={checkStatus(item.id)}
                                />
                              </Col>
                            :
                              <Col md="8" className="align-self-center">
                                <Input.TextArea
                                  className="w-100"
                                  autoSize={{ minRows: 2, maxRows: 2 }}
                                  value={item.reason || ''}
                                  disabled
                                />
                              </Col>
                            }
                          </Row>
                        </h6>
                      </Col>
                    </Row>
                  ))}
                  {/* <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Alamat Sesuai Identitas Diri</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0"><span className="mr-3 d-none d-md-inline-block">:</span>Jl. Wolter Monginsidi, no.1, Kebayoran Baru, Jakarta Selatan</h6>
                    </Col> */}
                  <Row>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Nomor Ponsel</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {detailClaim.data.phone_number || '-'}
                      </h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Alamat Email</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {detailClaim.data.email || '-'}
                      </h6>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>

          <Row className="pb-4">
            <Col md="12">
              <div className="custom-card-wrapper">
                <Card bordered={false}>
                  <Row>
                    <Col md="12" className="pb-3">
                      <h6 className="font-weight-bold">Tanda Tangan</h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Tempat</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        {detailClaim.data.signature_place || '-'}
                      </h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Tanggal</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        { detailClaim.data.signature_date || '-'}
                      </h6>
                    </Col>
                    <Col md="4" className="mb-1">
                      <h6 className="mb-0 font-grey">Digital Signature</h6>
                    </Col>
                    <Col md="8" className="mb-3">
                      <h6 className="mb-0 align-items-center d-flex">
                        <span className="mr-3 d-none d-md-inline-block">:</span>
                        <Icon type="file-image" className="mr-2" />
                        Terkirim
                        <Button
                          type="link"
                          style={{ cursor: 'pointer', color: '#69c0ff' }}
                          className="ml-3"
                          onClick={() => toggleImageModal(detailClaim.data.signature_image || '')
                          }
                        >
                          Lihat File
                        </Button>
                        {detailClaim.data.signature_image &&
                          <a href={detailClaim.data.signature_image} target="_blank" download={detailClaim.data.signature_image ? detailClaim.data.signature_image.slice(detailClaim.data.signature_image.lastIndexOf('/')+ 1) : 'file'}>Download</a>
                        }
                      </h6>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <div className="float-right btn-primary-wrapper">
                <Button onClick={() => history.push('/claim/claim-normal')} className="ml-2">Cancel</Button>
                {(statusClaim && (statusClaim.name !== "rejected" && statusClaim.name !== "fund transferred" && statusClaim.name !== "full rejection")) &&

                  <Button
                    type="danger"
                    className="ml-2"
                    loading={isSubmit}
                    onClick={() => toggleRejectModal()}
                  >Tolak klaim</Button>
                }
                {(statusClaim && statusClaim.name === 'document review')
                  && <Button type="primary" loading={isSubmit} className="ml-2" onClick={onSubmitRequest}>Validate Document</Button>
                }

                {(statusClaim && statusClaim.name === 'approved')
                  && <Button type="primary" loading={isSubmit} className="ml-2" onClick={() => handleStatusClaim('administration')}>Process Administration</Button>
                }

                {(statusClaim && statusClaim.name === 'administration')
                  && <Button type="primary" loading={isSubmit} className="ml-2" onClick={() => handleStatusClaim('fund transferred')}>Process Transfer</Button>
                }
                <Button type="primary" onClick={() => handleDownloadClaimDoc(detailClaim.data.name.replace('','_') || '')} loading={isExport} className="ml-2">Download Claim Document</Button>
              </div>
            </Col>
          </Row>
        </React.Fragment>
        )
      }
      {/*modal preview document*/}
      <Modal
        title="Preview Document"
        centered
        visible={imageModal}
        footer={null}
        onCancel={() => toggleImageModal('')}
        width={900}
      >
        {imgUrl === ''
          ? (
            <div className="text-center">
              <Spin />
            </div>
          )
          : (
            <SimpleReactLightbox>
              <SRLWrapper options={lightBoxSetting}>
                <div>
                  <img src={imgUrl} className="img-fluid" alt="detail-img" />
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
          )}
      </Modal>
      {/*modal full reject*/}
      <Modal
        title="Reject Reason"
        centered
        visible={rejectModal}
        footer={null}
        onCancel={() => toggleRejectModal()}
      >
        <Row>
          <Col md="12">
            <Input.TextArea rows={8} onChange={handleChangeRejectNotes} />
          </Col>
          <Col md="12">
            <div className="pt-3">
              <Button type="danger" className="float-right" loading={isSubmit} onClick={handleFullReject}>Reject Claim</Button>
            </div>
          </Col>
        </Row>
      </Modal>
    {/*modal upload bukti transfer*/}
      <Modal
          title="Upload Bukti Transfer"
          centered
          visible={uploadModal}
          footer={null}
          onCancel={() => toggleUploadModal()}
        >
          <Row>
            <Col md="12" className="mb-5">
              <h6>Upload new file:</h6>
              <Upload
                name="document"
                listType="document"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={e => handleChangeFile(e)}
                fileList={previewFile}
                accept="image/*,.pdf"
              >
                <Button>
                  <Icon type="upload" />
                  {' '}
                  Attach file
                </Button>
              </Upload>
            </Col>
            {detailClaim.data.payment_document &&
              <Col md="6">
                {detailClaim.data.payment_document.slice(detailClaim.data.payment_document.lastIndexOf('.')+ 1) === "pdf" ?
                  <div>
                    <h6>Current file:</h6>
                    <Button
                      type="link"
                      style={{
                        cursor: 'pointer',
                        color: '#69c0ff',
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                      }}
                      className="mx-0 px-0"
                      onClick={() => handleDownload(detailClaim.data.payment_document)
                      }
                    >
                      {detailClaim.data.payment_document.slice(detailClaim.data.payment_document.lastIndexOf('/')+ 1)}
                    </Button>
                  </div>
                :
                  <div>
                    <h6>Current file:</h6>
                    <a href={detailClaim.data.payment_document} target="_blank" download={detailClaim.data.payment_document ? detailClaim.data.payment_document.slice(detailClaim.data.payment_document.lastIndexOf('/')+ 1) : 'file'}>
                      <img className="img-fluid" src={detailClaim.data.payment_document} style={{width: '150px'}} alt="bukti transfer"/>
                    </a>
                  </div>
                }
              </Col>
            }
            {previewFile &&
              <Col md="6">
                {fileName.slice(fileName.lastIndexOf('.')+ 1) === "pdf" ?
                  <div>
                    <h6>New file:</h6>
                    <p>{fileName}</p>
                  </div>
                :
                  <div>
                    <h6>New file:</h6>
                    <img className="img-fluid" src={previewFile} style={{width: '150px'}} alt="bukti transfer"/>
                  </div>
                }
              </Col>
            }
            <Divider/>
            <Col md="12">
              <div className="float-right">
                <Button
                  type="primary"
                  onClick={handleUpload}
                  loading={isUploading}
                  disabled={previewFile ? false : true}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
    </div>
  )
}

ClaimNormalDetail.propTypes = {
  detailClaim: PropTypes.object,
  imageModal: PropTypes.bool,
  toggleImageModal: PropTypes.func,
  imgUrl: PropTypes.string,
  onChangeDoc: PropTypes.func,
  isFetchingData: PropTypes.bool,
  onSubmitRequest: PropTypes.func,
  handleStatusClaim: PropTypes.func,
  // documents: PropTypes.array,
  isSubmit: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  // handleScrollToTop: PropTypes.func,
  handleDownload: PropTypes.func,
  handleChangeNote: PropTypes.func,
  reason: PropTypes.object,
  handleFullReject: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  toggleRejectModal: PropTypes.func,
  rejectModal: PropTypes.bool,
  toggleUploadModal: PropTypes.func,
  uploadModal: PropTypes.bool,
  handleUpload: PropTypes.func,
  previewFile: PropTypes.any,
  isUploading: PropTypes.bool,
  handleChangeFile: PropTypes.func,
  fileName: PropTypes.string,
  checkStatus: PropTypes.func,
  handleDownloadClaimDoc: PropTypes.func,
  isExport: PropTypes.bool,
}

export default ClaimNormalDetail
