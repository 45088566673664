import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle } from 'recompose'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import CalculatorManagementView from 'components/pages/calculatorManagement'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Calculator management', 'Asumsi nilai suku bunga'])
      this.props.updateSiteConfiguration('titlePage', 'Asumsi Nilai Suku Bunga')
    },
  }),
)(CalculatorManagementView)