import { message } from 'antd'

export default class Helper {
  static getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  static beforeUpload(file) {
    const isFileType = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isFileType) {
      message.error('You can only upload JPG/PNG file!')
    }

    return false
  }

  static convertPrice(val) {
    if (val) {
      const convertInt = Math.ceil(parseFloat(val))
      const numberString = convertInt.toString()
      const sisa = numberString.length % 3
      let rupiah = numberString.substr(0, sisa)
      const ribuan = numberString.substr(sisa).match(/\d{3}/g)

      if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }
      return `Rp ${rupiah}`
    }
    return '0'
  }

  static currency(val) {
    if (val > 999 && val != 0) {
      return `${val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    }
    return `${val}`
  }

  static getPermissionId(data) {
    const newArr = data.map((item) => {
      if (item.id) {
        return (item.id)
      }
    })
    return newArr
  }

  static getPermissionName(data) {
    const newArr = data.map((item) => {
      if (item.name) {
        return (item.name)
      }
    })
    return newArr
  }
}
