import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button, Input,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormBannerApps = ({
  detail, onSubmit,
  form, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              onSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: detail.data.title,
            rules: [{ required: true, message: 'Title wajib diisi' }],
          })(
            <Input autoComplete="off" placeholder="Title" />,
          )}
        </Form.Item>
        <Form.Item label="Subtitle">
          {getFieldDecorator('subtitle', {
            initialValue: detail.data.subtitle,
            rules: [{ required: true, message: 'Subtitle wajib diisi' }],
          })(
            <Input autoComplete="off" placeholder="Subtitle" />,
          )}
        </Form.Item>
        <Form.Item label="Play Store URL">
          {getFieldDecorator('google_play_url', {
            initialValue: detail.data.google_play_url,
            rules: [
              { required: true, message: 'Play Store URL wajib diisi' },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                message: 'Masukan URL dengan format yang benar',
              },
            ],
          })(
            <Input autoComplete="off" placeholder="play.google.com/store/apps/details" />,
          )}
        </Form.Item>
        <Form.Item label="App Store URL">
          {getFieldDecorator('app_store_url', {
            initialValue: detail.data.app_store_url,
            rules: [
              { required: true, message: 'App Store URL wajib diisi' },
              {
                // eslint-disable-next-line no-useless-escape
                pattern: /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                message: 'Masukan URL dengan format yang benar',
              },
            ],
          })(
            <Input autoComplete="off" placeholder="apps.apple.com/id/app/" />,
          )}
        </Form.Item>

        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/web-management/banner-apps')} className="mr-2" style={{ display: 'inline-block' }}>Kembali</Button>
          <Button type="primary" loading={isSubmit} htmlType="submit">Simpan Perubahan</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormBannerApps.propTypes = {
  detail: PropTypes.object,
  onSubmit: PropTypes.func,
  form: PropTypes.any,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'bannerAppsForm' })(FormBannerApps)
