import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Row, Radio,
  Col, Select,
} from 'antd'
import history from 'utils/history'
import { data } from 'jquery'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },

}
const FormAdmin = ({
  handleSubmit, isEdit, isSubmit,
  detail, roleList, form,
}) => {
  // const [form] = Form.useForm()
  const { getFieldDecorator, validateFields } = form

  const emailRules = [{
    required: true,
    type: 'email',
  }]
  return (
    <div>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Card className="pt-4">
          <Row gutter={24} className="mb-5">
            <Col span={15}>
              <Form.Item label="Name">
                {getFieldDecorator('name', {
                  initialValue: detail.data ? detail.data.name : '',
                  rules: [{ required: true }],
                })(
                  <Input placeholder="Input Name" />,
                )}
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item label="Email">
                {getFieldDecorator('email', {
                  initialValue: detail.data ? detail.data.email : '',
                  rules: [
                    {
                      required: true,
                      type: 'email',
                    },
                  ],
                })(
                  <Input placeholder="Email" />,
                )}
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item label="Role">
                {getFieldDecorator('role', {
                  initialValue: detail.data.role ? detail.data.role.id : undefined,
                  rules: [{ required: true }],
                })(
                  <Select
                    placeholder="Role"
                  >
                    {(roleList.data || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={15}>
              <Form.Item label="Status">
                {getFieldDecorator('active', {
                  // initialValue: true,
                  initialValue: detail.data.status == 'active',
                  rules: [{ required: true }],
                })(
                  <Radio.Group>
                    <Radio value>Active</Radio>
                    <Radio value={false}>Inactive</Radio>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24} className="mb-5">
            <Col span={15}>
              <div className="d-flex justify-content-end btn-primary-wrapper">
                <Button onClick={() => history.goBack()} className="mr-2" style={{ display: 'inline-block' }} disabled={isSubmit}>Cancel</Button>
                <Button type="primary" loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Admin' : 'Submit Change'}</Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  )
}

FormAdmin.propTypes = {
  handleSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  detail: PropTypes.object,
  roleList: PropTypes.object,
  form: PropTypes.any,
}

export default Form.create({ name: 'adminForm' })(FormAdmin)
