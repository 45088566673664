import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import FaqList from 'containers/pages/faqs/List'
import FaqForm from 'containers/pages/faqs/Form'

const FAQ = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/faqs') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-csof.a.q-read') > -1) {
      return <FaqList location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-csof.a.q-write') > -1) {
      return <FaqForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

FAQ.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default FAQ
