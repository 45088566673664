import {
  QUIZ_ANSWER_REQUEST,
  QUIZ_ANSWER_SUCCESS,
  QUIZ_ANSWER_FAILURE,
  QUIZ_ANSWER_UPDATED,
  QUIZ_ANSWER_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataAnswer: [],
  detailAnswer: {},
  metaAnswer: {
    total_count: 0,
    current: 0,
  },
}

export default function quizAnswer(state = initialState, action) {
  switch (action.type) {
    case QUIZ_ANSWER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case QUIZ_ANSWER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataAnswer: action.data,
        metaAnswer: action.meta,
      }
    case QUIZ_ANSWER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case QUIZ_ANSWER_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataAnswer: state.dataAnswer.filter(item => item.id !== action.data.id),
      }
    case QUIZ_ANSWER_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailAnswer: action.data,
      }
    default:
      return state
  }
}
