import {
  CUSTOMER_REQUEST,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILURE,
  CUSTOMER_UPDATED,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_PROFILE_SUCCESS,
  // CUSTOMER_PROFILE_UPDATED,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataCustomer: [],
  detailCustomer: {},
  metaCustomer: {
    total_count: 0,
    current: 0,
  },
  dataListProfile: [],
  metaListProfile: {
    total_count: 0,
    current: 0,
  },
}

export default function customer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case CUSTOMER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataCustomer: action.data,
        metaCustomer: action.meta,
      }
    case CUSTOMER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case CUSTOMER_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataCustomer: state.dataCustomer.filter(item => item.id !== action.data.id),
      }
    case CUSTOMER_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailCustomer: action.data,
      }
    case CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataListProfile: action.data,
        metaListProfile: action.meta,
      }
    /* case CUSTOMER_PROFILE_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataCustomer: state.dataCustomer.filter(item => item.id !== action.data.id),
      } */
    default:
      return state
  }
}
