import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchPdp, updatePdp } from 'actions/Pdp'
import { message } from 'antd'
import FormPdp from 'components/pages/pdp/Form'

import history from 'utils/history'
import withForms from 'utils/hocs/withForms'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchPdp: bindActionCreators(fetchPdp, dispatch),
  updatePdp: bindActionCreators(updatePdp, dispatch),
})


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withForms(),
  withState('longDesc', 'setLongDesc', ''),
  withState('isSubmit', 'setIsSubmit', false),
  withState('data', 'setData', {}),
  withHandlers({
    handleChangeEditor: props => (values) => {
      props.setLongDesc(values)
    },
    onSubmit: props => (event) => {
      event.preventDefault()
      props.setIsSubmit(true)
      const payload = {
        ...props.form,
        is_active: props.status === 'active',
        content: props.longDesc ? props.longDesc : props.form.content,
      }
      props.updatePdp(payload, props.data.id)
        .then(() => {
          history.push('/pdp')
        }).catch((err) => {
          message.error(err)
          props.setIsSubmit(false)
        })
      /* if (props.form.title && props.longDesc) {

      } else {
        message.error('Please complete the form')
        props.setIsSubmit(false)
      } */
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'PDP', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'PDP Form')

      this.props.fetchPdp()
        .then((result) => {
          this.props.setData(result)
          this.props.setForm({
            title: result.title,
            content: result.content,
          })
        })
        .catch((error) => {
          message.error(error.message)
        })
    },
  }),
)(FormPdp)
