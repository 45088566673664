import {
  DEPOSIT_GUIDE_REQUEST,
  DEPOSIT_GUIDE_SUCCESS,
  DEPOSIT_GUIDE_FAILURE,
  DEPOSIT_GUIDE_UPDATED,
  DEPOSIT_GUIDE_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataDeposit: [],
  detailDeposit: {},
  metaDeposit: {
    total_count: 0,
    current: 0,
  },
}

export default function deposit(state = initialState, action) {
  switch (action.type) {
    case DEPOSIT_GUIDE_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case DEPOSIT_GUIDE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataDeposit: action.data,
        metaDeposit: action.meta,
      }
    case DEPOSIT_GUIDE_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case DEPOSIT_GUIDE_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataDeposit: state.dataDeposit.filter(item => item.id !== action.data.id),
      }
    case DEPOSIT_GUIDE_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailDeposit: action.data,
      }
    default:
      return state
  }
}
