import PropTypes from 'prop-types'
import {
  Table, Tag, Input,
} from 'antd'
import moment from 'moment'
import Helper from 'utils/Helper'

// const TitleCard = ({ handleSearch }) => (
//   <div className="float-left">
//     <div>
//       <Input.Search
//         allowClear
//         placeholder="Search..."
//         onSearch={handleSearch}
//       />
//     </div>
//   </div>
// )

const PaymentPage = ({
  isFetching, handlePage,
  metaTransaction, dataTransaction,
  handleSearch,
  /* handleDelete, */
}) => {
  const columns = [
    {
      title: 'Register Number',
      dataIndex: 'account_id',
      key: 'account_id',
    },
    {
      title: 'App',
      dataIndex: 'app',
      key: 'app',
      render: text => text.name || '-',
    },
    {
      title: 'Transaction Number',
      dataIndex: 'transaction_number',
      key: 'transaction_number',
      render: text => text || '-',
    },
    {
      title: 'Gross amount',
      dataIndex: 'gross_amount',
      key: 'gross_amount',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Payment Response',
      dataIndex: 'payment_response',
      key: 'payment_response',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Payment Deadline',
      dataIndex: 'payment_deadline',
      key: 'payment_deadline',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <Tag>{text || '-'}</Tag>,
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex flex-sm-row flex-column align-items-center btn-primary-wrapper">
          <div className="custom-input-txt pb-3 pb-md-0">
            <Input.Search
              allowClear
              placeholder="Search"
              onSearch={handleSearch}
              enterButton
            />
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataTransaction}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaTransaction ? metaTransaction.total_count : dataTransaction.length,
          current: metaTransaction ? metaTransaction.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

PaymentPage.propTypes = {
  isFetching: PropTypes.bool,
  dataTransaction: PropTypes.array,
  metaTransaction: PropTypes.object,
  handlePage: PropTypes.func,
  // handleDelete: PropTypes.func,
  handleSearch: PropTypes.func,
}

export default PaymentPage
