import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Layout, Breadcrumb, Button, Icon, Menu, Avatar,
  Dropdown,
} from 'antd'
import { Sidebar } from '../elements'

const { Header, Content } = Layout

const PrivateLayout = ({
  children, doLogout, collapse, handleCollapse,
  handleKeys, site, currentUser,
}) => {
  if (currentUser === undefined) {
    window.location.href = '/'
  }
  useEffect(() => {
    const pathname = window.location.href
    const newSplit = pathname?.split('/')
    const title = newSplit[newSplit?.length - 1]
    document.title = `${title?.charAt(0).toUpperCase()}${title?.slice(1)} - SIMPAN by DPLK Indolife` || 'SIMPAN by DPLK Indolife'
  }, [])
  return (
    <div className="app main">
      <div id="yield" className="app-main-body">
        <Layout className="h-100">
          <Sidebar
            collapse={collapse}
            site={site}
            handleKeys={(param, isOpenSub) => handleKeys(param, isOpenSub)}
          />
          <Layout className="main-content h-100">
            <Header className="header d-flex align-items-center justify-content-between px-4" style={{ boxShadow: '-1px 1px 12px rgba(0, 34, 108, 0.15)' }}>
              <div className="d-flex align-items-center">
                <Icon
                  className="trigger"
                  type={collapse ? 'menu-unfold' : 'menu-fold'}
                  onClick={handleCollapse}
                />
                <h6 className="mb-0 ml-3 d-none d-md-block" style={{ color: '#1B4DB3' }}>SIMPAN Indolife Admin Dashboard</h6>
              </div>
              <Dropdown
                overlay={(
                  <Menu onClick={doLogout}>
                    <Menu.Item key="0">
                      <Button type="link">
                        <Icon type="logout" />
                        {' '}
                        Logout
                      </Button>
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
              >
                <div className="d-flex align-self-center cursor-pointer align-items-center">
                  <Avatar shape="square" className="avatar-nav nav-text mr-2" size={34}>Admin</Avatar>
                  <div className="f-flex flex-column d-none d-md-block">
                    <p style={{ lineHeight: '18px', fontSize: '14px' }} className="admin-name mb-0">{currentUser && currentUser.name ? currentUser.name.split(' ').slice(0, -1) : 'Admin'}</p>
                    <p style={{ lineHeight: '18px', fontSize: '14px' }} className="mb-0">{currentUser && currentUser.email ? currentUser.email : '-'}</p>
                  </div>
                </div>
              </Dropdown>

            </Header>
            <div className="d-block d-md-none" style={{ paddingLeft: '24px' }}>
              {window.location.pathname === '/dashboard' ? (
                <p className="mb-0" style={{ margin: '16px 0' }}>{moment().format('DD MMMM YYYY')}</p>
              ) : (
                <Breadcrumb style={{ margin: '16px 0' }}>
                  {site.breadList.map(item => (
                    <Breadcrumb.Item key={Math.random()}>{item}</Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center pb-3 pt-2" style={{ padding: '0 24px 24px' }}>
              <h3 className="title-content mb-0">{site.titlePage || ''}</h3>
              {window.location.pathname === '/dashboard' ? (
                <p className="mb-0" style={{ margin: '16px 0' }}>{moment().format('DD MMMM YYYY')}</p>
              ) : (
                <Breadcrumb style={{ margin: '16px 0' }} className="d-none d-md-block">
                  {site.breadList.map(item => (
                    <Breadcrumb.Item key={Math.random()}>{item}</Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
            </div>
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                padding: '0 24px 24px',
              }}
            >
              {children}
            </Content>
          </Layout>
        </Layout>
      </div>
    </div>
  )
}

PrivateLayout.propTypes = {
  children: PropTypes.node,
  doLogout: PropTypes.func,
  handleKeys: PropTypes.func,
  site: PropTypes.object,
  currentUser: PropTypes.any,
  collapse: PropTypes.bool,
  handleCollapse: PropTypes.func,
}

export default PrivateLayout
