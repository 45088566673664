import { connect } from 'react-redux'
import {
  compose, withHandlers, lifecycle, withState,
} from 'recompose'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { clearCurrentUser } from 'actions/Auth'
import { updateSiteConfiguration } from 'actions/Site'
import { first, last, isEmpty } from 'lodash'
import history from 'utils/history'
import PrivateLayoutView from 'components/layouts/PrivateLayout'

export function mapStateToProps(state) {
  const { site } = state.root
  const { currentUser } = state.root.auth
  return {
    site,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  clearCurrentUser: bindActionCreators(clearCurrentUser, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('collapse', 'setCollapse', false),
  withHandlers({
    handleCollapse: props => () => {
      props.setCollapse(!props.collapse)
    },
    doLogout: props => () => {
      props.clearCurrentUser()
    },
    handleKeys: props => (param, isOpenSub) => {
      if (isOpenSub) {
        props.updateSiteConfiguration('activeSubPage', last(param))
      } else {
        props.updateSiteConfiguration('activePage', first(param.keyPath))
        history.push(`/${first(param.keyPath)}`)
        if (param.keyPath.length > 1) {
          props.updateSiteConfiguration('activeSubPage', last(param.keyPath))
        } else {
          props.updateSiteConfiguration('activeSubPage', first(param.keyPath))
        }
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { currentUser } = this.props
      if (isEmpty(currentUser)) {
        this.props.doLogout()
      }
    },
  }),
)(PrivateLayoutView)
