import PropTypes from 'prop-types'
import {
  Table, Icon,
  Button, Popconfirm,
} from 'antd'
import { Row, Col } from 'react-bootstrap'
import history from 'utils/history'
import Helper from 'utils/Helper'
// import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

const UserPage = ({
  /* handlePage, handleSearch, isFetching, */
  denomList, handleDelete, currentUser,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Nominal',
      dataIndex: 'nominal',
      key: 'nominal',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => text || '-',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/dana-ottocash/management-ottocash/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-ottocashmanagement-dana-ottocash-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this list?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <Row className="btn-primary-wrapper">
          <Col xs={12}>
            <div className="float-right">
              <Button
                type="primary"
                onClick={() => history.push('/dana-ottocash/management-ottocash/add')}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        loading={denomList.isFetching}
        columns={columns}
        dataSource={denomList.data}
      />
    </div>
  )
}

UserPage.propTypes = {
  denomList: PropTypes.object,
  // isFetching: PropTypes.bool,
  // handlePage: PropTypes.func,
  // handleSearch: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default UserPage
