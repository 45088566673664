import {
  VOUCHER_REQUEST,
  VOUCHER_SUCCESS,
  VOUCHER_FAILURE,
  VOUCHER_UPDATED,
  VOUCHER_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataVoucher: [],
  detailVoucher: {},
  metaVoucher: {
    total_count: 0,
    current: 0,
  },
}

export default function voucher(state = initialState, action) {
  switch (action.type) {
    case VOUCHER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case VOUCHER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataVoucher: action.data,
        metaVoucher: action.meta,
      }
    case VOUCHER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case VOUCHER_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataVoucher: state.dataVoucher.filter(item => item.id !== action.data.id),
      }
    case VOUCHER_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailVoucher: action.data,
      }
    default:
      return state
  }
}
