import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import history from 'utils/history'

const Home = ({ currentUser }) => (
  <React.Fragment>
    <div className="d-flex justify-content-center h-100">
      <div className="align-self-center text-center">
        <h1
          className="display-3 font-weight-light mb-0"
          style={{
            letterSpacing: '4px',
            textTransform: 'capitalize',
          }}
        >
          Hello
          {' '}
          {currentUser && currentUser.name ? `${currentUser.name.toString().split(' ').slice(0, 1)}!` : 'Admin!'}
        </h1>
        <p className="font-weight-light">Welcome to DPLK Content Management System</p>
      </div>
    </div>
  </React.Fragment>
)

Home.propTypes = {
  currentUser: PropTypes.object,
}

export default Home
