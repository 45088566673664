import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import LogPaymentView from 'containers/pages/autoDebit/logPayment/List'

const AutoDebitLogPayment = ({
  currentUser, location,
}) => {
  if (location.pathname === '/auto-debet/log-payment') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-autodebit-logpayment-read') > -1) {
      return <LogPaymentView location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

AutoDebitLogPayment.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
}

export default AutoDebitLogPayment
