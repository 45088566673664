import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchInvestmentYearList,
  fetchInvestmentYearDownload,
  uploadInvestmentYear,
} from 'actions/CalculationDplk'
import InvestmentYearView from 'components/pages/calculationDplk/investmentYear/List'
import { message } from 'antd'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataInvestmentYear,
    metaInvestmentYear,
    fileInvestmentYear,
    metaFileInvestmentYear,
  } = state.root.calculationDplk
  const { currentUser } = state.root.auth

  return {
    currentUser,
    isFetching,
    dataInvestmentYear,
    metaInvestmentYear,
    fileInvestmentYear,
    metaFileInvestmentYear,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchInvestmentYearList: bindActionCreators(fetchInvestmentYearList, dispatch),
  fetchInvestmentYearDownload: bindActionCreators(fetchInvestmentYearDownload, dispatch),
  uploadInvestmentYear: bindActionCreators(uploadInvestmentYear, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('uploadModal', 'setUploadModal', false),
  withState('uploading', 'setUploading', false),
  withState('isAttached', 'setIsAttached', true),
  withState('previewFile', 'setPreviewFile', ''),
  withState('file', 'setFile', ''),
  withHandlers({
    handleSearch: () => () => {},
    toggleUploadModal: props => () => {
      props.setUploadModal(!props.uploadModal)
    },
    handleChange: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      props.setPreviewFile(fileList)
      if (fileList.length > 0) {
        props.setIsAttached(false)
      } else {
        props.setIsAttached(true)
      }
      props.setFile(info.file)
    },
    handleUpload: props => (values) => {
      const formData = new FormData()
      formData.append('file', values)
      props.uploadInvestmentYear(formData).then(() => {
        props.setUploadModal(!props.uploadModal)
        message.success('File has been uploaded').then(() => (
          props.setUploadModal(!props.uploadModal)
        ))
      }).catch((err) => {
        message.error(err)
        props.setUploadModal(!props.uploadModal)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Dplk-Calculation-Table', 'Investment-Year'])
      this.props.updateSiteConfiguration('titlePage', 'Investment Year')
      this.props.fetchInvestmentYearList()
      this.props.fetchInvestmentYearDownload()
    },
  }),
)(InvestmentYearView)
