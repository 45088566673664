import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import List from 'containers/pages/customers/updateProfile/List'

const UserPage = ({ location, match, currentUser }) => {
  if (location.pathname === '/customer/update-profile') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-customer-managementverify-update-profile-read') > -1) {
      return <List location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  return ''
}

UserPage.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default UserPage
