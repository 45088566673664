import PropTypes from 'prop-types'
import {
  Table, Select, Button,
  Input, DatePicker,
} from 'antd'
import Helper from 'utils/Helper'

const AutoDebitLogPaymentView = ({
  isFetching, dataAutoDebitLogPayment, metaAutoDebitLogPayment,
  statusList, statusFrekuensiList, isExport, handleSearch,
  handleChangeStatus, handleChangeStatusFrekuensi, handleChangeDate,
  handlePage, handleReport,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 90,
      render: (text, record, index) => (metaAutoDebitLogPayment.per_page * (metaAutoDebitLogPayment.current_page - 1)) + index + 1,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      render: text => text || '-',
    },
    {
      title: 'Member ID',
      dataIndex: 'registered_number',
      key: 'registered_number',
      width: 110,
      render: text => text || '-',
    },
    {
      title: 'Frekuensi Iuran',
      dataIndex: 'deposit_frequency',
      key: 'deposit_frequency',
      width: 140,
      render: text => text || '-',
    },
    {
      title: 'Nomor Rek',
      dataIndex: 'account_number',
      key: 'account_number',
      width: 130,
      render: text => text || '-',
    },
    {
      title: 'Nomor Transaksi',
      dataIndex: 'transaction_number',
      key: 'transaction_number',
      width: 230,
      render: text => text || '-',
    },
    {
      title: 'ID Referensi',
      dataIndex: 'reference_id',
      key: 'reference_id',
      width: 180,
      render: text => text || '-',
    },
    {
      title: 'Jumlah',
      dataIndex: 'debit_amount',
      key: 'debit_amount',
      width: 130,
      render: text => Helper.convertPrice(text) || '-',
    },
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 160,
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 110,
      render: (text) => {
        let status
        if (text === 'draft') {
          status = <span>{'Draft' || '-'}</span>
        } else if (text === 'confirmed') {
          status = <span>{'Confirmed' || '-'}</span>
        } else if (text === 'settled') {
          status = <span>{'Settled' || '-'}</span>
        } else if (text === 'in progress') {
          status = <span>{'In Progress' || '-'}</span>
        } else {
          status = <span>{'Failed' || '-'}</span>
        }
        return status
      },
    },
  ]

  return (
    <div>
      <div className="pb-3 d-flex justify-content-between">
        <div className="d-flex justify-content-start">
          <div className="mr-3 btn-primary-wrapper">
            <Input.Search
              allowClear
              placeholder="Search...."
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div className="mr-3">
            <Select
              placeholder="Status"
              style={{ width: 120 }}
              onChange={handleChangeStatus}
              allowClear
            >
              {statusList.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <Select
              placeholder="Frekuensi"
              style={{ width: 120 }}
              onChange={handleChangeStatusFrekuensi}
              allowClear
            >
              {statusFrekuensiList.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="mr-3">
            <DatePicker.RangePicker
              format="YYYY-MM-DD"
              placeholder={['Start Date', 'End Date']}
              onChange={handleChangeDate}
            />
          </div>
        </div>
        <div className="btn-primary-wrapper">
          <Button
            loading={isExport}
            type="primary"
            className="align-items-center"
            onClick={handleReport}
          >
            Export
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        loading={isFetching}
        columns={columns}
        scroll={{ x: 1200 }}
        dataSource={dataAutoDebitLogPayment}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: metaAutoDebitLogPayment ? metaAutoDebitLogPayment.per_page : 10,
          total: metaAutoDebitLogPayment ? metaAutoDebitLogPayment.total_count : dataAutoDebitLogPayment.length,
          current: metaAutoDebitLogPayment ? metaAutoDebitLogPayment.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

AutoDebitLogPaymentView.propTypes = {
  isFetching: PropTypes.bool,
  dataAutoDebitLogPayment: PropTypes.array,
  metaAutoDebitLogPayment: PropTypes.object,
  statusList: PropTypes.array,
  statusFrekuensiList: PropTypes.array,
  isExport: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleChangeStatusFrekuensi: PropTypes.func,
  handleChangeDate: PropTypes.func,
  handlePage: PropTypes.func,
  handleReport: PropTypes.func,
}

export default AutoDebitLogPaymentView
