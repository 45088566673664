import PropTypes from 'prop-types'
import {
  Table, Icon, Avatar, Button, Popconfirm, Tag,
} from 'antd'
import history from 'utils/history'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

const Banners = ({
  bannerList, handleDelete, currentUser,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, row, idx) => (idx + 1),
      width: 100,
    },
    {
      title: 'Desktop Image',
      dataIndex: 'desktop_image',
      key: 'desktop_image',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={150} src={image} alt="desktop image" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={150} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Mobile Image',
      dataIndex: 'mobile_image',
      key: 'mobile_image',
      render: image => (image ? (
        <SimpleReactLightbox>
          <SRLWrapper options={lightBoxSetting}>
            <Avatar shape="square" size={150} src={image} alt="mobile image" style={{ cursor: 'pointer' }} />
          </SRLWrapper>
        </SimpleReactLightbox>
      ) : (
        <Avatar shape="square" size={150} src="/assets/default.jpg" alt="no img" />
      )),
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (text) => {
        let tags
        if (text === true) {
          tags = <Tag color="green">true</Tag>
        } else {
          tags = <Tag color="red">false</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 100,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementbanners-write') > -1) && (
            <Icon type="edit" className="mr-3" style={{ color: '#1b4db3' }} onClick={() => history.push(`/web-management/banners/${record.id}/edit`)} />
          )}
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementbanners-delete') > -1) && (
            <Popconfirm
              title="Apakah anda yakin ingin menghapus konten ini?"
              okText="Ya"
              cancelText="Tidak"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon style={{ color: '#ff5159' }} type="delete" />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center pb-3">
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/web-management/banners/add')}
          >
            Add Banner
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={bannerList.data}
        loading={bannerList.isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

Banners.propTypes = {
  bannerList: PropTypes.object,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default Banners
