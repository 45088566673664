import PropTypes from 'prop-types'
import {
  Table, Select, Tag,
  Icon,
} from 'antd'
import history from 'utils/history'

const PaymentChannelList = ({
  paymentConfigurationData, currentUser,
  statusList, handleChangeStatus,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: 'No',
      key: 'index',
      width: 60,
      render: (text, record, index) => (paymentConfigurationData.meta.per_page * (paymentConfigurationData.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Nama Metode Pembayaran',
      dataIndex: 'name',
      key: 'name',
      render: text => text || '-',
    },
    {
      title: 'Kategori Pembayaran',
      dataIndex: 'slug',
      key: 'slug',
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: text => <Tag color={text === true ? 'green' : 'red'} className="text-capitalize">{text === true ? 'Aktif' : 'Tidak Aktif'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <div className="d-flex justify-content-start ml-2">
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-payment-channel-write') > -1) && (
            <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`payment-configuration/${record.id}/edit`)} />
          )}
        </div>
      ),
    },
  ]
  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center">
          <div className="mr-3">
            <Select
              placeholder="Status"
              style={{ width: 180 }}
              onChange={handleChangeStatus}
              className="mr-2"
              allowClear
            >
              {statusList.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
            </Select>
          </div>
        </div>
      </div>
      <div className="pb-4">
        <Table
          rowKey="id"
          className="table-striped-rows"
          scroll={{ x: 1000 }}
          dataSource={paymentConfigurationData.data}
          loading={paymentConfigurationData.isFetching}
          columns={columns}
          pagination={false}
        />
      </div>
    </div>
  )
}

PaymentChannelList.propTypes = {
  paymentConfigurationData: PropTypes.object,
  statusList: PropTypes.array,
  handleChangeStatus: PropTypes.func,
  currentUser: PropTypes.object,
}

export default PaymentChannelList
