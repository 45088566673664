export const SET_SITE_THEME = 'SET_SITE_THEME'
export const SET_SITE_CONFIGURATION = 'SET_SITE_CONFIGURATION'
export const SET_SITE_CONFIGS = 'SET_SITE_CONFIGS'

export const AUTHENTICATE_USER_REQUEST = 'AUTHENTICATE_USER_REQUEST'
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS'
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE'
export const UPDATE_AUTH_CURRENT_USER = 'UPDATE_AUTH_CURRENT_USER'

// LEARNING CONTENT
export const LEARNING_CONTENT_REQUEST = 'LEARNING_CONTENT_REQUEST'
export const LEARNING_CONTENT_SUCCESS = 'LEARNING_CONTENT_SUCCESS'
export const LEARNING_CONTENT_FAILURE = 'LEARNING_CONTENT_FAILURE'
export const LEARNING_CONTENT_UPDATED = 'LEARNING_CONTENT_UPDATED'
export const LEARNING_CONTENT_DETAIL_SUCCESS = 'LEARNING_CONTENT_DETAIL_SUCCESS'

// QUIZ QUESTION
export const QUIZ_QUESTION_REQUEST = 'QUIZ_QUESTION_REQUEST'
export const QUIZ_QUESTION_SUCCESS = 'QUIZ_QUESTION_SUCCESS'
export const QUIZ_QUESTION_FAILURE = 'QUIZ_QUESTION_FAILURE'
export const QUIZ_QUESTION_UPDATED = 'QUIZ_QUESTION_UPDATED'
export const QUIZ_QUESTION_DETAIL_SUCCESS = 'QUIZ_QUESTION_DETAIL_SUCCESS'

// QUIZ ANSWER
export const QUIZ_ANSWER_REQUEST = 'QUIZ_ANSWER_REQUEST'
export const QUIZ_ANSWER_SUCCESS = 'QUIZ_ANSWER_SUCCESS'
export const QUIZ_ANSWER_FAILURE = 'QUIZ_ANSWER_FAILURE'
export const QUIZ_ANSWER_UPDATED = 'QUIZ_ANSWER_UPDATED'
export const QUIZ_ANSWER_DETAIL_SUCCESS = 'QUIZ_ANSWER_DETAIL_SUCCESS'

// NOTIFICATION ANNOUNCEMENT
export const NOTIFICATION_REQUEST = 'NOTIFICATION_REQUEST'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const NOTIFICATION_FAILURE = 'NOTIFICATION_FAILURE'
export const NOTIFICATION_UPDATED = 'NOTIFICATION_UPDATED'
export const NOTIFICATION_DETAIL_SUCCESS = 'NOTIFICATION_DETAIL_SUCCESS'

// USER
export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'
export const USER_UPDATED = 'USER_UPDATED'
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS'

// BENEFIT
export const BENEFIT_REQUEST = 'BENEFIT_REQUEST'
export const BENEFIT_SUCCESS = 'BENEFIT_SUCCESS'
export const BENEFIT_FAILURE = 'BENEFIT_FAILURE'
export const BENEFIT_UPDATED = 'BENEFIT_UPDATED'
export const BENEFIT_DETAIL_SUCCESS = 'BENEFIT_DETAIL_SUCCESS'
export const CLAIM_TYPE_SUCCESS = 'CLAIM_TYPE_SUCCESS'

// ABOUT
export const ABOUT_REQUEST = 'ABOUT_REQUEST'
export const ABOUT_SUCCESS = 'ABOUT_SUCCESS'
export const ABOUT_FAILURE = 'ABOUT_FAILURE'
export const ABOUT_UPDATED = 'ABOUT_UPDATED'
export const ABOUT_DETAIL_SUCCESS = 'ABOUT_DETAIL_SUCCESS'

// VOUCHER
export const VOUCHER_REQUEST = 'VOUCHER_REQUEST'
export const VOUCHER_SUCCESS = 'VOUCHER_SUCCESS'
export const VOUCHER_FAILURE = 'VOUCHER_FAILURE'
export const VOUCHER_UPDATED = 'VOUCHER_UPDATED'
export const VOUCHER_DETAIL_SUCCESS = 'VOUCHER_DETAIL_SUCCESS'

// DEPOSIT_GUIDE
export const DEPOSIT_GUIDE_REQUEST = 'DEPOSIT_GUIDE_REQUEST'
export const DEPOSIT_GUIDE_SUCCESS = 'DEPOSIT_GUIDE_SUCCESS'
export const DEPOSIT_GUIDE_FAILURE = 'DEPOSIT_GUIDE_FAILURE'
export const DEPOSIT_GUIDE_UPDATED = 'DEPOSIT_GUIDE_UPDATED'
export const DEPOSIT_GUIDE_DETAIL_SUCCESS = 'DEPOSIT_GUIDE_DETAIL_SUCCESS'

// CATEGORY CONTENT
export const CATEGORY_COURSE_REQUEST = 'CATEGORY_COURSE_REQUEST'
export const CATEGORY_COURSE_SUCCESS = 'CATEGORY_COURSE_SUCCESS'
export const CATEGORY_COURSE_FAILURE = 'CATEGORY_COURSE_FAILURE'
export const CATEGORY_COURSE_UPDATED = 'CATEGORY_COURSE_UPDATED'
export const CATEGORY_COURSE_DETAIL_SUCCESS = 'CATEGORY_COURSE_DETAIL_SUCCESS'

// FAQ
export const FAQ_REQUEST = 'FAQ_REQUEST'
export const FAQ_SUCCESS = 'FAQ_SUCCESS'
export const FAQ_FAILURE = 'FAQ_FAILURE'
export const FAQ_UPDATED = 'FAQ_UPDATED'
export const FAQ_DETAIL_SUCCESS = 'FAQ_DETAIL_SUCCESS'

// CUSTOMER
export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS'
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE'
export const CUSTOMER_UPDATED = 'CUSTOMER_UPDATED'
export const CUSTOMER_DETAIL_SUCCESS = 'CUSTOMER_DETAIL_SUCCESS'
export const CUSTOMER_PROFILE_SUCCESS = 'CUSTOMER_PROFILE_SUCCESS'
export const CUSTOMER_PROFILE_UPDATED = 'CUSTOMER_PROFILE_UPDATED'

// FAQ
export const PAKDE_REQUEST = 'PAKDE_REQUEST'
export const PAKDE_SUCCESS = 'PAKDE_SUCCESS'
export const PAKDE_FAILURE = 'PAKDE_FAILURE'
export const PAKDE_UPDATED = 'PAKDE_UPDATED'
export const PAKDE_DETAIL_SUCCESS = 'PAKDE_DETAIL_SUCCESS'
export const REMIND_FRIEND_SUCCESS = 'REMIND_FRIEND_SUCCESS'

// CALCULATOR MANAGEMENT
export const CALC_MANAGEMENT_REQUEST = 'CALC_MANAGEMENT_REQUEST'
export const CALC_MANAGEMENT_SUCCESS = 'CALC_MANAGEMENT_SUCCESS'
export const CALC_MANAGEMENT_FAILURE = 'CALC_MANAGEMENT_FAILURE'
export const CALC_MANAGEMENT_UPDATED = 'CALC_MANAGEMENT_UPDATED'
export const INVESTATION_LIST_SUCCESS = 'INVESTATION_LIST_SUCCESS'
export const CALC_MANAGEMENT_DETAIL_SUCCESS = 'CALC_MANAGEMENT_DETAIL_SUCCESS'

// DPLK CALCULATION TABLE
export const CALCULATION_DPLK_REQUEST = 'CALCULATION_DPLK_REQUEST'
export const CALCULATION_DPLK_SUCCESS = 'CALCULATION_DPLK_SUCCESS'
export const CALCULATION_DPLK_FAILURE = 'CALCULATION_DPLK_FAILURE'
export const CALCULATION_DPLK_UPDATED = 'CALCULATION_DPLK_UPDATED'
export const INVESTMENT_YEAR_SUCCESS = 'INVESTMENT_YEAR_SUCCESS'
export const INVEST_YEAR_DOWNLOAD_SUCCESS = 'INVEST_YEAR_DOWNLOAD_SUCCESS'
export const INVEST_PAST5YEAR_DOWNLOAD_SUCCESS = 'INVEST_PAST5YEAR_DOWNLOAD_SUCCESS'

// SETORAN
export const SETORAN_REQUEST = 'SETORAN_REQUEST'
export const SETORAN_SUCCESS = 'SETORAN_SUCCESS'
export const SETORAN_FAILURE = 'SETORAN_FAILURE'

// TRANSACTION
export const TRANSACTION_REQUEST = 'TRANSACTION_REQUEST'
export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS'
export const TRANSACTION_FAILURE = 'TRANSACTION_FAILURE'
export const TRANSACTION_UPDATED = 'TRANSACTION_UPDATED'
export const TRANSACTION_DETAIL_SUCCESS = 'TRANSACTION_DETAIL_SUCCESS'

// COMMISSION
export const COMMISSION_REQUEST = 'COMMISSION_REQUEST'
export const COMMISSION_SUCCESS = 'COMMISSION_SUCCESS'
export const COMMISSION_FAILURE = 'COMMISSION_FAILURE'

// ULTRA VOUCHER CATEGORY
export const ULTRA_VOUCHER_CATEGORY_REQUEST = 'ULTRA_VOUCHER_CATEGORY_REQUEST'
export const ULTRA_VOUCHER_CATEGORY_SUCCESS = 'ULTRA_VOUCHER_CATEGORY_SUCCESS'
export const ULTRA_VOUCHER_CATEGORY_FAILURE = 'ULTRA_VOUCHER_CATEGORY_FAILURE'
export const ULTRA_VOUCHER_CATEGORY_UPDATED = 'ULTRA_VOUCHER_CATEGORY_UPDATED'
export const ULTRA_VOUCHER_CATEGORY_DETAIL_SUCCESS = 'ULTRA_VOUCHER_CATEGORY_DETAIL_SUCCESS'

// ABOUT DPLK
export const ABOUT_DPLK_REQUEST = 'ABOUT_DPLK_REQUEST'
export const ABOUT_DPLK_SUCCESS = 'ABOUT_DPLK_SUCCESS'
export const ABOUT_DPLK_FAILURE = 'ABOUT_DPLK_FAILURE'
export const ABOUT_DPLK_UPDATED = 'ABOUT_DPLK_UPDATED'
export const ABOUT_DPLK_DETAIL_SUCCESS = 'ABOUT_DPLK_DETAIL_SUCCESS'

// ULTRA VOUCHER
export const ULTRA_VOUCHER_REQUEST = 'ULTRA_VOUCHER_REQUEST'
export const ULTRA_VOUCHER_SUCCESS = 'ULTRA_VOUCHER_SUCCESS'
export const ULTRA_VOUCHER_FAILURE = 'ULTRA_VOUCHER_FAILURE'

// ULTRA VOUCHER BRAND
export const ULTRA_VOUCHER_BRAND_REQUEST = 'ULTRA_VOUCHER_BRAND_REQUEST'
export const ULTRA_VOUCHER_BRAND_SUCCESS = 'ULTRA_VOUCHER_BRAND_SUCCESS'
export const ULTRA_VOUCHER_BRAND_FAILURE = 'ULTRA_VOUCHER_BRAND_FAILURE'

// COMMISSION CONFIG
export const COMMISSION_CONFIG_REQUEST = 'COMMISSION_CONFIG_REQUEST'
export const COMMISSION_CONFIG_SUCCESS = 'COMMISSION_CONFIG_SUCCESS'
export const COMMISSION_CONFIG_FAILURE = 'COMMISSION_CONFIG_FAILURE'

// OTTOCASH
export const OTTOCASH_REQUEST = 'OTTOCASH_REQUEST'
export const OTTOCASH_SUCCESS = 'OTTOCASH_SUCCESS'
export const OTTOCASH_FAILURE = 'OTTOCASH_FAILURE'
export const STATUS_OTTOCASH_REQUEST = 'STATUS_OTTOCASH_REQUEST'
export const STATUS_OTTOCASH_SUCCESS = 'STATUS_OTTOCASH_SUCCESS'
export const STATUS_OTTOCASH_FAILURE = 'STATUS_OTTOCASH_FAILURE'

// OTTOPAY
export const OTTOPAY_REQUEST = 'OTTOPAY_REQUEST'
export const OTTOPAY_SUCCESS = 'OTTOPAY_SUCCESS'
export const OTTOPAY_FAILURE = 'OTTOPAY_FAILURE'

export const OTTOPAY_SYNC_REQUEST = 'OTTOPAY_SYNC_REQUEST'
export const OTTOPAY_SYNC_SUCCESS = 'OTTOPAY_SYNC_SUCCESS'
export const OTTOPAY_SYNC_FAILURE = 'OTTOPAY_SYNC_FAILURE'

// AUTO DEBIT
export const AUTO_DEBIT_PENDAFTARAN_REQUEST = 'AUTO_DEBIT_PENDAFTARAN_REQUEST'
export const AUTO_DEBIT_PENDAFTARAN_SUCCESS = 'AUTO_DEBIT_PENDAFTARAN_SUCCESS'
export const AUTO_DEBIT_PENDAFTARAN_FAILURE = 'AUTO_DEBIT_PENDAFTARAN_FAILURE'

export const AUTO_DEBIT_PEMBATALAN_REQUEST = 'AUTO_DEBIT_PEMBATALAN_REQUEST'
export const AUTO_DEBIT_PEMBATALAN_SUCCESS = 'AUTO_DEBIT_PEMBATALAN_SUCCESS'
export const AUTO_DEBIT_PEMBATALAN_FAILURE = 'AUTO_DEBIT_PEMBATALAN_FAILURE'
export const AUTO_DEBIT_PEMBATALAN_DETAIL_SUCCESS = 'AUTO_DEBIT_PEMBATALAN_DETAIL_SUCCESS'
export const AUTO_DEBIT_PEMBATALAN_DETAIL_FAILURE = 'AUTO_DEBIT_PEMBATALAN_DETAIL_FAILURE'

export const AUTO_DEBIT_LOG_PAYMENT_REQUEST = 'AUTO_DEBIT_LOG_PAYMENT_REQUEST'
export const AUTO_DEBIT_LOG_PAYMENT_SUCCESS = 'AUTO_DEBIT_LOG_PAYMENT_SUCCESS'
export const AUTO_DEBIT_LOG_PAYMENT_FAILURE = 'AUTO_DEBIT_LOG_PAYMENT_FAILURE'

// UV LOGS
export const UVLOGS_REQUEST = 'UVLOGS_REQUEST'
export const UVLOGS_SUCCESS = 'UVLOGS_SUCCESS'
export const UVLOGS_FAILURE = 'UVLOGS_FAILURE'

// DASHBOARD
export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE'
