import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import CommissionPage from 'containers/pages/commission/List'
import CommissionDetail from 'containers/pages/commission/Detail'

const Commission = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/commission') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-commission-read') > -1) {
      return <CommissionPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-commission-read') > -1) {
      return <CommissionDetail location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Commission.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Commission
