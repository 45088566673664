import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Select, InputNumber,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormCalcManagement = ({
  form, handleSubmit, handleSelect,
  dataInvestationType, loadStatus,
  detailCalcManagement, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  const investType = detailCalcManagement && detailCalcManagement.investment_type ? detailCalcManagement.investment_type : ''
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Investment type">
          {getFieldDecorator('investment_type_id', {
            initialValue: investType && investType.investment_type_id ? investType.investment_type_id : '',
          })(
            <Select
              onChange={handleSelect}
              loading={loadStatus}
              placeholder="Select Payment"
              disabled
            >
              {(dataInvestationType || []).map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Asumsi nilai suku bunga">
          {getFieldDecorator('asumsi_nilai_suku_bunga', {
            initialValue: detailCalcManagement && detailCalcManagement.asumsi_nilai_suku_bunga ? detailCalcManagement.asumsi_nilai_suku_bunga : '',
            rules: [{ required: true }],
          })(
            <InputNumber
              min={0}
              max={100}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
            />,
          )}
        </Form.Item>

        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/calculator-management/asumsi-nilai-suku-bunga')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit" loading={isSubmit}>Submit change</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormCalcManagement.propTypes = {
  detailCalcManagement: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  handleSelect: PropTypes.func,
  loadStatus: PropTypes.bool,
  dataInvestationType: PropTypes.array,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'calcManagementForm' })(FormCalcManagement)
