import axios from 'axios'
import config from 'app/config'
import Browser from 'utils/Browser'
import { getAccessToken, removeToken } from 'actions/Auth'
import { mainPersistConfig } from 'store/configureStore'
import { purgeStoredState } from 'redux-persist'
import { message } from 'antd'

const apiBaseURL = config.api_url

axios.defaults.baseURL = apiBaseURL
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.request.use(
  (response) => {
    if (!response.headers.Authorization) {
      const token = getAccessToken()
      if (token) {
        response.headers.Authorization = `Bearer ${token}`
      }
    }
    return response
  },
  error => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => {
    if (response.data.meta) {
      if (response.data.meta.message === 'Token signature is expired') {
        message.error(response.ata.meta.message)
        purgeStoredState(mainPersistConfig).then(() => {
          removeToken()
          Browser.setWindowHref('/')
        })
      }
    }

    return response
  },
  (error) => {
    if (error.response) {
      const { data } = error.response
      if ((data.meta && data.meta.message === 'not authorized') || (data.meta && data.meta.message === 'NotAuthorized')) {
        message.error(data.meta.message)
        purgeStoredState(mainPersistConfig).then(() => {
          removeToken()
          Browser.setWindowHref('/')
        })
      } else if (data.meta && data.meta.message) {
        message.error(data.meta.message)
      } else if (data) {
        message.error(data)
      } else {
        message.error(error.message)
      }
    }
    return Promise.reject(error)
  },
)

export default class API {
  static get(path, options = {}) {
    return axios.get(
      path, { ...options },
    )
  }

  static post(path, data = {}, options = {}) {
    return axios.post(
      path, data, { ...options },
    )
  }

  static put(path, data = {}, options = {}) {
    return axios.put(
      path, data, { ...options },
    )
  }

  static delete(path, options = {}) {
    return axios.delete(
      path, { ...options },
    )
  }
}
