import API from 'utils/API'
import {
  AUTO_DEBIT_LOG_PAYMENT_REQUEST,
  AUTO_DEBIT_LOG_PAYMENT_SUCCESS,
  AUTO_DEBIT_LOG_PAYMENT_FAILURE,
} from 'constants/ActionTypes'

export const autoDebitLogPaymentRequest = () => ({
  type: AUTO_DEBIT_LOG_PAYMENT_REQUEST,
})

export const autoDebitLogPaymentSuccess = (data, meta) => ({
  type: AUTO_DEBIT_LOG_PAYMENT_SUCCESS,
  data,
  meta,
})

export const autoDebitLogPaymentFailure = errorMessage => ({
  type: AUTO_DEBIT_LOG_PAYMENT_FAILURE,
  errorMessage,
})


export const fetchAutoDebitLogPayment = params => (
  (dispatch) => {
    dispatch(autoDebitLogPaymentRequest())
    const url = `${process.env.APP_CONFIG.api_url}/autodebet/payment-log-list${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(autoDebitLogPaymentSuccess(data, meta))
        } else {
          dispatch(autoDebitLogPaymentFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(autoDebitLogPaymentFailure(err.message))
    })
  }
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url}/autodebet/export-payment-log-list${params || ''}`).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)
