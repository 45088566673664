import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form,
  Input, Button,
  Select, Radio,
  Icon,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormFaq = ({
  detailFaq, handleSubmit,
  form, isEdit, stateTitle,
  currentState, handleRadio,
  isSubmitting,
}) => {
  const {
    getFieldDecorator, validateFields, setFields, getFieldsValue,
  } = form

  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Form Type">
          {getFieldDecorator('form_type', {
            initialValue: currentState.is_title ? 1 : 0,
            rules: [{ required: false }],
          })(
            <Radio.Group
              buttonStyle="solid"
              disabled={isEdit}
              onChange={(e) => {
                handleRadio(e)
                setFields({
                  title_id: { value: undefined },
                  title: { value: '' },
                  question: { value: '' },
                  answer: { value: '' },
                })
              }}
            >
              <Radio.Button value={1}>Title</Radio.Button>
              <Radio.Button value={0}>Content</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        {!isEdit && (currentState.is_title || currentState.is_title === 1)
          ? (
            <Form.Item label="Category" style={{ display: 'none' }}>
              {getFieldDecorator('title_id', {
                initialValue: '00000000-0000-0000-0000-000000000000',
              })(
                <Input placeholder="New Category" type="hidden" />,
              )}
            </Form.Item>
          )
          : (
            <Form.Item label="Category">
              {getFieldDecorator('title_id', {
                initialValue: detailFaq.is_title ? detailFaq.id : detailFaq.title_id,
                rules: [{ required: true }],
              })(
                <Select
                  placeholder="Select Title"
                  loading={stateTitle.isFetching}
                  onChange={val => setFields({ title: { value: (stateTitle.options.find(item => item.title_id === val) || {}).title } })}
                >
                  {(getFieldsValue().form_type === 1 && !isEdit) && <Select.Option value="00000000-0000-0000-0000-000000000000">New Title</Select.Option>}
                  {(isEdit || getFieldsValue().form_type === 0) && stateTitle.options && stateTitle.options.map(item => (
                    <Select.Option key={item.title_id} value={item.title_id}>{item.title}</Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          )
        }
        <Form.Item label="Category Label">
          {getFieldDecorator('title', {
            initialValue: detailFaq.title,
            rules: [{ required: isEdit ? detailFaq.is_title : currentState.is_title }],
          })(
            <Input placeholder="Input Title" disabled={isEdit ? !detailFaq.is_title : !currentState.is_title} />,
          )}
        </Form.Item>
        <Form.Item label="Question" style={{ display: !isEdit && (currentState.is_title || currentState.is_title === 1) ? 'none' : '' }}>
          {getFieldDecorator('question', {
            initialValue: detailFaq.question,
            rules: [{ required: isEdit ? detailFaq.is_content : currentState.is_content }],
          })(
            <Input.TextArea rows={2} placeholder="Input Question" disabled={isEdit ? !detailFaq.is_content : !currentState.is_content} />,
          )}
        </Form.Item>
        <Form.Item label="Answer" style={{ display: !isEdit && (currentState.is_title || currentState.is_title === 1) ? 'none' : '' }}>
          {getFieldDecorator('answer', {
            initialValue: detailFaq.answer,
            rules: [{ required: isEdit ? detailFaq.is_content : currentState.is_content }],
          })(
            <Input.TextArea rows={4} placeholder="Input Answer" disabled={isEdit ? !detailFaq.is_content : !currentState.is_content} />,
          )}
        </Form.Item>

        {isEdit && (
          <Form.Item label="Order">
            {getFieldDecorator('order', {
              initialValue: detailFaq.order,
              rules: [{ required: false, type: 'number', transform: val => (val ? Number(val) : '') }],
            })(
              <Input placeholder="Input Order" />,
            )}
          </Form.Item>
        )}


        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/faqs')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {isSubmitting && <Icon type="loading" />}
            {!isEdit ? `Add ${currentState.is_title ? 'Title' : 'Content'} FAQ` : 'Submit Change'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormFaq.propTypes = {
  detailFaq: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  stateTitle: PropTypes.object,
  currentState: PropTypes.object,
  handleRadio: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

export default Form.create({ name: 'faqForm' })(FormFaq)
