import React from 'react'
import PropTypes from 'prop-types'
import {
  Table, Icon, Popconfirm,
  Button, Input,
} from 'antd'
import history from 'utils/history'
import moment from 'moment'

const NotificationsPage = ({
  isFetching, handlePage,
  metaNotif, dataNotif,
  handleDelete, handleSearch,
  currentUser,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 80,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 250,
    },
    {
      title: 'Description',
      dataIndex: 'content',
      key: 'content',
      render: text => (
        // eslint-disable-next-line react/no-danger
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0" dangerouslySetInnerHTML={{ __html: text || '-' }} /></div>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      width: 180,
      render: text => (text ? moment.utc(text).format('DD MMM YYYY HH:mm') : '-'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <div className="d-flex justify-content-start">
          <Icon type="edit" className="mr-3" style={{ color: '#1b4db3' }} onClick={() => history.push(`/notification/${record.id}/edit`)} />
          <Popconfirm
            title="Apakah anda yakin ingin menghapus notification ini?"
            okText="Ya"
            cancelText="Tidak"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            {(currentUser.permissions && currentUser.permissions.indexOf('manage-notification-announcement-delete') > -1) && (
              <Icon type="delete" style={{ color: '#ff5159' }} />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search by title"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => history.push('/notification/add')}
            >
              Tambah Notifikasi
            </Button>
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataNotif}
        loading={isFetching}
        scroll={{ x: true }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaNotif ? metaNotif.total_count : dataNotif.length,
          current: metaNotif ? metaNotif.current_page : 1,
          onChange: handlePage,
        }}
      />
    </React.Fragment>
  )
}

NotificationsPage.propTypes = {
  isFetching: PropTypes.bool,
  dataNotif: PropTypes.array,
  metaNotif: PropTypes.object,
  handlePage: PropTypes.func,
  handleDelete: PropTypes.func,
  handleSearch: PropTypes.func,
  currentUser: PropTypes.object,
}

export default NotificationsPage
