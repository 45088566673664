import {
  ULTRA_VOUCHER_BRAND_REQUEST,
  ULTRA_VOUCHER_BRAND_SUCCESS,
  ULTRA_VOUCHER_BRAND_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataUltraVoucherBrand: [],
  metaUltraVoucherBrand: {},
}

export default function ultraVoucherBrand(state = initialState, action) {
  switch (action.type) {
    case ULTRA_VOUCHER_BRAND_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case ULTRA_VOUCHER_BRAND_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataUltraVoucherBrand: action.data,
        metaUltraVoucherBrand: action.meta,
      }
    case ULTRA_VOUCHER_BRAND_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
