import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import PembatalanView from 'containers/pages/autoDebit/pembatalan/List'
import PembatalanViewDetail from 'containers/pages/autoDebit/pembatalan/Detail'

const AutoDebitPembatalan = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/auto-debet/pembatalan') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-autodebit-registration-cancellation-read') > -1) {
      return <PembatalanView location={location} />
    }
    return <ForbiddenPage />
  }
  if (match.params.id && location.pathname.includes('/detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-autodebit-registration-cancellation-read') > -1) {
      return <PembatalanViewDetail match={match} location={location} currentUser={currentUser} />
    }
    return <ForbiddenPage />
  }
  return ''
}

AutoDebitPembatalan.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default AutoDebitPembatalan
