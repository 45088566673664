import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import Browser from 'utils/Browser'
import { getAccessToken } from 'actions/Auth'
// import config from 'app/config'
// import isEmpty from 'lodash/isEmpty'

export const mapStateToProps = (state) => {
  /* const {
    currentUser,
  } = isEmpty(state.root.auth) ? { isFetching: false } : state.root.auth */
  const {
    currentUser,
  } = state.root.auth

  return {
    currentUser,
  }
}

const publicComponent = (Component, redirect = false, redirectPath = '/home') => compose(
  connect(
    mapStateToProps,
  ),
  lifecycle({
    componentDidMount() {
      if (getAccessToken() && redirect) {
        Browser.setWindowHref(redirectPath)
      }
    },
  }),
)(Component)

export default publicComponent
