import { connect } from 'react-redux'
import { compose, lifecycle, withState, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchDetailCommission } from 'actions/Commission'
import DetailCommissionView from 'components/pages/commission/Detail'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailCustomer,
  } = state.root.customer

  return {
    isFetching,
    detailCustomer,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDetailCommission: bindActionCreators(fetchDetailCommission, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('detail', 'setDetail', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getDetail: props => (params) => {
      const { match, setDetail } = props
      props.fetchDetailCommission(match.params.id, params)
        .then((result) => {
          setDetail({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setDetail({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
      }
      props.getDetail(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match, getDetail } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Commission Detail'])
      this.props.updateSiteConfigs({
        titlePage: 'Commission Detail',
        activePage: 'commission',
        activeSubPage: 'commission',
      })
      if (match.params.id) {
        getDetail()
      }
    },
  }),
)(DetailCommissionView)
