import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { bindActionCreators } from 'redux'
import { fetchDashboard } from 'actions/Dashboard'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import DashboardView from 'components/pages/dashboard/List'
// import { message } from 'antd'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataDashboard,
    metaDashboard,
  } = state.root.dashboard

  return {
    isFetching,
    dataDashboard,
    metaDashboard,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDashboard: bindActionCreators(fetchDashboard, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      // const { setData, setIsFetching, fetchDashboard } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home'])
      this.props.updateSiteConfigs({
        titlePage: 'Dashboard',
        activePage: 'dashboard',
        activeSubPage: 'dashboard',
      })
      this.props.fetchDashboard()
    },
  }),
)(DashboardView)
