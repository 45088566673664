import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Radio, Input,
  Button,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormManagementDanaOttocash = ({
  handleSubmit, form, isEdit, isSubmit,
  handleRadio, denomDetail,
}) => {
  const { getFieldDecorator, validateFields } = form
  const handleNumber = (e) => {
    if (e.which !== 8 && isNaN(String.fromCharCode(e.which))) {
      e.preventDefault()
    }
  }

  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Nominal">
          {getFieldDecorator('nominal_amount', {
            initialValue: isEdit ? denomDetail.data.nominal : '',
            rules: [{ required: true, message: 'Please input Nominal' }],
          })(
            <Input
              addonBefore="Rp."
              style={{ width: 300 }}
              onKeyDown={handleNumber}
            />,
          )}
        </Form.Item>
        <Form.Item label="Price">
          {getFieldDecorator('price_amount', {
            initialValue: isEdit ? denomDetail.data.price : '',
            rules: [{ required: true, message: 'Please input Nominal Price' }],
          })(
            <Input
              addonBefore="Rp."
              style={{ width: 300 }}
              onKeyDown={handleNumber}
            />,
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('status', {
            initialValue: denomDetail.data.status || 'inactive',
            rules: [{ required: false }],
          })(
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                handleRadio(e)
              }}
            >
              <Radio.Button value="inactive">Inactive</Radio.Button>
              <Radio.Button value="active">Active</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/dana-ottocash/management-ottocash')} className="mr-2">Cancel</Button>
          <Button loading={isSubmit} type="primary" htmlType="submit">{!isEdit ? 'Add Data' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormManagementDanaOttocash.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  handleRadio: PropTypes.func,
  denomDetail: PropTypes.object,
}

export default Form.create({ name: 'managementDanaOttocashForm' })(FormManagementDanaOttocash)
