import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle } from 'recompose'
import { updateSiteConfigs } from 'actions/Site'
import UserView from 'components/pages/users'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth
  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      // this.props.updateSiteConfigs({
      //   titlePage: 'Member Management',
      //   activePage: 'user',
      //   activeSubPage: 'user',
      // })
    },
  }),
)(UserView)
