import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { message } from 'antd'
import { fetchMessageDetail, updateMessage } from 'actions/ContactUsMessages'
import FormContactUsMessagesView from 'components/pages/webManagement/contactUsMessages/Form'


import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchMessageDetail: bindActionCreators(fetchMessageDetail, dispatch),
  updateMessage: bindActionCreators(updateMessage, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('detail', 'setDetail', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    /* handleRadio: props => () => {
      props.setStatus(!props.status)
    }, */
    handleSubmit: props => () => {
      props.setIsSubmit(true)
      if (props.match.params.id) {
        props.updateMessage({ solved: true }, props.match.params.id)
          .then(() => {
            message.success('Message status has been update to solved!')
            props.setIsSubmit(false)
            history.push('/web-management/contact-us-messages')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetail,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Contact Us Messages', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Contact Us Messages Form')
      if (match.params.id) {
        this.props.fetchMessageDetail(match.params.id)
          .then((res) => {
            setDetail(res.data)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormContactUsMessagesView)
