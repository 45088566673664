import {
  TRANSACTION_REQUEST,
  TRANSACTION_SUCCESS,
  TRANSACTION_FAILURE,
  TRANSACTION_UPDATED,
  /* TRANSACTION_DETAIL_SUCCESS, */
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataTransaction: [],
  // detailTransaction: {},
  metaTransaction: {
    total_count: 0,
    current: 0,
  },
}

export default function transaction(state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case TRANSACTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataTransaction: action.data,
        metaTransaction: action.meta,
      }
    case TRANSACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case TRANSACTION_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataTransaction: state.dataTransaction.filter(item => item.id !== action.data.id),
      }
    // case TRANSACTION_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     isFetching: false,
    //     detailTransaction: action.data,
    //   }
    default:
      return state
  }
}
