import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import ReviewsPage from 'containers/pages/webManagement/reviews/List'
import ReviewsForm from 'containers/pages/webManagement/reviews/Form'

const Reviews = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/web-management/reviews') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementreviews-read') > -1) {
      return <ReviewsPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementreviews-write') > -1) {
      return <ReviewsForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Reviews.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Reviews
