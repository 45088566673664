import PropTypes from 'prop-types'
import {
  Table, Popconfirm,
  Button, Input, Avatar, Tag,
} from 'antd'
import history from 'utils/history'

/* const TitleCard = ({ handleSearch }) => (
  <div className="d-flex justify-content-between align-items-center">
    <div>
      <Input.Search
        allowClear
        placeholder="Search..."
        onSearch={handleSearch}
      />
    </div>
  </div>
) */

const UserPage = ({
  isFetching, handlePage, isVerifyPhone,
  metaCustomer, dataCustomer,
  handleVerify, handleSearch,
  currentUser,
}) => {
  const columns = [
    {
      title: 'Avatar',
      dataIndex: 'profile_pic',
      key: 'profile_pic',
      render: text => <Avatar shape="square" size={100} src={text || '/assets/default.jpg'} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="custom-link-btn">
          <Button type="link" onClick={() => history.push(`/customer/${record.id}/detail`)}>{text}</Button>
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
    },
    /* {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: text => text.name || '-',
    }, */
    /* {
      title: 'Last Login',
      dataIndex: 'last_login',
      key: 'last_login',
      render: text => (text !== '0001-01-01T00:00:00Z' ? moment.utc(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    }, */
    /* {
      title: 'Token Reset Exp',
      dataIndex: 'token_reset_expired',
      key: 'token_reset_expired',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    }, */
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => <Tag>{text || '-'}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      fixed: 'right',
      render: (text, record) => {
        if (isVerifyPhone) {
          return (
            <div className="btn-primary-wrapper">
              <Button type="edit" size="small" className="mr-2 my-2" onClick={() => history.push(`/customer/${record.id}/edit`)}>Edit</Button>
              {(currentUser.permissions && currentUser.permissions.indexOf('manage-customer-managementverify-phone-number-write') > -1) && (
              <Popconfirm
                title="Are you sure want to verify this Customer?"
                okText="Yes"
                cancelText="No"
                placement="topLeft"
                onConfirm={() => handleVerify(record.id, record.email)}
              >
                <Button type="primary" className="my-2" size="small">
                  Verify Customer
                </Button>
              </Popconfirm>
              )}
            </div>
          )
        }
        return ('-')
      },
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search..."
              onSearch={handleSearch}
              enterButton
            />
          </div>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataCustomer}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaCustomer ? metaCustomer.total_count : dataCustomer.length,
          current: metaCustomer ? metaCustomer.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}
/* TitleCard.propTypes = {
  handleSearch: PropTypes.func,
} */

UserPage.propTypes = {
  isFetching: PropTypes.bool,
  isVerifyPhone: PropTypes.bool,
  dataCustomer: PropTypes.array,
  metaCustomer: PropTypes.object,
  handlePage: PropTypes.func,
  handleVerify: PropTypes.func,
  handleSearch: PropTypes.func,
  currentUser: PropTypes.object,
}

export default UserPage
