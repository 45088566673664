import API from 'utils/API'
import config from 'app/config'
import { message } from 'antd'

export const fetchPenjelasan = params => (
  () => new Promise((resolve, reject) => (
    API.get(
      `${process.env.APP_CONFIG.api_url_content}/simpan-description${params || ''}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createPenjelasan = payload => (
  () => new Promise((resolve, reject) => (
    API.post(
      `${process.env.APP_CONFIG.api_url_content}/simpan-description`,
      payload,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Banner has been created')
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updatePenjelasan = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(
      `${process.env.APP_CONFIG.api_url_content}/simpan-description/${id}`,
      payload,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Banner has been edited')
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const deletePenjelasan = id => (
  () => new Promise((resolve, reject) => (
    API.delete(
      `${process.env.APP_CONFIG.api_url_content}/simpan-description/${id}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
          message.success('Data has been deleted')
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
