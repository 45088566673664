import PropTypes from 'prop-types'
import {
  Table, Icon, Tag, Button, Popconfirm,
} from 'antd'
import history from 'utils/history'

const VideoSimpanList = ({
  videoList, handleDelete, currentUser,
}) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      render: text => text || '-',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 250,
      render: text => text || '-',
    },
    {
      title: 'Video URL',
      dataIndex: 'url',
      key: 'url',
      width: 400,
      render: text => text || '-',
    },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      width: 80,
      render: (text) => {
        let tags
        if (text === true) {
          tags = <Tag color="green">True</Tag>
        } else {
          tags = <Tag color="red">False</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 90,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`/web-management/video-simpan/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-web-managementvideo-simpan-delete') > -1) && (
            <Popconfirm
              title="Are you sure delete this video?"
              okText="Yes"
              cancelText="No"
              placement="topLeft"
              onConfirm={() => handleDelete(record.id)}
            >
              <Icon type="delete" style={{ color: '#ff5159' }} />
            </Popconfirm>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center pb-3">
        <div className="btn-primary-wrapper">
          <Button
            type="primary"
            onClick={() => history.push('/web-management/video-simpan/add')}
          >
            Add Video
          </Button>
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        scroll={{ x: 1000 }}
        dataSource={videoList.data}
        loading={videoList.isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

VideoSimpanList.propTypes = {
  videoList: PropTypes.any,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default VideoSimpanList
