import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchBenefit, deleteBenefit } from 'actions/Benefit'
import BenefitView from 'components/pages/benefits/List'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataBenefit,
    metaBenefit,
  } = state.root.benefit

  const { currentUser } = state.root.auth

  return {
    isFetching,
    dataBenefit,
    metaBenefit,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchBenefit: bindActionCreators(fetchBenefit, dispatch),
  deleteBenefit: bindActionCreators(deleteBenefit, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({
    handleSearch: () => () => {},
    handlePage: props => (page) => {
      props.fetchBenefit(page ? `?${page}` : '')
    },
    handleDelete: props => (id) => {
      props.deleteBenefit(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Benefit'])
      this.props.updateSiteConfiguration('titlePage', 'Pelajari Manfaat')
      this.props.fetchBenefit()
    },
  }),
)(BenefitView)
