import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Button, Row,
  Col, Popconfirm,
} from 'antd'
import history from 'utils/history'

const FormContactUsMessages = ({
  detail, handleSubmit, isSubmit,
}) => (
  <div>
    <Card className="mb-3">
      <Row>
        <Col xs={24} sm={12} className="mb-3">
          <p className="mr-3 mb-2 font-weight-bold">Nama</p>
          <p style={{ fontSize: '16px' }}>{detail.name || '-'}</p>
        </Col>
        <Col xs={24} sm={12} className="mb-3">
          <p className="mr-3 mb-2 font-weight-bold">Email</p>
          <p style={{ fontSize: '16px' }}>{detail.email || '-'}</p>
        </Col>
        <Col xs={24} sm={12} className="mb-3">
          <p className="mr-3 mb-2 font-weight-bold">Message</p>
          <p style={{ fontSize: '16px' }}>{detail.message || '-'}</p>
        </Col>
        <Col xs={24} sm={12} className="mb-3">
          <p className="mr-3 mb-2 font-weight-bold">Category</p>
          <p style={{ fontSize: '16px' }}>{detail.category && detail.category.name ? detail.category.name : '-'}</p>
        </Col>
        <Col xs={24} sm={24} className="btn-primary-wrapper d-flex justify-content-end">
          <Button onClick={() => history.push('/web-management/contact-us-messages')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
          {detail.solved
            ? <Button type="primary" disabled>Issue has been solved</Button>
            : (
              <Popconfirm
                title="Are you sure want to change this message status to solved?"
                okText="Yes"
                cancelText="No"
                placement="topLeft"
                onConfirm={handleSubmit}
              >
                <Button type="primary" loading={isSubmit}>Solve this issue</Button>
              </Popconfirm>
            )}
        </Col>
      </Row>
    </Card>
  </div>
)

FormContactUsMessages.propTypes = {
  detail: PropTypes.object,
  handleSubmit: PropTypes.func,
  isSubmit: PropTypes.bool,
}

export default FormContactUsMessages
