import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Card, Button, Input,
  Icon, Checkbox, Tooltip,
  Tag, Modal, Alert, Spin,
  Upload, Divider, Breadcrumb
} from 'antd'
import { Row, Col } from 'react-bootstrap'

import { isEmpty } from 'lodash'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import history from 'utils/history'

const DetailCustomerClaimDeath = ({
  detailClaim, imageModal, toggleImageModal,
  imgUrl, onChangeDoc, isFetchingData, onSubmitRequest,
  handleStatusClaim, isSubmit, scrollToTop, handleDownload,
  handleChangeNote, reason, handleFullReject,
  handleChangeRejectNotes, toggleRejectModal, rejectModal,
  toggleUploadModal, uploadModal, previewFile,
  isUploading, handleChangeFile, handleUpload, fileName,
  checkStatus, handleDownloadClaimDoc, isExport
  /* handleScrollToTop, documents, */
}) => {

  const naik = useRef(null)
  if (scrollToTop) {
    naik.current.scrollIntoView({ behavior: 'smooth' })
  }
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }
  const statusClaim = detailClaim.data && detailClaim.data.status

  let tagColor
  if (detailClaim.data.status && detailClaim.data.status.name === 'fund transferred') {
    tagColor = 'green'
  } else if ((detailClaim.data.status && detailClaim.data.status.name === 'rejected') || (detailClaim.data.status && detailClaim.data.status.name === 'full rejection')) {
    tagColor = 'red'
  } else {
    tagColor = ''
  }

  let alertStatus
  if (statusClaim && statusClaim.name === 'rejected') {
    alertStatus = {
      message: 'Permohonan dokumen telah ditolak, Form pengajaun dapat direview kembali setelah peserta sudah memperbaharui dokumen yang tidak sesuai',
      type: 'error',
    }
  } else if (statusClaim && statusClaim.name === 'approved') {
    alertStatus = {
      message: "Dokumen telah lengkap dan sudah valid, klik 'Approve Document' untuk menyetujui permohonan klaim",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'administration') {
    alertStatus = {
      message: "Permohonan klaim telah disetujui, klik 'Process Transfer' untuk melakukan Transfer",
      type: 'info',
    }
  } else if (statusClaim && statusClaim.name === 'full rejection') {
    alertStatus = {
      message: detailClaim.data.reviewer && detailClaim.data.reviewer.review_notes ? detailClaim.data.reviewer.review_notes : 'Full Rejected',
      type: 'error',
    }
  } 

  return (
    <div className="pb-5">
      <div ref={naik} />
      <Row className="pb-4">
        {alertStatus
          && (
          <Col md="12">
            <Alert
              message={alertStatus.message}
              type={alertStatus.type}
              closable
            />
          </Col>
          )
        }
        <Col md="12" className="float-right btn-primary-wrapper">
          {statusClaim && (statusClaim.name === 'inquiry' || statusClaim.name === 'need review')
            && <Button className="float-right" type="primary" loading={isSubmit} onClick={() => handleStatusClaim('document review')}>Review Document</Button>
          }
        </Col>
      </Row>

      <Row className="pb-4">
        <Col md="8">
          <div className="custom-card-wrapper">
            <Card bordered={false}>
              <Row>
                <Col md="4" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Tanggal Permohonan</h6>
                  <p className="mb-0">
                    <span>{detailClaim.data.created_at || '-'}</span>
                  </p>
                </Col>
                <Col md="4" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Nama Peserta</h6>
                  <p className="mb-0">
                    <span>{detailClaim.data.account_name || '-'}</span>
                  </p>
                </Col>
                <Col md="4" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Jenis Klaim</h6>
                  <p className="mb-0">
                    <span>{detailClaim.data.claim_type?detailClaim.data.claim_type.name: '-'}</span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Nomor Peserta</h6>
                  <p className="mb-0">
                    <span>{detailClaim.data.registered_number || '-'}</span>
                  </p>
                </Col>
                <Col md="4" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Tanggal Bergabung</h6>
                  <p className="mb-0">
                    <span>{detailClaim.data.join_date || '-'}</span>
                  </p>
                </Col>
                <Col md="4" className="mb-3">
                  <h6 className="mb-1 mb-md-2">Status Permohonan</h6>
                  <Tag color={tagColor} style={{ fontSize: '0.8rem' }}>{statusClaim && statusClaim.status_label ? statusClaim.status_label : '-'}</Tag>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
        <Col md="4">
          <div className="custom-card-wrapper">
            <Card bordered={false}>
              <Row>
                <Col md="12" className="mb-3 mt-1">
                  <h6 className="mb-1 md-2 mb-4">Dokumen Persyaratan</h6>
                  {detailClaim.data.documents?.map(item => (
                    <Row className="ml-2">
                      <Icon type="file-image" className="mr-2 mt-2" />
                        Terkirim
                      <Button
                        type="secondary"
                        style={{ cursor: 'pointer', color: '#69c0ff' }}
                        className="ml-3 mb-3 mr-2 float-right"
                        onClick={() => toggleImageModal(item.url || '')
                        }
                      >
                        Lihat File
                      </Button>
                      {item.url &&
                        <Button type="primary" className="float-right" href={item.url} target="_blank" download={item.url ? item.url.slice(item.url.lastIndexOf('/')+ 1) : 'file'}>Download</Button>
                      }
                    </Row>
                  ))}
                  {!detailClaim.data.documents
                    && (
                      <Row md="12" className="ml-2">
                        -
                      </Row>
                    )
                  }
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col md="8">
          <div className="custom-card-wrapper">
            <Card bordered={false}>
              <Row className="mb-3">
                <Col md="12" className="pb-3">
                  <h6 className="font-weight-bold">Informasi Data Pemohon</h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Nama Pemohon</h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.name) ? detailClaim.data.name : '-'}
                  </h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Email Pemohon </h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.email) ? detailClaim.data.email : ''}
                  </h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Hubungan</h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.relation_type) ? detailClaim.data.relation_type.name : '-'}
                  </h6>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col md="8">
          <div className="custom-card-wrapper">
            <Card bordered={false}>
              <Row className="mb-3">
                <Col md="12" className="pb-3">
                  <h6 className="font-weight-bold">Penerima Klaim</h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Nama Lengkap</h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.recipient_name) ? detailClaim.data.recipient_name : '-'}
                  </h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Bank </h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.bank) ? detailClaim.data.bank.bank_name : ''}
                  </h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Nomor Rekening</h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.bank) ? detailClaim.data.bank.bank_account_no : '-'}
                  </h6>
                </Col>
                <Col md="4" className="mb-1">
                  <h6 className="mb-0 font-grey">Email</h6>
                </Col>
                <Col md="8" className="mb-3">
                  <h6 className="mb-0">
                    <span className="mr-3 d-none d-md-inline-block">:</span>
                    {!isEmpty(detailClaim.data.recipient_email) ? detailClaim.data.recipient_email : '-'}
                  </h6>
                </Col>
              </Row>
            </Card>
          </div>
        </Col>
      </Row>
      {isFetchingData
        && (
        <Row className="pb-4">
          <Col md="12">
            <div className="custom-card-wrapper">
              <Card bordered={false}>
                <div className="text-center">
                  <Spin className="mb-3" />
                  <p className="mb-0 font-weight-bold">Load Data...</p>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
        )
      }
      {statusClaim && (statusClaim.name !== 'inquiry' && statusClaim.name !== 'need review')
        && (
        <React.Fragment>
          <Row>
            <Col md="12">
              <div className="float-left btn-primary-wrapper">
                <Button onClick={() => history.push('/claim/claim-by-admin')} className="ml-2">Cancel</Button>
                {(statusClaim && (statusClaim.name !== "rejected" && statusClaim.name !== "fund transferred" && statusClaim.name !== "full rejection")) &&

                  <Button
                    type="danger"
                    className="ml-2"
                    loading={isSubmit}
                    onClick={() => toggleRejectModal()}
                  >Tolak klaim</Button>
                }
                {(statusClaim && statusClaim.name === 'document review')
                  && <Button type="primary" loading={isSubmit} className="ml-2" onClick={onSubmitRequest}>Validate Document</Button>
                }

                {(statusClaim && statusClaim.name === 'approved')
                  && <Button type="primary" loading={isSubmit} className="ml-2" onClick={() => handleStatusClaim('administration')}>Process Administration</Button>
                }

                {(statusClaim && statusClaim.name === 'administration')
                  && <Button type="primary" loading={isSubmit} className="ml-2" onClick={() => handleStatusClaim('fund transferred')}>Process Transfer</Button>
                }
                <Button type="primary" onClick={() => handleDownloadClaimDoc(detailClaim.data.name.replace('','_') || '')} loading={isExport} className="ml-2">Download Claim Document</Button>
              </div>
            </Col>
          </Row>
        </React.Fragment>
        )
      }
      {/*modal preview document*/}
      <Modal
        title="Preview Document"
        centered
        visible={imageModal}
        footer={null}
        onCancel={() => toggleImageModal('')}
        width={900}
      >
        {imgUrl === ''
          ? (
            <div className="text-center">
              <Spin />
            </div>
          )
          : (
            <SimpleReactLightbox>
              <SRLWrapper options={lightBoxSetting}>
                <div>
                <iframe className='pdf-frame' src={imgUrl} title="Iframe Example" />
                </div>
              </SRLWrapper>
            </SimpleReactLightbox>
          )}
      </Modal>
      {/*modal full reject*/}
      <Modal
        title="Reject Reason"
        centered
        visible={rejectModal}
        footer={null}
        onCancel={() => toggleRejectModal()}
      >
        <Row>
          <Col md="12">
            <Input.TextArea rows={8} onChange={handleChangeRejectNotes} />
          </Col>
          <Col md="12">
            <div className="pt-3">
              <Button type="danger" className="float-right" loading={isSubmit} onClick={handleFullReject}>Reject Claim</Button>
            </div>
          </Col>
        </Row>
      </Modal>
    {/*modal upload bukti transfer*/}
      <Modal
          title="Upload Bukti Transfer"
          centered
          visible={uploadModal}
          footer={null}
          onCancel={() => toggleUploadModal()}
        >
          <Row>
            <Col md="12" className="mb-5">
              <h6>Upload new file:</h6>
              <Upload
                name="document"
                listType="document"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={e => handleChangeFile(e)}
                fileList={previewFile}
                accept="image/*,.pdf"
              >
                <Button>
                  <Icon type="upload" />
                  {' '}
                  Attach file
                </Button>
              </Upload>
            </Col>
            {detailClaim.data.payment_document &&
              <Col md="6">
                {detailClaim.data.payment_document.slice(detailClaim.data.payment_document.lastIndexOf('.')+ 1) === "pdf" ?
                  <div>
                    <h6>Current file:</h6>
                    <Button
                      type="link"
                      style={{
                        cursor: 'pointer',
                        color: '#69c0ff',
                        whiteSpace: 'pre-line',
                        textAlign: 'left',
                      }}
                      className="mx-0 px-0"
                      onClick={() => handleDownload(detailClaim.data.payment_document)
                      }
                    >
                      {detailClaim.data.payment_document.slice(detailClaim.data.payment_document.lastIndexOf('/')+ 1)}
                    </Button>
                  </div>
                :
                  <div>
                    <h6>Current file:</h6>
                    <a href={detailClaim.data.payment_document} target="_blank" download={detailClaim.data.payment_document ? detailClaim.data.payment_document.slice(detailClaim.data.payment_document.lastIndexOf('/')+ 1) : 'file'}>
                      <img className="img-fluid" src={detailClaim.data.payment_document} style={{width: '150px'}} alt="bukti transfer"/>
                    </a>
                  </div>
                }
              </Col>
            }
            {previewFile &&
              <Col md="6">
                {fileName.slice(fileName.lastIndexOf('.')+ 1) === "pdf" ?
                  <div>
                    <h6>New file:</h6>
                    <p>{fileName}</p>
                  </div>
                :
                  <div>
                    <h6>New file:</h6>
                    <img className="img-fluid" src={previewFile} style={{width: '150px'}} alt="bukti transfer"/>
                  </div>
                }
              </Col>
            }
            <Divider/>
            <Col md="12">
              <div className="float-right">
                <Button
                  type="primary"
                  onClick={handleUpload}
                  loading={isUploading}
                  disabled={previewFile ? false : true}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
    </div>
  )
}

DetailCustomerClaimDeath.propTypes = {
  detailClaim: PropTypes.object,
  imageModal: PropTypes.bool,
  toggleImageModal: PropTypes.func,
  imgUrl: PropTypes.string,
  onChangeDoc: PropTypes.func,
  isFetchingData: PropTypes.bool,
  onSubmitRequest: PropTypes.func,
  handleStatusClaim: PropTypes.func,
  // documents: PropTypes.array,
  isSubmit: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  // handleScrollToTop: PropTypes.func,
  handleDownload: PropTypes.func,
  handleChangeNote: PropTypes.func,
  reason: PropTypes.object,
  handleFullReject: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  toggleRejectModal: PropTypes.func,
  rejectModal: PropTypes.bool,
  toggleUploadModal: PropTypes.func,
  uploadModal: PropTypes.bool,
  handleUpload: PropTypes.func,
  previewFile: PropTypes.any,
  isUploading: PropTypes.bool,
  handleChangeFile: PropTypes.func,
  fileName: PropTypes.string,
  checkStatus: PropTypes.func,
  handleDownloadClaimDoc: PropTypes.func,
  isExport: PropTypes.bool,
}

export default DetailCustomerClaimDeath
