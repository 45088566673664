import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchDetailNotification, createNotification, updateNotification } from 'actions/Notification'
import { message } from 'antd'
import FormNotificationView from 'components/pages/notifications/Form'
import moment from 'moment'
import Helper from 'utils/Helper'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailNotif,
  } = state.root.notification
  return {
    isFetching,
    detailNotif,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDetailNotification: bindActionCreators(fetchDetailNotification, dispatch),
  createNotification: bindActionCreators(createNotification, dispatch),
  updateNotification: bindActionCreators(updateNotification, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('longDesc', 'setLongDesc', ''),
  withState('isEdit', 'setIsEdit', false),
  withState('previewFile', 'setPreviewFile', ''),
  withState('file', 'setFile', ''),
  withHandlers({
    handleChangeEditor: props => (values) => {
      props.setLongDesc(values)
    },
    handleChange: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      props.setPreviewFile(fileList)
      if (fileList.length > 0) {
        Helper.getBase64(info.file, file => props.setFile(file))
      } else {
        props.setFile('')
      }
    },
    handleSubmit: props => (values) => {
      const { longDesc } = props
      // eslint-disable-next-line eqeqeq
      if (longDesc == '') {
        message.error('content is required')
        return
      }
      const payload = {
        ...values,
        date: moment(values.date).format(),
        send_now: moment().format() > moment(values.date).format(),
        recipients: '',
        content: longDesc,
      }
      if (moment().format() < moment(values.date).format()) {
        delete payload.date
        return
      }
      if (props.match.params.id) {
        if (props.detailNotif.send_all && !values.send_all) {
          if (props.file === '') {
            message.error('Need file list peserta')
            return
          }
          payload.recipients = props.file
        }
        props.updateNotification(payload, props.match.params.id)
          .then(() => {
            message.success('Notifikasi berhasil di update')
            history.push('/notification')
          })
          .catch(() => {
            // message.error(err)
          })
      } else {
        if (!values.send_all) {
          if (props.file === '') {
            message.error('Mohon masukan file list peserta')
            return
          }
          payload.recipients = props.file
        }
        props.createNotification(payload)
          .then(() => {
            message.success('Notifikasi berhasil di tambahkan')
            history.push('/notification')
          })
          .catch(() => {
            // message.error(err)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Notification', 'Form'])
      this.props.updateSiteConfigs({
        titlePage: 'Notification Form',
      })
      if (match.params.id) {
        this.props.fetchDetailNotification(match.params.id)
        setIsEdit(true)
      }
    },
  }),
)(FormNotificationView)
