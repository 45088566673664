import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchCategory, deleteCategory } from 'actions/Category'
import CategoryView from 'components/pages/categories/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataCategory,
    metaCategory,
  } = state.root.category
  const { currentUser } = state.root.auth
  return {
    isFetching,
    dataCategory,
    metaCategory,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchCategory: bindActionCreators(fetchCategory, dispatch),
  deleteCategory: bindActionCreators(deleteCategory, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchCategory(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleDelete: props => (id) => {
      props.deleteCategory(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'e-Learning', 'Category'])
      this.props.updateSiteConfigs({
        titlePage: 'e-Learning Category',
        activePage: 'elearning/categories',
        activeSubPage: 'elearning-management',
      })
      this.props.fetchCategory()
    },
  }),
)(CategoryView)
