import API from 'utils/API'
import { message } from 'antd'
import {
  QUIZ_ANSWER_REQUEST,
  QUIZ_ANSWER_SUCCESS,
  QUIZ_ANSWER_FAILURE,
  QUIZ_ANSWER_UPDATED,
  QUIZ_ANSWER_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const quizAnswerRequest = () => ({
  type: QUIZ_ANSWER_REQUEST,
})

export const quizAnswerSuccess = (data, meta) => ({
  type: QUIZ_ANSWER_SUCCESS,
  data,
  meta,
})

export const quizAnswerFailure = errorMessage => ({
  type: QUIZ_ANSWER_FAILURE,
  errorMessage,
})

export const quizAnswerUpdate = data => ({
  type: QUIZ_ANSWER_UPDATED,
  data,
})

export const quizAnswerDetail = data => ({
  type: QUIZ_ANSWER_DETAIL_SUCCESS,
  data,
})

export const fetchAnswer = params => (
  (dispatch) => {
    dispatch(quizAnswerRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/courses-questions-answers${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(quizAnswerSuccess(data, meta))
        } else {
          dispatch(quizAnswerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(quizAnswerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailAnswer = id => (
  (dispatch) => {
    dispatch(quizAnswerRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/courses-questions-answers/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(quizAnswerDetail(data))
        } else {
          dispatch(quizAnswerFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(quizAnswerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteAnswer = id => (
  (dispatch) => {
    dispatch(quizAnswerRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/courses-questions-answers/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Answer has been deleted')
          dispatch(quizAnswerUpdate(data))
        } else {
          message.success(meta.message)
          dispatch(quizAnswerFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.success(err.message)
      dispatch(quizAnswerFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createAnswer = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/courses-questions-answers`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updateAnswer = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/courses-questions-answers/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)
