import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { bindActionCreators } from 'redux'
import { compose, withHandlers, withState } from 'recompose'
import { authenticateByCredentials, forgotPassword } from 'actions/Auth'
import withForms from 'utils/hocs/withForms'
import LoginView from 'components/login/Login'
import history from 'utils/history'
import Swal from 'sweetalert2'

export function mapStateToProps(state) {
  const {
    isAuthenticating,
    errorMessage,
  } = isEmpty(state.root.auth)
    ? { isAuthenticating: false, errorMessage: null }
    : state.root.auth

  return {
    isAuthenticating,
    errorMessage,
  }
}

const mapDispatchToProps = dispatch => ({
  loginWithCredentials: bindActionCreators(authenticateByCredentials, dispatch),
  forgotPassword: bindActionCreators(forgotPassword, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withForms(),
  withState('validated', 'setValidated', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('viewSwitch', 'setViewSwitch', true),
  withHandlers({
    handleViewSwitch: props => () => {
      props.setViewSwitch(!props.viewSwitch)
    },
    onSubmitForgot: props => (event) => {
      event.preventDefault()
      props.setIsSubmit(true)
      const payload = {
        identifier: props.form.identifier,
      }
      props.forgotPassword(payload)
        .then(() => {
          Swal.fire(
            'Link to Reset Password has been sent',
            'Please Check your Email',
            'success',
          ).then(() => {
            history.push('/')
          })
          props.setIsSubmit(false)
        })
        .catch(() => {
          props.setIsSubmit(false)
        })
    },
    onSubmit: props => (event) => {
      event.preventDefault()
      props.setValidated(true)

      if (props.form.identifier !== '' && props.form.password !== '') {
        props.setIsSubmit(true)
        props.loginWithCredentials(props.form)
          .then(() => {
            props.setIsSubmit(false)
          })
          .catch(() => {
            props.setIsSubmit(false)
          })
      }
    },
  }),
)(LoginView)
