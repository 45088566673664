import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchVideos, createVideo, updateVideo } from 'actions/VideoSimpan'
import { message } from 'antd'
import FormVideoSimpanView from 'components/pages/webManagement/videoSimpan/Form'
import { find } from 'lodash'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchVideos: bindActionCreators(fetchVideos, dispatch),
  createVideo: bindActionCreators(createVideo, dispatch),
  updateVideo: bindActionCreators(updateVideo, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('detail', 'setDetail', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      const payload = {
        ...values,
        active: props.status,
      }
      if (props.match.params.id) {
        props.updateVideo(payload, props.match.params.id)
          .then(() => {
            message.success('Video berhasil di update')
            props.setIsSubmit(false)
            history.push('/web-management/video-simpan')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      } else {
        props.createVideo(payload)
          .then(() => {
            message.success('Video berhasil di tambahkan')
            props.setIsSubmit(false)
            history.push('/web-management/video-simpan')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetail, setStatus,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Video Simpan', 'Form'])
      if (match.params.id) {
        this.props.fetchVideos()
          .then((result) => {
            const detail = find(result.data, ['id', match.params.id])
            setDetail(detail)
            setStatus(detail.active)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormVideoSimpanView)
