import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchAutoDebitPembatalanDetail, updatedStatusPembatalan } from 'actions/AutoDebitPembatalan'
import history from 'utils/history'
import { message } from 'antd'
import PembatalanViewDetail from 'components/pages/autoDebit/pembatalan/Detail'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailAutoDebitPembatalan,
  } = state.root.autoDebitPembatalan

  return {
    isFetching,
    detailAutoDebitPembatalan,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAutoDebitPembatalanDetail: bindActionCreators(fetchAutoDebitPembatalanDetail, dispatch),
  updatedStatusPembatalan: bindActionCreators(updatedStatusPembatalan, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('rejectNotes', 'setRejectNotes', ''),
  withState('isApprove', 'setIsApprove', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('rejectModal', 'setRejectModal', false),
  withHandlers({
    handleChangeRejectNotes: props => (e) => {
      props.setRejectNotes(e.target.value)
    },
    toggleRejectModal: props => () => {
      props.setRejectModal(!props.rejectModal)
    },
    handleReject: props => () => {
      const {
        match, setIsSubmit, rejectNotes,
      } = props
      if (rejectNotes === '') {
        message.error('Alasan penolakan tidak boleh kosong')
        return
      }
      setIsSubmit(true)
      const payload = {
        is_approved: false,
        admin_notes: rejectNotes,
      }
      props.updatedStatusPembatalan(match.params.id, payload)
        .then(() => {
          setIsSubmit(false)
          message.success('Request has been rejected')
          history.push('/auto-debet/pembatalan')
        })
        .catch((err) => {
          setIsSubmit(false)
          message.error(err)
        })
    },
    handleApprove: props => () => {
      const {
        match, setIsApprove,
      } = props
      setIsApprove(true)
      const payload = {
        is_approved: true,
      }
      props.updatedStatusPembatalan(match.params.id, payload)
        .then(() => {
          setIsApprove(false)
          message.success('Request has been approved')
          history.push('/auto-debet/pembatalan')
        })
        .catch((err) => {
          setIsApprove(false)
          message.error(err)
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Autodebet - Pembatalan', 'Detail'])
      this.props.updateSiteConfigs({
        titlePage: 'Autodebet - Pembatalan',
        activePage: '/auto-debet/pembatalan/:id/detail',
        activeSubPage: 'detail',
      })
      if (match.params.id) {
        this.props.fetchAutoDebitPembatalanDetail(match.params.id)
      }
    },
  }),
)(PembatalanViewDetail)
