import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import history from 'utils/history'

const BannerAppsView = ({
  bannerAppsList,
}) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => text || '-',
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
      key: 'subtitle',
      render: text => text || '-',
    },
    {
      title: 'Play Store URL',
      dataIndex: 'google_play_url',
      key: 'google_play_url',
      render: text => text || '-',
    },
    {
      title: 'App Store URL',
      dataIndex: 'app_store_url',
      key: 'app_store_url',
      render: text => text || '-',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" style={{ color: '#1b4db3' }} onClick={() => history.push(`/web-management/banner-apps/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <Table
      rowKey="id"
      className="table-striped-rows"
      columns={columns}
      dataSource={bannerAppsList.data}
      loading={bannerAppsList.isFetching}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  )
}

BannerAppsView.propTypes = {
  bannerAppsList: PropTypes.any,
}

export default BannerAppsView
