import PropTypes from 'prop-types'
import {
  Table, Avatar,
  Button, Modal,
  Input, Icon, Popconfirm,
} from 'antd'
import history from 'utils/history'

const LearningContent = ({
  dataCourse, isFetching,
  handleModal, modalContent,
  handleSearch, metaCourse,
  handlePage, handleDelete,
  currentUser,
}) => {
  const columns = [
    {
      title: 'Banner',
      dataIndex: 'banner',
      key: 'banner',
      width: 150,
      render: text => <Avatar shape="square" size={100} src={text} />,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text}</p></div>
      ),
    },
    {
      title: 'Content',
      dataIndex: 'content',
      width: 500,
      key: 'content',
      render: text => (
        <div className="ellipsis-wrapper line-clamp"><p className="mb-0">{text}</p></div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: text => (text ? text.name : '-'),
    },
    {
      title: 'File',
      dataIndex: 'file_type',
      key: 'file_type',
      width: 100,
      render: (text, record) => (
        <Button type="link" onClick={() => handleModal(record)} className="d-flex align-items-center text-reset">
          {text}
          <Icon type="eye" />
        </Button>
      ),
    },
    /* {
      title: 'Active',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 80,
      render: text => (text
        ? <Tag color="#87d068" className="rounded-pill">Active</Tag>
        : <Tag color="#f50" className="rounded-pill">Inactive</Tag>),
    }, */
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/elearning/content/${record.id}/edit`)} />
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-e-toolse-learning-content-delete') > -1) && (
          <Popconfirm
            title="Are you sure delete this item?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            <Icon type="delete" />
          </Popconfirm>
          )}
        </div>
      ),
    },
  ]
  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-between align-items-center btn-primary-wrapper">
          <div>
            <Input.Search
              allowClear
              placeholder="Search by title"
              onSearch={handleSearch}
              enterButton
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => history.push('/elearning/content/add')}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
      <div className="table-wrapper">
        <Table
          rowKey="id"
          className="table-striped-rows"
          columns={columns}
          dataSource={dataCourse}
          loading={isFetching}
          scroll={{ x: 1300 }}
          pagination={{
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            total: metaCourse.total_count,
            current: metaCourse.current_page,
            onChange: handlePage,
          }}
        />
      </div>
      <Modal
        visible={!!modalContent}
        onCancel={handleModal}
        footer={null}
      >
        <div className="text-center">
          {modalContent}
        </div>
      </Modal>
    </div>
  )
}


LearningContent.propTypes = {
  isFetching: PropTypes.bool,
  dataCourse: PropTypes.array,
  metaCourse: PropTypes.object,
  modalContent: PropTypes.any,
  handleModal: PropTypes.func,
  handleSearch: PropTypes.func,
  handlePage: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default LearningContent
