import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  USER_UPDATED,
  USER_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataUser: [],
  detailUser: {},
  metaUser: {
    total_count: 0,
    current: 0,
  },
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataUser: action.data,
        metaUser: action.meta,
      }
    case USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    case USER_UPDATED:
      return {
        ...state,
        isFetching: false,
        dataUser: state.dataUser.filter(item => item.id !== action.data.id),
      }
    case USER_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        detailUser: action.data,
      }
    default:
      return state
  }
}
