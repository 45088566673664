import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  createFormRequest,
} from 'actions/Claim'
import { message } from 'antd'
import FormRequestView from 'components/pages/claim/claimMeninggalDunia/formRequest'
import history from 'utils/history'

export function mapStateToProps(state) {
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  createFormRequest: bindActionCreators(createFormRequest, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      props.setIsSubmit(true)
      props['createFormRequest'](values).then(() => {
        props.setIsSubmit(false)
        message.success('Form Request has been created').then(() => (
          history.push('/claim/claim-by-admin')
        ))
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Claim', 'Claim By Admin'])
      this.props.updateSiteConfiguration('titlePage', 'Claim By Admin Form Request')
    },
  }),
)(FormRequestView)
