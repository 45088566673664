import API from 'utils/API'
import { message } from 'antd'
import {
  ABOUT_DPLK_REQUEST,
  ABOUT_DPLK_SUCCESS,
  ABOUT_DPLK_FAILURE,
  ABOUT_DPLK_UPDATED,
  ABOUT_DPLK_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const aboutDplkRequest = () => ({
  type: ABOUT_DPLK_REQUEST,
})

export const aboutDplkSuccess = (data, meta) => ({
  type: ABOUT_DPLK_SUCCESS,
  data,
  meta,
})

export const aboutDplkFailure = errorMessage => ({
  type: ABOUT_DPLK_FAILURE,
  errorMessage,
})

export const aboutDplkUpdate = data => ({
  type: ABOUT_DPLK_UPDATED,
  data,
})

export const aboutDplkDetail = data => ({
  type: ABOUT_DPLK_DETAIL_SUCCESS,
  data,
})

export const fetchAboutDplk = params => (
  (dispatch) => {
    dispatch(aboutDplkRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/about-dplk${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(aboutDplkSuccess(data, meta))
        } else {
          dispatch(aboutDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(aboutDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailAboutDplk = id => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/about-dplk/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

/* export const fetchDetailAboutDplk = id => (
  (dispatch) => {
    dispatch(aboutDplkRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/about-dplk/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(aboutDplkDetail(data))
        } else {
          dispatch(aboutDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(aboutDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
) */

export const deleteAboutDplk = id => (
  (dispatch) => {
    dispatch(aboutDplkRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/about-dplk/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success(data)
          dispatch(fetchAboutDplk())
        } else {
          message.error(meta.message)
          dispatch(aboutDplkFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(aboutDplkFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createAboutDplk = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/about-dplk`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const updateAboutDplk = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/about-dplk/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)
