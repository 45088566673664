import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchUltraVoucherBrands, updateBrand } from 'actions/UltraVoucherBrands'
import { fetchUltraVoucherCategory } from 'actions/UltraVoucherCategories'
import { message } from 'antd'
import FormUltraVoucherBrandView from 'components/pages/ultraVouchers/brands/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataUltraVoucherBrand,
    metaUltraVoucherBrand,
  } = state.root.ultraVoucherBrand

  return {
    isFetching,
    dataUltraVoucherBrand,
    metaUltraVoucherBrand,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateBrand: bindActionCreators(updateBrand, dispatch),
  fetchUltraVoucherBrands: bindActionCreators(fetchUltraVoucherBrands, dispatch),
  fetchUltraVoucherCategory: bindActionCreators(fetchUltraVoucherCategory, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isSubmit', 'setIsSubmit', false),
  withState('detail', 'setDetail', {}),
  withState('default', 'setDefault', true),
  withState('loadStatus', 'setLoadStatus', false),
  withState('loadCategory', 'setLoadCategory', false),
  withState('selectOption', 'setSelectOption', []),
  withState('isSubmit', 'setIsSubmit', false),
  withHandlers({
    handleRadio: props => (e) => {
      let value
      if (e.target === '1') {
        value = true
      } else {
        value = false
      }
      props.setDefault({ is_active: value })
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      props.updateBrand(values, props.match.params.id).then(() => {
        message.success('Brand Category has been updated')
        history.push('/ultra-vouchers/brands')
        props.setIsSubmit(false)
      }).catch((err) => {
        message.error(err)
        props.setIsSubmit(false)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setDetail, dataUltraVoucherBrand,
        setSelectOption, setLoadCategory,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Ultra Voucher', 'Brand', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Ultra Voucher - Brand Form')
      this.props.fetchUltraVoucherBrands().then(() => {
        setDetail(dataUltraVoucherBrand.filter(x => x.id === match.params.id)[0])
      }).catch((err) => {
        message.error(err)
      })

      this.props.fetchUltraVoucherCategory().then((res) => {
        setSelectOption(res)
        setLoadCategory(false)
      }).catch((err) => {
        message.error(err)
      })
    },
  }),
)(FormUltraVoucherBrandView)
