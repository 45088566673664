import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  benefitDetail,
  fetchDetailBenefit,
  createBenefit,
  updateBenefit,
  fetchClaimType,
} from 'actions/Benefit'
import { message } from 'antd'
import FormBenefitView from 'components/pages/benefits/Form'
import Helper from 'utils/Helper'
import { pickBy, identity } from 'lodash'

export function mapStateToProps(state) {
  const {
    isFecthing,
    /* detailBenefit,
    metaDetailBenefit, */
    dataClaimType,
    metaClaimType,
  } = state.root.benefit

  return {
    isFecthing,
    /* detailBenefit,
    metaDetailBenefit, */
    dataClaimType,
    metaClaimType,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailBenefit: bindActionCreators(fetchDetailBenefit, dispatch),
  createBenefit: bindActionCreators(createBenefit, dispatch),
  updateBenefit: bindActionCreators(updateBenefit, dispatch),
  benefitDetail: bindActionCreators(benefitDetail, dispatch),
  fetchClaimType: bindActionCreators(fetchClaimType, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('stateFile', 'setStateFile', ''),
  withState('detailBenefit', 'setDetailBenefit', {}),
  withState('descContent1', 'setDescContent1', ''),
  withState('descContent2', 'setDescContent2', ''),
  withState('descContent3', 'setDescContent3', ''),
  withHandlers({
    handleChangeEditor1: props => (val) => {
      props.setDescContent1(val)
    },
    handleChangeEditor2: props => (val) => {
      props.setDescContent2(val)
    },
    handleChangeEditor3: props => (val) => {
      props.setDescContent3(val)
    },
    handleUpload: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      if (fileList.length > 0) {
        return Helper.getBase64(info.file, file => props.setStateFile(file))
      }
      return false
    },
    handleSubmit: props => (values) => {
      const payload = {
        ...values,
        tax_desc_content_1: props.descContent1,
        tax_desc_content_2: props.descContent2,
        tax_desc_content_3: props.descContent3,
        doc_desc_title: '-', // not used
        doc_desc_header: '-', // not used
        claim_desc: '-', // not used
        benefit_desc_title: '-', // not used
        benefit_desc_header: '-', // not used
        benefit_desc_footer: '-', // not used
        tax_desc_title: '-', // not used
        tax_desc_footer_1: '-', // not used
        tax_desc_footer_2: '-', // not used
        tax_desc_footer_3: '-', // not used
      }
      const formData = new FormData()
      Object.keys(pickBy(payload, identity)).map((item) => {
        if (item === 'benefit_icon' && payload[item]) {
          return formData.append(item, payload[item].file)
        }
        return formData.append(item, payload[item])
      })
      if (props.match.params.id) {
        props.updateBenefit(formData, props.match.params.id).catch((err) => {
          message.error(err)
        })
      } else {
        props.createBenefit(formData).catch((err) => {
          message.error(err)
        })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetailBenefit, setStateFile,
        setDescContent1, setDescContent2, setDescContent3,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Benefit', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Pelajari Manfaat Form')
      this.props.benefitDetail({})
      this.props.fetchClaimType()
      if (match.params.id) {
        this.props.fetchDetailBenefit(match.params.id)
          .then((res) => {
            setDetailBenefit(res)
            setStateFile(res.benefit_icon)
            setDescContent1(res.tax_desc_content_1)
            setDescContent2(res.tax_desc_content_2)
            setDescContent3(res.tax_desc_content_3)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormBenefitView)
