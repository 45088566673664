import { Card, Result, Button } from 'antd'
import history from 'utils/history'

const Forbidden = () => (
  <Card>
    <Result
      status="403"
      title="Tidak Memiliki Akses"
      subTitle="Maaf, Anda tidak memiliki akses pada menu ini."
      extra={<Button type="primary" onClick={() => history.push('/home')}>Kembali</Button>}
    />
  </Card>
)

export default Forbidden
