import {
  COMMISSION_REQUEST,
  COMMISSION_SUCCESS,
  COMMISSION_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataCommission: [],
  metaCommission: {},
}

export default function commission(state = initialState, action) {
  switch (action.type) {
    case COMMISSION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case COMMISSION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataCommission: action.data,
        metaCommission: action.meta,
      }
    case COMMISSION_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
