import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchDetailContentCourse, fetchCourseCategories, createContentCourse,
  updateContentCourse, learningContentDetail,
} from 'actions/LearningContent'
import { message } from 'antd'
import FormLearningContentView from 'components/pages/elearnings/contents/Form'
import Helper from 'utils/Helper'
import { pickBy, identity } from 'lodash'

export function mapStateToProps(state) {
  const {
    isFecthing,
    detailCourse,
  } = state.root.learningContent
  return {
    isFecthing,
    detailCourse,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailContentCourse: bindActionCreators(fetchDetailContentCourse, dispatch),
  fetchCourseCategories: bindActionCreators(fetchCourseCategories, dispatch),
  createContentCourse: bindActionCreators(createContentCourse, dispatch),
  updateContentCourse: bindActionCreators(updateContentCourse, dispatch),
  learningContentDetail: bindActionCreators(learningContentDetail, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('bannerFileList', 'setBannerFileList', ''),
  withState('loadCategory', 'setLoadCategory', true),
  withState('selectOption', 'setSelectOption', []),
  withState('isEdit', 'setIsEdit', false),
  withState('defaultFileList', 'setDefaultFileList', []),
  withHandlers({
    handleSearch: () => () => {},
    handlePage: props => (page) => {
      props.fetchDetailContentCourse(`?page=${page}`)
    },
    handleUpload: props => (info, type) => {
      if (type === 'banner') return Helper.getBase64(info.file, file => props.setBannerFileList(file))

      return props.setDefaultFileList([info.file])
    },
    handleSubmit: props => (val) => {
      const values = {
        ...val,
        file_type: 'link',
      }
      const formData = new FormData()
      Object.keys(pickBy(values, identity)).map((item) => {
        if ((item === 'banner_file' || item === 'file_url') && values[item]) return formData.append(item, values[item].file)
        if (item === 'is_active') return formData.append(item, values[item])
        return formData.append(item, values[item])
      })
      if (props.match.params.id) {
        props.updateContentCourse(formData, props.match.params.id).catch((err) => {
          message.error(err)
        })
      } else {
        props.createContentCourse(formData).catch((err) => {
          message.error(err)
        })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setSelectOption,
        setLoadCategory, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'e-Learning', 'Content', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'e-Learning Content Form')
      this.props.fetchCourseCategories().then((res) => {
        setSelectOption(res)
        setLoadCategory(false)
      })
      if (match.params.id) {
        setIsEdit(true)
        this.props.fetchDetailContentCourse(match.params.id)
      } else {
        this.props.learningContentDetail({})
      }
    },
    componentWillReceiveProps(nextProps) {
      const { detailCourse, setBannerFileList, setDefaultFileList } = this.props
      if (detailCourse !== nextProps.detailCourse) {
        setBannerFileList(nextProps.detailCourse.banner)
        setDefaultFileList([{
          uid: '1',
          name: 'file.mp4',
          status: 'done',
          url: nextProps.detailCourse.file,
        }])
      }
    },
  }),
)(FormLearningContentView)
