import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfigs, updateSiteConfiguration } from 'actions/Site'
import { fetchProfileList } from 'actions/UpdateProfile'
import UpdateDataView from 'components/pages/updateData/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchProfileList: bindActionCreators(fetchProfileList, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('list', 'setList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getList: props => (params) => {
      const { setList } = props
      props.fetchProfileList(params)
        .then((result) => {
          setList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onSetStateSearch: props => () => {
      const { setStateSearch, stateSearch } = props
      const parseQS = qs.parse(window.location.search)
      setStateSearch({
        ...stateSearch,
        ...parseQS,
      })
    },

    onChangeLinkPage: props => () => {
      window.history.replaceState(null, undefined, `?${qs.stringify(pickBy(props.stateSearch, identity))}`)
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, search, status,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        query: search || '',
        status: status || '',
        limit: 10,
      }
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
      props.onChangeLinkPage()
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        status: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Verify Perubahan Data'])
      this.props.updateSiteConfigs({
        titlePage: 'Pengajuan Perubahan Data Nasabah',
        activePage: 'update-data',
        activeSubPage: 'update-data',
      })
      this.props.getList()
    },
  }),
)(UpdateDataView)
