import PropTypes from 'prop-types'
import {
  Table, Select,
  Button, Input, Tag,
  Tooltip, Icon, Popconfirm,
} from 'antd'
import history from 'utils/history'
import Helper from 'utils/Helper'
import { isEmpty } from 'lodash'

const ClaimDeathView = ({
  meta, claimList, isLoadStatus,
  isFetching, handlePage, statusList,
  handleSearch, handleChangeStatus, claimType,
  handleChangeClaimType, handleDelete, currentUser,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 50,
      render: (text, record, index) => (meta.per_page * (meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Nama Pemohon',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hubungan Pemohon',
      dataIndex: 'relation_type',
      key: 'relation_type',
      render: text => (!isEmpty(text) ? text.name : '-'),
    },
    {
      title: 'Nomor Peserta',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Jenis Klaim',
      dataIndex: 'claim_type',
      key: 'claim_type',
      render: text => (!isEmpty(text) ? text.name : '-'),
    },
    {
      title: 'Jumlah Klaim',
      dataIndex: 'claim_amount',
      key: 'claim_amount',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let tags
        if (text.name === 'fund transferred') {
          tags = <Tag color="green">{text.status_label || '-'}</Tag>
        } else if (text.name === 'rejected' || text.name === 'full rejection') {
          tags = <Tag color="red">{text.status_label || '-'}</Tag>
        } else {
          tags = <Tag>{text.status_label || '-'}</Tag>
        }
        return tags
      },
    },
    {
      title: 'Claim',
      dataIndex: '',
      key: 'claim',
      render: (text, record) => (
        <Button type="link" style={{ cursor: 'pointer', color: '#69c0ff' }} className="px-0" onClick={() => history.push(`/claim/claim-by-admin/${record.id}/claim-request`)}>Process Claim</Button>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around align-items-center">
          {/* <Icon type="edit" onClick={() => history.push(`/apps-profile/${record.id}/edit`)} /> */}
          <Popconfirm
            title="Are you sure delete this notification?"
            okText="Yes"
            cancelText="No"
            placement="topLeft"
            onConfirm={() => handleDelete(record.id)}
          >
            {(currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-normal-delete') > -1) && (
            <Icon type="delete" />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
            <h6 className="mb-1 mr-2 align-self-center">Filter By: </h6>
            <Select
              placeholder="Status"
              style={{ width: 200 }}
              onChange={handleChangeStatus}
              loading={isLoadStatus}
              disabled={isLoadStatus}
              className="mr-2"
              allowClear
            >
              {(statusList || []).map(item => (
                <Select.Option key={item.name} value={item.name}>
                  <Tooltip placement="topLeft" title={item.status_label}>
                    {item.status_label}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Jenis Klaim"
              style={{ width: 200 }}
              onChange={handleChangeClaimType}
              loading={claimType.isFetching}
              disabled={claimType.isFetching}
              className="mr-2"
              allowClear
            >
              {(claimType.data || []).map(item => (
                <Select.Option key={item.id} value={item.id}>
                  <Tooltip placement="topLeft" title={item.name}>
                    {item.name}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          
            <div className="mr-3">
              <Input.Search
                allowClear
                placeholder="Search..."
                onSearch={handleSearch}
                enterButton
              />
            </div>
          

          {/* <div className="mr-3">
            <Button type="primary">
              <Icon type="download" />
              Unduh Tabel Klaim
            </Button>
          </div> */} 
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-meninggal-write') > -1) && (
                  <Button
                    type="danger"
                    className="ml-auto"
                    onClick={() => history.push('/claim/claim-by-admin/add-form-request')}
                  >
                  Form Request
                  </Button>
          )}
         {(currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-meninggal-write') > -1) && (

                  <Button
                    type="primary"
                    className="ml-2"
                    onClick={() => history.push('/claim/claim-by-admin/add')}
                    >
                  <Icon className="mb-1" type="plus-circle"/>
                   Tambah Klaim
                  </Button>
         )}
          </div>
      </div>
        <Table
          rowKey="id"
          className="table-striped-rows"
          columns={columns}
          dataSource={claimList}
          loading={isFetching}
          pagination={{
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
            total: meta ? meta.total_count : claimList.length,
            current: meta ? meta.current_page : 1,
            onChange: handlePage,
          }}
        />
    </div>
  )
}

ClaimDeathView.propTypes = {
  isFetching: PropTypes.bool,
  isLoadStatus: PropTypes.bool,
  claimList: PropTypes.array,
  meta: PropTypes.object,
  statusList: PropTypes.array,
  claimType: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleChangeClaimType: PropTypes.func,
  handleDelete: PropTypes.func,
  currentUser: PropTypes.object,
}

export default ClaimDeathView
