import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  faqDetail, fetchDetailFaq,
  createFaq, updateFaq,
  getTitleFaq,
} from 'actions/Faq'
import { message } from 'antd'
import { pickBy, identifier } from 'lodash'
import FormFaqView from 'components/pages/faqs/Form'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailFaq,
  } = state.root.faq
  return {
    isFetching,
    detailFaq,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailFaq: bindActionCreators(fetchDetailFaq, dispatch),
  createFaq: bindActionCreators(createFaq, dispatch),
  updateFaq: bindActionCreators(updateFaq, dispatch),
  faqDetail: bindActionCreators(faqDetail, dispatch),
  getTitleFaq: bindActionCreators(getTitleFaq, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmitting', 'setIsSubmitting', false),
  withState('currentState', 'setCurrentState', {
    is_title: true,
    is_content: false,
    firstTab: true,
  }),
  withState('stateTitle', 'setStateTitle', {
    isFetching: true,
    options: [],
  }),
  withHandlers({
    handleSubmit: props => (values) => {
      const { params } = props.match
      const payload = {
        ...values,
        is_title: values.form_type === 1,
        is_content: values.form_type === 0,
        order: values.order ? Number(values.order) : '',
      }
      delete payload.form_type
      props.setIsSubmitting(true)

      props[`${params.id ? 'upd' : 'cre'}ateFaq`](pickBy(payload, identifier), (params.id || null)).then(() => {
        message.success(`FAQ has been ${params.id ? 'upd' : 'cre'}ated`)
        props.setIsSubmitting(false)
        history.push('/faqs')
      }).catch(() => {
        props.setIsSubmitting(false)
      })
    },
    handleRadio: props => (e) => {
      const { value } = e.target
      props.setCurrentState({
        is_title: value,
        is_content: !value,
        firstTab: !props.currentState.firstTab,
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, stateTitle, setStateTitle,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'F.A.Q'])
      this.props.updateSiteConfiguration('titlePage', 'F.A.Q Form')
      this.props.faqDetail({})
      this.props.getTitleFaq('').then(res => (
        setStateTitle({
          ...stateTitle,
          isFetching: false,
          options: res,
        })
      ))
      if (match.params.id) {
        this.props.fetchDetailFaq(match.params.id)
        setIsEdit(true)
      }
    },
  }),
)(FormFaqView)
