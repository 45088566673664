import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { fetchAdminList, fetchRoleList, deleteAdmin } from 'actions/Admin'
import AdminListView from 'components/pages/adminManagement/admin/List'
import { pickBy, identity } from 'lodash'
import { message } from 'antd'
import qs from 'query-string'
import { updateSiteConfiguration } from 'actions/Site'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAdminList: bindActionCreators(fetchAdminList, dispatch),
  fetchRoleList: bindActionCreators(fetchRoleList, dispatch),
  deleteAdmin: bindActionCreators(deleteAdmin, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

const status = [
  { id: 1, value: 'active', name: 'Active' },
  { id: 2, value: 'inactive', name: 'Inactive' },
]

const sortBy = [
  { id: 1, value: 'name', name: 'Name' },
  { id: 2, value: 'last_login', name: 'Last Sign-in' },
  { id: 3, value: 'email', name: 'Email' },
]

const orderBy = [
  { id: 1, value: 'ASC', name: 'Ascending' },
  { id: 2, value: 'DESC', name: 'Descending' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('status', 'setStatus', status),
  withState('orderBy', 'setOrderBy', orderBy),
  withState('sortBy', 'setSortBy', sortBy),
  withState('stateSearch', 'setStateSearch', {}),
  withState('roleList', 'setRoleList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('list', 'setList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getList: props => (params) => {
      const { setList } = props
      props.fetchAdminList(params)
        .then((result) => {
          setList({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
    getRoles: props => (param) => {
      const { setRoleList } = props
      props.fetchRoleList(param)
        .then((result) => {
          const { data, meta } = result
          setRoleList({
            data,
            meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setRoleList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onSetStateSearch: props => () => {
      const { setStateSearch, stateSearch } = props
      const parseQS = qs.parse(window.location.search)
      setStateSearch({
        ...stateSearch,
        ...parseQS,
      })
    },
    onChangeLinkPage: props => () => {
      window.history.replaceState(null, undefined, `?${qs.stringify(pickBy(props.stateSearch, identity))}`)
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, filterStatus, order, role, limit,
        query, sort,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        limit: limit || '',
        filter: filterStatus || '',
        order_by: order || '',
        sort_by: sort || '',
        search: role || '',
        query: query || '',
      }
      if (isSearch) {
        props.setStateSearch({
          ...props.stateSearch,
          page: 1,
        })
      }
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
      props.onChangeLinkPage()
    },
  }),
  withHandlers({
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        filterStatus: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeSort: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        sort: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeOrder: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        order: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeRole: () => () => {
      // role param doesnt exist

      // const { onLoad, setStateSearch, stateSearch } = props
      // setStateSearch({
      //   ...stateSearch,
      //   role: val,
      // })
      // setTimeout(() => {
      //   onLoad()
      // }, 300)
    },
    handlePage: props => (val, pageSize) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
        limit: pageSize,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        query: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleDelete: props => (id) => {
      const { getList } = props
      props.deleteAdmin(id)
        .then(() => {
          message.success('Admin has been Deleted')
          getList()
        })
        .catch((err) => { message.error(err) })
    },
  }),
  lifecycle({
    async componentDidMount() {

      this.props.updateSiteConfiguration('breadList', ['Home', 'Admin Role'])
      this.props.updateSiteConfiguration('titlePage', 'Admin Role')
      // await this.props.onSetStateSearch()
      this.props.onLoad()
      // this.props.getRoles('?limit=10000')
    },
  }),
)(AdminListView)
