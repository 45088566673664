import { connect } from 'react-redux'
import {
  compose, lifecycle, withState, withHandlers,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchDetailAppsVersion, updateAppsVersion } from 'actions/AppsVersion'
import FormAppsVersionView from 'components/pages/appsVersion/Form'
import history from 'utils/history'
import { message } from 'antd'

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDetailAppsVersion: bindActionCreators(fetchDetailAppsVersion, dispatch),
  updateAppsVersion: bindActionCreators(updateAppsVersion, dispatch),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('detailAppsVersion', 'setDetailAppsVersion', {}),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleSubmit: props => (values) => {
      const { params } = props.match
      props.setIsSubmit(!props.isSubmit)
      const payload = {
        ...values,
        force_update: props.status,
      }

      props.updateAppsVersion(payload, (params.id || null)).then(() => {
        message.success('Data has been updated').then(() => (
          history.push('/apps-version')
        ))
      }).catch((err) => {
        message.error(err)
        props.setIsSubmit(!props.isSubmit)
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Apps Management', 'Apps Version'])
      this.props.updateSiteConfigs({
        titlePage: 'Management Apps Version',
        activePage: 'apps-version',
        activeSubPage: 'apps-version',
      })
      this.props.fetchDetailAppsVersion(this.props.match.params.id)
        .then((result) => {
          this.props.setDetailAppsVersion(result.data)
          this.props.setStatus(result.data.force_update)
        })
        .catch((error) => {
          message.error(error.message)
        })
    },
  }),
)(FormAppsVersionView)
