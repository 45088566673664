import {
  COMMISSION_CONFIG_REQUEST,
  COMMISSION_CONFIG_SUCCESS,
  COMMISSION_CONFIG_FAILURE,
} from 'constants/ActionTypes'

const initialState = {
  isFetching: false,
  dataConfig: [],
  metaConfig: {},
}

export default function commissionConfig(state = initialState, action) {
  switch (action.type) {
    case COMMISSION_CONFIG_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case COMMISSION_CONFIG_SUCCESS:
      return {
        ...state,
        isFetching: false,
        dataConfig: action.data,
        metaConfig: action.meta,
      }
    case COMMISSION_CONFIG_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMessage,
      }
    default:
      return state
  }
}
