import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import {
  fetchDetailAdmin, createAdmin, fetchRoleList, updateAdmin,
} from 'actions/Admin'
import { message } from 'antd'
import FormAdminView from 'components/pages/adminManagement/admin/Form'
// import Helper from 'utils/Helper'
// import { pickBy, identity } from 'lodash'
import history from 'utils/history'
import { updateSiteConfiguration } from 'actions/Site'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  fetchDetailAdmin: bindActionCreators(fetchDetailAdmin, dispatch),
  createAdmin: bindActionCreators(createAdmin, dispatch),
  updateAdmin: bindActionCreators(updateAdmin, dispatch),
  fetchRoleList: bindActionCreators(fetchRoleList, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('loadStatus', 'setLoadStatus', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('status', 'setStatus', false),
  withState('roleList', 'setRoleList', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('detail', 'setDetail', {
    data: {},
    meta: {},
    isFetching: true,
  }),
  withHandlers({
    getDetail: props => (id) => {
      const { setDetail } = props
      props.fetchDetailAdmin(id)
        .then((result) => {
          const { data, meta } = result
          if (data.name.toLowerCase() === 'superadmin') {
            message.warning("Super admin can't be edited")
            history.push('/admin-management/admin')
          } else {
            setDetail({
              data,
              meta,
              isFetching: false,
            })
          }
        })
        .catch(() => {
          setDetail({
            data: {},
            meta: {},
            isFetching: false,
          })
        })
    },
    getRoles: props => (param) => {
      const { setRoleList } = props
      props.fetchRoleList(param)
        .then((result) => {
          const { data, meta } = result
          setRoleList({
            data,
            meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setRoleList({
            data: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    handleRadio: props => () => {
      props.setStatus(!props.status)
    },
    handleSubmit: props => (values) => {
      const {
        match, setIsSubmit,
      } = props
      setIsSubmit(true)
      if (match.params.id) {
        props.updateAdmin(match.params.id, values)
          .then(() => {
            setIsSubmit(false)
            message.success('Admin has been Updated')
            history.push('/admin-management/admin')
          })
          .catch(() => {
            setIsSubmit(false)
          })
      } else {
        props.createAdmin(values)
          .then(() => {
            setIsSubmit(false)
            message.success('Admin has been Created')
            history.push('/admin-management/admin')
          })
          .catch(() => {
            setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Admin Role', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Admin Role', 'Form')
      const {
        match, setIsEdit, getDetail, getRoles,
      } = this.props
      getRoles('?limit=10000')
      if (match.params.id) {
        getDetail(match.params.id)
        setIsEdit(true)
      }
    },
  }),
)(FormAdminView)
