import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input, Icon,
  Button, Select, Switch,
} from 'antd'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const FormQuestion = ({
  detailQuestion, handleSubmit,
  form, handleSelect, isEdit,
  selectOption, loadCourse,
  isAnswer, detailAnswer,
  selectedOption,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        {isAnswer
          ? (
            <div>
              <Form.Item label="Question">
                {getFieldDecorator('question_id', {
                  initialValue: selectedOption || detailAnswer.question_id,
                  rules: [{ required: true }],
                })(
                  <Select
                    onChange={handleSelect}
                    loading={loadCourse}
                    placeholder="Select Question"
                  >
                    {selectOption.map(item => <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Answer">
                {getFieldDecorator('answer', {
                  initialValue: detailAnswer.answer,
                  rules: [{ required: true }],
                })(
                  <Input placeholder="Input Answer" />,
                )}
              </Form.Item>
              <Form.Item label="Correct Answer">
                {getFieldDecorator('is_correct_answer', {
                  initialValue: detailAnswer.is_correct_answer,
                })(
                  <Switch
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                  />,
                )}
              </Form.Item>
            </div>
          )
          : (
            <div>
              <Form.Item label="Course">
                {getFieldDecorator('course_id', {
                  initialValue: detailQuestion.course_id,
                  rules: [{ required: true }],
                })(
                  <Select
                    onChange={handleSelect}
                    loading={loadCourse}
                    placeholder="Select Course"
                  >
                    {selectOption.map(item => <Select.Option key={item.id} value={item.id}>{item.label}</Select.Option>)}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item label="Question">
                {getFieldDecorator('question', {
                  initialValue: detailQuestion.question,
                  rules: [{ required: true }],
                })(
                  <Input placeholder="Input Title" />,
                )}
              </Form.Item>
            </div>
          )
        }
        <Form.Item>
          <Button type="primary" htmlType="submit">{isEdit ? 'Submit Changes' : 'Create'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormQuestion.propTypes = {
  detailQuestion: PropTypes.object,
  detailAnswer: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleSelect: PropTypes.func,
  selectOption: PropTypes.array,
  selectedOption: PropTypes.string,
  loadCourse: PropTypes.bool,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  isAnswer: PropTypes.bool,
}

export default Form.create({ name: 'quizForm' })(FormQuestion)
