import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import UpdateDataPage from 'containers/pages/updateData/List'
import UpdateDataDetail from 'containers/pages/updateData/Detail'
import DuplicateData from 'containers/pages/updateData/duplicateData/Duplicate'

const UpdateData = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/update-data') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pengkinian-data-read') > -1) {
      return <UpdateDataPage location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('detail') && location.pathname.includes('duplicate')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pengkinian-data-write') > -1) {
      return <DuplicateData location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/detail')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-pengkinian-data-read') > -1) {
      return <UpdateDataDetail location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

UpdateData.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default UpdateData
