/* eslint-disable prefer-promise-reject-errors */
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import {
  Button, Form, Input, Card,
} from 'antd'

const ResetPass = (props) => {
  const {
    onSubmit,
    isSubmit,
    form,
  } = props

  const { getFieldDecorator, validateFields, getFieldValue } = form
  return (
    <div
      className="form-container d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: 'url(/assets/bg_dplk.svg)',
        height: '100vh',
        backgroundSize: 'cover',
        backgroundColor: '#fff',
      }}
    >
      <Form
        name="resetPass"
        className="form-reset-password px-3 pt-4 pb-2 card-login"
        layout="vertical"
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              onSubmit(values)
            }
          })
        }}
      >
        <h5 className="mb-4" style={{ color: '#384A76' }}>Change Password</h5>
        <Row>
          <Col lg={10} className="mx-auto">
            <Form.Item label="Password" hasFeedback>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  { pattern: /^.{8,}$/, message: '*Please enter at least 8 characters\n' },
                  { pattern: /(?=.*[A-Z])(?=.*\d)/, message: '*Password must contain at least one uppercase and number\n' },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item label="Confirm Password" hasFeedback>
              {getFieldDecorator('password_confirmation', {
                rules: [
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  {
                    validator: (rule, value) => {
                      if (getFieldValue('password') != value) {
                        return Promise.reject(('The Password you entered does not match!'))
                      }
                      return Promise.resolve()
                    },
                  },
                ],
              })(<Input.Password />)}
            </Form.Item>
            <Form.Item className="pt-3 btn-primary-wrapper">
              <Button type="primary" className="w-100" size="large" loading={isSubmit} htmlType="submit">Submit</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

ResetPass.propTypes = {
  onSubmit: PropTypes.func,
  onSubmitFailed: PropTypes.func,
  isSubmit: PropTypes.bool,
  form: PropTypes.any,
}

export default Form.create({ name: 'ResetPass' })(ResetPass)
