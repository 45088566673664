import API from 'utils/API'
import { message } from 'antd'
import {
  CATEGORY_COURSE_REQUEST,
  CATEGORY_COURSE_SUCCESS,
  CATEGORY_COURSE_FAILURE,
  CATEGORY_COURSE_UPDATED,
  CATEGORY_COURSE_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const categoryRequest = () => ({
  type: CATEGORY_COURSE_REQUEST,
})

export const categorySuccess = (data, meta) => ({
  type: CATEGORY_COURSE_SUCCESS,
  data,
  meta,
})

export const categoryFailure = errorMessage => ({
  type: CATEGORY_COURSE_FAILURE,
  errorMessage,
})

export const categoryUpdate = data => ({
  type: CATEGORY_COURSE_UPDATED,
  data,
})

export const categoryDetail = data => ({
  type: CATEGORY_COURSE_DETAIL_SUCCESS,
  data,
})

export const fetchCategory = params => (
  (dispatch) => {
    dispatch(categoryRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/course-categories${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(categorySuccess(data, meta))
        } else {
          dispatch(categoryFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(categoryFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailCategory = id => (
  (dispatch) => {
    dispatch(categoryRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/course-categories/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(categoryDetail(data))
        } else {
          dispatch(categoryFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(categoryFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteCategory = id => (
  (dispatch) => {
    dispatch(categoryRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/course-categories/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Data has been deleted')
          dispatch(categoryUpdate(data))
        } else {
          message.error(meta.message)
          dispatch(categoryFailure(meta.message))
        }
      },
    ).catch((err) => {
      message.error(err.message)
      dispatch(categoryFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createCategory = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/course-categories`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)

export const updateCategory = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/course-categories/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      if (err.response && err.response.data) {
        const { meta } = err.response.data
        message.error(meta.message)
        reject(meta.message)
      } else {
        message.error(err.message)
        reject(err.message)
      }
    })
  ))
)
