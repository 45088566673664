import PropTypes from 'prop-types'
import {
  Table, Icon, Button,
} from 'antd'
import moment from 'moment'
import history from 'utils/history'

const CommissionConfigPage = ({
  isFetching, dataConfig,
  /* handleSearch, */metaConfig,
  handlePage,
}) => {
  const columns = [
    {
      title: 'Commission Percent',
      dataIndex: 'commission_percent',
      key: 'commission_percent',
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/commission/config/${record.id}/edit`)} />
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center btn-primary-wrapper pb-3">
        {/* <div className="mr-3">
          <Input.Search
            allowClear
            placeholder="Search..."
            onSearch={handleSearch}
          />
        </div> */}
        <div>
          <Button
            type="primary"
            onClick={() => history.push('/commission/config/add')}
          >
            Add Data
          </Button>
        </div>
      </div>
      <Table
        bordered
        rowKey="id"
        columns={columns}
        dataSource={dataConfig}
        loading={isFetching}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: metaConfig ? metaConfig.total_count : dataConfig.length,
          current: metaConfig ? metaConfig.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}


CommissionConfigPage.propTypes = {
  isFetching: PropTypes.bool,
  dataConfig: PropTypes.array,
  /* handleSearch: PropTypes.func, */
  metaConfig: PropTypes.object,
  handlePage: PropTypes.func,
}

export default CommissionConfigPage
