import { SET_SITE_CONFIGURATION, SET_SITE_CONFIGS } from 'constants/ActionTypes'

export const updateSiteConfiguration = (dataKey, dataValue) => (
  {
    type: SET_SITE_CONFIGURATION,
    dataKey,
    dataValue,
  }
)

export const updateSiteConfigs = params => (
  {
    type: SET_SITE_CONFIGS,
    params,
  }
)
