import API from 'utils/API'
import config from 'app/config'

export const fetchAdminList = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/admin${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailAdmin = id => (
  () => new Promise((resolve, reject) => (
    API.get(`/admin/${id || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createAdmin = payload => (
  () => new Promise((resolve, reject) => (
    API.post('/admin', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateAdmin = (id, payload) => (
  () => new Promise((resolve, reject) => (
    API.put(`/admin/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const deleteAdmin = id => (
  () => new Promise((resolve, reject) => (
    API.delete(`/admin/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchPermission = params => (
  () => new Promise((resolve, reject) => (
    API.get(
      `/permissions${params || ''}`,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchRoleList = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/roles${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createRole = payload => (
  () => new Promise((resolve, reject) => (
    API.post('/roles', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateRole = (id, payload) => (
  () => new Promise((resolve, reject) => (
    API.put(`/roles/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailRole = id => (
  () => new Promise((resolve, reject) => (
    API.get(`/roles/${id || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const deleteRole = id => (
  () => new Promise((resolve, reject) => (
    API.delete(`/roles/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateAdminPassword = payload => (
  () => new Promise((resolve, reject) => (
    API.post(
      '/auth/password',
      payload,
      { headers: { 'x-api-key': config.api_key } },
    ).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
