import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form,
  Input, Button, Icon,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormPakDe = ({
  handleSubmit,
  form, isEdit,
  isSubmitting,
  detailPakDe,
}) => {
  const {
    getFieldDecorator, validateFields,
  } = form

  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: detailPakDe.name,
            rules: [{ required: !isEdit }],
          })(
            <Input placeholder="Name" disabled={isEdit} />,
            /* <Select
              placeholder="Select Name"
              disabled={isEdit}
            >
              <Select.Option value="link1">Link Video Pengenalan DPLK Indolife</Select.Option>
              <Select.Option value="link2">Link Video Pengenalan Program PAKDE</Select.Option>
            </Select>, */
          )}
        </Form.Item>
        <Form.Item label="Value">
          {getFieldDecorator('value', {
            initialValue: isEdit ? detailPakDe.value : '',
            rules: [{ required: true }],
          })(
            <Input.TextArea rows={4} placeholder="Input Value" />,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/content-management/pakde/list')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {isSubmitting && <Icon type="loading" />}
            {!isEdit ? 'Add PAKDE' : 'Submit Change'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormPakDe.propTypes = {
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  detailPakDe: PropTypes.object,
}

export default Form.create({ name: 'pakdeForm' })(FormPakDe)
