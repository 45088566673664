import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchCategories, createCategory, updateCategory } from 'actions/ContactUsMessages'
import { message } from 'antd'
import FormContactUsCategoryView from 'components/pages/webManagement/contactUsCategories/Form'
import { find } from 'lodash'
import history from 'utils/history'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchCategories: bindActionCreators(fetchCategories, dispatch),
  createCategory: bindActionCreators(createCategory, dispatch),
  updateCategory: bindActionCreators(updateCategory, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', false),
  withState('isDisable', 'setIsDisable', true),
  withState('categoryName', 'setCategoryName', ''),
  withState('detail', 'setDetail', {}),
  withHandlers({
    handleChangeCategoryName: props => (e) => {
      const { categoryName, setIsDisable } = props
      if (categoryName === e.target.value) {
        setIsDisable(true)
      } else {
        setIsDisable(false)
      }
    },
    handleSubmit: props => (values) => {
      props.setIsSubmit(true)
      if (props.match.params.id) {
        props.updateCategory(values, props.match.params.id)
          .then(() => {
            props.setIsSubmit(false)
            history.push('/web-management/contact-us-categories')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      } else {
        props.createCategory(values)
          .then(() => {
            props.setIsSubmit(false)
            history.push('/web-management/contact-us-categories')
          })
          .catch((err) => {
            message.error(err)
            props.setIsSubmit(false)
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit, setDetail, setCategoryName,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Web Management', 'Contact Us Category', 'Form'])
      this.props.updateSiteConfiguration('titlePage', 'Category Form')
      if (match.params.id) {
        this.props.fetchCategories()
          .then((result) => {
            const detail = find(result.data, ['id', match.params.id])
            setDetail(detail)
            setCategoryName(detail.name)
          })
          .catch((err) => {
            message.error(err)
          })
        setIsEdit(true)
      }
    },
  }),
)(FormContactUsCategoryView)
