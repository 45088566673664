import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  Layout, Menu, Icon,
} from 'antd'

const { Sider } = Layout
const { SubMenu } = Menu

const SideBar = ({
  handleKeys, site,
  collapse,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)
  const [isBroken, setIsBroken] = useState(false)

  return (
    <Sider
      theme="light"
      width={200}
      className="site-layout-background h-100"
      style={{ boxShadow: '-1px 1px 12px rgba(0, 34, 108, 0.15)' }}
      breakpoint="lg"
      collapsedWidth={isCollapse ? '0' : '84'}
      trigger={null}
      collapsible
      collapsed={isBroken ? !collapse : collapse}
      onBreakpoint={(broken) => {
        if (broken) {
          setIsCollapse(true)
          setIsBroken(true)
        } else {
          setIsCollapse(false)
          setIsBroken(false)
        }
      }}
      onCollapse={() => {

      }}
    >
      <div className="logo p-3">
        {isBroken
          ? <img src="/assets/logo_dplk.svg" className={`main-logo ${!collapse ? 'transition-logo' : ''}`} alt="logo" />
          : <img src="/assets/logo_dplk.svg" className={`main-logo ${collapse ? 'transition-logo' : ''}`} alt="logo" />
        }
      </div>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[site.activePage]}
        openKeys={[site.activeSubPage]}
        onClick={e => handleKeys(e)}
        onOpenChange={e => handleKeys(e, true)}
      >
        <Menu.Item key="dashboard">
          <Icon type="home" />
          <span>Dashboard</span>
        </Menu.Item>
        <SubMenu
          key="admin-management"
          title={(
            <React.Fragment>
              <Icon type="user" />
              <span>Admin Management</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="admin-management/role">Role</Menu.Item>
          <Menu.Item key="admin-management/admin">Admin</Menu.Item>
        </SubMenu>
        <Menu.Item key="notification">
          <Icon type="notification" />
          <span>Notification Announcement</span>
        </Menu.Item>
        {/* <Menu.Item key="categories">
          <span>
            <Icon type="appstore" />
            Course Category
          </span>
        </Menu.Item> */}
        <SubMenu
          key="elearning-management"
          title={(
            <React.Fragment>
              <Icon type="tool" />
              <span>E-Tools</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="elearning/content">E-Learning Content</Menu.Item>
          <Menu.Item key="elearning/categories">Course Category</Menu.Item>
          {/* <Menu.Item key="elearning/quiz/question">E-Learning Quiz</Menu.Item> */}
        </SubMenu>
        {/* <Menu.Item key="about">
          <Icon type="info-circle" />
          <span>Tentang DPLK Indolife</span>
        </Menu.Item> */}
        {/* <Menu.Item key="calculator-management">
            <span>
              <Icon type="calculator" />
              Asumsi Nilai Suku Bunga
            </span>
        </Menu.Item>
  */}
        <SubMenu
          key="calculator-management"
          title={(
            <React.Fragment>
              <Icon type="calculator" />
              <span>Calculator Management</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="calculator-management/asumsi-nilai-suku-bunga">Asumsi Nilai Suku Bunga</Menu.Item>
          <Menu.Item key="calculator-management/biaya-dplk">Biaya DPLK</Menu.Item>
        </SubMenu>

        <SubMenu
          key="dplk-calculation-table"
          title={(
            <React.Fragment>
              <Icon type="history" />
              <span>Riwayat Hasil Pengembangan DPLK</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="dplk-calculation-table/investment-history">Investment History</Menu.Item>
          <Menu.Item key="dplk-calculation-table/investment-year">Investment Year</Menu.Item>
        </SubMenu>
        <Menu.Item key="about-dplk">
          <Icon type="picture" />
          <span>DPLK Anda</span>
        </Menu.Item>
        <Menu.Item key="user">
          <Icon type="team" />
          <span>Member Management</span>
        </Menu.Item>
        <Menu.Item key="download">
          <Icon type="appstore" />
          <span>List Download</span>
        </Menu.Item>
        <SubMenu
          key="customer-management"
          title={(
            <React.Fragment>
              <Icon type="idcard" />
              <span>Customer Management</span>
            </React.Fragment>
          )}
        >
          {/* <Menu.Item key="customer/verify">Verify Customer</Menu.Item> */}
          <Menu.Item key="customer/phone">Verify Phone Number</Menu.Item>
          <Menu.Item key="customer/update-profile">Verify Update Profile</Menu.Item>
        </SubMenu>
        <Menu.Item key="inject-point">
          <Icon type="project" />
          <span>Inject Point</span>
        </Menu.Item>
        <SubMenu
          key="content-management"
          title={(
            <React.Fragment>
              <Icon type="deployment-unit" />
              <span>Content Management</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="content-management/pakde/list">Ajak Teman</Menu.Item>
          <Menu.Item key="content-management/colek-teman">Colek Teman</Menu.Item>
          {/* <Menu.Item key="content-management/ingatkan-teman-menabung">Ingatkan Teman Menabung</Menu.Item> */}
        </SubMenu>

        <Menu.Item key="payment-configuration">
          <Icon type="bank" />
          <span>Payment Configuration</span>
        </Menu.Item>
        <Menu.Item key="ottopay">
          <Icon type="pay-circle" />
          <span>Ottopay</span>
        </Menu.Item>
        <SubMenu
          key="auto-debet"
          title={(
            <React.Fragment>
              <Icon type="credit-card" />
              <span>Autodebet</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="auto-debet/pendaftaran">Pendaftaran</Menu.Item>
          <Menu.Item key="auto-debet/pembatalan">Pembatalan</Menu.Item>
          <Menu.Item key="auto-debet/log-payment">Log Payment</Menu.Item>
        </SubMenu>
        <Menu.Item key="commission">
          <Icon type="dollar" />
          <span>Commission</span>
        </Menu.Item>
        {/* <SubMenu
          key="commission"
          title={(
            <React.Fragment>
              <Icon type="dollar" />
              <span>Commission</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="commission/pending-list">Pending List</Menu.Item>
          <Menu.Item key="commission/approved-list">Approved List</Menu.Item>
        </SubMenu> */}
        <Menu.Item key="benefit">
          <Icon type="rise" />
          <span>Pelajari Manfaat</span>
        </Menu.Item>

        {/* HIDE PANDUAN SETORAN
        <Menu.Item key="deposit-guide">
          <Icon type="account-book" />
          <span>Panduan Setoran</span>
        </Menu.Item> */}
        {/* <Menu.Item key="faqs">
          <Icon type="question-circle" />
          <span>FAQ</span>
        </Menu.Item> */}
        <SubMenu
          key="claim"
          title={(
            <React.Fragment>
              <Icon type="file-protect" />
              <span>Claim</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="claim/claim-normal">Normal</Menu.Item>
          <Menu.Item key="claim/claim-by-admin">By Admin</Menu.Item>
        </SubMenu>
        <SubMenu
          key="ultra-vouchers"
          title={(
            <React.Fragment>
              <Icon type="gift" />
              <span>Voucher</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="ultra-vouchers/categories">Voucher Category</Menu.Item>
          <Menu.Item key="ultra-vouchers/brands">Voucher Brand</Menu.Item>
          <Menu.Item key="ultra-vouchers/products">Voucher Product</Menu.Item>
          <Menu.Item key="ultra-vouchers/logs">Voucher Logs</Menu.Item>
        </SubMenu>
        <SubMenu
          key="cso"
          title={(
            <React.Fragment>
              <Icon type="info-circle" />
              <span>CSO</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="pdp">PDP</Menu.Item>
          <Menu.Item key="faqs">F.A.Q</Menu.Item>
          <Menu.Item key="about">Tentang DPLK</Menu.Item>
        </SubMenu>
        <SubMenu
          key="ottocash"
          title={(
            <React.Fragment>
              <Icon type="wallet" />
              <span>Ottocash</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="dana-ottocash/management-ottocash">Management Ottocash</Menu.Item>
          <Menu.Item key="dana-ottocash/management-ottocash/logs">Logs</Menu.Item>
        </SubMenu>
        <Menu.Item key="update-data">
          <Icon type="sync" />
          <span>Pengkinian Data</span>
        </Menu.Item>
        <Menu.Item key="apps-version">
          <Icon type="appstore" />
          <span>Apps Version</span>
        </Menu.Item>
        <SubMenu
          key="web-management"
          title={(
            <React.Fragment>
              <Icon type="control" />
              <span>Web Management</span>
            </React.Fragment>
          )}
        >
          <Menu.Item key="web-management/contact-us-categories">Contact Us Categories</Menu.Item>
          <Menu.Item key="web-management/contact-us-messages">Simpan Messages</Menu.Item>
          <Menu.Item key="web-management/reviews">Reviews</Menu.Item>
          <Menu.Item key="web-management/banners">Banners</Menu.Item>
          <Menu.Item key="web-management/banner-apps">Banner Apps</Menu.Item>
          <Menu.Item key="web-management/penjelasan-simpan">Penjelasan Simpan</Menu.Item>
          <Menu.Item key="web-management/highlight-simpan">Highlight Simpan</Menu.Item>
          <Menu.Item key="web-management/video-simpan">Video Simpan</Menu.Item>
          <Menu.Item key="web-management/about-pak-d">About Pak-D</Menu.Item>
          <Menu.Item key="web-management/pak-d-benefits">Pak-D Benefits</Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  )
}

SideBar.propTypes = {
  handleKeys: PropTypes.func,
  site: PropTypes.object,
  collapse: PropTypes.bool,
}

export default SideBar
