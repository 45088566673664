import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input, Button,
  Upload, Icon, Avatar, /* Switch, */
  Select,
} from 'antd'
import { isEmpty } from 'lodash'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormLearningContent = ({
  /* handleSelectType,  defaultFileList, */
  handleSubmit, form, handleUpload,
  bannerFileList, handleSelect,
  selectOption, loadCategory,
  isEdit, detailCourse,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Category">
          {getFieldDecorator('category_id', {
            initialValue: detailCourse.category ? detailCourse.category.id : undefined,
            rules: [{ required: true }],
          })(
            <Select
              onChange={handleSelect}
              loading={loadCategory}
              disabled={isEmpty(selectOption)}
              placeholder="Select Category"
            >
              {selectOption.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
            </Select>,
          )}
        </Form.Item>
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: detailCourse.title,
            rules: [{ required: true }],
          })(
            <Input placeholder="Input Title" />,
          )}
        </Form.Item>
        <Form.Item label="Content">
          {getFieldDecorator('content', {
            initialValue: detailCourse.content,
            rules: [{ required: true }],
          })(
            <Input.TextArea rows={4} placeholder="Input Content" />,
          )}
        </Form.Item>
        <Form.Item label="Banner Image">
          {getFieldDecorator('banner_file', {
            // initialValue: detailCourse.banner_file,
            rules: [{ required: !isEdit }],
          })(
            <Upload
              name="banner_file"
              listType="picture-card"
              className="avatar-uploader banner-content"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={info => handleUpload(info, 'banner')}
            >
              {bannerFileList
                ? (
                  <Avatar src={bannerFileList} shape="square" className="banner-preview" />
                )
                : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" style={{ fontSize: '24px' }} />
                    </p>
                    <p className="ant-upload-text">Upload Banner</p>
                  </div>
                )
              }
            </Upload>,
          )}
        </Form.Item>
        {/* <Form.Item label="File Type">
          {getFieldDecorator('file_type', {
            initialValue: detailCourse.file_type || 'link',
            rules: [{ required: true }],
          })(
            <Select
              onChange={handleSelectType}
              placeholder="Select File Type"
              defaultValue="link"
              disabled
            >
              <Select.Option value="video">Video</Select.Option>
              <Select.Option value="pdf">Pdf</Select.Option>
              <Select.Option value="link">Link</Select.Option>
            </Select>,
          )}
        </Form.Item> */}
        {/* <Form.Item label="File">
          {getFieldDecorator(getFieldsValue().file_type === 'link' ? 'link' : 'file_url', {
            // initialValue: detailCourse.file,
            rules: [{ required: !isEdit }],
          })(
            getFieldsValue().file_type === 'link'
              ? (
                <Input placeholder="Input link" />
              )
              : (
                <Upload
                  name="file_url"
                  beforeUpload={() => false}
                  fileList={defaultFileList}
                  onRemove={false}
                  onChange={info => handleUpload(info, 'file_url')}
                >
                  <Button>
                    <Icon type="upload" />
                    {' '}
                    Upload
                  </Button>
                </Upload>
              ),
          )}
        </Form.Item> */}
        <Form.Item label="File">
          {getFieldDecorator('link', {
            initialValue: isEdit ? detailCourse.file_url : detailCourse.link,
            rules: [{ required: !isEdit }],
          })(
            <Input placeholder="Input link" />,
          )}
        </Form.Item>
        {/* <Form.Item label="is Active">
          {getFieldDecorator('is_active', {
            initialValue: detailCourse.is_active,
          })(
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
            />,
          )}
        </Form.Item> */}
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/elearning/content')} className="mr-2">Cancel</Button>
          <Button type="primary" htmlType="submit">{!isEdit ? 'Add Content Course' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormLearningContent.propTypes = {
  detailCourse: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleUpload: PropTypes.func,
  bannerFileList: PropTypes.any,
  handleSelect: PropTypes.func,
  // handleSelectType: PropTypes.func,
  selectOption: PropTypes.array,
  loadCategory: PropTypes.bool,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  // defaultFileList: PropTypes.array,
}

export default Form.create({ name: 'learningForm' })(FormLearningContent)
