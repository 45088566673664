import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, Upload, Avatar,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormUltraVoucherCategory = ({
  detailUltraVoucherCategory, handleSubmit,
  form, isEdit, handleUpload, iconFile,
  isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Icon">
          {getFieldDecorator('image', {
            rules: [{ required: !isEdit }],
          })(
            <Upload
              name="icon"
              listType="picture-card"
              className="avatar-uploader banner-content"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={e => handleUpload(e)}
              accept="image/*"
              fileList={iconFile}
            >
              {iconFile ? (
                <Avatar src={iconFile} size={250} shape="square" className="banner-preview" />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: isEdit ? detailUltraVoucherCategory.name : '',
            rules: [{ required: true }],
          })(
            <Input placeholder="Input Name" />,
          )}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            initialValue: isEdit ? detailUltraVoucherCategory.description : '',
            rules: [{ required: true }],
          })(
            <Input.TextArea rows={4} placeholder="Input Description" />,
          )}
        </Form.Item>

        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/ultra-vouchers/categories')} className="mr-2">Cancel</Button>
          <Button loading={isSubmit} type="primary" htmlType="submit">{!isEdit ? 'Add Data' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormUltraVoucherCategory.propTypes = {
  detailUltraVoucherCategory: PropTypes.object,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  bannerFileList: PropTypes.any,
  handleUpload: PropTypes.func,
  iconFile: PropTypes.any,
  isSubmit: PropTypes.bool,
}

export default Form.create({ name: 'ultraVoucherCategoryForm' })(FormUltraVoucherCategory)
