import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchContentCourse, deleteContentCourse } from 'actions/LearningContent'
import LearningContentView from 'components/pages/elearnings/contents/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

export function mapStateToProps(state) {
  const {
    isFecthing,
    dataCourse,
    metaCourse,
  } = state.root.learningContent
  const { currentUser } = state.root.auth

  return {
    isFecthing,
    dataCourse,
    metaCourse,
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchContentCourse: bindActionCreators(fetchContentCourse, dispatch),
  deleteContentCourse: bindActionCreators(deleteContentCourse, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('modalContent', 'setModalContent', ''),
  withState('stateSearch', 'setStateSearch', {}),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page, search } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
      }

      props.fetchContentCourse(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleModal: props => (record) => {
      let value
      let isWatch
      let url
      if (record.file_type) {
        // const { file } = record
        switch (record.file_type) {
          case 'link':
            isWatch = record.file_url.includes('watch') || ''
            if (isWatch) {
              const x = record.file_url.replace('watch?v=', 'embed/')
              url = x.substring(0, x.indexOf('&'))
            } else {
              url = `https://www.youtube.com/embed/${record.file_url.substring(record.file_url.lastIndexOf('/') + 1)}`
            }
            return (
              props.setModalContent(
                <iframe width="420" height="315" src={url} frameBorder="0" title="video" />,
              )
            )
          case 'video':
            return (
              props.setModalContent(
                <video width="420" height="315" controls="controls" preload="metadata">
                  <source src={record.file_url} type="video/mp4" />
                  <track kind="captions" />
                </video>,
              )
            )
          default:
            return (
              props.setModalContent('')
            )
        }
      } else {
        return props.setModalContent(value)
      }
    },
    handleDelete: props => (id) => {
      props.deleteContentCourse(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'e-Learning', 'Content'])
      this.props.updateSiteConfigs({
        titlePage: 'e-Learning Content',
        activePage: 'elearning/content',
        activeSubPage: 'elearning-management',
      })
      this.props.fetchContentCourse()
    },
  }),
)(LearningContentView)
