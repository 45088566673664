import API from 'utils/API'
import {
  OTTOCASH_REQUEST,
  OTTOCASH_SUCCESS,
  OTTOCASH_FAILURE,
  STATUS_OTTOCASH_REQUEST,
  STATUS_OTTOCASH_FAILURE,
  STATUS_OTTOCASH_SUCCESS,
} from 'constants/ActionTypes'

export const ottocashRequest = () => ({
  type: OTTOCASH_REQUEST,
})

export const statusOttocashRequest = () => ({
  type: STATUS_OTTOCASH_REQUEST,
})

export const ottocashSuccess = (data, meta) => ({
  type: OTTOCASH_SUCCESS,
  data,
  meta,
})

export const statusOttocashSuccess = (data, meta) => ({
  type: STATUS_OTTOCASH_SUCCESS,
  data,
  meta,
})

export const ottocashFailure = errorMessage => ({
  type: OTTOCASH_FAILURE,
  errorMessage,
})

export const statusOttocashFailure = errorMessage => ({
  type: STATUS_OTTOCASH_FAILURE,
  errorMessage,
})


/* export const fetchDanaOttocash = () => (
  () => new Promise((resolve, reject) => (
    API.get(`https://private-ec1f5-dplk.apiary-mock.com/danaottocash`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
) */

export const fetchDenomOttocash = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/ottocash-nominal${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const createDenomOttocash = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/ottocash-nominal`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const updateDenomOttocash = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/ottocash-nominal/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailDenom = id => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/ottocash-nominal/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const deleteDenom = id => (
  () => new Promise((resolve, reject) => (
    API.delete(`${process.env.APP_CONFIG.api_url_content}/ottocash-nominal/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchOttocashLog = params => (
  (dispatch) => {
    dispatch(ottocashRequest())
    const url = `${process.env.APP_CONFIG.api_url_payment}/ottocash/disburse-logs${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(ottocashSuccess(data, meta))
        } else {
          dispatch(ottocashFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ottocashFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const statusOttocashLog = params => (
  (dispatch) => {
    dispatch(statusOttocashRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/ottocash/getstatus`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(statusOttocashSuccess(data, meta))
        } else {
          dispatch(statusOttocashFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(ottocashFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_payment}/ottocash/disburse-logs/export${params || ''}`, { responseType: 'arraybuffer' }).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
