import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfigs, updateSiteConfiguration } from 'actions/Site'
import { fetchCommissionTx } from 'actions/Commission'
import DownlinePoint from 'components/pages/users/downline'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchCommissionTx: bindActionCreators(fetchCommissionTx, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('data', 'setData', {
    list: [],
    meta: {},
    isFetching: false,
  }),
  withHandlers({
    getList: props => (params) => {
      const { match, setData } = props
      props.fetchCommissionTx(match.params.id, params)
        .then((result) => {
          setData({
            list: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch(() => {
          setData({
            list: [],
            meta: {},
            isFetching: false,
          })
        })
    },
  }),
  withHandlers({
    onLoad: props => (isSearch) => {
      const { page } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
      }
      props.getList(`?${qs.stringify(pickBy(payload, identity))}`)
    },
  }),
  withHandlers({
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Home', 'Member Management', 'History Poin', 'Downline'])
      this.props.updateSiteConfigs({
        titlePage: 'Downline User',
        activePage: 'user',
        activeSubPage: 'user',
      })
      this.props.getList()
    },
  }),
)(DownlinePoint)
