import API from 'utils/API'
import {
  DASHBOARD_REQUEST,
  DASHBOARD_SUCCESS,
  DASHBOARD_FAILURE,
} from 'constants/ActionTypes'

export const dashboardRequest = () => ({
  type: DASHBOARD_REQUEST,
})

export const dashboardSuccess = (data, meta) => ({
  type: DASHBOARD_SUCCESS,
  data,
  meta,
})

export const dashboardFailure = errorMessage => ({
  type: DASHBOARD_FAILURE,
  errorMessage,
})


export const fetchDashboard = params => (
  (dispatch) => {
    dispatch(dashboardRequest())
    const url = `/auth/dashboard${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(dashboardSuccess(data, meta))
        } else {
          dispatch(dashboardFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(dashboardFailure(err.message)) // eslint-disable-line no-console
    })
  }
)
