import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDetailVerifyCustomer } from 'actions/Customer'
import DetailCustRegisView from 'components/pages/customers/detail'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailCustomer,
  } = state.root.customer

  return {
    isFetching,
    detailCustomer,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailVerifyCustomer: bindActionCreators(fetchDetailVerifyCustomer, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withHandlers({

  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Customer', 'Detail'])
      if (match.params.id) {
        this.props.fetchDetailVerifyCustomer(match.params.id)
      }
    },
  }),
)(DetailCustRegisView)
