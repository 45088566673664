import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose, lifecycle } from 'recompose'
import { updateSiteConfigs } from 'actions/Site'
import AdminView from 'components/pages/adminManagement/admin'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfigs({
        titlePage: 'Admin',
        activePage: 'admin-management/admin',
        activeSubPage: 'admin-management',
      })
    },
  }),
)(AdminView)
