import API from 'utils/API'
import {
  COMMISSION_REQUEST,
  COMMISSION_SUCCESS,
  COMMISSION_FAILURE,
} from 'constants/ActionTypes'
import { message } from 'antd'
import history from 'utils/history'

export const commissionRequest = () => ({
  type: COMMISSION_REQUEST,
})

export const commissionSuccess = (data, meta) => ({
  type: COMMISSION_SUCCESS,
  data,
  meta,
})

export const commissionFailure = errorMessage => ({
  type: COMMISSION_FAILURE,
  errorMessage,
})

export const fetchCommission = params => (
  (dispatch) => {
    dispatch(commissionRequest())
    const url = `/commission${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(commissionSuccess(data, meta))
        } else {
          dispatch(commissionFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(commissionFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const approveCommission = payload => (
  () => new Promise((resolve, reject) => (
    API.put('/commission', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success(data)
          history.push('/commission')
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/commission/export${params || ''}`, { responseType: 'blob' }).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchDetailCommission = (id, params) => (
  () => new Promise((resolve, reject) => (
    API.get(`/commission/member/${id}${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

export const fetchCommissionTx = id => (
  () => new Promise((resolve, reject) => (
    API.get(`/commission/transaction/${id}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)

