import API from 'utils/API'
import {
  UVLOGS_REQUEST,
  UVLOGS_SUCCESS,
  UVLOGS_FAILURE,
} from 'constants/ActionTypes'

export const uvLogsRequest = () => ({
  type: UVLOGS_REQUEST,
})

export const uvLogsSuccess = (data, meta) => ({
  type: UVLOGS_SUCCESS,
  data,
  meta,
})

export const uvLogsFailure = errorMessage => ({
  type: UVLOGS_FAILURE,
  errorMessage,
})


export const fetchUvLogs = params => (
  (dispatch) => {
    dispatch(uvLogsRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/voucher-request${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(uvLogsSuccess(data, meta))
        } else {
          dispatch(uvLogsFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(uvLogsFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`${process.env.APP_CONFIG.api_url_content}/voucher-requests/export${params || ''}`, { responseType: 'arraybuffer' }).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message) // eslint-disable-line no-console
    })
  ))
)
