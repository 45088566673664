import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Row, Col,
  Tag, Skeleton, Button,
  Modal, Icon, Input,
} from 'antd'
import Helper from 'utils/Helper'
import history from 'utils/history'

const AutoDebitPembatalanDetail = ({
  isFetching, isSubmit, isApprove,
  detailAutoDebitPembatalan, currentUser, rejectModal, rejectNotes,
  handleReject, handleApprove, handleChangeRejectNotes,
  toggleRejectModal,
}) => (
  <div className="pb-5">
    {isFetching
      ? [1, 2, 3].map(index => (
        <Row className="mb-3" key={index}>
          <Col span={13}>
            <Card className="shadow-sm mb-3">
              <div>
                <Skeleton active />
              </div>
            </Card>
          </Col>
        </Row>
      ))
      : (
        <>
          <Row className="mb-3">
            <Col span={13}>
              <Card className="shadow-sm">
                <Row gutter={[24, 6]}>
                  <Col span={8}>
                    <div className="mb-3">
                      <p className="fs-base font-weight-bold mb-2">Nama Peserta</p>
                      <p className="fs-base mb-0">{detailAutoDebitPembatalan.name || '-'}</p>
                    </div>
                    <div className="mb-3">
                      <p className="fs-base font-weight-bold mb-2">Nomor Rekening</p>
                      <p className="fs-base mb-0">{detailAutoDebitPembatalan.account_number || '-'}</p>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-3">
                      <p className="fs-base font-weight-bold mb-2">Member ID</p>
                      <p className="fs-base mb-0">{detailAutoDebitPembatalan.registered_number || '-'}</p>
                    </div>
                    <div className="mb-3">
                      <p className="fs-base font-weight-bold mb-2">Nomor Telephone</p>
                      <p className="fs-base ml-1 mb-0">{detailAutoDebitPembatalan.phone_number || '-'}</p>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="mb-3">
                      <p className="fs-base font-weight-bold mb-2">Status Pengajuan</p>
                      {detailAutoDebitPembatalan.status === 'approved' && <Tag color="green" className="px-2 py-1 text-capitalize">{detailAutoDebitPembatalan.status || '-'}</Tag>}
                      {detailAutoDebitPembatalan.status === 'in progress' && <Tag color="yellow" style={{ borderColor: '#fadb14', fontWeight: '500' }} className="px-2 py-1 text-capitalize">{detailAutoDebitPembatalan.status || '-'}</Tag>}
                      {detailAutoDebitPembatalan.status === 'failed' && <Tag color="red" className="px-2 py-1 text-capitalize">{detailAutoDebitPembatalan.status || '-'}</Tag>}
                      {detailAutoDebitPembatalan.status === 'rejected' && <Tag color="red" className="px-2 py-1 text-capitalize">{detailAutoDebitPembatalan.status || '-'}</Tag>}
                      {detailAutoDebitPembatalan.status === 'inquiry' && <Tag className="px-3 py-1 text-capitalize" style={{ borderColor: '#8f8f8f', color: '#8f8f8f', fontWeight: '500' }}>{detailAutoDebitPembatalan.status || '-'}</Tag>}
                    </div>
                    <div className="mb-3">
                      <p className="fs-base font-weight-bold mb-2">Alasan Admin</p>
                      <p className="fs-base mb-0">{detailAutoDebitPembatalan.admin_notes || '-'}</p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col span={13}>
              <Card className="shadow-sm">
                <h6 className="fs-base font-weight-bold mb-3">Data Iuran</h6>
                <Row gutter={[24, 6]}>
                  <Col span={10}>
                    <p className="fs-base">Frekuensi Iuran</p>
                    <p className="fs-base">Amount</p>
                    <p className="fs-base">Tanggal Pendebitan</p>
                    <p className="fs-base">Tanggal Pengajuan</p>
                    <p className="fs-base">Alasan Pembatalan</p>
                  </Col>
                  <Col span={10}>
                    <p className="fs-base">
                      <span>: </span>
                      {detailAutoDebitPembatalan.deposit_frequency || '-'}
                    </p>
                    <p className="fs-base">
                      <span>: </span>
                      {Helper.convertPrice(detailAutoDebitPembatalan.debit_amount) || '-'}
                    </p>
                    <p className="fs-base">
                      <span>: </span>
                      {detailAutoDebitPembatalan.debit_date || '-'}
                    </p>
                    <p className="fs-base">
                      <span>: </span>
                      {detailAutoDebitPembatalan.created_at || '-'}
                    </p>
                    <p className="fs-base">
                      <span>: </span>
                      {detailAutoDebitPembatalan.user_notes || '-'}
                    </p>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-autodebit-registration-cancellation-write') > -1) && (
          <div className="d-flex justify-content-start">
            {(detailAutoDebitPembatalan.status === 'inquiry') && (
            <React.Fragment>
              <Button onClick={handleApprove} loading={isApprove} disabled={isApprove} className="mr-3 text-white border-0 shadow" style={{ height: '35px', backgroundColor: '#21c198', fontWeight: '500' }}>Approve</Button>
              <Button onClick={() => toggleRejectModal()} className="text-white border-0 px-4 shadow" style={{ height: '35px', backgroundColor: '#ff0000', fontWeight: '500' }}>Reject</Button>
            </React.Fragment>
            )}
          </div>
          )}
          <div className="d-flex justify-content-start">
            {(detailAutoDebitPembatalan.status !== 'inquiry') && (
            <React.Fragment>
              <Button onClick={() => history.push('/auto-debet/pembatalan')} className="bg-transparent" style={{ height: '35px', color: '#5179c6', borderColor: '#5179c6' }}>
                Kembali
              </Button>
            </React.Fragment>
            )}
          </div>
          <Modal
            centered
            visible={rejectModal}
            footer={null}
            closable={false}
            onCancel={() => toggleRejectModal()}
          >
            <div className="text-left px-3">
              <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#f4232d" className="d-block text-left mb-3" style={{ fontSize: '45px' }} />
              <h6 className="text-capitalize mb-1" style={{ fontSize: '20px', color: '#444554' }}>Tulis Alasan Penolakan</h6>
              <p className="font-weight-light" style={{ fontSize: '16px', color: '#444554' }}>Sampaikan alasan penolakan ke nasabah</p>
              <Row>
                <Col className="mb-3">
                  <Input.TextArea onChange={handleChangeRejectNotes} placeholder="Pengajuan anda ditolak karena..." autoSize={{ minRows: 2 }} style={{ borderColor: '#d4dff6' }} />
                </Col>
                <Col>
                  <div className="d-flex justify-content-end">
                    <div className="mr-3">
                      <Button onClick={() => toggleRejectModal()} className="bg-transparent" style={{ height: '35px', color: '#5179c6', borderColor: '#5179c6' }}>Kembali</Button>
                    </div>
                    <div>
                      <Button type="primary" className="border-0" onClick={handleReject} loading={isSubmit} disabled={isSubmit} style={{ height: '35px', backgroundColor: rejectNotes === '' ? '#9d9da2' : '#1b4db3', borderColor: rejectNotes === '' ? '#9d9da2' : '#1b4db3' }}>
                        Tolak Perubahan
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        </>
      )
      }
  </div>
)

AutoDebitPembatalanDetail.propTypes = {
  isFetching: PropTypes.bool,
  detailAutoDebitPembatalan: PropTypes.object,
  isSubmit: PropTypes.bool,
  isApprove: PropTypes.bool,
  rejectModal: PropTypes.bool,
  rejectNotes: PropTypes.string,
  handleReject: PropTypes.func,
  handleApprove: PropTypes.func,
  handleChangeRejectNotes: PropTypes.func,
  toggleRejectModal: PropTypes.func,
  currentUser: PropTypes.object,
}

export default AutoDebitPembatalanDetail
