import API from 'utils/API'
import {
  COMMISSION_CONFIG_REQUEST,
  COMMISSION_CONFIG_SUCCESS,
  COMMISSION_CONFIG_FAILURE,
} from 'constants/ActionTypes'
import { message } from 'antd'
import history from 'utils/history'

export const configRequest = () => ({
  type: COMMISSION_CONFIG_REQUEST,
})

export const configSuccess = (data, meta) => ({
  type: COMMISSION_CONFIG_SUCCESS,
  data,
  meta,
})

export const configFailure = errorMessage => ({
  type: COMMISSION_CONFIG_FAILURE,
  errorMessage,
})

export const fetchConfig = params => (
  (dispatch) => {
    dispatch(configRequest())
    const url = `/commission/configs${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(configSuccess(data, meta))
        } else {
          dispatch(configFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(configFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createConfig = payload => (
  () => new Promise((resolve, reject) => (
    API.post('/commission/configs', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success(data)
          history.push('/commission/config')
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updateConfig = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`/commission/configs/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          message.success('Config has been updated')
          history.push('/commission/config')
          resolve(data)
        } else {
          message.error(meta.message)
          reject(meta.message)
        }
      },
    ).catch((err) => {
      message.error(err.message)
      reject(err.message)
    })
  ))
)
