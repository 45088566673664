import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import PaymentConfigurationList from 'containers/pages/paymentConfiguration/List'
import PaymentConfigurationForm from 'containers/pages/paymentConfiguration/Form'

const PaymentConfiguration = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/payment-configuration') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-payment-channel-read') > -1) {
      return <PaymentConfigurationList currentUser={currentUser} location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/edit') || location.pathname.includes('/add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-payment-channel-write') > -1) {
      return <PaymentConfigurationForm currentUser={currentUser} match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

PaymentConfiguration.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default PaymentConfiguration
