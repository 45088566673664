import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Button,
  Input, Avatar, Upload,
  Radio, message,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormPakdeBenefit = ({
  detailBenefits, handleSubmit,
  form, isEdit, status,
  stateFile, handleUpload, isSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  const [fileUpload, setFileUpload] = React.useState(false)
  const handleSizeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Ukuran file melebihi maksimal. Mohon untuk upload file kembali dengan ukuran dibawah 2 MB')
      setFileUpload(true)
      return true
    }
    setFileUpload(false)
    return false
  }
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (fileUpload) {
              message.error('Ukuran file melebihi maksimal. Mohon untuk upload file kembali dengan ukuran dibawah 2 MB')
              return
            }
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Icon">
          {getFieldDecorator('icon', {
            rules: [{ required: !isEdit, message: 'Icon wajib diupload' }],
          })(
            <Upload
              name="icon"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={handleSizeUpload}
              onChange={e => handleUpload(e)}
              accept="image/jpg,image/jpeg,image/png"
              fileList={stateFile}
            >
              {stateFile ? (
                <Avatar src={stateFile} size={50} shape="square" />
              ) : (
                <div>
                  <div className="ant-upload-text">Upload</div>
                </div>
              )}
            </Upload>,
          )}
        </Form.Item>
        <Form.Item label="Title">
          {getFieldDecorator('title', {
            initialValue: detailBenefits.title,
            rules: [{ required: true, message: 'Title wajib diisi' }],
          })(
            <Input placeholder="Title" />,
          )}
        </Form.Item>

        <Form.Item label="Short Description">
          {getFieldDecorator('content', {
            initialValue: detailBenefits.content,
            rules: [{ required: true, message: 'Short Description wajib diisi' }],
          })(
            <Input placeholder="Short Description" />,
          )}
        </Form.Item>
        <Form.Item label="Status">
          {getFieldDecorator('active', {
            initialValue: status && status === true,
            rules: [{ required: true, message: 'Status wajib dipilih' }],
          })(
            <Radio.Group
              buttonStyle="solid"
              name="active"
            >
              <Radio.Button value>True</Radio.Button>
              <Radio.Button value={false}>False</Radio.Button>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/web-management/pak-d-benefits')} className="mr-2" style={{ display: 'inline-block' }}>Cancel</Button>
          <Button type="primary" loading={isSubmit} htmlType="submit">{!isEdit ? 'Add Benefit' : 'Submit Change'}</Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormPakdeBenefit.propTypes = {
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  status: PropTypes.bool,
  form: PropTypes.any,
  stateFile: PropTypes.any,
  detailBenefits: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleUpload: PropTypes.func,
}

export default Form.create({ name: 'pakdeBenefitForm' })(FormPakdeBenefit)
