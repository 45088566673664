import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import PakdePage from 'containers/pages/pakde/List'
import PakdeForm from 'containers/pages/pakde/Form'

const Pakde = ({
  currentUser, location, match,
}) => {
  if (location.pathname === '/content-management/pakde/list') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-content-managementajak-teman-read') > -1) {
      return <PakdePage location={location} match={match} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('edit') || location.pathname.includes('add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-content-managementajak-teman-write') > -1) {
      return <PakdeForm location={location} match={match} />
    }
    return <ForbiddenPage />
  }

  return ''
}

Pakde.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default Pakde
