import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Avatar, Row, Col, Skeleton,
} from 'antd'
import moment from 'moment'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'


const DetailCustRegisPage = ({
  detailCustomer, isFetching,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }

  return (
    <div className="pb-5">
      {isFetching
        ? [1, 2, 3].map(index => (
          <Card key={index} className="mb-3">
            <div>
              <Skeleton active />
            </div>
          </Card>
        ))
        : (
          <React.Fragment>
            <Card className="mb-3">
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-4">Customer Detail</h5>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Name:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.core_input ? detailCustomer.core_input.NamaLengkap : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">User Id:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.user_input ? detailCustomer.user_input.cer_number : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Core Identity Number:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.core_input ? detailCustomer.core_input.NoIdentitas : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">User Identity Number:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.user_input ? detailCustomer.user_input.user_id_number : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Date of Birth:</p>
                  <p style={{ fontSize: '16px' }}>
                    {detailCustomer.core_input ? detailCustomer.core_input.TempatLahir : '-'}
                    ,
                    {' '}
                    {detailCustomer.core_input ? moment(detailCustomer.core_input.TanggalLahir).format('DD MMMM YYYY') : '-'}
                  </p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Email:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.core_input ? detailCustomer.core_input.email : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Phone Number:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.user_input ? detailCustomer.user_input.user_phone_number : '-'}</p>
                </Col>
              </Row>
            </Card>
            <Card className="mb-3">
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-4">NPWP Detail</h5>
                </Col>
                <Col span={24} className="pb-3">
                  <p className="mr-3 mb-2 font-weight-bold">NPWP Photo:</p>
                  {detailCustomer.npwp_photo ? (
                    <SimpleReactLightbox>
                      <SRLWrapper options={lightBoxSetting}>
                        <Avatar shape="square" size={300} src={detailCustomer.npwp_photo} alt="NPWP Photo" style={{ cursor: 'pointer' }} />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  ) : (
                    <Avatar shape="square" size={300} src="/assets/default.jpg" alt="no img" />
                  )}
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">NPWP Number:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.core_input ? detailCustomer.core_input.NPWP : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">KTP Address:</p>
                  <p className="mb-1" style={{ fontSize: '16px' }}>{detailCustomer.core_input ? `${detailCustomer.core_input.AlamatTinggalBaris1},` : ''}</p>
                  <p className="mb-1" style={{ fontSize: '16px' }}>{detailCustomer.core_input ? `${detailCustomer.core_input.AlamatTinggalBaris2},` : ''}</p>
                  <p className="mb-1" style={{ fontSize: '16px' }}>{detailCustomer.core_input ? detailCustomer.core_input.AlamatTinggalBaris3 : ''}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Postal Code:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.core_input ? detailCustomer.core_input.AlamatTinggalKodePos : '-'}</p>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-4">Income Detail</h5>
                </Col>
                <Col span={24} className="pb-3">
                  <p className="mr-3 mb-2 font-weight-bold">Identity Photo:</p>
                  {detailCustomer.identity_photo ? (
                    <SimpleReactLightbox>
                      <SRLWrapper options={lightBoxSetting}>
                        <Avatar shape="square" size={300} src={detailCustomer.identity_photo} alt="Identity Photo" style={{ cursor: 'pointer' }} />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  ) : (
                    <Avatar shape="square" size={300} src="/assets/default.jpg" alt="no img" />
                  )}
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Identity:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.identity && detailCustomer.identity.name ? detailCustomer.identity.name : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Income:</p>
                  <p style={{ fontSize: '16px' }}>{detailCustomer.income && detailCustomer.income.name ? detailCustomer.income.name : '-'}</p>
                </Col>
                <Col span={12}>
                  <p className="mr-3 mb-2 font-weight-bold">Source Income:</p>
                  {detailCustomer.source_of_income ? (
                    (detailCustomer.source_of_income).map(item => (
                      <p key={item.id} className="mb-2" style={{ fontSize: '16px' }}>
                        -
                        {item.name || '-'}
                      </p>
                    ))
                  ) : (
                    '-'
                  )}
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        )
      }
    </div>
  )
}

DetailCustRegisPage.propTypes = {
  detailCustomer: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default DetailCustRegisPage
