import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, Upload, Avatar,
  Radio, Tooltip,
} from 'antd'
import CKEditor from 'ckeditor4-react'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 5,
      span: 12,
    },
  },
}

const FormAboutDplk = (props) => {
  const {
    // handleSubmit,
    form, isEdit, imageFile, imageThumbnail,
    handleUpload, handleChangeEditor, orderNumber,
    handleRadio, isSubmit, handleUploadThumbnail,
    /* detailData, */

    onChange, onSubmit, status, onChangeOrder,
  } = props

  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={onSubmit}
      >
        <Form.Item label="Thumbnail">
          <Upload
            name="thumbnail"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={() => false}
            onChange={e => handleUploadThumbnail(e)}
            accept="image/*"
            fileList={imageThumbnail}
          >
            {imageThumbnail ? (
              <Avatar src={imageThumbnail} size={250} shape="square" />
            ) : (
              <div>
                <div className="ant-upload-text">Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item label="Image">
          <Upload
            name="img"
            listType="picture-card"
            className="avatar-uploader banner-content"
            showUploadList={false}
            beforeUpload={() => false}
            onChange={e => handleUpload(e)}
            accept="image/*"
            fileList={imageFile}
          >
            {imageFile ? (
              <Avatar src={imageFile} size={250} shape="square" className="banner-preview" />
            ) : (
              <div>
                <div className="ant-upload-text">Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item label="Title">
          <Input name="title" type="text" placeholder="Input Title" value={form.title || ''} onChange={onChange} />
        </Form.Item>
        <Form.Item label="Short Description">
          <Input.TextArea name="short_description" type="text" maxLength={255} rows={4} placeholder="Input Description (max 255 char)" value={form.short_description} onChange={onChange} />
        </Form.Item>
        {isEdit ? (
          <Form.Item label="Content">
            {form.content
              ? (
                <CKEditor
                  name="content"
                  data={form.content}
                  config={{
                    extraPlugins: 'justify,font,colorbutton,autogrow,liststyle',
                    toolbarGroups: [
                      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                      {
                        name: 'paragraph',
                        groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                      },
                      { name: 'links' },
                      { name: 'insert' },
                      '/',
                      { name: 'styles' }, // 'font and fontsize' -> 'font' plugin
                      { name: 'colors' }, // 'colors' -> 'colorbutton' plugin
                      { name: 'tools' },
                      { name: 'others' },
                      { name: 'about' },
                    ],
                    stylesSet: [
                      { name: 'Italic Title', element: 'h2', styles: { 'font-style': 'italic' } },
                      { name: 'Special Container', element: 'div', styles: { background: '#eeeeee', border: '1px solid #cccccc', padding: '5px 10px' } },
                      { name: 'Marker: Yellow', element: 'span', styles: { 'background-color': 'Yellow' } },
                      { name: 'Marker: Blue', element: 'span', styles: { 'background-color': 'Blue', color: '#fff' } },
                      { name: 'Marker: Red', element: 'span', styles: { 'background-color': 'Red', color: '#fff' } },
                      { name: 'Img: Auto', element: 'img', styles: { width: '100% !important', height: 'auto !important' } },
                    ],
                    removeButtons: 'Anchor,Table',
                  }}
                  onChange={e => handleChangeEditor(e.editor.getData())}
                />
              )
              : <p>Loading editor...</p>
            }
          </Form.Item>
        ) : (
          <Form.Item label="Content">
            <CKEditor
              name="content"
              data={form.content}
              config={{
                extraPlugins: 'justify,font,colorbutton,autogrow,liststyle',
                toolbarGroups: [
                  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                  {
                    name: 'paragraph',
                    groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                  },
                  { name: 'links' },
                  { name: 'insert' },
                  '/',
                  { name: 'styles' }, // 'font and fontsize' -> 'font' plugin
                  { name: 'colors' }, // 'colors' -> 'colorbutton' plugin
                  { name: 'tools' },
                  { name: 'others' },
                  { name: 'about' },
                  { name: 'document', groups: ['mode', 'document', 'doctools'] },
                ],
                stylesSet: [
                  { name: 'Italic Title', element: 'h2', styles: { 'font-style': 'italic' } },
                  { name: 'Special Container', element: 'div', styles: { background: '#eeeeee', border: '1px solid #cccccc', padding: '5px 10px' } },
                  { name: 'Marker: Yellow', element: 'span', styles: { 'background-color': 'Yellow' } },
                  { name: 'Marker: Blue', element: 'span', styles: { 'background-color': 'Blue', color: '#fff' } },
                  { name: 'Marker: Red', element: 'span', styles: { 'background-color': 'Red', color: '#fff' } },
                  { name: 'Img: Auto', element: 'img', styles: { width: '100% !important', height: 'auto !important' } },
                ],
                removeButtons: 'Anchor,Table',
              }}
              onChange={e => handleChangeEditor(e.editor.getData())}
            />
          </Form.Item>
        )}
        <Form.Item label="Order">
          <Tooltip placement="topLeft" title="Maksimal 10">
            <Input name="order" type="number" placeholder="Input Order" value={orderNumber || ''} onChange={e => onChangeOrder(e.target.value)} />
          </Tooltip>
        </Form.Item>
        <Form.Item label="Form Type">
          <Radio.Group
            buttonStyle="solid"
            name="status"
            onChange={(e) => {
              handleRadio(e)
            }}
            value={status}
          >
            <Radio.Button value="active">Active</Radio.Button>
            <Radio.Button value="inactive">Inactive</Radio.Button>
          </Radio.Group>
          ,
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button onClick={() => history.push('/about-dplk')} className="mr-2">Cancel</Button>
          <Button
            loading={isSubmit}
            type="primary"
            htmlType="submit"
          >
            {!isEdit ? 'Add Data' : 'Submit Change'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormAboutDplk.propTypes = {
  onChange: PropTypes.func,
  // handleSubthumbnailmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  handleUpload: PropTypes.func,
  handleChangeEditor: PropTypes.func,
  handleRadio: PropTypes.func,
  isSubmit: PropTypes.bool,
  imageFile: PropTypes.string,
  imageThumbnail: PropTypes.string,
  onSubmit: PropTypes.func,
  handleUploadThumbnail: PropTypes.func,
  onChangeOrder: PropTypes.func,
  orderNumber: PropTypes.any,
  status: PropTypes.any,
}

export default Form.create({ name: 'aboutDplkForm' })(FormAboutDplk)
