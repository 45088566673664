import PropTypes from 'prop-types'
import { Table, Icon } from 'antd'
import history from 'utils/history'

const CalcManagement = ({
  isFetching, dataCalcManagement,
}) => {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Investment Type',
      dataIndex: 'investment_type',
      key: 'investment_type',
      render: text => text.name || '-',
    },
    {
      title: 'Asumsi Suku Bunga',
      dataIndex: 'asumsi_nilai_suku_bunga',
      key: 'asumsi_nilai_suku_bunga',
      width: 200,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      width: 80,
      fixed: 'right',
      render: (text, record) => (
        <div className="d-flex justify-content-around">
          <Icon type="edit" onClick={() => history.push(`/calculator-management/asumsi-nilai-suku-bunga/${record.id}/edit`)} />
        </div>
      ),
    },
    /* {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    },
    {
      title: 'Updated at',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: text => (text ? moment(text).format('DD-MMM-YYYY, HH:mm') : '-'),
    }, */
  ]

  return (
    <div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataCalcManagement}
        loading={isFetching}
        scroll={{ x: 1300 }}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

CalcManagement.propTypes = {
  isFetching: PropTypes.bool,
  dataCalcManagement: PropTypes.array,
}

export default CalcManagement
