import PropTypes from 'prop-types'
import {
  Table, Button, Select,
  Input,
} from 'antd'
import history from 'utils/history'

const PakDeDownlinePage = ({
  dataPakD, statusClaim, statusIuran,
  handleChangeStatusClaim, handleChangeStatusIuran, handleSearch,
  handlePage,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      width: 100,
      render: (text, record, index) => (dataPakD.meta.per_page * (dataPakD.meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 180,
      render: text => text || '-',
    },
    {
      title: 'No Peserta',
      dataIndex: 'registered_number',
      key: 'registered_number',
      width: 120,
      render: text => text || '-',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 240,
      render: text => text || '-',
    },
    {
      title: 'No Telepon',
      dataIndex: 'phone_number',
      key: 'phone_number',
      width: 150,
      render: text => text || '-',
    },
    {
      title: 'Status Iuran',
      dataIndex: 'deposit_status',
      key: 'deposit_status',
      width: 140,
      render: text => (<span className="text-capitalize">{text || '-'}</span>),
    },
    {
      title: 'Status Klaim',
      dataIndex: 'claim_status',
      key: 'claim_status',
      width: 140,
      render: text => (<span className="text-capitalize">{text || '-'}</span>),
    },
    {
      title: 'Komisi',
      dataIndex: 'point_commission',
      key: 'point_commission',
      width: 200,
      render: text => (
        <span>
          {text || '-'}
          {' '}
          Poin
        </span>
      ),
    },
  ]
  return (
    <div className="pb-3">
      <div className="btn-primary-wrapper mb-5">
        <Button type="primary" icon="arrow-left" onClick={() => history.goBack()}>Go Back</Button>
      </div>
      <div className="pb-3">
        <div className="d-flex justify-content-start btn-primary-wrapper">
          <div>
            <Input.Search
              placeholder="Search"
              style={{ width: 260 }}
              className="mr-3"
              onSearch={handleSearch}
              allowClear
              enterButton
            />
          </div>
          <div className="d-flex align-items-center mr-2">
            <h6 className="mb-0 mr-2" style={{ fontSize: '14px' }}>Status Klaim :</h6>
            <Select
              placeholder="Status Klaim"
              defaultValue=""
              style={{ width: 160 }}
              onChange={handleChangeStatusClaim}
              allowClear
            >
              {statusClaim.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
            </Select>
          </div>
          <div className="d-flex align-items-center mr-2">
            <h6 className="mb-0 mr-2" style={{ fontSize: '14px' }}>Status Iuran :</h6>
            <Select
              placeholder="Status Iuran"
              defaultValue=""
              style={{ width: 160 }}
              onChange={handleChangeStatusIuran}
              allowClear
            >
              {statusIuran.map(item => <Select.Option key={item.id} value={item.value}>{item.label}</Select.Option>)}
            </Select>
          </div>
        </div>
      </div>
      <Table
        rowKey="member_id"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataPakD.data}
        loading={dataPakD.isFetching}
        scroll={{ x: 1200 }}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          pageSize: dataPakD.meta ? dataPakD.meta.per_page : 10,
          total: dataPakD.meta ? dataPakD.meta.total_count : dataPakD.data.length,
          current: dataPakD.meta ? dataPakD.meta.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

PakDeDownlinePage.propTypes = {
  dataPakD: PropTypes.object,
  statusClaim: PropTypes.array,
  statusIuran: PropTypes.array,
  handleChangeStatusClaim: PropTypes.func,
  handleChangeStatusIuran: PropTypes.func,
  handleSearch: PropTypes.func,
  handlePage: PropTypes.func,
}

export default PakDeDownlinePage
