import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchDetailUser, resendEmail, updateDateDetail } from 'actions/User'
import DetailUserView from 'components/pages/users/detail'
import { message } from 'antd'
import moment from 'moment'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailUser,
  } = state.root.user
  const { currentUser } = state.root.auth

  return {
    currentUser,
    isFetching,
    detailUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailUser: bindActionCreators(fetchDetailUser, dispatch),
  resendEmail: bindActionCreators(resendEmail, dispatch),
  updateDateDetail: bindActionCreators(updateDateDetail, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', {
    resendEmail: false,
    resendPin: false,
  }),
  withState('isSubmitEdit', 'setIsSubmitEdit', false),
  withState('isDisable', 'setIsDisable', false),
  withHandlers({
    handleResend: props => (params) => {
      props.resendEmail(params)
        .then(() => {
          message.success('Email was successfully resent')
          props.setIsSubmit({
            resendEmail: false,
            resendPin: false,
          })
        })
        .catch(() => {
          props.setIsSubmit({
            resendEmail: false,
            resendPin: false,
          })
        })
    },
  }),
  withHandlers({
    handleChangeEdit: props => () => {
      const { isEdit, setIsEdit } = props
      setIsEdit(!isEdit)
    },
    handleSubmit: props => (values) => {
      const {
        match, setIsSubmitEdit, setIsDisable, setIsEdit,
      } = props
      setIsSubmitEdit(true)
      const payload = {
        email: values.email || '',
        birthdate: moment(values.birthdate).format('YYYY-MM-DD') || '',
        birthplace: values.birthplace || '',
      }

      props.updateDateDetail(match.params.id, payload)
        .then(() => {
          setIsSubmitEdit(false)
          setIsDisable(false)
          setIsEdit(false)
          message.success('Data has been updated')
          history.push('/user')
        }).catch((err) => {
          setIsDisable(false)
          setIsEdit(false)
          setIsSubmitEdit(false)
          message.error(err)
        })
    },
    handleResendEmail: props => () => {
      props.setIsSubmit({ resendEmail: true })
      props.handleResend({
        event: 'approve-user-email',
        user_id: props.match.params.id,
      })
    },
    handleResendEmailPin: props => () => {
      props.setIsSubmit({ resendPin: true })
      props.handleResend({
        event: 'forgot-pin-email',
        user_id: props.match.params.id,
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Member Management', 'Detail'])
      this.props.updateSiteConfiguration('titlePage', 'Detail Member')
      if (match.params.id) {
        this.props.fetchDetailUser(match.params.id)
      }
    },
  }),
)(DetailUserView)
