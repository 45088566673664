import PropTypes from 'prop-types'
import { ForbiddenPage } from 'components/elements'
import List from 'containers/pages/elearnings/contents/List'
import Form from 'containers/pages/elearnings/contents/Form'

const LearningContent = ({ location, match, currentUser }) => {
  if (location.pathname === '/elearning/content') {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-e-toolse-learning-content-read') > -1) {
      return <List location={location} />
    }
    return <ForbiddenPage />
  }
  if (location.pathname.includes('/edit') || location.pathname.includes('/add')) {
    if (currentUser.permissions && currentUser.permissions.indexOf('manage-e-toolse-learning-content-write') > -1) {
      return <Form match={match} location={location} />
    }
    return <ForbiddenPage />
  }
  return ''
}

LearningContent.propTypes = {
  currentUser: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
}

export default LearningContent
